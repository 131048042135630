
import './App.css';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { React, useEffect, useMemo, useState } from "react";
import Registration from './components/Registration.component';
import PumpDetails from './components/pump/PumpDetails.component';
import Subscription from './components/Subscription.component';
import Login from './components/Login.component.jsx';
import FuelConfig from './components/fuel/FuelConfig.component';
import CreditDebit from './components/transaction/CreditDebit.component';
import PrivateRoute from './PrivateRoute';
import TankConfig from './components/fuel/TankConfig.component'
import Logout from './components/Logout';
import Forgotpassworduser from './components/Forgotpassworduser.component';
import ForgotUserName from './components/ForgotUserName.component';
import SuccessUsername from './components/SuccessUsername.component';
import Successpassword from './components/Successpassword.component';
import Setnewpassworduser from './components/Setnewpassworduser.component';
import TargetConfig from './components/pump/TargetConfig.component';
import RackConfig1 from './components/rack/RackConfig1.component'
import Product from './components/product/Product.component'
import UsersList from './components/users/UsersList.component'
import Settings from './components/settings/Settings.Component'
import fuelConfigReport from './components/report/fuelConfigReport.component';
import NonfuelConfigReport from './components/report/NonfuelConfigReport.component'
import NozzleConfig from './components/Nozzleconfig.component'
import ProductRack from './components/rack/ProductRack.component'
import RackProductList from './components/rack/RackProductList.component'
import DigitalPayment from './components/transaction/DigitalPayment.component'
import Customer from './components/customers/Customer.component'
import BankOpeningBalance from './components/bank/BankOpeningBalance.component'
import FleetCardConfig from './components/fleetcard/FleetCardConfig.component'
import MlaCoupon from './components/mlacoupon/MlaCoupon.component'
import EmployeeOpeningBalance from './components/employee/EmployeeOpeningBalance.component'
import VendorConfig from './components/vendor/VendorConfig.component'
import DealershipConfig from './components/dealership/DealershipConfig.component'
import OpenBalance from './components/transaction/OpenBalance.component'
import OwnTankerConfig from './components/tanker/OwnTankerConfig.component'
import TankerConfig from './components/tanker/TankerConfig.component'
import TankerExpConfig from './components/tanker/TankerExpConfig.component'
import MachineriesConfig from './components/assets/MachineriesConfig.component'
import OtherIncome from './components/transaction/OtherIncome.component'
import ExpensesConfig from './components/transaction/ExpensesConfig.component'
import TaskConfig from './components/transaction/TaskReminders.component';
import FuelUnloading from './components/fuel/FuelUnloading.component';
import FuelConfig2 from './components/fuel/FuelConfig2.component';
import BalanceSheet from './components/balance-sheet/BalanceSheet.component'
import BalanceSheet1 from './components/balance-sheet/balancesheet1.component'
import NozzleAlloEmplist from './components/operational-model/Employee/nozzle/NozzleAlloEmplist.component'
import NozzleAllocation from './components/operational-model/Employee/nozzle/NozzleAllocation.component'
import NozzleAllocation2 from './components/operational-model/Employee/nozzle/NozzleAllocation2.component.jsx'
import NozzleAlloEmplist2 from './components/operational-model/Employee/nozzle/NozzleAlloEmplist2.component.jsx'
import PackedOilConfig from './components/operational-model/Employee/PackedOil/PackedOilConfig.component'
import LooseOilConfig from './components/operational-model/Employee/LooseOil/LooseOilConfig.component'
import CreditSalesConfig from './components/operational-model/Employee/CreditSales/CreditSalesConfig.component'
import MlaCouponConfig from './components/operational-model/Employee/MlaCoupon/MlaCouponConfig.component'
import FleetCardSalesConfig from './components/operational-model/Employee/FleetCardSales/FleetCardSalesConfig.component'
import CardSalesConfig from './components/operational-model/Employee/DigitalPayement/CardSalesConfig.component'
import BagCollectionConfig from './components/operational-model/Employee/BagCollection/BagCollectionConfig.component'
import ExpenseConfig from './components/operational-model/Employee/Expense/ExpenseConfig.component';
import ReceiptVoucherConfig from './components/operational-model/Employee/ReceiptVoucher/ReceiptVoucherConfig.component';
import  CashPaymentConfig from './components/operational-model/Employee/CashPayment/CashPaymentConfig.component';
import  ClosePaymentConfig from './components/operational-model/Employee/ClosePayment/ClosePaymentConfig.component';
import OperationalDate from './components/operational-model/OperationalDate.component';
import OperationalDates from './components/operational-model/OperationalDates.component';
import ReceiptConfig from './components/operational-model/Manager/Receipt/ReceiptConfig.component';
import PaymentVoucherConfig from './components/operational-model/Manager/PaymentVoucher/PaymentVoucherConfig.component';
import NonFuelProductConfig from './components/operational-model/Manager/NonFuelProduct/NonFuelProductConfig.component';
import Productsconfig from './components/product/Productsconfig.component.jsx';
import { GasCylinderSalesConfig } from './components/product/GasCylinderSalesConfig.jsx';
import { GasCylinderSalesEmployeeConfig } from './components/product/GasCylinderSalesEmployeeConfig.jsx'; 
import GasCylinderPurchaseConfig from './components/product/GasCylinderPurchaseConfig.jsx';
import NonFuelProductConfigEmployee from './components/operational-model/Manager/NonFuelProductemployee/NonFuelProductConfigEmployee.component.jsx';
import  NonFuelProductPurchaseConfig  from './components/operational-model/Manager/NonFuelProductPurchase/NonFuelProductPurchaseConfig.component';
import FuelSellPriceConfig from './components/operational-model/Manager/FuelSellPriceChange/FuelSellPriceChangeConfig.component';
import ProductTransferRackConfig from './components/operational-model/Manager/ProductTransferRack/ProductTransferRackConfig.component';
import  AccountToAccountConfig from './components/operational-model/Manager/AccountToAccountTransfer/AccountToAccountTransferConfig.component';
import  DayClosingOperationConfig from './components/operational-model/Manager/DayClosingOperation/DayClosingOperationConfig.component';
import  DayClosingScreenConfig from './components/operational-model/Manager/DayClosingScreen/DayClosingScreenConfig.component';
import  PurchaseReturnNonFuelConfig  from './components/operational-model/Manager/PurchaseReturnNonFuel/PurchaseReturnNonFuelConfig.component';
import  UserManagementConfig  from './components/operational-model/Manager/UserManagement/UserManagementConfig.component';
import  NotificationSettingConfig  from './components/operational-model/Manager/NotificationSetting/NotificationSettingConfig.component';
import  SubscriptionManagementConfig  from './components/operational-model/Manager/SubscriptionManagement/SubscriptionManagementConfig.component';
import  NonFuelProductSellingPriceChangeConfig  from './components/operational-model/Manager/NonFuelProductSellingPriceChange/NonFuelProductSellingPriceChangeConfig.component';
import   OperationalSettings  from './components/operational-model/operational-settings/OperationalSettings.component';
import LoginHeaderOperational from './components/operational-model/LoginHeaderOperational';
import AllNotification from './components/Notification/AllNotification.component';
import DsrConfig from './components/operational-model/Employee/DsrReport/DSRReportView.component';
import Roles from './components/permissions/RoleConfig.component';

import RecoupConfig from './components/operational-model/Employee/Recoup/RecoupConfig.component';
import FuelUnloadingConfig1 from './components/operational-model/Manager/Fuel-Unloading/FuelUnloadingConfig1.component'
import FuelUnloadingConfig from './components/operational-model/Manager/FuelPurchase/FuelUnloadingConfig1.component'
import FuelUnloadingConfig2 from './components/operational-model/Manager/Fuel-Unloading/FuelUnloadingConfig2.component'
import FuelUnloadingDensityConfig from './components/operational-model/Manager/Fuel-Unloading/FuelUnloadingDensityConfig.component'
import FuelUnConfig from './components/operational-model/Manager/Fuel-Un/FuelUnConfig.component'
import DensityAfterUnloadConfig from './components/operational-model/Manager/Fuel-Unloading/DensityAfterUnloadingConfig.component';
import DecantingMSConfig from './components/operational-model/Manager/Fuel-Unloading/DecantingMSConfig.component';
import DayClosingConfig from './components/operational-model/DayClosing/DayClosingConfig.component';
import BalanceSheetOP from  './components/operational-model/BalanceSheetOP.component';
import ProfitLossOP from  './components/operational-model/ProfitLossOP.component';
import SimplifiedBalanceSheet from  './components/operational-model/SimplifiedBalanceSheet.component'
import DailyTransactionSheet from  './components/operational-model/DailyTransactionSheet.component'
import EmployeeWorkSheet from './components/operational-model/Employee/EmployeeWorksheetComponent';
import DsrReportComponent from './components/operational-model/Employee/DsrReport/DsrReport.component';

import DensityConfig from './components/operational-model/Employee/DensityReport/DensityReportView.component';
import Density from './components/operational-model/Employee/DensityReport/DensityConfig.component';
// import  DailyTransactionSummary from './components/operational-model/DailyTransactionSummary.component'
import StatementOfAccount from './components/operational-model/StatementOfAccount.component';
import DashBoard from './components/operational-model/Employee/DashBoard.component';
import FuelEdit from './components/operational-model/OPFuelEdit/FuelEditComponent'
import TankEdit from './components/operational-model/OPFuelEdit/TankEdit.component'
import NozzleEdit from './components/operational-model/OPFuelEdit/NozzleEdit.component'
import paymentFailed from './components/Payment/PaymentFail'
import PaymentSuccess from './components/Payment/PaymentSuccess.component.jsx'
import AdminDashBoard from './components/operational-model/Admin/AdminDashBoard.component';
import SetPump from './components/operational-model/Admin/Pump/SetPump.component';
import Permision from './components/permissions/PermissionConfig.component';
import AdminRoles from './components/operational-model/Admin/Role/RoleConfig.component';
import AdminSubscriptionManagement from './components/operational-model/Admin/Subscription/AdminSubscriptionManagement.component';
import AdminUserManagementConfig from './components/operational-model/Admin/UserManagement/UserManagementConfig.component';
import PageNotFound from './components/PageNotFound.component';
import TankModalView from './components/operational-model/OPFuelEdit/TankModalViewComponent'
import getDetails from "./components/getDetails";
import { useHistory } from "react-router-dom";
import { API_URL } from "./constant/API_Settings";
import LooseOilComponent from './components/looseoil/LooseOilComponent';
import OilBarrelProductConfigEmployee from './components/operational-model/Manager/OilBarrelProduct/OilBarrelProductConfigEmployee.jsx';
import OilBarrelProductConfig from './components/operational-model/Manager/OilBarrelProduct/OilBarrelProductConfig.jsx';

export default function App() { 
	const history = useHistory()
  const permission_details = useMemo(() => {
    return getDetails();
  }, []);
  console.log(permission_details)
const [baseProps, setBaseProps] = useState({
    permission_details: {
      ...permission_details,
    },
  });
  const props = useMemo(() => {
    return {
      ...baseProps,   
      setBaseProps: setBaseProps,
    };
  }, [baseProps]);

  useEffect(() => {
    check_meta_date()
    }, []);

  function check_meta_date() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      }
    };
    fetch(`${API_URL}/check_meta_date`, requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        
        if(responseData.message == "Unauthenticated.")
        {
        localStorage.removeItem("AUTH_TOKEN")
        localStorage.removeItem("ROLE_ID")
        localStorage.clear()
        history.push("/login")
       
        }
        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }

  return (
    <Router>
    <Switch>
    <Route path="/" component={Login} redirect exact/>
    <Route path="/login" component={Login} redirect exact/>
    <Route path="/register" component={Registration} exact/>
    <Route path="/logout" component={Logout} exact/>
    <Route path="/forgotpassword" component={Forgotpassworduser} exact/>
    <Route path="/successforgot" component={Successpassword} exact/>
    <Route path="/set-new-password/:id" component={Setnewpassworduser} exact />
    <Route path="/forgotusername" component={ForgotUserName} exact />
    <Route path="/username" component={SuccessUsername} exact />
   
    
  
   {/* configurational Modules */}
   <PrivateRoute path="/config-report"  component={NonfuelConfigReport} exact />
   <PrivateRoute path="/config-report/:id"  component={NonfuelConfigReport} exact />
   <PrivateRoute path="/settings"  component={Settings } exact />
   <PrivateRoute path="/subscription"  component={Subscription} exact />
   <PrivateRoute path="/subscription/:id"  component={Subscription  } exact />
   <PrivateRoute path="/pump-details"  component={PumpDetails} exact />
   {/* <PrivateRoute path="/fuel-config"  component={() => <FuelConfig {...props} />} exact /> */}
   <PrivateRoute path="/fuel-config"  component={FuelConfig2 } exact />
   <PrivateRoute path="/nozzle-config"  component={NozzleConfig } exact />
   <PrivateRoute path="/tank-config"  component={TankConfig } exact  />
   <PrivateRoute path="/target-config"  component={TargetConfig } exact  />
   {/* <PrivateRoute path="/rack-config"  component={() => <RackConfig {...props} />} exact  /> */}
   <PrivateRoute path="/rack-config"  component={RackConfig1 } exact  />
   <PrivateRoute path="/rack-config/:id"  component={RackConfig1 } exact  />
   <PrivateRoute path="/products"  component={Product} exact  />
   <PrivateRoute path="/products/:id"  component={Product} exact  />
   <PrivateRoute path="/Productsconfig"  component={Productsconfig} exact  />
   <PrivateRoute path="/Productsconfig/:id"  component={Productsconfig} exact  />
   <PrivateRoute path="/gas_cylinder_sales"  component={GasCylinderSalesConfig} exact/>
   <PrivateRoute path="/gas_cylinder_sales_empoyee"  component={GasCylinderSalesEmployeeConfig} exact/>
   <PrivateRoute path="/gas_cylinder_purchase"  component={GasCylinderPurchaseConfig} exact/>
   <PrivateRoute path="/fuel-config-report"  component={fuelConfigReport} exact  />
   <PrivateRoute path="/fuel-config-report/:id"  component={fuelConfigReport} exact  />
   {/* <PrivateRoute path="/non-fuel-config-report"  component={() => <NonfuelConfigReport {...props} />} exact  /> */}
   <PrivateRoute path="/users/:id"  component={UsersList} exact  />
   <PrivateRoute path="/users"  component={UsersList} exact  />
   <PrivateRoute path="/product-rack"  component={ProductRack } exact  />
   <PrivateRoute path="/rack-product-list"  component={RackProductList } exact  />
   <PrivateRoute path="/creditor-debitor"  component={CreditDebit} exact  />
   <PrivateRoute path="/creditor-debitor/:id"  component={CreditDebit } exact  />
   <PrivateRoute path="/digital-payment"  component={DigitalPayment } exact  />
   <PrivateRoute path="/digital-payment/:id"  component={DigitalPayment} exact  />
   <PrivateRoute path="/customers/:id"  component={Customer } exact  />
   <PrivateRoute path="/customers"  component={ Customer }  exact  />
   <PrivateRoute path="/bank-opening"  component={BankOpeningBalance } exact  />
   <PrivateRoute path="/bank-opening/:id"  component={BankOpeningBalance } exact  />
   <PrivateRoute path="/fleet-card-config"  component={FleetCardConfig } exact  />
   <PrivateRoute path="/fleet-card-config/:id"  component={FleetCardConfig} exact  />

   <PrivateRoute path="/loose-oil-config"  component={LooseOilComponent } exact  />
   <PrivateRoute path="/loose-oil-config/:id"  component={LooseOilComponent } exact  />


   <PrivateRoute path="/MLA-coupon"  component={MlaCoupon } exact  />
   <PrivateRoute path="/MLA-coupon/:id"  component={MlaCoupon } exact  />
   {/* <PrivateRoute path="/employee"  component={() => <EmployeeOpeningBalance {...props} />} exact  /> */}
   <PrivateRoute path="/vendor-config/:id"  component={VendorConfig } exact  />
   <PrivateRoute path="/vendor-config"  component={VendorConfig } exact  />
   <PrivateRoute path="/dealership-config"  component={() => <DealershipConfig {...props} />} exact  />
   <PrivateRoute path="/open-balance"  component={() => <OpenBalance {...props} />} exact  />
   <PrivateRoute path="/owntanker-config"  component={OwnTankerConfig } exact  />
   <PrivateRoute path="/owntanker-config/:id"  component={OwnTankerConfig } exact  />
   <PrivateRoute path="/tanker-income"  component={TankerConfig } exact  />
   <PrivateRoute path="/tanker-income/:id"  component={TankerConfig } exact  />
   <PrivateRoute path="/tanker-expense"  component={TankerExpConfig } exact  />
   <PrivateRoute path="/tanker-expense/:id"  component={TankerExpConfig } exact  />
   {/* <PrivateRoute path="/tanker"  component={() => <TankerConfig {...props} />} exact  /> */}
    <PrivateRoute path="/assets"  component={MachineriesConfig  } exact  />
   <PrivateRoute path="/assets/:id"  component={MachineriesConfig } exact  /> 
   <PrivateRoute path="/other-income"  component={OtherIncome } exact  />
   <PrivateRoute path="/other-income/:id"  component={OtherIncome } exact  />
   <PrivateRoute path="/expenses"  component={ExpensesConfig } exact  />
   <PrivateRoute path="/expenses/:id"  component={ExpensesConfig } exact  />
   <PrivateRoute path="/task-config"  component={TaskConfig} exact  />
   <PrivateRoute path="/task-config/:id"  component={TaskConfig} exact  />
   <PrivateRoute path="/fuel-unloading"  component={() => <FuelUnloading {...props} />} exact  />
   <PrivateRoute path="/cbalance-sheet"  component={BalanceSheet} exact/>
   <PrivateRoute path="/cbalance-sheet/:id"  component={BalanceSheet} exact/>
   <PrivateRoute path="/balance-sheet"  component={() => <BalanceSheet1 {...props} />} exact  />
   <PrivateRoute path="/permission"  component={() => <Permision {...props} />} exact  />
   <PrivateRoute path="/role-list"  component={() => <Roles {...props} />} exact  />

   {/* operational modules */}
   <PrivateRoute path="/operational-settings" component={() => <OperationalSettings {...props} />} exact />
   <PrivateRoute path="/login-header-operational" component={() => < LoginHeaderOperational {...props} />} exact />
   <PrivateRoute path='/operational-date' component={() => <OperationalDate {...props} />}/>
   <PrivateRoute path='/operational-dates' component={() => <OperationalDates {...props} />}/>
   <PrivateRoute path="/nozzle-employee-list" component={() => <NozzleAlloEmplist {...props} />} exact />
   <PrivateRoute path="/nozzle-closing" component={() => <NozzleAllocation {...props} />} exact />
   {/* <PrivateRoute path="/nozzle-employee-list" component={() => <NozzleAlloEmplist2 {...props} />} exact /> */}
   <PrivateRoute path="/newnozzle-closing" component={() => <NozzleAllocation2 {...props} />} exact />
   {/* <PrivateRoute path="/packed-oil-list" component={() => <PackedOilConfig {...props} />} exact /> */}
   {/* <PrivateRoute path="/loose-oil-list" component={() => <LooseOilConfig {...props} />} exact /> */}
   <PrivateRoute path="/credit-sales" component={() => <CreditSalesConfig {...props} />} exact />
   <PrivateRoute path="/mla-coupon-sales" component={() => <MlaCouponConfig {...props} />} exact />
   <PrivateRoute path="/fleet-card-sales" component={() => <FleetCardSalesConfig {...props} />} exact />
   <PrivateRoute path="/card-sales" component={() => <CardSalesConfig {...props} />} exact />
   <PrivateRoute path="/bag-collection" component={() => <BagCollectionConfig {...props} />} exact />
   <PrivateRoute path="/ReceiptVoucher" component={() => < ReceiptVoucherConfig  {...props} />} exact />
   <PrivateRoute path="/expense" component={() => <ExpenseConfig {...props} />} exact />
   <PrivateRoute path="/cashpayment" component={() => < CashPaymentConfig {...props} />} exact />
   <PrivateRoute path="/closepayment" component={() => < ClosePaymentConfig {...props} />} exact />
   <PrivateRoute path="/recoup" component={() => <RecoupConfig {...props} />} exact />

   {/* Manger module */}
   <PrivateRoute path="/receipt" component={() => < ReceiptConfig {...props} />} exact />
   <PrivateRoute path="/payment" component={() => < PaymentVoucherConfig {...props} />} exact />
   <PrivateRoute path="/nonfuel-product-direct" component={() => < NonFuelProductConfig {...props} />} exact />
   <PrivateRoute path="/nonfuel-product-direct-employee" component={() => < NonFuelProductConfigEmployee {...props} />} exact />

   <PrivateRoute path="/oil-barrel-sales" component={() => < OilBarrelProductConfig {...props} />} exact />
   <PrivateRoute path="/oil-barrel-sales-employee" component={() => < OilBarrelProductConfigEmployee {...props} />} exact />
   <PrivateRoute path="/fuel-sell-price" component={() => < FuelSellPriceConfig {...props} />} exact />
   <PrivateRoute path="/nonfuel-product-purchase" component={() => <NonFuelProductPurchaseConfig {...props} />} exact />
   <PrivateRoute path="/product-transfer-rack" component={() => <ProductTransferRackConfig {...props} />} exact /> 
   <PrivateRoute path="/account-to-account-transfer" component={() => < AccountToAccountConfig {...props} />} exact /> 
   <PrivateRoute path="/day-closing-operation" component={() => <  DayClosingOperationConfig {...props} />} exact /> 
   <PrivateRoute path="/day-closing-screen" component={() => <  DayClosingScreenConfig {...props} />} exact /> 
   <PrivateRoute path="/purchase-return-non-fuel" component={() => <  PurchaseReturnNonFuelConfig  {...props} />} exact /> 
   <PrivateRoute path="/subscription-management" component={() => <  SubscriptionManagementConfig  {...props} />} exact /> 
   <PrivateRoute path="/user-management-op" component={() => <  UserManagementConfig  {...props} />} exact /> 
   <PrivateRoute path="/user-management-op/:id" component={() => <  UserManagementConfig  {...props} />} exact />
   <PrivateRoute path="/notification-setting" component={() => <  NotificationSettingConfig  {...props} />} exact /> 
   <PrivateRoute path="/NonFuel-product-Selling-Price-Change" component={() => < NonFuelProductSellingPriceChangeConfig   {...props} />} exact /> 
    {/* Density and DSR */}
   <PrivateRoute path="/density" component={() => <DensityConfig {...props} />} exact /> 
   <PrivateRoute path="/op-density-report" component={() => <Density {...props} />} exact /> 
   <PrivateRoute path="/dsr" component={() => <DsrConfig {...props} />}  exact /> 
  {/* {fuel_unloding} */}
  {/* <PrivateRoute path="/op-fuel-unloading" component={() => <FuelUnloadingConfig1 {...props} />}exact /> */}
  <PrivateRoute path="/op-fuelunloading" component={() => <FuelUnloadingConfig {...props} />}exact /> 
  <PrivateRoute path="/fuel-unloading-2" component={() => <FuelUnloadingConfig2 {...props} />} exact />
  <PrivateRoute path="/decanting-ms" component={() => <DecantingMSConfig {...props} />}  exact /> 
  <PrivateRoute path="/fuel-unloading-density" component={() => <FuelUnloadingDensityConfig {...props} />}  exact />  
  <PrivateRoute path="/density-after-unloading" component={() => <DensityAfterUnloadConfig {...props} />}  exact /> 
  <PrivateRoute path="/fuel-unload" component={() => <FuelUnConfig {...props} />}  exact /> 
  <PrivateRoute path="/day-closing" component={DayClosingConfig }  exact /> 

  {/* Blance Sheet _operational-model  ---(Athulya A(07/06/2021:2pm)*/}
  <PrivateRoute path="/op-balance-sheet"  component={() => <BalanceSheetOP {...props} />} exact /> 
  <PrivateRoute path="/op_profit_loss"  component={() => <ProfitLossOP {...props} />} exact /> 
  <PrivateRoute path="/op-simplified-balance-sheet"  component={() => <SimplifiedBalanceSheet {...props} />}exact /> 
  <PrivateRoute path="/daily-transaction-sheet"  component={() => <DailyTransactionSheet {...props} />} exact /> 
  {/* <PrivateRoute path="/daily-transaction-summary"  component={  DailyTransactionSummary} exact />  */} 
  <PrivateRoute path='/op-worksheet' component={() => <EmployeeWorkSheet {...props} />} exact/>
  <PrivateRoute path='/op-dsr-report' component={() => <DsrReportComponent {...props} />} exact/>
  <PrivateRoute path='/statement-of-account' component={() => <StatementOfAccount {...props} />} exact/>
  <PrivateRoute path='/dashboard' component={() => <DashBoard {...props} />}  exact />

   <PrivateRoute path='/op-fuel-edit' component={() => <FuelEdit {...props} />}/>
  <PrivateRoute path='/op-tank-edit' component={() => <TankEdit {...props} />}/>
  <PrivateRoute path='/tank-view' component={TankModalView}/>
  <PrivateRoute path='/op-nozzle-edit' component={() => <NozzleEdit {...props} />}/> 
  
  {/*Notification*/ }
  <PrivateRoute path='/all_notifications/:id' component={() => <AllNotification {...props} />}/>
  

  {/* payment response */}

  <PrivateRoute path="/payment-Failed" component={() => <paymentFailed {...props} />} /> 
  <PrivateRoute path="/payment-Success" component={() => <PaymentSuccess {...props} />} /> 


{  /**********super admin**********/}


<PrivateRoute path="/admin_dashboard" component={() => <AdminDashBoard {...props}/>} exact /> 
<PrivateRoute path="/pump_name" component={() => <SetPump {...props} />} exact />
   <PrivateRoute path="/admin-role-list" component={() => <AdminRoles {...props} />} exact />
<PrivateRoute path="/admin-subscription-management" component={() => <AdminSubscriptionManagement {...props}/>} exact />
   <PrivateRoute path="/admin-user-management" component={() => < AdminUserManagementConfig  {...props}/>} exact /> 

<Route component={PageNotFound} />

  
</Switch>
</Router>
  
  
  );
}



