import { useState,useEffect } from 'react'
import React from 'react'
import Sidebar_Progress from '../layouts/Operational_Sidebar_Progress.component'
import ManagerLoginHeader from '../operational-model/Manager/ManagerLoginHeader.component'
import moment from 'moment'
import swal from "sweetalert";
import { API_URL } from '../../constant/API_Settings'
import { selling_mode } from '../../constant/constant'
import Loader from '../operational-model/Employee/Loader.component';
import TabHeader from '../operational-model/Employee/TabHeader.component'
import SummarySheet from '../operational-model/Employee/SummarySheet.component'
import PaginationComponent from '../layouts/PaginationComponent'
export const GasCylinderSalesEmployeeConfig = (props) => {
  let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
  const [permission, setPermission] = useState([])

  
  const UseModal = () => {

  const [isShowing, setIsShowing] = useState(false);

      function toggle() {
          setIsShowing(!isShowing);
          getProducts();
          setErrors(''); 
          setInputValues({}); 
          settotal('');
          setProducts([''])

          
      }
  return {
    isShowing,
    toggle,
  }
};
  
  const { isShowing, toggle } = UseModal();
  const [dropdown,setDropdownData] = useState([]);
  const [summary, setSummary] = useState([])
  const[inputValues,setInputValues] = useState([]);
  const[inputValues1,setInputValues1] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [ishit, setishit] = useState(false);
  const [dropmrp,setmrp] = useState([]);
  const [total,settotal] = useState([]);
  const [productsdropdown,Setproductsdropdown] = useState([]);
  const [list_gas, setListgas] = useState([])
  const [Loading, setLoading] = useState(false);
  const [editisShowing, seteditisShowing] = useState(false);
  console.log(productsdropdown,"productsdropdownproductsdropdown")
  const [products, setProducts] = useState([{ id: '', productType: '', Qty: '', mrp: '' }]);
  const handleAddProduct = () => {
      setProducts([...products, { id: '', productType: '', Qty: '', mrp: '' }]);
    };

    const PaginationComp = (props) => (
      < PaginationComponent  {...props} />
  );

  const [pages, setpages] = useState(
  {
    current_page: 1,
    last_page: 0,
    total_pages: 0,
    pagination_item: [],
    total_pages: 0,
  })

  const handlePagination = (number) => {
  setpages({
    ...pages,
    current_page: number        //---pagination---//
  }
  )

  list_gas(number)
}
  
   

  //   const handleProductChange = (index, field, value) => {
  //     const newProducts = [...products];
  //     newProducts[index][field] = value;
  //     setProducts(newProducts);

      

  //     if (field === 'productType' || field === 'id') {
  //         getmrp(index);
          
         
  //     }
  //     calculatesubtotal();
  //   };


  const handleProductChange = (index, field, value) => {
      const newProducts = [...products];
  
      
      if (typeof newProducts[index] !== 'object' || newProducts[index] === null) {
          newProducts[index] = {};
      }
  
      newProducts[index][field] = value;
      setProducts(newProducts);
  
      if (field === 'productType' || field === 'id') {
          getmrp(index);
      }
  
      calculatesubtotal();
  };
  
    const handleEditModalOpen = (id) => {
      edit_gas_sales(id)
      seteditisShowing(true)
    }
    const handleEditModalclose = (id) => {
      
      seteditisShowing(false)
      
    }


const handleRemoveProduct = (index) => {
  const newProducts = products.filter((_, i) => i !== index);
  setProducts(newProducts);
  calculatesubtotal();
};
const [dropdownDatas, setDropdownDatas] = useState([])
function getPayments() {
  const requestOptions = {
      method: 'get',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
  };
  fetch(API_URL + "/get_digital_payment", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
          var datas = []
          
          
          let newData = [];
           newData = responseData.map(elem => ({
              value: elem.id,
              label: elem.method_name,
          }));

          setDropdownDatas(dropdownDatas => [
              ...newData
          ]);

          // responseData.forEach((elem) => {
          // 	setDropdownDatas((dropdownDatas) => [
          // 	  ...dropdownDatas,
          // 	  {
          // 		value: elem.id,
          // 		label: elem.method_name,
          // 	  },
          // 	]);
          //   });
      })
      .catch(e => {
          console.log(e);

      });
}
console.log(dropdownDatas,"paymentpaymentpayment")
const handleChange = (e) => {
  setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value
  });
  

  if (e.target.name === "sales_type" || e.target.value === "0") {
      setInputValues(prevValues => ({
          ...prevValues,
          customer_id: ""
      }));
  }

  if (e.target.value === "1") {
      getCustomer();
  }

  if (e.target.value === "2") {
      getPayments();
  }

  // if (e.target.name === "productType") {
  //     getmrp();
  // }
};

console.log(inputValues,"inputValues")

function getCustomer() {
  const requestOptions = {
      method: 'get',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"

      },
  };
  fetch(API_URL + "/get_customers", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
          var datas = []

          responseData.forEach(elem => {
              datas.push([elem.id, elem.name])
          })

          setDropdownData(datas);

          return responseData;
      })
      .catch(e => {
          console.log(e);

      });
}
console.log(products,"dropdowndropdowndropdowndropdown")

function getSummerySheet() {
  const requestOptions = {
    method: 'post',
    body: JSON.stringify({
      op_emp_id: localStorage.getItem('op_emp_id'),
      emp_id: localStorage.getItem('emp_id'),
      op_date: localStorage.getItem('op_date'),
    }),

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Accept": "application/json"

    },
  };
  fetch(API_URL + "/getSummerySheet", requestOptions)
    .then(response => { return response.json(); })
    .then(responseData => {

      setSummary(responseData)
      return responseData;
    })
    .catch(e => {
      console.log(e);

    });
}

function getProducts() {
  const requestOptions = {
      method: 'get',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"

      },
  };
  fetch(API_URL + "/gas_products", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
          var PRODUCTdatas = []

          responseData.data.forEach(elem1 => {
              PRODUCTdatas.push([elem1.id, elem1.product_name])
          })

          Setproductsdropdown(PRODUCTdatas);
         
         

          return responseData;
      })
      .catch(e => {
          console.log(e);

      });
}

function getmrp(index){
  const productdata=[...products]

  const requestOptions = {
      method: 'POST',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
          "Accept": "application/json"
      },
      body: JSON.stringify({
          selling_mode: productdata[index].productType, 
          id: productdata[index].id
      }),
  };
  fetch(API_URL + "/gas_price", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
          productdata[index]['mrp'] = responseData.data.mrp

          setProducts(productdata);
          calculatesubtotal();
        
      //   alert( productdata[index]['mrp'])

          return responseData;
      })
      .catch(e => {
          console.log(e);

      });
}

function calculatesubtotal() {
  let total_gasproduct = 0;

  products.map((pro, idx) => {
      let prod_lop = pro; 
      if(pro.productType){
          total_gasproduct = total_gasproduct + (pro.Qty * pro.mrp)
      }
      console.log(total_gasproduct, `qtyyyyyyy`);
     settotal(total_gasproduct)
  });


}


{console.log(inputValues,"inputValuesinputValues")}

const [errors, setErrors] = useState({sales_type:"",
                                     customer_id:"",
                                       digital_type:"",
                                       productid:"",
                                       productType:"",
                                       Qty:"",
                                  
                   
})

function validation(){
  let formIsValid = true;
  let errors =[]
  if ((inputValues.sales_type != 0 && inputValues.sales_type != 1 && inputValues.sales_type != 2)) {
        
    formIsValid = false;
    errors["sales_type"] = "Sales type is required"
} else {
    errors["sales_type"] = ""
}
if (inputValues.sales_type == 1 && !inputValues.customer_id) {
  formIsValid = false;
  errors["customer_id"] = "Customer Name is required"
} else {
  errors["customer_id"] = "";
}
if (inputValues.sales_type == 2 && !inputValues.digital_type) {
  formIsValid = false;
  errors["digital_type"] = "Select Payment mode"
} else {
  errors["digital_type"] = "";
}


  if (!Array.isArray(products) || products.length === 0) {
      formIsValid = false;
      errors["products"] = "At least one product is required";
  } else {
      errors["products"] = "";
      products.forEach((product, index) => {
          if (!product.id) {
              formIsValid = false;
              errors[`product_${index}_id`] = "Select Product";
          } else {
              errors[`product_${index}_id`] = "";
          }
          if (!product.productType) {
              formIsValid = false;
              errors[`product_${index}_productType`] = "Select Product Type";
          } else {
              errors[`product_${index}_productType`] = "";
          }
          if (!product.Qty) {
              formIsValid = false;
              errors[`product_${index}_Qty`] = "Enter Quantity";
          } else {
              errors[`product_${index}_Qty`] = "";
          }
      });
  }



  setErrors(errors)
  return formIsValid;
  
}

const [isSubmitting, setIsSubmitting] = useState(false);


const handleSubmit = () => {

  if(validation())
{ 
  saveGas();
 
}  
}


function saveGas() {
  const requestOptions = {
      method: 'POST',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"

      },
      body: JSON.stringify({
         products_data:products,
         sales_type:inputValues.sales_type,
         customer_id:inputValues.customer_id,
         note:inputValues.note,
         digital_payment:inputValues.digital_type,
         date: document.getElementById("m_op_date").value,
         TotalAmount:total,
         op_emp_id: localStorage.getItem('op_emp_id'),
				 emp_id: localStorage.getItem('emp_id'),
      }),
  };

  fetch(API_URL + "/cylinder_save", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
  //     if (responseData.status == true) {alert(2)
  //     swal("", responseData.message, responseData.status ? "success" : "warning")
  //     toggle();
  //     gas_list();
  // }
  // if (responseData.status == false) { 
  //     alert(1)
  //  swal("",  responseData.message, responseData.status ? "success" : "warning") 
  // }
  if (responseData.status === true) {
      
      swal("", responseData.message, "success");
      toggle()
      gas_list(pages.current_page);
  } else if (responseData.status === false) {
    let newErrors = {};
    for (const [key, value] of Object.entries(responseData.message)) {
        newErrors[key] = value;
    }
    setErrors(prevErrors => ({
        ...prevErrors,
        ...newErrors
    }));
    swal("", responseData.message[Object.keys(responseData.message)[0]], "warning");
}

  getSummerySheet()
      
  
 
      })
      .catch(e => {
          console.log(e);

      });
}



{console.log(inputValues,"inputnnn")}

function gas_list() {
  const requestOptions = {
      method: 'post',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
      body: JSON.stringify({
          pathurl: window.location.pathname,
          op_emp_id: localStorage.getItem('op_emp_id'),
          emp_id: localStorage.getItem('emp_id'),
          op_date: localStorage.getItem('op_date'),

      }),
  };

  fetch(`${API_URL}/cylinder_sales`, requestOptions)
      .then(response => { return response.json(); })
      

      .then(responseData => {
          setListgas(responseData.cylinder_listing)
          setLoading(true);
          getSummerySheet()
          return responseData;
      })
      .catch(e => {
          console.log(e)
      });

}

console.log(productsdropdown,"productsdropdown")


function edit_gas_sales(id) {
  const requestOptions = {
      method: 'POST', 
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
          "Accept": "application/json"
      },
      body: JSON.stringify({
          pathurl: window.location.pathname,
          id: id 
      }),
  };

  fetch(`${API_URL}/cylinder_edit`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
          setInputValues(prevValues => ({
              ...prevValues,
              customer_id: responseData.data_list.customer_id,
              note: responseData.data_list.note,
              digital_type: responseData.data_list.digital_payment_id,
              sales_type: responseData.data_list.selling_mode,
              op_date: responseData.data_list.op_date,
              total: responseData.data_list.total_stock,
          }));
          
          console.log(inputValues, "setInputValues");
         
          

          

          let array =responseData.meta_products;
          let forms_array = [];
          let total_sale= [];
          {console.log(array,"inputValuesinputValuesinputValues")}
          array?.map((obj,index) =>{
              forms_array.push({
                  id:obj.product_id,
                  productType :obj.sale_type,
                  Qty :obj.qty,
                  mrp:obj.sales_price,
                  total:obj.sales_value,

                
              })
              total_sale.push({
                 
                  total:obj.sales_value,

                
              })
              
             

          
          })
          {console.log(forms_array,"forms_array")}
          setProducts(forms_array);
          settotal(total_sale)
          {console.log(total_sale,"salearray")}
          {console.log(total,"salearray1")}
          
          
         
          

          getProducts();
          getPayments();
          // getCustomer();
        

          return responseData;
      })
      .catch(e => {
          console.log(e);
      });
}
{console.log(products,"products11")}
useEffect(() => {
  gas_list();
  //  check_url()
  //  setLocation(location.location.pathname)
}, [''])

const [delete_id, deleteId] = useState([])

function handledeletegas(id){
  // alert(id)
  setIsDeleteOpen(true)
  deleteId(id)
  
}

function gas_delete() {
  const requestOptions = {
      method: 'post', 
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
          "Accept": "application/json"
      },
      body: JSON.stringify({
          
          id: delete_id
      }),
  };

  fetch(`${API_URL}/cylinder_delete`, requestOptions)
      .then(response => response.json())
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        handledeletegasclose();
        gas_list();
        getSummerySheet()
          return responseData;
      })
      .catch(e => {
          console.log(e);
      });
}



function handledeletegasclose( ){
  setIsDeleteOpen(false)
}

  
return (
  <>
  <div className="main-container page-wrap">
      <div className="d-md-flex">
        <Sidebar_Progress props={props} progress={20} operation={1} />
        <div className="content-area">
          <ManagerLoginHeader props={props} page_title="" />
          <div class="container pb-3 tab-view">
          <div class="row  mt-3 form-container">
          <div className="col-md-9">
            
<TabHeader progress={15} />


                <div className="row">

                </div>
              
              {permissions.includes("Customer Attendant") ?
                <div className="form-wrap mt-5">
                  <div className="text-center">
                    You don't have permission to access this page
                  </div></div> : permission.status == 3 ?
                  <div className="form-wrap mt-5">
                    <div className="text-center">
                      
                    </div></div> :
                  <>

                    <div >
                      
                      <div class="form-wrap ">
                                              {Loading ? gas_list : <Loader />}
                        <div class="tab-content" id="myTabContent">
                          <div class="tab-pane  fade show active" id="packedoil" role="tabpanel" aria-labelledby="packedoil-tab">
                            <div class="">
                              <div class="row mb-3">
                                <div class="col-md">
                                  <div class="row">

                                    <div class="col-md col pl-0">
                                      <h3 class="table-title">Gas Cylinder Sales</h3> <h4>{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
                                    </div>

                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <a className="btn btn-primary px-3 mb-md-0 mb-2" data-bs-target="#mla" onClick={toggle} > Gas Cylinder Product</a>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-group mb-3 search">
                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                    <input type="text" className="form-control" placeholder="Search  " value={''} onChange={''} />
                                    <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={''} >Submit</a>
                                  </div>
                                </div>
                              </div>


                              <div className="table-widget">
  <table className="table border">
      <thead>
          <tr>
              <th>Sales Type</th>
              <th>Customer</th>
              <th>Digital Type</th>
              <th>Description</th>
              <th>Operation Date</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
          {console.log(list_gas, "list_gas")}
          {
              list_gas.map((pro, proindex) => (
                  <React.Fragment key={proindex}>
                      {console.log(pro, "pro")}
                      {console.log(pro.m, "metas")}
                      {pro.m.map((submeta, submetaindex) => (
                          <tr key={submetaindex}>
                              {submetaindex === 0 && (
                                  <td rowSpan={pro.m.length}>
                                      {pro.cust_name === null 
                                          ? (pro.digital_payment !== null ? "Digital Payment" : "Cash Sales") 
                                          : "Credit Sales"}
                                  </td>
                              )}
                              {submetaindex === 0 && (
                                  <td rowSpan={pro.m.length}>
                                      {pro.cust_name === null ? "--" : pro.cust_name}
                                  </td>
                              )}
                              {submetaindex === 0 && (
                                  <td rowSpan={pro.m.length}>
                                      {pro.digital_payment === null ? "--" : pro.digital_payment}
                                  </td>
                              )}
                              {submetaindex === 0 && (
                                  <td rowSpan={pro.m.length}>
                                      {pro.note ? pro.note : null}
                                  </td>
                              )}
                              {submetaindex === 0 && (
                                  <td rowSpan={pro.m.length}>
                                      {moment(pro.op_date).format("DD-MM-YYYY")}
                                  </td>
                              )}
                              <td>{submeta.product_name}</td>
                              <td>{submeta.qty}</td>
                              <td>{submeta.sales_price}</td>
                              {submetaindex === 0 && (
                                  <td className="py-2" rowSpan={pro.m.length}>
                                      <a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => handleEditModalOpen(pro.id)}>
                                          <i className="fa fa-eye" aria-hidden="true"></i>
                                      </a>
                                      {pro.status < 4 && (
                                          <a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={() => handledeletegas(pro.id)}>
                                              <i className="fa fa-trash-o me-2" aria-hidden="true"></i>
                                          </a>
                                      )}
                                      {permissions.includes("Deny Approval") && (
                                          <></>
                                      )}
                                  </td>
                              )}
                          </tr>
                      ))}
                  </React.Fragment>
              ))
          }



      </tbody>
  </table>
  {Loading && (
      <PaginationComp
          pages={pages}
          handlePagination={handlePagination}
      />
  )}
</div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div></>}
                    </div>
                    <div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>
            </div>
            

          </div>
        </div>
      </div>
    </div>

{console.log(inputValues,"vvvvvvvvvvvvvvvvvvvv")}
{
 isShowing ?(
  <>
<div className="modal-overlay" />
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div className="modal  modalxl">
            <div className="modal-body">

              <div className="modal-header">
                <h5 className="modal-title">Add Gas Cylinder Products</h5>
                <button type="button" className="close" onClick={toggle}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form>
                      <div className="form-group">
                    <label>Sales Type:</label><sup class="star">*</sup>
                    <select className="form-control" name="sales_type" value={inputValues.sales_type} onChange={handleChange}>
                        <option value="">select</option>
                      <option value="0">Cash Sales</option>
                      <option value="1">Credit Sales</option>
                                              <option value="2">Digital Payment Sales</option>
                    </select>
                    <div className="errorMsg">{errors.sales_type}</div>
                  </div>
   {inputValues.sales_type == 0 || inputValues.sales_type == 1 ? (
    <div className="form-group">
      <label htmlFor="customerName">Customer Name:</label><sup class="star">*</sup>
      {/* <label className="red-symbol">*</label> */}
      <select className="form-control" id="customerName" name="customer_id" value={inputValues.customer_id} onChange={handleChange}>
     

        {inputValues.sales_type == 0 ? <option>Cash</option> : <option value="">Select</option>}
    {inputValues.sales_type == 1 &&

        dropdown.map((data) => {
       return <option value={data[0]}>{data[1]}</option>
  })
                      }
      </select>
      <div className="errorMsg">{errors.customer_id}</div>
    </div>
    ) : null}
    {inputValues.sales_type == 2 ? (
    <div className="form-group">
  <label htmlFor="customerName">Digital Payment Type:</label><sup class="star">*</sup>
  {/* <label className="red-symbol">*</label> */}
  
      <select
          className="form-control"
          id="digitaltype"
          name="digital_type"
          value={inputValues.digital_type}
          onChange={handleChange}
      >
          <option value="">Select</option>
          {dropdownDatas.map(item => (
              <option key={item.value} value={item.value}>
                  {item.label}
              </option>
          ))}
      </select>
      <div className="errorMsg">{errors.digital_type}</div>
</div>
) : null}

<div className="form-group">
              
                  <label htmlFor="customerName">Description</label>
               
                  <input type="text" class="form-control " name="note" value={inputValues.note} onChange={handleChange} />
                </div>
               
{console.log(dropdownDatas, "dropdownDatas")}

  
{console.log(products,"fyyffyfy")}
<div id="products">
  
    {products.map((product, index) => (
      <div className="product-item" key={index}>
        <h4>Product {index + 1}</h4>

        <div className="form-group">
          <label htmlFor={`productid${index}`}>Product Name:</label><sup class="star">*</sup>
          {/* <label className="red-symbol">*</label> */}
          <select
            className="form-control"
            id={`productId${index}`}
            name={`productid${index}`}
          //   value={productsdropdown.id}
          value={product.id}
            onChange={(e) => handleProductChange(index, 'id', e.target.value)}
          >
            <option value="">Select</option>
            {productsdropdown.map((product, index) => (
      <option key={index} value={product[0]}>
        {product[1]}
      </option>
    ))}
          </select>
          <div className="errorMsg">{errors[`product_${index}_id`]}</div>
        </div>

        <div className="form-group">
          <label htmlFor={`productType${index}`}>Sales from Type:</label><sup class="star">*</sup>
          {/* <label className="red-symbol">*</label> */}
          <select
            className="form-control"
            id={`productType${index}`}
            name={`productType${index}`}
            value={product.productType}
            onChange={(e) => handleProductChange(index, 'productType', e.target.value)}
          >
            <option value="">Select</option>
            <option value="1">Full cylinder</option>
            <option value="2">Empty to Full cylinder Exchange</option>
          </select>
          <div className="errorMsg">{errors[`product_${index}_productType`]}</div>
        </div>

        <div className="form-group">
          <label htmlFor={`Qty${index}`}>Quantity:</label><sup class="star">*</sup>
          <input
            type="text"
            className="form-control"
            id={`Qty${index}`}
            name={`Qty${index}`}
            value={product.Qty}
            onChange={(e) => handleProductChange(index, 'Qty', e.target.value)}
          />
          <div className="errorMsg">{errors[`product_${index}_Qty`]}</div>
        </div>

        <div className="form-group">
          <label htmlFor={`mrp${index}`}>MRP:</label><sup class="star">*</sup>
          {/* <label className="red-symbol">*</label> */}
          <input
            type="number"
            className="form-control"
            id={`mrp${index}`}
            name={`mrp${index}`}
            placeholder="Enter MRP"
            value={product.mrp}
            onChange={(e) => handleProductChange(index, 'mrp', e.target.value)}
          />
       
        </div>

        <button  type="button"
        className="btn btn-danger" onClick={() => handleRemoveProduct(index)}>
          Remove
        </button>
      </div>
    ))}
  
  </div>

    <div className="row mb-3">
<div className="col">
  <button
    type="button"
    className="btn btn-success "
    onClick={handleAddProduct}
  >
    + Add Product
  </button>
</div>

</div>

    {/* <div className="form-group">
      <label htmlFor="subtotal">Subtotal:</label>
      <input type="text" disabled className="form-control" id="subtotal" name="subtotal" />
    </div> */}

    <div className="form-group">
  <label htmlFor="operationalDate">Operational date:</label>
  <input
      disabled
      type="date"
      className="form-control"
      name="op_date"
      id="m_op_date"
      value={inputValues.op_date || localStorage.getItem("m_op_date") || ""}
      onChange={handleChange}
  />
</div>


    <div className="form-group">
      <label htmlFor="invoiceAmount">Invoice amount:</label>
      <input type="text" disabled className="form-control" name="total" id="invoiceAmount" value={total} />
    </div>

    
  </form>
              </div>

              <div class="modal-footer text-center">
                <button class="btn btn-danger w-auto" onClick={toggle}>Cancel</button>
                <button class="btn btn-primary  w-auto " type="submit" onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>
  </>
 ) : null
}

{
editisShowing ? (
<>
<div className="modal-overlay" />
        <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
          <div className="modal  modalxl">
            <div className="modal-body">

              <div className="modal-header">
                <h5 className="modal-title">Edit Gas Cylinder Products</h5>
                <button type="button" className="close" onClick={handleEditModalclose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body">
                <form>
                                  <div  className="form-group">
                    <label>Sales Type:</label>
                    <select disabled className="form-control" name="sales_type" value={inputValues.sales_type} onChange={handleChange}>
                        <option value="">select</option>
                      <option value="0">Cash Sales</option>
                      <option value="1">Credit Sales</option>
                                              <option value="2">Digital Payment Sales</option>
                    </select>
                    <div className="errorMsg">{errors.sales_type}</div>
                  </div>
   {inputValues.sales_type == 0 || inputValues.sales_type == 1 ? (
    <div  className="form-group">
      <label htmlFor="customerName">Customer Name:</label>
      <label className="red-symbol">*</label>
      <select disabled className="form-control" id="customerName" name="customer_id" value={inputValues.customer_id} onChange={handleChange}>
     

        {inputValues.sales_type == 0 ? <option>Cash</option> : <option value="">Select</option>}
    {inputValues.sales_type == 1 &&

        dropdown.map((data) => {
       return <option value={data[0]}>{data[1]}</option>
  })
                      }
      </select>
      <div className="errorMsg">{errors.customer_id}</div>
    </div>
    ) : null}
    {inputValues.sales_type == 2 ? (
    <div className="form-group">
  <label htmlFor="customerName">Digital Payment Type:</label>
  <label className="red-symbol">*</label>
  
  <select disabled
  className="form-control"
  id="digitaltype"
  name="digital_type"
  value={inputValues.digital_type}
  onChange={handleChange}
>
  <option value="">Select</option>
  {dropdownDatas.map(item => (
      <option key={item.value} value={item.value}>
          {item.label}
      </option>
  ))}
</select>

      <div className="errorMsg">{errors.digital_type}</div>
</div>
) : null}

<div className="form-group">
              
                  <label htmlFor="customerName">Description</label>
               
                  <input disabled type="text" class="form-control " name="note" value={inputValues.note} onChange={handleChange} />
                </div>
               
{console.log(dropdownDatas, "dropdownDatas")}

  
{console.log(products,"fyyffyfy")}
<div id="products">
  
    {products.map((product, index) => (
      <div className="product-item" key={index}>
        <h4>Product {index + 1}</h4>

        <div className="form-group">
          <label htmlFor={`productid${index}`}>Product Name:</label>
          <label className="red-symbol">*</label>
          <select disabled
            className="form-control"
            id={`productId${index}`}
            name={`productid${index}`}
            value={product.id}
            onChange={(e) => handleProductChange(index, 'id', e.target.value)}
          >
            <option value="">Select</option>
            {productsdropdown.map((product, index) => (
      <option key={index} value={product[0]}>
        {product[1]}
      </option>
    ))}
          </select>
          <div className="errorMsg">{errors[`product_${index}_id`]}</div>
        </div>

        <div className="form-group">
          <label htmlFor={`productType${index}`}>Sales from Type:</label>
          <label className="red-symbol">*</label>
          <select disabled
            className="form-control"
            id={`productType${index}`}
            name={`productType${index}`}
            value={product.productType}
            onChange={(e) => handleProductChange(index, 'productType', e.target.value)}
          >
            <option value="">Select</option>
            <option value="1">Full cylinder</option>
            <option value="2">Empty to Full cylinder Exchange</option>
          </select>
          <div className="errorMsg">{errors[`product_${index}_productType`]}</div>
        </div>

        <div className="form-group">
          <label htmlFor={`Qty${index}`}>Quantity:</label>
          <input disabled
            type="text"
            className="form-control"
            id={`Qty${index}`}
            name={`Qty${index}`}
            value={product.Qty}
            onChange={(e) => handleProductChange(index, 'Qty', e.target.value)}
          />
          <div className="errorMsg">{errors[`product_${index}_Qty`]}</div>
        </div>

        <div className="form-group">
          <label htmlFor={`mrp${index}`}>MRP:</label>
          <label className="red-symbol">*</label>
          <input disabled
            type="number"
            className="form-control"
            id={`mrp${index}`}
            name={`mrp${index}`}
            placeholder="Enter MRP"
            value={product.mrp}
            onChange={(e) => handleProductChange(index, 'mrp', e.target.value)}
          />
       
        </div>

        <button  disabled type="button"
        className="btn btn-danger" onClick={() => handleRemoveProduct(index)}>
          Remove
        </button>
      </div>
    ))}
  
  </div>

    <div className="row mb-3">
<div className="col">
  <button disabled
    type="button"
    className="btn btn-success "
    onClick={handleAddProduct}
  >
    + Add Product
  </button>
</div>

</div>


    <div className="form-group">
  <label htmlFor="operationalDate">Operational date:</label>
  <input
      disabled
      type="date"
      className="form-control"
      name="op_date"
      id="m_op_date"
      value={inputValues.op_date }
      onChange={handleChange}
  />
</div>


    <div className="form-group">
      <label htmlFor="invoiceAmount">Invoice amount:</label>
      <input type="text" disabled className="form-control" name="total" id="invoiceAmount" value={inputValues.total} />
    </div>

    
  </form>
              </div>

              <div hidden class="modal-footer text-center">
                <button class="btn btn-danger w-auto" onClick={toggle}>Cancel</button>
                <button class="btn btn-primary  w-auto " type="submit" onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        </div>


</>): null
}








{isDeleteOpen ? (
              <>
                  <div className="modal-overlay" />
                  <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal">
                          <div className="modal-header">
                              <h3 className="modal-title" id="staticBackdropLabel">Do you want to delete this product?</h3>
                              <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={handledeletegasclose}>
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                         
                              <div class="text-center py-3">
                                    <a class="btn btn-danger w-auto" onClick={handledeletegasclose}>Cancel</a>
                                    <a class="btn btn-primary w-auto" onClick={gas_delete}>Delete</a>
                                  </div> 
                       
                      </div>
                  </div>
              </>
          ) : null}

          </>
)


}
