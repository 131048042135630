import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
// import NozzleEmpModal from './NozzleAlloEmplist.component'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
// import NozzleEmpModal from './NozzleEmpModal.component';
// import NozzleEmpModal from './NozzleEmpModal2.component';
import DesignConfigModal from './Designdemo.component';
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
// import NozzleEmpModal from './NozzleEmpModal1.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Loader from '../Loader.component'
import { preventNonNumericalInput } from '../../../layouts/Operational_Sidebar_Progress.component';
import moment from 'moment';
const format = 'HH:mm '

const NozzleEmp = (props) => ReactDOM.createPortal(
	// <NozzleEmpModal  {...props} />, document.body 
<DesignConfigModal  {...props} />, document.body
);
const NozzleEmpConfig2 = (props) => {

	const [delete_id, deleteId] = useState([])
	const [summary, setSummary] = useState([])


	const [input, setInputs] = useState([])
	const [total, setTotal] = useState([])
	const [inputValues, setInputValues] = useState({ vehicle_no: [''] });
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [selling_price, setPrice] = useState({})
	const [product_id, setProduct] = useState({})
	const [opening_reading, setOpeningReading] = useState({})
	const [errors, setErrors] = useState({ vehicle_no: [''] })
	const [double, setDouble] = useState(true);
	const [permission, setPermission] = useState([])
	const [Values, setValues] = useState([])
	const [mulerror, setMulerror] = useState([]);

	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	const handleInput = (value) => {
		console.log(value,"endtime")
		setInputValues({
			...inputValues,
			end_from: value
		})
	}; 

	const handleInput1 = (i, e) => {

		var product_list = []

		product_list = [...Values]
		console.log(product_list,"default")
		product_list[i][e.target.name]=e.target.value;
		setValues(product_list)
        // console.log(Values,"updated")
		// console.log("name",e.target.name,"value",e.target.value,"index",i)
	};

	




	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {

		check_url()
	}, []);
	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/newnozzle-employee-list");
				}
				else {

					//setInputs({ ...input, op_date: responseData.op_date })
					ListNozzleAllocation(pages.current_page, localStorage.getItem('op_date'))
					getSummerySheet()

				}

				return responseData;
			})
			.catch(e => {
			});

	}
	function ListNozzleAllocation(page, date) {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}, body: JSON.stringify({
				op_date: date,
				n_emp_id: localStorage.getItem("op_emp_id"),
				emp_id: localStorage.getItem("emp_id"),
				search_keyword: search
			})
		};
		fetch(`${API_URL}/get_nozzle_allocation?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })
				}
				else {
					setList(responseData.data.data)
					setTotal({
						...total,
						totalamount: responseData.totalamt,
					})

					
					const mla = responseData.mla_coupon_status
					const fleet = responseData.fleet_card_status
					const shift_time = responseData.shift_time

					
					localStorage.setItem('mla_status', mla);
					localStorage.setItem('fleet_status', fleet);
					localStorage.setItem('shift_time', shift_time);

					var total_pages = responseData.data.last_page

					var items = []
					for (let number = 1; number <= total_pages; number++) {
						number === page ? items.push(
							<li key={number} className="page-item active"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,) : items.push(
								<li key={number} className="page-item"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,)
					}

					setpages({
						...pages,
						current_page: responseData.data.current_page,
						last_page: responseData.data.last_page,
						total_pages: responseData.data.last_page,
						pagination_items: items
					})
				}
				setLoading(true);
				return responseData;
			})
			.catch(e => {


			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListNozzleAllocation(number, localStorage.getItem('op_date'))
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			if (isShowing) {
				setInputValues({})
				setOpeningReading({})
				// setErrors({})
				setMulerror([])
				setFields([''])
			}
			if (!isShowing) {

				setErrors({})
				setDouble(true)
			}
			setIsShowing(!isShowing);
		}

		return {
			isShowing,
			toggle,
		}
	};
	const { isShowing, toggle } = UseModal();

	inputValues['product_id'] = product_id.product_id;
	inputValues['current_stock'] = inputValues.current_stock;
	inputValues['price'] = inputValues.price ? inputValues.price: selling_price.price;
	inputValues['end_from'] = inputValues.end_from;
	inputValues['sugest_end_from'] = inputValues.sugest_end_from;
	inputValues['nozzle_status'] = inputValues.nozzle_status;
	inputValues['nz_count'] = inputValues.nz_count;
	inputValues['nz_close_reading'] = inputValues.nz_close_reading;
	inputValues['start_from']=inputValues.start_from;



	const handleAmount =(i,e) =>{
		let NozzleAmount = []
		
		NozzleAmount =[...Values]
		console.log(Values,"mkmmmkmk")
		
		
		
		if(e.target){
			NozzleAmount[i][e.target.name]=e.target.value;
		

			if(e.target.name =='clossing_reading'){
				let sub = parseFloat(e.target.value) - parseFloat(NozzleAmount[i]["open_reading"])
				if(sub <0){
					sub=0;
				}
				if(sub >= 0){
					NozzleAmount[i]["quantity"]=Number(sub);
					let amount = sub * NozzleAmount[i]["price"];
					NozzleAmount[i]["amount"] = amount;
				}
				
	
				console.log(sub,"nini")
				console.log( NozzleAmount[i]["open_reading"],"okkkkkkkkkkkkkkkk")
				
	
	
    /*****new condition******/ 

	//  if(NozzleAmount[i]["nz_count" == "Yes"]){
	//  	let newopread=parseFloat(e.target.value)
	//  	NozzleAmount[i]["open_reading"] = parseFloat(newopread)
	//  }
console.log(NozzleAmount,"mainarray")
console.log(i,"index")
console.log(NozzleAmount[i]["nozzle_id"],"nozzle")

console.log(NozzleAmount[i]["id"],"njjnvcvbnj")

// let arr = NozzleAmount;
// let index = i;

// let currentElement = arr[index];
// console.log(currentElement,"currentElement")
// for (let j = index + 1; j < arr.length; j++) {
//   let nextElement = arr[j];
//   console.log(nextElement,"nextElement")
//   if (nextElement.nozzle_id === currentElement.nozzle_id && nextElement.id > currentElement.id) {
// 	alert(j);
// 	NozzleAmount[j]["open_reading"] = e.target.value;
// 	break;


//   }



			// }


			let array =NozzleAmount;
			console.log(array,"Array")

			let indexvalue =i;
			console.log(indexvalue,"indexvalue")

			let CurrentArrayValue =array[indexvalue];
			console.log(CurrentArrayValue,"CurrentArrayValue")


			for (let q = indexvalue+1; q < array.length;q++){
				let NextValue = array[q];
				console.log(NextValue,"NextValue")
				if(NextValue.nozzle_id == CurrentArrayValue.nozzle_id && 
					NextValue.id > CurrentArrayValue.id &&
					 NextValue.nz_count == ''){
					
					NozzleAmount[q]["open_reading"]= e.target.value;
					
		   let sub_sub = parseFloat(NozzleAmount[q]["clossing_reading"]) - parseFloat(e.target.value)
				if(sub_sub <0){
					sub_sub=0;
				}
				if(sub_sub >= 0){
					NozzleAmount[q]["quantity"]=Number(sub_sub);
					let amount = sub_sub * NozzleAmount[q]["price"];
					NozzleAmount[q]["amount"] = amount;
				}else{
					NozzleAmount[q]["quantity"] = '';
					NozzleAmount[q]["amount"]   = '';
				}
				
				}


			}
	// /***********/ 



			}
	
			if(e.target.name == 'quantity'){
				let add = parseFloat(e.target.value) + parseFloat(NozzleAmount[i]["open_reading"])
				NozzleAmount[i]["clossing_reading"] = Number(add);
				let amount = e.target.value * NozzleAmount[i]["price"];
				NozzleAmount[i]["amount"] = amount;
				console.log(add,"njnnn")
			}
	
			if(e.target.name == 'amount'){
				let t_price = parseFloat(e.target.value) / parseFloat(NozzleAmount[i]["price"])
				NozzleAmount[i]["quantity"] = parseFloat(t_price).toFixed(2);
				// alert(isNaN(NozzleAmount[i]["quantity"]))
			let CloseRead =	Number(NozzleAmount[i]["quantity"]) + Number(NozzleAmount[i]["open_reading"])
			if(CloseRead < 0){
				NozzleAmount[i]["clossing_reading"] = '';
			}else{
				NozzleAmount[i]["clossing_reading"] = parseFloat(CloseRead);
			}
				
	
				console.log(CloseRead,"kolkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk")
			}
		
		
		}else{

			console.log(e,"checking")
			NozzleAmount[i]["end_from"]=e;
			NozzleAmount[i]["sent_end_from"] = e.format(format);

		}
		
       
		

		



		// if(e.target.name =='end_form'){
		// 	let end_time = e.target.value || NozzleAmount[i]['sugest_end_from']
		// 	NozzleAmount[i]['end_from'] = end_time;
		// }


		setValues(NozzleAmount)
		
		console.log(Values,"pppppppppp")

	}

	

 function multiplevalidationform(){
	
	let formIsValid = true
	let validationdata=[]
	validationdata =[...Values]

	

	for (var i = 0; i < validationdata.length; i++) {
		// alert(validationdata[i]['quantity'] == '')
		// alert(isNaN(validationdata[i]["clossing_reading"]) == true )
		if(validationdata[i]['clossing_reading'] == null 
		
		|| validationdata[i]['clossing_reading'] == undefined || validationdata[i]['clossing_reading'] == '' || 
		isNaN(validationdata[i]["clossing_reading"]) == true){

			mulerror[i]["close_reading_error"] = "Please enter the closing reading"; 
			formIsValid = false 
			setDouble(true)
		                                                                                                  
		}else if(Number(validationdata[i]['clossing_reading']) < (Number(validationdata[i]["open_reading"]))){
		
			mulerror[i]["close_reading_error"] = "Closing Reading must greater than or equal to Opening Readings"; 
			formIsValid = false 
			setDouble(true)
		}
		else{

			mulerror[i]["close_reading_error"] = ""; 

		}


		 

		 if(validationdata[i]['quantity'] == null 
		 
		 || validationdata[i]['quantity'] == undefined || 
		 validationdata[i]['quantity'] < 0 ||
		   isNaN(validationdata[i]["quantity"]) == true){
    
			mulerror[i]['quanity_error'] ="Please enter the Quantity";
			formIsValid = false
			setDouble(true)
			
		 }
		//  else if(validationdata[i]['quantity'] == ''){
		// 	// mulerror[i]['quanity_error'] ="Please enter the Quantity";
		// 	// formIsValid = false
		//  }
		 else{
			mulerror[i]['quanity_error'] ="";
		 }


		//  alert(validationdata[i]['amount'])
		 if(validationdata[i]['amount'] == null || validationdata[i]['amount'] == undefined || validationdata[i]['amount'] < 0){
			
			mulerror[i]['amount_error'] ="Please enter the amount"
			formIsValid = false
			setDouble(true)
		 }
		//  else if(validationdata[i]['amount'] == ''){
		// 	// mulerror[i]['amount_error'] ="Please enter the amount"
		// 	// formIsValid = false
		//  }
		 else{
			mulerror[i]['amount_error'] ="";
		 }

		 if(Number(validationdata[i]['current_stock']) <(Number( validationdata[i]['quantity']))){
			mulerror[i]['current_stock_error'] ="Insufficient Quantity of fuel in the tank"
			formIsValid=false
			setDouble(true)
		 }
		 else{
			mulerror[i]['current_stock_error'] ="";
		 }


    console.log(validationdata[i],"okno")

	
	}
	console.log(mulerror,"mulerrorarray")
	// setErrors(errors)
	// formIsValid = false
	setMulerror(mulerror)
	setValues(validationdata)
	console.log(mulerror,"error")
	return formIsValid;
 }
	
	const handleInputs = (e) => {

		let errors = []
		let formIsValid = true
		var quantity_saved = inputValues['quantity'];

		if (e.target.name == "clossing_reading") {
			var quantity = e.target.value - opening_reading.reading ? e.target.value - opening_reading.reading : 0
			if (quantity < 0) {
				quantity = 0;
			}
			var q = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : 0;
			if (Number(inputValues['current_stock']) < Number(q)) {
				//	formIsValid = false;
				errors["quantity"] = "Insufficient Quantity of fuel in the tank"
			}
			else {
				errors["quantity"] = ""
			}
			if (((e.target.value) <= (inputValues.b_opening_reading)) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {
				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				var amount = q * inputValues.b_selling_price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0;
			}
			else if ((e.target.value > inputValues.b_opening_reading) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {
				var b_qty = inputValues.b_opening_reading - opening_reading.reading ? inputValues.b_opening_reading - opening_reading.reading : 0
				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				var total_b_qty = inputValues.b_selling_price * b_qty;

				var qty = e.target.value - inputValues.b_opening_reading ? e.target.value - inputValues.b_opening_reading : 0
				var total_qty = selling_price.price * qty;

				var t_amount = total_b_qty + total_qty;
				inputValues['amount'] = parseFloat(t_amount) ? parseFloat(t_amount).toFixed(2) : 0
			}
			else if (selling_price.price) {
				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				var amount = q * selling_price.price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0
			}
			else {
			}
		}
		else if (e.target.name == "amount") {

			if (selling_price.price && inputValues.b_selling_price != '') {

				var amount = e.target.value;
				var q = amount / inputValues.b_selling_price;

				var closereading = Number(opening_reading.reading) + Number(q);

				if (closereading <= inputValues.b_opening_reading) {
					inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
					inputValues['clossing_reading'] = parseFloat(closereading) ? parseFloat(closereading).toFixed(3) : 0;
				}
				else if (closereading > inputValues.b_opening_reading) {
					var o_qty = inputValues.b_opening_reading - opening_reading.reading;

					var o_qty_amount = o_qty * inputValues.b_selling_price;
					var r_amt = amount - o_qty_amount;
					var r_qty = r_amt / selling_price.price;
					var quantity = Number(o_qty) + Number(r_qty);
					var clossing_reading = Number(opening_reading.reading) + Number(o_qty) + Number(r_qty);

					inputValues['quantity'] = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : 0;
					inputValues['clossing_reading'] = parseFloat(clossing_reading) ? parseFloat(clossing_reading).toFixed(3) : 0;

				}
			}
			else if (selling_price.price && inputValues.b_selling_price == '') {

				var amount = e.target.value;
				var q = amount / selling_price.price;

				var closereading = Number(opening_reading.reading) + Number(q);

				inputValues['quantity'] = parseFloat(q) ? parseFloat(q).toFixed(2) : 0;
				inputValues['clossing_reading'] = parseFloat(closereading) ? parseFloat(closereading).toFixed(3) : 0;

			}

		}
		else if (e.target.name == "quantity") {

			var closereading = Number(opening_reading.reading) + Number(e.target.value);
			inputValues['clossing_reading'] = parseFloat(closereading) ? parseFloat(closereading).toFixed(3) : 0;


			var quantity = e.target.value;
			var q = parseFloat(quantity) ? parseFloat(quantity).toFixed(2) : 0;
			if (Number(inputValues['current_stock']) < Number(q)) {
				//formIsValid = false;
				errors["quantity"] = "Insufficient Quantity of fuel in the tank"
			}
			else {
				errors["quantity"] = ""
			}
			if (((closereading) <= (inputValues.b_opening_reading)) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {

				var amount = q * inputValues.b_selling_price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0
			}
			else if ((closereading > inputValues.b_opening_reading) && (selling_price.price != '') && (inputValues.b_opening_reading != '') && (inputValues.b_selling_price != '')) {
				var b_qty = inputValues.b_opening_reading - opening_reading.reading ? inputValues.b_opening_reading - opening_reading.reading : 0

				var total_b_qty = inputValues.b_selling_price * b_qty;

				var qty = e.target.value - inputValues.b_opening_reading ? inputValues['clossing_reading'] - inputValues.b_opening_reading : 0

				var total_qty = selling_price.price * qty;

				var t_amount = total_b_qty + total_qty;
				inputValues['amount'] = parseFloat(t_amount) ? parseFloat(t_amount).toFixed(2) : 0
			}
			else if (selling_price.price) {
				var amount = q * selling_price.price;
				inputValues['amount'] = parseFloat(amount) ? parseFloat(amount).toFixed(2) : 0
			}
			else {
			}
		}

		if (inputValues['end_from'] == null) {
			inputValues['end_from'] = inputValues['sugest_end_from'];
		}
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});

	};
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();

				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}


	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		//event.preventDefault();
		
		 setDouble(false);
		if (multiplevalidationform()) {
		
			 sendBankData();
		}


	}
	//---validations---//	
	// function validateForm() {
	// 	let errors = []
	// 	let formIsValid = true

	// 	if (Number(inputValues.clossing_reading) < 0) {
	// 		setDouble(true)
	// 		formIsValid = false;
	// 		errors["clossing_reading"] = "Enter Clossing Reading"
	// 	}
	// 	else if (Number(opening_reading.reading) > Number(inputValues.clossing_reading)) {
	// 		formIsValid = false;
	// 		setDouble(true)
	// 		errors["clossing_reading"] = "Closing Reading must greater than Opening Reading"
	// 	}
	// 	else if (inputValues.nz_close_reading && Number(inputValues.clossing_reading) > Number(inputValues.nz_close_reading)) {
	// 		formIsValid = false;
	// 		setDouble(true)
	// 		errors["clossing_reading"] = "Maximum alloved reading";
	// 	}
	// 	else {
	// 		errors["clossing_reading"] = ""
	// 	}
	// 	if (!inputValues.end_from) {
	// 		formIsValid = false;
	// 		setDouble(true)
	// 		errors["end_from"] = "Enter End Time"
	// 	}
	// 	else {
	// 		errors["end_from"] = ""
	// 	}
	// 	if ((inputValues.quantity == null)) {
	// 		formIsValid = false;
	// 		setDouble(true)
	// 		errors["quantity"] = "Enter Quantity"
	// 	}
	// 	else {
	// 		errors["quantity"] = ""
	// 	}
	// 	var check_qty = 0;
	// 	if (Number(inputValues.quantity) > Number(inputValues.quantity_saved)) {
	// 		check_qty = Number(inputValues.quantity) - Number(inputValues.quantity_saved)
	// 	}
	// 	if (Number(inputValues.current_stock) < check_qty) {
	// 		formIsValid = false;
	// 		setDouble(true)
	// 		errors["quantity"] = "Insufficient Quantity of fuel in the tank"
	// 	}
	// 	else {
	// 		errors["quantity"] = ""
	// 	}

	// 	if ((inputValues.amount == null)) {
	// 		formIsValid = false;
	// 		setDouble(true)
	// 		errors["amount"] = "Enter Amount"
	// 	}
	// 	else {
	// 		errors["amount"] = ""
	// 	}
	// 	setErrors(errors)

	// 	return formIsValid;
	// }
	function sendBankData() {
		


	

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}, body: JSON.stringify({
				array:Values
				// amount: parseFloat(inputValues.amount) ? parseFloat(inputValues.amount).toFixed(2) : 0,
				// b_opening_reading: inputValues.b_opening_reading,
				// b_selling_price: inputValues.b_selling_price,
				// clossing_reading: parseFloat(inputValues.clossing_reading) ? parseFloat(inputValues.clossing_reading).toFixed(3) : 0,
				// comma: inputValues.comma,
				// current_stock: inputValues.current_stock,
				// end_from: inputValues.end_from.format(format),
				// id: inputValues.id,
				// op_emp_id: inputValues.op_emp_id,
				// price: inputValues.price,
				// start_from:inputValues.start_from,
				// op_date: localStorage.getItem('op_date'),
				// product_id: inputValues.product_id,
				// quantity: parseFloat(inputValues.quantity) ? parseFloat(inputValues.quantity).toFixed(2) : 0,
				// sugest_end_from: inputValues.sugest_end_from,
				
			})
		
		};


		fetch(API_URL + "/mul_nozzle_closing", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["amount"] = responseData.response.amount
					setDouble(true)
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/newnozzle-closing")
					setDouble(true)
					toggle();
					check_meta_date();
				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {

				setDouble(true)
			});
	}



	//---Edit employee list---//	
	function add_nozzle_clossing(id,nozzle_name=null) {
		setErrors([])
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			}, body: JSON.stringify({
				op_date: localStorage.getItem('op_date'),
				id: id,
				nozzle_name: nozzle_name,
			})
		};
		fetch(API_URL + "/get_all_product_price", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				// if (responseData.status == false) {
				// 	errors["name"] = responseData.response.name
				// }
				// setErrors([''])
				let Nozzle=[]
				let mulerror=[]
				console.log("noooooooooo")
				responseData.forEach((elem, index) => {

							Nozzle.push({
							end_from:elem.end_from != null ? moment(elem.end_from, 'HH:mm:ss') : null,
						    sent_end_from:elem.end_from != null ? moment(elem.end_from, 'HH:mm:ss').format(format) : moment(elem.sugest_end_from, 'HH:mm:ss').format(format),
			                sugest_end_from:elem.sugest_end_from != '' ? moment(elem.sugest_end_from, 'HH:mm:ss') : null,
							clossing_reading:elem.close_reading,
						    b_opening_reading:elem.b_opening_reading,
						    b_selling_price:elem.b_selling_price,
						    comma:elem.b_selling_price != '' ? ',' : '',
							quantity:elem.quantity,
							amount:elem.amount,
							op_date: localStorage.getItem('op_date'),
							product_id:elem.fuel_p_id,
							// price:elem.saved_price,
							current_stock:elem.current_stock,
							nozzle_id:elem.nozzle_id,
							nozzle_status:elem.nozzle_status,
							nz_close_reading:elem.nz_close_reading,
							// nz_count:elem.nz_count,
							nz_count:elem.nz_count,
							start_from:elem.start_from != '' ? moment(elem.start_from, 'HH:mm:ss') : null,
						    op_emp_id:elem.op_emp_id,
							nozzle_name:elem.nozzle_name,
				            open_reading:elem.opening_reading,
							price: elem.selling_price,
							id:elem.id 
					})
                      
					mulerror.push({
						close_reading_error:"",
						quanity_error:"",
						amount_error:"",

					            })
					


				})
				 console.log(Nozzle,"okkk")
				 setMulerror(mulerror)
				 setValues(Nozzle)

				 
				 toggle();
				// responseData.foreach((elem.index))


				})

			.catch(e => {

			});
	}
console.log(inputValues,"dis")
	///////////////

	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={13} />
									<div className="col-5">
										<div className="row">
											{Loading ? ListNozzleAllocation :
												// <div class="loader text-primary">Loading...</div>
												<Loader />
											}
										</div>
									</div>
									<div class="form-wrap ">




										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="nozzleclosing" role="tabpanel" aria-labelledby="nozzleclosing-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Nozzle Closing({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h3>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" class="form-control" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" value={search.search_keyword} onChange={handleSearch}></input>
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
															</div>

														</div>
													</div>


													<div class="table-widget">
														<table class="table border">
															<thead>
																<tr>
																	<th>Nozzle Name</th>
																	<th className="col-2">Start Time</th>
																	<th className="col-2">End Time</th>
																	<th>Opening Reading</th>
																	<th>Closing Reading</th>
																	<th >Quantity in Lt.</th>
																	<th>Price</th>
																	<th>Operation Date</th>
																	<th>Amount</th>
																	<th>Action</th>
																</tr>
															</thead>

															<tbody>
																{
																	list_data.map((obj) => (
																		<tr>
																			<td>{obj.nozzle_name}</td>
																			<td>{moment(obj.start_from, 'HH:mm:ss').format('hh:mm A')}</td>
																			<td >{obj.end_from == null ? "" : moment(obj.end_from, 'HH:mm:ss').format('hh:mm A')}</td>
																			<td>{obj.opening_reading}</td>
																			<td>{obj.close_reading}</td>
																			<td>{obj.quantity}</td>
																			<td>{obj.price}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>
																			<td>{obj.amount}</td>
																			<td><div className="d-flex icon-btn">
																				<button onClick={() => add_nozzle_clossing(obj.id,obj.nozzle_name)}>  <i className="fa fa-ellipsis-h" aria-hidden="true"></i></button>

																			</div></td>
																		</tr>

																	))}
																<tr>
																	<td colSpan={8} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={2}>{total.totalamount}</td>
																</tr>

															</tbody>
														</table>
														<nav aria-label="Page navigation example">
															<ul className="pagination justify-content-end">
																{pages.total_pages > 1 && pages.pagination_items}
															</ul>
														</nav>

													</div>

												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			{isShowing ? (<NozzleEmp
				isShowing={isShowing}
				hide={toggle}
				handleInputs={handleInputs}
				handleInput={handleInput}
				handleInput1={handleInput1}
				preventNonNumericalInput={preventNonNumericalInput}
				inputValues={inputValues}
				Values={Values}
				fields={fields}
				double={double}
				mulerror={mulerror}
				handleSubmit={handleSubmit}
				handleAmount={handleAmount}
				errors={errors}
			/>) : null
			}
		</>
	)
}








export default NozzleEmpConfig2