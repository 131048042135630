import React, { Component, Fragment } from "react";
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip'
import moment from 'moment';
import Select from "react-select";
import {preventNonNumericalInput,prevNonNumericalWithDec,prevDec} from "../../../layouts/Operational_Sidebar_Progress.component";


const ReceiptModal = ({ isShowing, hide,denm,accountData,selected_options,double,startDate, acCategory, errors,success, inputValues,input,denomination,handleChange,handleSelect,handleClick, fields, dropdownData, handleSubmit, handleInputs,outstanding,cashbalance }) => {
  var today = new Date().toISOString().split('T')[0];
  const DropDown = ({data}) => (
    <select className="form-control highlight" name="ac_name_slug" onChange={handleSelect} value={input.ac_name_slug}>
      <option selected value="">Account Name</option>
      {
        data.map(({ac_cat, sub_cat}, key) => (
        <optgroup label={ac_cat} key={key}>
        {
          sub_cat.map(({id, sub_head}) => (
          <option value={id}>
            {sub_head}
          </option>
          ))
        }
        </optgroup>
      ))
      }
    </select>
    )

  return (
        <React.Fragment>

         
            <div className="container pb-3">
              
            
        <div className="row mb-3 justify-content-center">
            <div className="modal-overlay" />
                <div className="modal-wrapper  modal-style" aria-modal aria-hidden tabIndex={-1} role="dialog">
                      <div className="modal modal-dialog-xl modalxl col-12 ">
                          <div className="modal-header mb-3">
                                  <h5 className=" text-center" id="staticBackdropLabel">Receipt Voucher</h5>
                                  <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                           </div>


<div className="row mb-3 ">
{/* <div className="row g-3 justify-content-center  mb-2">
        <div className="col-3 ">
        <label className="col-form-label mx-2">System Voucher Number</label>
        </div>
          <div className="col-9">
          <input type="text" className="form-control highlight" name="sys_voucher_no" value={input.sys_voucher_no} onChange={handleInputs}></input>
          </div>
</div> */}
<div className="row g-3   mb-2">
          <div className="col-3">
          <label class="col-form-label mx-2">Account Name <sub className="star">*</sub></label>
          </div>
       
                {/* <div class="col-3">
                
                </div> */}
                <div class="col-9">
                <Fragment>
                 <Select name="from_ac_name_slug" options={acCategory} defaultValue={selected_options} onChange={handleSelect}/>
                 </Fragment>
                  <div className="errorMsg">{errors.ac_name_slug}</div>
                </div>
            </div>
<div className="row g-3 justify-content-center  mb-2">
                <div className="col-3">
                  <label className="col-form-label mx-2" >Amount <sub className="star">*</sub></label>
                  
                </div>
                <div className="col-3">
                  <input type="number"  onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} className="form-control hide-arrow highlight" id="amount" name="amount" value={input.amount} onChange={handleInputs}></input>
                   <div className="errorMsg">{errors.amount}</div>
                   <span class="amount-words"></span>

                </div>
            <div className="col-6"> 
                  <div className="accordion denomination " id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button btn-success collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          Denomination
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse accordion-overlay collapse" aria-labelledby="headingOne" 
                        data-bs-parent="#accordionExample" >
                      <div class="accordion-body">
                        <div class="table-widget">
                          <table class="table border-0">
                            <tbody>
                            { denm.map((obj,idx)=>{
                              
                                return (
                                  
                              
                              <tr>
                                
                              <td ><input type="text" class="form-control highlight elem" name="elem" value={obj.amount} id={"amount" + idx} onChange={ event=> {handleChange(event,idx)}}/></td>
                              <td>*</td>
                              <td><input type="number"  onKeyPress={(event)=>prevNonNumericalWithDec(event,event.target.value)}min="0" class="form-control hide-arrow highlight" name="change" value={denomination[idx] ? denomination[idx]['count'] : '' } id={"change" + idx} onChange={ event=> {handleChange(event,idx)}}/></td>
                              <td>=</td>
                              <td width="110px"><strong><input type="text" readOnly class="form-control highlight" name="multiple" id={"multiple" + idx} value={denomination[idx] ? denomination[idx]['multiple'] : '' } onChange={ event=> {handleChange(event,idx)}}/></strong></td>
                              
                            </tr>
                                )
                              })
                              }
                              <tr>
                              <td></td>
                              <td></td>
                              <td>Total</td>
                              
                              <td>=</td>
                              <td><input type="label"  className="form-control highlight" name="amount"   id="amountww" ></input></td>
                              </tr>

                            </tbody>
                            </table>
                          </div>
                       </div>
                    </div>
                  </div>
                 </div>
            </div>
                                                    
</div>
<div className="row g-3 justify-content-center  mb-2">
          <div className="col-3">
          <label className="col-form-label mx-2">Description</label>
          </div>
          <div className="col-9">
          <input type="text" className="form-control highlight" name="note" value={input.note} onChange={handleInputs}></input>
          </div>

</div>
<div className="row g-3 justify-content-center  mb-2">
        <div className="col-3">
        <label className="col-form-label mx-2">Voucher Number</label>
        </div>
        <div className="col-9">
        <input type="text" onKeyPress={(event)=>prevDec(event,event.target.value)}  className="form-control hide-arrow highlight" name="voucher_no" value={input.voucher_no} onChange={handleInputs}></input>
        </div>

</div>

<div className="row g-3   mb-2">
<div className="col-3">
            <label className="col-form-label mx-2" > Operation Date <sub className="star">*</sub></label>
        </div>
        <div className="col-3 d-md-flex">
            <input type="date" className="form-control highlight" name="op_date" id={"m_op_date"}  value={input.op_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate>today?" ":startDate):localStorage.getItem("m_op_date")) :input.op_date}
               min={startDate} max={today} onChange={handleInputs} disabled ></input>
              <div className="errorMsg">{errors.op_date}</div>
        </div></div>
        

<div className="row">
<div className="col-6 mt-3">         
    <div className="row g-3 justify-content-center mb-2">
        <div className="">
        <label className="col-form-label mx-2"><b>Outstanding Before This Transaction</b></label>
        </div>
        
              <div className="col-6">
              <label className="col-form-label mx-2">Current Outstanding</label>
              </div>
              <div className="col-6">
              <input type="text"  value={outstanding.before_approval ?parseFloat(outstanding.before_approval).toFixed(2):'0'} className="form-control" readOnly={true}></input>
              </div>
              
              <div  className="col-6">
              <label className="col-form-label mx-2">After Approval</label>
              </div>
              <div  className="col-6">
          <input type="text" value={outstanding.after_approval?parseFloat(outstanding.after_approval).toFixed(2):'0'} className="form-control" readOnly={true}></input>
              </div>
              </div>
              <div  className="row g-3 justify-content-center mb-2">
              
              <div   className="col-6">
              <label className="col-form-label mx-2">After Dayclosing</label>
                                  </div>
              <div  className="col-6">
              <input type="text" value={outstanding.after_dayclose?parseFloat(outstanding.after_dayclose).toFixed(2):'0'} className="form-control" readOnly={true}></input>
              </div>          
        </div>
        <div  className="row g-3 justify-content-center mb-2">
        <div className="">
        <label className="col-form-label mx-2"><b>Outstanding  After This Transaction </b></label>
        </div>
        
                            <div  className="col-6">
                            <label className="col-form-label mx-2"> Before Approval</label>
                            </div>
                            <div className="col-6">
                            <input type="text" id ="outs_before_approval" className="form-control" readOnly={true}></input>
                            </div>
                            
        </div>                   
</div>
<div  className="col-6 mt-3">
                


    <div className="row g-3 justify-content-center mb-2">
        <div className="">
        <label className="col-form-label"> <b>Cash Balance Before This Transaction</b></label>
        </div>
        
                            <div className="col-6">
                            <label className="col-form-label">Before Approval</label>
                            </div>
                            <div className="col-6">
                            <input type="text" value={cashbalance.before_approval? parseFloat(cashbalance.before_approval).toFixed(2):'0'}  className="form-control" readOnly={true}></input>
                            </div>
                            <div className="col-6">
                            <label className="col-form-label">After Approval</label>
                            </div>
                            <div className="col-6">
                        <input type="text"  value={cashbalance.after_approval?parseFloat(cashbalance.after_approval).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                            </div>
           </div>
           <div className="row g-3 justify-content-center mb-2">
                            
                            <div className="col-6">
                            <label className="col-form-label">After Dayclosing</label>
                                                </div>
                            <div className="col-6">
                            <input type="text"  value={cashbalance.after_dayclose?parseFloat(cashbalance.after_dayclose).toFixed(2):'0'} className="form-control" readOnly={true}></input>
                            </div>
                            
        </div>
        <div className="row g-3 justify-content-center mb-2">
        <div className="">
        <label className="col-form-label"><b>Cash Balance  After This Transaction</b></label>
        </div>
        
                            <div className="col-6">
                            <label className="col-form-label">Before Approval</label>
                            </div>
                            <div className="col-6">
                            <input type="text"  id ="cashb_before_approval"  className="form-control" readOnly={true}></input>
                            </div>
                            
                            
                            
        </div>
                        
               
                        
                        

</div>
</div>





<div className="modal-footer text-center">
             
             <a href="#" className="btn btn-danger w-auto" type="submit" onClick={hide}>Cancel</a>
             {double===true ?<a href="#" class="btn btn-primary  w-auto " onClick={handleSubmit}>Insert</a>:   <a href="#" class="btn btn-primary  w-auto " disabled>Insert</a>}
   </div>      
        </div>
    </div>
    </div>
</div>
</div>

       </React.Fragment>

    );
}
export default ReceiptModal 