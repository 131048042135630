import React,{useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip' 
import TimePicker from 'rc-time-picker';
import { Link,useLocation} from "react-router-dom";
import moment from 'moment/moment';
import Select from 'react-select';
const NozzEmp1ConfigModal = ({ preventNonNumericalInput,isSameIdsubmit,hide,Updatenoz,UpdateEmployee,selected_options_edit,isSameId,handleSelect,selected_options,deleteNozzle,handleInputedit,EdithandleDelete,dropdownDatas,handleeditSubmit,handleChange_nozzle_id_edit,handleInput,double,isShowing1, errors,handleDelete,handleAdd,success,open_reading,chek_nz, inputValues,inputValues1,handleAddedit,handleRemove,handleChange_nozzle_id,fields,dropdownData,nozzledropdownData,handleSubmit, edithandleInputs, allocated_list,inputstarttime }) => {
  const location_details = useLocation()
  let nnnnn= dropdownDatas.map((data) => {
    return <option value={data[0]}>{data[1]}</option>
})

  return (
  <React.Fragment>

 {console.log(fields,"llllllllllllllllllllllllllllll")} 
<div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal modal-dialog-xl modalxl">
       
        <div class="modal-header mb-3">
        <h5 class="modal-title" id="staticBackdropLabel">Nozzle Allocation-{moment(inputValues.op_date).format("DD-MM-YYYY")}</h5>
        <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
          <div class="modal-body">


        <div class="row mb-3 justify-content-center">
  <div className="table-widget">
												<table className="table border status-table">
													<thead>
														<tr>
															<th>Allocated Nozzles</th>
															<th>Opening Reading</th>
                                                            <th>Closing Reading</th>
															<th>Action</th>
														</tr>
													</thead>

													<tbody>
                                                        
                                               

{

allocated_list.map((obj) => (	
   
                                                    <tr>
                                                          <td>{obj.nozzle_name}</td>
                                                          <td>{obj.opening_reading}</td>
                                                          <td>{obj.close_reading}</td>
                                                          <td><a href="#" class="text-secondary" type="Delete"onClick={() => deleteNozzle(obj.nozzle_id,obj.id)}><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a></td>
                                                          {console.log(obj,"xxx")}
                                                           </tr>
                                                   
))}

													</tbody>
												</table>


											</div>

                                              
        <div class="col-md-12">
       
        <div class="form-row mb-1">
       
          <label class="text-secondary mb-2">Employee Name </label>
         
            <select id="" class="form-control highlight" name="employee_id" value={inputValues1.employee_id} onClick={edithandleInputs}  onChange={edithandleInputs}>
            {inputValues.role == "employee" ? <option value=''>select</option>:<option value=''>select</option> } 
              {dropdownData.map((data)=>{
              return <option value={data[0]}>{data[1]}</option>
            })
          }
            </select>
        
          <div className="errorMsg">{errors.employee_id}</div>
        </div>
        <Link to={location => ({ ...location, fields:{ fields: true }, backpage: location_details.pathname, pathname: "/user-management-op/nozzle-employee-list" })} class="link-text"><a href="/user-management-op/nozzle-employee-list">Redirect to Add Employee</a></Link>
        {/* <div class="form-row mb-2"> 
        {dropdownData.length == 0 && inputValues.role != "employee"? 
                  <Link to={location => ({ ...location, fields:{ fields: true }, backpage: location_details.pathname, pathname: "/users/users" })} class="link-text"> Add Employee</Link>
        : '' } 
        </div> */}

{/* <div>
{isSameId == true ?    
<a
  className="btn btn-primary"
  type="submit"
  disabled
  onClick={''}
  style={{
    width: '90px',
    paddingRight:'50px'
  }}
>
  update
</a>:
<a
  className="btn btn-primary"
  type="submit"
  onClick={''}
  style={{
    width: '90px',
    paddingRight:'50px'
  }}
>
  update
</a>
}
</div> */}

<div>
  {isSameId ?    
    <button
      className="btn btn-primary"
      type="update"
      disabled 
      onClick={UpdateEmployee}
      style={{
        width: '90px',
        paddingRight: '50px'
      }}
    >
      update
    </button> :
    <button
      className="btn btn-primary"
      type="update"
      onClick={UpdateEmployee}
      style={{
        width: '90px',
        paddingRight: '50px'
      }}
    >
      update
    </button>
  }
</div>


                                             

  
         <div class="form-row mb-2">
           </div>
        
       
        <div class="form-row  mb-3">
        <div class="row addfield-wrp">
                  <div class="btn-grp-fxd text-end mt-4">
                        <button class="btn-add mt-2  me-2" onClick={() => handleAdd()}>+</button>
                        <button class="btn-add mt-2  me-2 d-none "  onClick={(event) => handleRemove( event)} >-</button>
                      </div>
                  {fields.map((inpuvalue, idx) => {
                  var er = "nozzle_id"+ idx;
                return (
                  <>
                  <div class="col-md-6">
                    <div class="">
                      <div class="row g-3 align-items-center">
                      
                        <div class="col-12">
                        <label class="text-secondary mb-2">Nozzle Names with Opening Reading  </label>
                      
                        {/* <div class="select-container">
                          <select id={"nozzle_id"+ idx} class="form-control  nozzle_ids" name="nozzle_id"  onChange={(event) => handleChange_nozzle_id(idx, event)} >
                              <option value='0'>select</option>
                              {nozzledropdownData.map((data)=>{
                
                               return <option value={data[0]}>{data[1]}</option>
                            })
                          }
                          </select>
                        </div>  */}
                        <div class="select-container">
                          <select id={"nozzle_id"+ idx} class="form-control  nozzle_ids" value={inpuvalue} name="nozzle_id"   onChange={(event) => handleChange_nozzle_id(idx, event)} >
                              {/* <option value='0'>select</option>
                              {nozzledropdownData.map((data)=>{
                                  // return <option  class={chek_nz.includes(data[0]) == true ? "d-none" : ''} value={data[0]}>{data[1]}</option>
                                  return  <option style={chek_nz.includes(data[0]) == true ? { display: 'none' } : {}} value={data[0]}>
                                  {data[1]}
                                </option> */}
                                 <option value='0'>select</option>
                              {nozzledropdownData.map((data)=>{
                                  // return <option  class={chek_nz.includes(data[0]) == true ? "d-none" : ''} value={data[0]}>{data[1]}</option>
                                  return  <option style={chek_nz.includes(data[0]) == true ? { display: 'none' } : {}} value={data[0]}>
                                  {data[1]}
                                </option>
                            })
                          }
                          </select>
                        </div>
                        {/* <div className="errorMsg">{errors["nozzle_id"+idx]}</div> */}
                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="">
                      <div class="row g-3 align-items-center">
                        <div class="col-12">
                          <label  class="col-form-label mb-2">Opening Reading</label>
                               <input type="text"  name="opening_reading"  id={"opening_reading"+idx}  readOnly class="form-control" aria-describedby="passwordHelpInline"/>
                        </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-1">
                  {idx!==0 ?
                  <button class="btn-add mt-4 ms-1" onClick={(e) => handleDelete(idx)}>-</button>:''}</div>
                      </>
                        );
                      })}
                      
                </div>
                 
           {console.log(inputValues.start_from,"start")}
           {console.log(inputValues.sugest_start_from,"start1")}
          
          <div class="row mt-3" >
            <div class="col-md-6">
              <label  class="col-form-label mb-2">Start Time</label>
                <div className="digitaltimepicker">
                <TimePicker
                    showSecond={false} 
                    // defaultValue={(!inputstarttime.sugest_start_from) ? inputstarttime.sugest_start_from : inputValues.start_from }
                    name="start_from"
                    onChange={handleInput}
                    value={inputValues.start_from}
                    use12Hours
                  />   
                  {console.log(inputValues.sugest_start_from,"arrayyyyyyyy")}
                  </div>  
                {/* <div className="errorMsg">{errors.start_from}</div>   */}
            </div>
            <div class="row mt-3" >
          <div class="col-md-6">
          <label  class="col-form-label mb-2">Digital Payment</label>
      
           <Select   
             isMulti
             value={selected_options}
             onChange={handleSelect}
             options={dropdownDatas}
            > 
            </Select>
            <p className="note mt-2">Note: Select digital payment type linked with bank account</p>
                </div> 

                <div>
<a
  className="btn btn-primary"
  type="submit"
  onClick={Updatenoz}
  style={{
    width: '90px',
    paddingRight:'50px'
   
  }}
>
  update
</a>
</div>    

{/* <div>
  {isSameIdsubmit ?    
    <button
      className="btn btn-primary"
      type="update"
      onClick={Updatenoz}
      style={{
        width: '90px',
        paddingRight: '50px'
      }}
    >
      update
    </button> :
    <button
      className="btn btn-primary"
      type="update"
      disabled 
      onClick={Updatenoz}
      style={{
        width: '90px',
        paddingRight: '50px'
      }}
    >
      update
    </button>
  }
</div> */}
           </div>

           {/*} <div class="col-md-5">
            <label  class="col-form-label mb-2">Opening Cash</label>
              <input type="number"  name="opening_cash" value={inputValues.opening_cash} onChange={handleInputs} class="form-control hide-arrow" onKeyPress={(event)=>preventNonNumericalInput(event,event.target.value)} />
            </div> */}
          </div>
        </div>            
        </div>

        <div class="col-md-10">
          
     
        </div>
        </div>
      </div>
      <div className="modal-footer text-center">
            {/* {double === false ?   
                <a className="btn btn-danger w-auto" type="submit" disabled style={{ width: '100px' }}>Cancel</a>
                : 
                <a className="btn btn-danger w-auto" type="submit" onClick={''} style={{ width: '100px' }}>Cancel</a>
            } */}
             
                <a className="btn btn-danger w-auto" type="submit" onClick={hide} disabled style={{ width: '150px' }}>Cancel</a>
             
               
        </div>
      </div>
    </div>
</React.Fragment>
  );
}
       export default NozzEmp1ConfigModal;