import React, { useEffect, useMemo, useState } from 'react'
import Sidebar_Progress from '../layouts/Sidebar_Progress.component'
import LoginHeader from '../layouts/LoginHeader.component'
import ReactDOM from 'react-dom';
import swal from 'sweetalert'
import { Link } from "react-router-dom";
import museum from '../../img/museum.svg'
import { convertAmountToWords, preventNonNumericalInput, preventNonNumericalInputNON } from '../layouts/Sidebar_Progress.component'
import Loader from '../operational-model/Employee/Loader.component';
import { useHistory } from 'react-router-dom'
import { API_URL } from '../../constant/API_Settings';


const LooseOilComponent = (location) => {
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [updateId, setUpdateId] = useState(null)
    const [errors, setErrors] = useState([])
    const [stat_operation, setStartoperation] = useState('')
    const [checkoption, ckeckOption] = useState([])
    const [Loading, setLoading] = useState(false);
    const history = useHistory()
    const [check, setCheck] = useState()
    const [list_data, setList] = useState([])
    const [path, setLocation] = useState([])
    const [showAdditional, setShowAdditional] = useState(false);
    const [numberOfMachines, setNumberOfMachines] = useState('');
    const [isGreater, setIsGreater] = useState(false)
    const [oilMachine, setOilMachine] = useState(null)
    const [alreadyUsedMachineNames, setAlreadyUsedMachineNames] = useState([])


    // state variables starting

    const [looseOil, setLooseOil] = useState("")
    const [productName, setProductName] = useState('');
    const [packingQuantityInLitre, setPackingQuantityInLitre] = useState('');
    const [productNameWithUnit, setProductNameWithUnit] = useState('');
    const [totalStockInLitre, setTotalStockInLitre] = useState('');
    const [numberOfBarrel, setNumberOfBarrel] = useState('');
    const [barrelBalance, setBarrelBalance] = useState('');
    const [gstInPercent, setGstInPercent] = useState('');
    const [purchasePricePerBarrel, setPurchasePricePerBarrel] = useState('');
    const [purchasePricePerBarrelExcGst, setPurchasePricePerBarrelExcGst] = useState('');
    const [purchasePricePerBarrelGst, setPurchasePricePerBarrelGst] = useState('');
    const [sellingPricePerBarrel, setSellingPricePerBarrel] = useState('');
    const [returnGstPerBarrel, setReturnGstPerBarrel] = useState('');
    const [marginPerPieceBarrel, setMarginPerPieceBarrel] = useState('');
    const [cgstBarrel, setCgstBarrel] = useState('');
    const [sgstBarrel, setSgstBarrel] = useState('');
    const [purchasePricePerLitreIncGst, setPurchasePricePerLitreIncGst] = useState('');
    const [purchasePricePerLitreExcGst, setPurchasePricePerLitreExcGst] = useState('');
    const [purchasePricePerLitreGst, setPurchasePricePerLitreGst] = useState('');
    const [sellingPricePerLitre, setSellingPricePerLitre] = useState('');
    const [returnGstPerLitre, setReturnGstPerLitre] = useState('');
    const [marginPerPiecePerLitre, setMarginPerPiecePerLitre] = useState('');
    const [cgstPerLitre, setCgstPerLitre] = useState('');
    const [sgstPerLitre, setSgstPerLitre] = useState('');
    const [stockValue, setStockValue] = useState('');

    const [nameField, setNameField] = useState([]);
    const [readingField, setReadingField] = useState([]);
    const [stockField, setStockField] = useState([]);
    const [capacityField, setCapacityField] = useState([]);
    const [stockErrors, setStockErrors] = useState([]);


    // onChange functions for each field

    const handleLooseOilChange = (e) => {
        setLooseOil(e.target.value)
    }

    const handleProductNameChange = (e) => {
        const newProductName = e.target.value;
        setProductName(newProductName);
        if (newProductName && packingQuantityInLitre) {
            const combinedName = `${newProductName} ${packingQuantityInLitre} LT`;
            setProductNameWithUnit(combinedName);
        } else {
            setProductNameWithUnit('');
        }
    };

    const handlePackingQuantityChange = (e) => {
        const newPackingQuantity = parseFloat(e.target.value)
        if (newPackingQuantity) {
            if (newPackingQuantity > barrelBalance) {
                setIsGreater(false)
            } else {
                setIsGreater(true)
            }
            setPackingQuantityInLitre(newPackingQuantity);
            calculatePurchasePricePerLitre(parseFloat(purchasePricePerBarrel) || 0, newPackingQuantity, parseFloat(gstInPercent) || 0)
        } else {
            setPackingQuantityInLitre('')
            setPurchasePricePerLitreIncGst('')
            setBarrelBalance('')
        }


        let totalOilMachineStock = stockField.reduce((sum, val) => sum + (parseFloat(val) || 0), 0);

        if (!newPackingQuantity) {
            setTotalStockInLitre('')
        }

        if (productName && newPackingQuantity) {
            const combinedName = `${productName} ${newPackingQuantity} LT`;
            setProductNameWithUnit(combinedName);
        } else {
            setProductNameWithUnit('');
        }

        calculateTotalStock(newPackingQuantity, parseFloat(numberOfBarrel) || 0, parseFloat(barrelBalance) || 0, totalOilMachineStock);
    };

    const handleNumberOfBarrelChange = (e) => {
        const newNumberOfBarrel = parseFloat(e.target.value)
        if (barrelBalance < packingQuantityInLitre) {
            setIsGreater(false)
        }
        if (newNumberOfBarrel || newNumberOfBarrel === 0) {
            setNumberOfBarrel(newNumberOfBarrel);
        } else {
            setNumberOfBarrel("");
            setTotalStockInLitre('')
        }
        let totalOilMachineStock = stockField.reduce((sum, val) => sum + (parseFloat(val) || 0), 0);

        calculateTotalStock(parseFloat(packingQuantityInLitre) || 0, newNumberOfBarrel, parseFloat(barrelBalance) || 0, totalOilMachineStock);
    }

    const handleBarrelBalanceChange = (e) => {
        let newBarrelBalance = parseFloat(e.target.value)
        if (newBarrelBalance || newBarrelBalance === 0) {
            if (newBarrelBalance >= packingQuantityInLitre) {
                setIsGreater(true)
                return
            } else {
                setIsGreater(false)
            }
            setBarrelBalance(newBarrelBalance);
        } else {
            setBarrelBalance('');
        }
        let totalOilMachineStock = stockField.reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
        if (!newBarrelBalance) {
            newBarrelBalance = 0
        }
        calculateTotalStock(parseFloat(packingQuantityInLitre) || 0, parseFloat(numberOfBarrel) || 0, newBarrelBalance, totalOilMachineStock);
    }

    const handleGstInPercentChange = (e) => {
        const gstPercent = e.target.value;
        setGstInPercent(gstPercent);

        const gstPercentValue = parseFloat(gstPercent) || 0;
        const purchasePriceAsNumber = parseFloat(purchasePricePerBarrel) || 0;
        const sellingPriceAsNumber = parseFloat(sellingPricePerBarrel) || 0;

        const purchasePricePerLitreAsNumber = parseFloat(purchasePricePerLitreIncGst) || 0;
        const totalStockValue = parseFloat(totalStockInLitre) || 0;

        if (!gstPercent) {
            setPurchasePricePerBarrelExcGst('');
            setPurchasePricePerBarrelGst('');
            setReturnGstPerBarrel('');
            setCgstBarrel('');
            setSgstBarrel('');
            setPurchasePricePerLitreExcGst('');
            setPurchasePricePerLitreGst('');
            setReturnGstPerLitre('');
            setMarginPerPieceBarrel('');
            setCgstPerLitre('');
            setSgstPerLitre('');
            setMarginPerPiecePerLitre('')
            setStockValue('')
            return;
        }

        const excGst = purchasePriceAsNumber * (100 / (100 + parseFloat(gstPercentValue)))
        const gst = purchasePriceAsNumber * (parseFloat(gstPercent) / (100 + parseFloat(gstPercentValue)))

        const gstLitre = purchasePricePerLitreAsNumber * (parseFloat(gstPercent) / (100 + parseFloat(gstPercentValue)))

        const litreExcGst = purchasePricePerLitreAsNumber * (100 / (100 + parseFloat(gstPercentValue)))
        const litreGst = purchasePricePerLitreAsNumber * (parseFloat(gstPercent) / (100 + parseFloat(gstPercentValue)))

        setPurchasePricePerBarrelExcGst(excGst);
        setPurchasePricePerBarrelGst(gst);

        setPurchasePricePerLitreExcGst(litreExcGst);
        setPurchasePricePerLitreGst(litreGst);

        const halfGst = gst / 2;
        setCgstBarrel(halfGst);
        setSgstBarrel(halfGst);

        const halfGstLitre = gstLitre / 2;
        setCgstPerLitre(halfGstLitre);
        setSgstPerLitre(halfGstLitre);

        calculateReturnGstPerBarrel(sellingPriceAsNumber, purchasePriceAsNumber, gstPercentValue);
        calculateMarginPerPieceBarrel(sellingPriceAsNumber, purchasePriceAsNumber, gstPercentValue);
        calculateReturnGstPerLitre(parseFloat(sellingPricePerLitre) || 0, purchasePricePerLitreAsNumber, gstPercentValue);
        calculateMarginPerPiecePerLitre(parseFloat(sellingPricePerLitre) || 0, purchasePricePerLitreAsNumber, gstPercentValue);
        calculatePurchasePricePerLitre(parseFloat(purchasePricePerBarrel) || 0, parseFloat(packingQuantityInLitre) || 0, gstPercentValue)
        calculateStockValue(litreExcGst, totalStockValue)
    };

    const handlePurchasePricePerBarrelChange = (e) => {
        const price = e.target.value
        setPurchasePricePerBarrel(price);

        const gstPercentValue = parseFloat(gstInPercent) || 0;

        if (!price) {
            setPurchasePricePerBarrelExcGst('');
            setPurchasePricePerBarrelGst('');
            setReturnGstPerBarrel('');
            setCgstBarrel('');
            setSgstBarrel('');
            setPurchasePricePerBarrel('')
            setPurchasePricePerLitreIncGst('')
            setPurchasePricePerLitreExcGst('');
            setPurchasePricePerLitreGst('');
            setReturnGstPerLitre('')
            setCgstPerLitre('');
            setSgstPerLitre('');
            setMarginPerPiecePerLitre('')
            setStockValue('')
            return;
        }

        const priceAsNumber = parseFloat(price);

        const excGst = priceAsNumber * (100 / (100 + gstPercentValue));
        const gst = priceAsNumber * (gstPercentValue / (100 + gstPercentValue));

        setPurchasePricePerBarrelExcGst(excGst);
        setPurchasePricePerBarrelGst(gst);

        const halfGst = gst / 2;
        setCgstBarrel(halfGst);
        setSgstBarrel(halfGst);

        calculateReturnGstPerBarrel(parseFloat(sellingPricePerBarrel) || 0, priceAsNumber, gstPercentValue);
        calculateMarginPerPieceBarrel(parseFloat(sellingPricePerBarrel) || 0, priceAsNumber, gstPercentValue)
        calculatePurchasePricePerLitre(priceAsNumber, parseFloat(packingQuantityInLitre) || 0, gstPercentValue)
    };

    const handleSellingPricePerBarrelChange = (e) => {
        const price = e.target.value
        setSellingPricePerBarrel(price);

        const gstPercentValue = parseFloat(gstInPercent) || 0;

        if (!price) {
            setReturnGstPerBarrel('');
            setMarginPerPieceBarrel('');
            return;
        }

        calculateReturnGstPerBarrel(parseFloat(price), parseFloat(purchasePricePerBarrel) || 0, gstPercentValue);
        calculateMarginPerPieceBarrel(parseFloat(price), parseFloat(purchasePricePerBarrel) || 0, gstPercentValue)
    };

    const handlePurchasePricePerLitreChange = (e) => {
        const price = e.target.value
        setPurchasePricePerLitreIncGst(price);

        const gstPercentValue = parseFloat(gstInPercent) || 0;
        const totalStockValue = parseFloat(totalStockInLitre) || 0;

        if (!price) {
            setPurchasePricePerLitreExcGst('');
            setPurchasePricePerLitreGst('');
            setReturnGstPerLitre('')
            setCgstPerLitre('');
            setSgstPerLitre('');
            setMarginPerPiecePerLitre('')
            setStockValue('')
            return;
        }

        const priceAsNumber = parseFloat(price);

        const excGst = priceAsNumber * (100 / (100 + gstPercentValue));
        const gst = priceAsNumber * (gstPercentValue / (100 + gstPercentValue));

        const halfGst = gst / 2;
        setCgstPerLitre(halfGst);
        setSgstPerLitre(halfGst);

        setPurchasePricePerLitreExcGst(excGst);
        setPurchasePricePerLitreGst(gst);

        calculateReturnGstPerLitre(parseFloat(sellingPricePerLitre) || 0, priceAsNumber, gstPercentValue);
        calculateMarginPerPiecePerLitre(parseFloat(sellingPricePerLitre) || 0, priceAsNumber, gstPercentValue);
        calculateStockValue(excGst, totalStockValue);
    };

    const handleSellingPricePerLitreChange = (e) => {
        const price = e.target.value
        setSellingPricePerLitre(price);

        const gstPercentValue = parseFloat(gstInPercent) || 0;

        if (!price) {
            setReturnGstPerLitre('');
            setMarginPerPiecePerLitre('')
            return;
        }

        calculateReturnGstPerLitre(parseFloat(price), parseFloat(purchasePricePerLitreIncGst) || 0, gstPercentValue);
        calculateMarginPerPiecePerLitre(parseFloat(price), parseFloat(purchasePricePerLitreIncGst) || 0, gstPercentValue)
    };

    // complex calculation functiions

    const calculateReturnGstPerBarrel = (sellingPrice, purchasePrice, gstPercent) => {
        const ans1 = sellingPrice * (100 / (100 + gstPercent));
        const ans2 = purchasePrice * (100 / (100 + gstPercent));
        const ans3 = ans1 - ans2;
        const returnGst = ans3 * (gstPercent / 100);

        setReturnGstPerBarrel(returnGst);
    };

    const calculateMarginPerPieceBarrel = (sellingPrice, purchasePrice, gstPercent) => {
        const ans1 = sellingPrice * (100 / (100 + gstPercent));
        const ans2 = purchasePrice * (100 / (100 + gstPercent));
        const ans3 = ans1 - ans2;
        const returnGst = ans3 * (gstPercent / 100);

        const margin = sellingPrice - (purchasePrice + returnGst);
        setMarginPerPieceBarrel(margin);
    };

    const calculateReturnGstPerLitre = (sellingPrice, purchasePrice, gstPercent) => {
        const ans1 = sellingPrice * (100 / (100 + gstPercent));
        const ans2 = purchasePrice * (100 / (100 + gstPercent));
        const ans3 = ans1 - ans2;
        const returnGst = ans3 * (gstPercent / 100);

        setReturnGstPerLitre(returnGst);
    };

    const calculateMarginPerPiecePerLitre = (sellingPrice, purchasePrice, gstPercent) => {
        const ans1 = sellingPrice * (100 / (100 + gstPercent));
        const ans2 = purchasePrice * (100 / (100 + gstPercent));
        const ans3 = ans1 - ans2;
        const returnGst = ans3 * (gstPercent / 100);

        const margin = sellingPrice - (purchasePrice + returnGst);
        setMarginPerPiecePerLitre(margin);
    };

    const calculateStockValue = (purchasePriceExcGst, totalStockInLitre) => {
        const excGst = formatToTwoDecimal(purchasePriceExcGst);  // ensure it's a number
        const totalStock = parseFloat(totalStockInLitre) || 0;  // ensure it's a number
        const calculatedStockValue = excGst * totalStock;
        setStockValue(calculatedStockValue);  // Update the state
    }

    const calculateTotalStock = (packingQuantity, numberOfBarrel, barrelBalance, totalOilMachineStock) => {
        if (packingQuantity) {
            if (numberOfBarrel || numberOfBarrel === 0) {
                const totalStock = (parseFloat(packingQuantity) * parseFloat(numberOfBarrel)) + parseFloat(barrelBalance) + parseFloat(totalOilMachineStock);
                if (totalStock) {
                    setTotalStockInLitre(totalStock);
                    calculateStockValue(purchasePricePerLitreExcGst, totalStock);
                } else {
                    setTotalStockInLitre(0)
                    setStockValue(0)
                }
            } else {
                setTotalStockInLitre('');
                setStockValue('')
            }
        }
    };

    const calculatePurchasePricePerLitre = (purchasePriceBarrel, packingQuantity, gstPercentValue) => {
        const ans = parseFloat(purchasePriceBarrel) / parseFloat(packingQuantity)
        setPurchasePricePerLitreIncGst(ans);
        const totalStockValue = parseFloat(totalStockInLitre) || 0;

        const excGst = ans * (100 / (100 + gstPercentValue));
        const gst = ans * (gstPercentValue / (100 + gstPercentValue));
        const halfGst = gst / 2;
        setCgstPerLitre(halfGst);
        setSgstPerLitre(halfGst);

        setPurchasePricePerLitreExcGst(excGst);
        setPurchasePricePerLitreGst(gst);


        calculateReturnGstPerLitre(parseFloat(sellingPricePerLitre) || 0, ans, gstPercentValue);
        calculateMarginPerPiecePerLitre(parseFloat(sellingPricePerLitre) || 0, ans, gstPercentValue);
        calculateStockValue(excGst, totalStockValue);
    };

    const formatToTwoDecimal = (value) => {
        const number = parseFloat(value);
        if (isNaN(number) || !isFinite(number)) {
            return '0.00'; // Return a safe default if the value is NaN or Infinity
        }
        return number.toFixed(2); // Format to two decimal places
    };

    // -------------------------------------------------


    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            sendLooseOilDetails();
        }
    }

    const handleEditLooseOilSubmit = (id) => {
        if (validateForm()) {
            sendEditedLooseOilDetails(id)
            handleEditModalClose()
        }
    }

    function check_url() {
        const requestOptions = {
            method: 'post',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                pathurl: window.location.pathname,
            }),
        };
        fetch(`${API_URL}/check_url`, requestOptions)
            .then(response => { return response.json(); })

            .then(responseData => {
                setStartoperation(responseData.stat_operation)
                if (responseData.allowurl == 1) {
                    if (location.location.pathname == "/loose-oil-config/loose-oil-config") {
                        history.push(location.location.pathname);
                    }
                    else {
                        history.push(responseData.url);
                    }
                    ListMla()
                }
                else {

                    history.goBack();
                }

                return responseData;
            })
            .catch(e => {
                console.log(e)
            });

    }

    async function getLooseOilDetailsToEdit(id) {
        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({ id: id })
        };
        await fetch(API_URL + "/edit-loose-oil", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                if (responseData) {
                    if (responseData.loosefields.length > 0) {
                        let nameArray = []
                        let stockArray = []
                        let readingArray = []
                        let capacityArray = []
                        for (let i = 0; i < responseData.loosefields.length; i++) {
                            nameArray.push(responseData.loosefields[i].name_field)
                            stockArray.push(responseData.loosefields[i].stock_field)
                            readingArray.push(responseData.loosefields[i].reading_field)
                            capacityArray.push(responseData.loosefields[i].capacity_field)
                        }
                        setNameField(nameArray)
                        setStockField(stockArray)
                        setReadingField(readingArray)
                        setCapacityField(capacityArray)
                        setOilMachine("1")
                        setNumberOfMachines(responseData.loosefields.length)

                        setAlreadyUsedMachineNames((prev) =>
                            prev.filter(name => !nameArray.includes(name))
                        );
                    } else {
                        setOilMachine("2")
                        setShowAdditional(false)
                    }
                    setLooseOil('yes')
                    setUpdateId(id)
                    setProductName(responseData.product_name)
                    setBarrelBalance(responseData.barrel_balance)
                    setCgstBarrel(responseData.cgst_barrel)
                    setSgstBarrel(responseData.sgst_barrel)
                    setGstInPercent(responseData.gst_in_percent)
                    setNumberOfBarrel(responseData.number_of_barrel)
                    setPackingQuantityInLitre(responseData.packing_quantity_in_litre)
                    setTotalStockInLitre(responseData.total_stock_in_litre)
                    setStockValue(responseData.stock_value)
                    setCgstPerLitre(responseData.cgst_per_litre)
                    setSgstPerLitre(responseData.sgst_per_litre)
                    setPurchasePricePerBarrel(responseData.purchase_price_per_barrel)
                    setPurchasePricePerBarrelExcGst(responseData.purchase_price_per_barrel_exc_gst)
                    setPurchasePricePerBarrelGst(responseData.purchase_price_per_barrel_gst)
                    setPurchasePricePerLitreIncGst(responseData.purchase_price_per_litre_inc_gst)
                    setPurchasePricePerLitreExcGst(responseData.purchase_price_per_litre_exc_gst)
                    setPurchasePricePerLitreGst(responseData.purchase_price_per_litre_gst)
                    setProductNameWithUnit(responseData.product_name_with_unit)
                    setSellingPricePerBarrel(responseData.selling_price_per_barrel)
                    setSellingPricePerLitre(responseData.selling_price_per_litre)
                    setReturnGstPerBarrel(responseData.return_gst_per_barrel)
                    setReturnGstPerLitre(responseData.return_gst_per_litre)
                    setMarginPerPieceBarrel(responseData.margin_per_piece_barrel)
                    setMarginPerPiecePerLitre(responseData.margin_per_piece_per_litre)
                }

                let errors = []
                if (responseData.status == false) {
                    swal("", responseData.message, responseData.status ? "success" : "warning")
                    errors["looseOil"] = responseData.response.looseOil
                    setDouble(true)
                }
                if (responseData.status == true) {
                    swal("", responseData.message, responseData.status ? "success" : "danger")
                    setDouble(true)
                    toggle();

                    // ListMla();
                }
                else {
                    // swal("Something went wrong", "", "warning")
                }
                setErrors(errors)
                return responseData;
            }).catch(e => {
                // swal("Something went wrong","","warning")
                console.log(e);
                setDouble(true)
            });
    }


    async function sendEditedLooseOilDetails(id) {
        setDouble(false)
        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                id: id,
                stat_operation: stat_operation,
                barrel_balance: barrelBalance,
                gst_in_percent: gstInPercent,
                loose_oil: looseOil,
                number_of_barrel: numberOfBarrel,
                packing_quantity_in_litre: packingQuantityInLitre,
                product_name: productName,
                product_name_with_unit: productNameWithUnit,
                purchase_price_per_barrel: purchasePricePerBarrel,
                purchase_price_per_barrel_exc_gst: formatToTwoDecimal(purchasePricePerBarrelExcGst),
                purchase_price_per_barrel_gst: formatToTwoDecimal(purchasePricePerBarrelGst),
                purchase_price_per_litre_inc_gst: formatToTwoDecimal(purchasePricePerLitreIncGst),
                purchase_price_per_litre_exc_gst: formatToTwoDecimal(purchasePricePerLitreExcGst),
                purchase_price_per_litre_gst: formatToTwoDecimal(purchasePricePerLitreGst),
                selling_price_per_barrel: sellingPricePerBarrel,
                selling_price_per_litre: sellingPricePerLitre,
                total_stock_in_litre: totalStockInLitre,
                stock_value: formatToTwoDecimal(stockValue),
                sgst_per_litre: formatToTwoDecimal(sgstPerLitre),
                cgst_per_litre: formatToTwoDecimal(cgstPerLitre),
                margin_per_piece_per_litre: formatToTwoDecimal(marginPerPiecePerLitre),
                return_gst_per_litre: formatToTwoDecimal(returnGstPerLitre),
                return_gst_per_barrel: formatToTwoDecimal(returnGstPerBarrel),
                margin_per_piece_barrel: formatToTwoDecimal(marginPerPieceBarrel),
                cgst_barrel: formatToTwoDecimal(cgstBarrel),
                sgst_barrel: formatToTwoDecimal(sgstBarrel),
                num_of_machines: numberOfMachines,
                name_field: nameField,
                reading_field: readingField,
                stock_field: stockField,
                capacity_field: capacityField
            })
        };
        await fetch(API_URL + "/update-loose-oil", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                setDouble(true)
                console.log(responseData);
                ListMla();
                let errors = []
                if (responseData.status == false) {
                    swal("", responseData.response.message, responseData.status ? "success" : "warning")
                    errors["looseOil"] = responseData.response.looseOil
                    setDouble(true)
                }
                if (responseData.status == true) {
                    swal("", responseData.message, responseData.status ? "success" : "danger")
                    setDouble(true)
                }
                ListMla();
                setErrors(errors)
                return responseData;
            }).catch(e => {
                swal("Something went wrong", "", "warning")
                console.log(e);
                setDouble(true)
            });
    }

    async function handleDeleteLooseOilSubmit(id) {
        setDouble(false)
        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                id: id
            })
        };
        await fetch(API_URL + "/delete-loose-oil", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                ListMla();
                let errors = []
                if (responseData.status == false) {
                    swal("", responseData.message, responseData.status ? "success" : "warning")
                    errors["looseOil"] = responseData.response.looseOil
                    setDouble(true)
                }
                if (responseData.status == true) {
                    swal("", responseData.message, responseData.status ? "success" : "danger")
                    setDouble(true)
                    handleDeleteModalClose()
                    ListMla();
                }
                else {
                    swal("Something went wrong", "", "warning")
                }
                setErrors(errors)
                return responseData;
            }).catch(e => {
                // swal("Something went wrong","","warning")
                console.log(e);
                setDouble(true)
            });
    }


    const sendLooseOilDetails = async () => {
        setDouble(false)
        const requestOptions = {
            method: 'POST',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                stat_operation: stat_operation,
                barrel_balance: barrelBalance,
                gst_in_percent: gstInPercent,
                loose_oil: looseOil,
                number_of_barrel: numberOfBarrel,
                packing_quantity_in_litre: packingQuantityInLitre,
                product_name: productName,
                product_name_with_unit: productNameWithUnit,
                purchase_price_per_barrel: purchasePricePerBarrel,
                purchase_price_per_barrel_exc_gst: formatToTwoDecimal(purchasePricePerBarrelExcGst),
                purchase_price_per_barrel_gst: formatToTwoDecimal(purchasePricePerBarrelGst),
                purchase_price_per_litre_inc_gst: formatToTwoDecimal(purchasePricePerLitreIncGst),
                purchase_price_per_litre_exc_gst: formatToTwoDecimal(purchasePricePerLitreExcGst),
                purchase_price_per_litre_gst: formatToTwoDecimal(purchasePricePerLitreGst),
                selling_price_per_barrel: sellingPricePerBarrel,
                selling_price_per_litre: sellingPricePerLitre,
                total_stock_in_litre: formatToTwoDecimal(totalStockInLitre),
                stock_value: formatToTwoDecimal(stockValue),
                sgst_per_litre: formatToTwoDecimal(sgstPerLitre),
                cgst_per_litre: formatToTwoDecimal(cgstPerLitre),
                margin_per_piece_per_litre: formatToTwoDecimal(marginPerPiecePerLitre),
                return_gst_per_litre: formatToTwoDecimal(returnGstPerLitre),
                return_gst_per_barrel: formatToTwoDecimal(returnGstPerBarrel),
                margin_per_piece_barrel: formatToTwoDecimal(marginPerPieceBarrel),
                cgst_barrel: formatToTwoDecimal(cgstBarrel),
                sgst_barrel: formatToTwoDecimal(sgstBarrel),
                num_of_machines: numberOfMachines,
                name_field: nameField,
                reading_field: readingField,
                stock_field: stockField,
                capacity_field: capacityField
            })
        };
        await fetch(API_URL + "/save-loose-oil", requestOptions)
            .then(response => {
                return response.json();
            })
            .then(responseData => {
                let errors = []
                console.log(responseData);
                if (responseData.status == false) {
                    swal("", responseData.response.message, responseData.status ? "success" : "warning")
                    errors["looseOil"] = responseData.response.looseOil
                    setDouble(true)
                }
                if (responseData.status == true) {
                    swal("", 'Saved Successfully', "success")
                    setDouble(true)
                    toggle();
                }
                ListMla();
                setErrors(errors)
                return responseData;
            }).catch(e => {
                swal("Something went wrong", "", "warning")
                console.log(e);
                setDouble(true)
            });
    }

    const preventToFour = (event, currentValue) => {
        const char = String.fromCharCode(event.which);
    
        // Allow digits, one decimal point, and basic control keys (like backspace, delete, arrows, etc.)
        const allowedSpecialKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
        if (allowedSpecialKeys.includes(event.key)) {
            return; // Allow these keys
        }
    
        // Only allow digits and decimal points
        if (!/^[0-9.]$/.test(char)) {
            event.preventDefault(); // If not a digit or a decimal point, prevent the action
            return;
        }
    
        // Split current input into parts: before and after decimal point
        const parts = currentValue.split(".");
    
        // Allow only one decimal point
        if (char === "." && parts.length > 1) {
            event.preventDefault(); // If there's already a decimal point, prevent a new one
            return;
        }
    
        // If no decimal point, ensure the first part (before the decimal) doesn't exceed 5 digits
        if (parts.length === 1 && parts[0].length >= 5 && char !== ".") {
            event.preventDefault(); // If trying to add more than 5 digits before the decimal, prevent the action
            return;
        }
    
        // If there's a decimal point, ensure only 2 digits are allowed after it
        if (parts.length === 2 && parts[1].length >= 2) {
            event.preventDefault(); // If trying to add more than 2 digits after the decimal, prevent the action
            return;
        }
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        // Validation for Loose Oil selection
        if ((!looseOil || looseOil === "select") && list_data.length === 0) {
            formIsValid = false;
            errors["looseOil"] = "Please select if you have Loose Oil";
        } else {
            errors["looseOil"] = "";
        }

        if (looseOil === "yes" || list_data.length > 0) {
            // Validation for Product Name
            if (!productName) {
                formIsValid = false;
                errors["productName"] = "Please enter the product name";
            } else {
                errors["productName"] = "";
            }

            // Validation for Packing Quantity in Litres
            if ((!packingQuantityInLitre || packingQuantityInLitre <= 0) && stat_operation !== "Yes") {
                formIsValid = false;
                errors["packingQuantityInLitre"] = "Please enter a valid packing quantity in litres";
            } else {
                errors["packingQuantityInLitre"] = "";
            }

            if (parseFloat(packingQuantityInLitre) <= parseFloat(barrelBalance)) {
                formIsValid = false;
                errors["barrelBalance"] = "Barrel Balance cannot be greater than or equal to Packing Quantity";
            } else {
                errors["barrelBalance"] = "";
            }

            // Validation for Number of Barrels
            if ((numberOfBarrel === '' || isNaN(numberOfBarrel) || numberOfBarrel < 0) && stat_operation !== "Yes") {
                formIsValid = false;
                errors["numberOfBarrel"] = "Please enter a valid number of barrels";
            } else {
                errors["numberOfBarrel"] = "";
            }

            // Validation for Barrel Balance
            if ((barrelBalance === '' || isNaN(barrelBalance) || barrelBalance < 0) && stat_operation !== "Yes") {
                formIsValid = false;
                errors["barrelBalance"] = "Please enter a valid barrel balance";
            } else {
                errors["barrelBalance"] = "";
            }

            if (!oilMachine) {
                formIsValid = false;
                errors["oilMachine"] = "Please select if you have oil machines";
            } else if (oilMachine === "1") {
                // Check if machines need to be validated
                // Number of machines should be positive
                if (!numberOfMachines || isNaN(numberOfMachines) || numberOfMachines < 1) {
                    formIsValid = false;
                    errors["numberOfMachines"] = "Please enter a valid number of machines";
                }

                // Validate machine-related fields
                const uniqueNames = new Set();
                for (let i = 0; i < numberOfMachines; i++) {
                    const currentName = nameField[i]?.trim();
                    const lowerCaseName = currentName?.toLowerCase();

                    if (!currentName || currentName === "") {
                        formIsValid = false;
                        errors[`machineName${i}`] = `Machine ${i + 1} name is required`;
                    } else if (uniqueNames.has(lowerCaseName)) {
                        formIsValid = false;
                        errors[`machineName${i}`] = `Machine ${i + 1} name must be unique`;
                    } else if (alreadyUsedMachineNames.map(name => name.toLowerCase()).includes(lowerCaseName)) {
                        formIsValid = false;
                        errors[`machineName${i}`] = `Machine name already taken`;
                    } else {
                        uniqueNames.add(lowerCaseName);
                    }

                    const capacity = parseFloat(capacityField[i]);
                    const stock = parseFloat(stockField[i]);
                    const reading = parseFloat(readingField[i]);

                    if (!capacity || isNaN(capacity) || capacity <= 0) {
                        formIsValid = false;
                        errors[`machineCapacity${i}`] = `Machine ${i + 1} capacity must be positive`;
                    }

                    if ((!stock || isNaN(stock)) && stat_operation !== "Yes") {
                        formIsValid = false;
                        console.log(formIsValid);
                        errors[`machineStock${i}`] = `Machine ${i + 1} stock is required`;
                    } else if (stock > capacity) {
                        formIsValid = false;
                        errors[`machineStock${i}`] = `Machine ${i + 1} stock cannot exceed capacity`;
                    }

                    if ((!reading || isNaN(reading)) && reading !== 0) {
                        formIsValid = false;
                        errors[`machineReading${i}`] = `Machine ${i + 1} reading is required`;
                    }
                }
            }

            // Validation for GST Percentage
            if (!gstInPercent || gstInPercent <= 0) {
                formIsValid = false;
                errors["gstInPercent"] = "Please enter a valid GST percentage";
            } else {
                errors["gstInPercent"] = "";
            }

            // Validation for Purchase Price per Barrel
            if (!purchasePricePerBarrel || purchasePricePerBarrel <= 0) {
                formIsValid = false;
                errors["purchasePricePerBarrel"] = "Please enter a valid purchase price per barrel";
            } else {
                errors["purchasePricePerBarrel"] = "";
            }

            // Validation for Selling Price per Barrel
            if (!sellingPricePerBarrel || sellingPricePerBarrel <= 0) {
                formIsValid = false;
                errors["sellingPricePerBarrel"] = "Please enter a valid selling price per barrel";
            } else {
                errors["sellingPricePerBarrel"] = "";
            }

            if (!sellingPricePerLitre || sellingPricePerLitre <= 0) {
                formIsValid = false;
                errors["sellingPricePerLitre"] = "Please enter a valid selling price per barrel";
            } else {
                errors["sellingPricePerLitre"] = "";
            }

        }

        if (oilMachine === "1" && !numberOfMachines) {
            formIsValid = false;
            errors["numberOfOilMachines"] = "Either Enter non-zero number of Machines or Select No";
        } else {
            errors["numberOfOilMachines"] = "";
        }

        setErrors(errors);
        return formIsValid;
    };

    console.log(errors);

    const ListMla = async () => {
        const requestOptions = {
            method: 'post',
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
                "Accept": "application/json"

            },
        };
        await fetch(API_URL + "/get-loose-oil", requestOptions)
            .then(response => { return response.json(); })
            .then(responseData => {
                console.log(responseData);
                if (responseData.status == 3) {
                    setPermission({ ...permission, status: responseData.status, message: responseData.message })
                }

                setList(responseData.data)
                setCheck(responseData.meta)
                const machineNames = [];

                responseData.data.forEach(item => {
                    if (item.loosefields) {
                        item.loosefields.forEach(field => {
                            if (field.name_field && !machineNames.includes(field.name_field)) {
                                machineNames.push(field.name_field);
                            }
                        });
                    }
                });
                console.log(machineNames);

                // Set the machine names to the state
                setAlreadyUsedMachineNames(machineNames);

                setLoading(true)
                ckeckOption({
                    ...checkoption,
                    isdayclose: responseData.isdayclose
                })
                return responseData;
            })
            .catch(e => {
                console.log(e);

            });
    }

    function checkSkip() {
        history.push("/Productsconfig")
    }

    const backPage = useMemo(() => {

        if (location?.location?.backpage) {
            return location?.location?.backpage
        }
        else
            return undefined
    }, [location?.location?.pathname])

    const [double, setDouble] = useState(true);
    const [permission, setPermission] = useState([])

    const UseModal = () => {
        const [isShowing, setIsShowing] = useState(false);
        function toggle() {
            setProductName('')
            setBarrelBalance('')
            setCgstBarrel('')
            setSgstBarrel('')
            setGstInPercent('')
            setNumberOfBarrel('')
            setPackingQuantityInLitre('')
            setNumberOfMachines('')
            setTotalStockInLitre('')
            setStockValue('')
            setCgstPerLitre('')
            setSgstPerLitre('')
            setPurchasePricePerBarrel('')
            setPurchasePricePerBarrelExcGst('')
            setPurchasePricePerBarrelGst('')
            setPurchasePricePerLitreIncGst('')
            setPurchasePricePerLitreExcGst('')
            setPurchasePricePerLitreGst('')
            setProductNameWithUnit('')
            setSellingPricePerBarrel('')
            setSellingPricePerLitre('')
            setReturnGstPerBarrel('')
            setReturnGstPerLitre('')
            setMarginPerPieceBarrel('')
            setMarginPerPiecePerLitre('')
            setNameField([])
            setStockField([])
            setReadingField([])
            setCapacityField([])
            setOilMachine(null)
            setLooseOil('')
            setShowAdditional(false)
            var isdayclose = checkoption.isdayclose;
            if (isdayclose == 1) {
                swal("", "You do not have this permission after day close", false ? "success" : "warning")
            } else {
                if (isShowing) {

                }
                if (!isShowing) {
                    setDouble(true);
                    setErrors({})
                }
                setIsShowing(!isShowing);
                setErrors({})
            }
        }
        return {
            isShowing,
            toggle,
        }
    };

    const { isShowing, toggle } = UseModal();
    const [search, setSearch] = useState({})

    useEffect(() => {
        check_url()
        setLocation(location.location.pathname)
    }, [search]);


    const handleNumMachinesChange = (e) => {
        const num = parseInt(e.target.value, 10);
        if (isNaN(num) || num < 1) {
            setNumberOfMachines(1);
            console.error('Invalid number of machines');
            let totalOilMachineStock = stockField.reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
            calculateTotalStock(packingQuantityInLitre, numberOfBarrel, barrelBalance, totalOilMachineStock)
        } else {
            setNumberOfMachines(num);
            setNameField((prev) => resizeArray(prev, num, ''));
            setStockField((prev) => resizeArray(prev, num, ''));
            setReadingField((prev) => resizeArray(prev, num, ''));
            setCapacityField((prev) => resizeArray(prev, num, ''));
            setStockErrors((prev) => resizeArray(prev, num, ''));
            const totalOilMachineStock = stockField.slice(0, num).reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
            calculateTotalStock(packingQuantityInLitre, numberOfBarrel, barrelBalance, totalOilMachineStock)
        }
    };

    const resizeArray = (arr, newSize, defaultValue) => {
        if (arr.length > newSize) {
            return arr.slice(0, newSize);
        } else {
            return [...arr, ...Array(newSize - arr.length).fill(defaultValue)];
        }
    };

    const handleRemoveMachine = (index) => {
        const newStockField = [...stockField];
        const newArr = newStockField.filter((_, i) => i !== index)
        setNameField(nameField.filter((_, i) => i !== index));
        setStockField(stockField.filter((_, i) => i !== index));
        setReadingField(readingField.filter((_, i) => i !== index));
        setCapacityField(capacityField.filter((_, i) => i !== index));
        setStockErrors(stockErrors.filter((_, i) => i !== index));
        setNumberOfMachines(numberOfMachines - 1);

        const totalOilMachineStock = newArr.reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
        calculateTotalStock(packingQuantityInLitre, numberOfBarrel, barrelBalance, totalOilMachineStock);
    };

    const incrementMachines = () => {
        setNumberOfMachines((prev) => prev + 1);
    };

    const decrementMachines = () => {
        setNumberOfMachines((prev) => {
            const newCount = prev > 1 ? prev - 1 : 1;
            // Trim all related arrays to match the new number of machines
            setNameField((prevFields) => prevFields.slice(0, newCount));
            setStockField((prevFields) => prevFields.slice(0, newCount));
            setReadingField((prevFields) => prevFields.slice(0, newCount));
            setCapacityField((prevFields) => prevFields.slice(0, newCount));
            setStockErrors((prevErrors) => prevErrors.slice(0, newCount));

            // Recalculate total stock with updated fields
            const totalOilMachineStock = stockField.slice(0, newCount).reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
            calculateTotalStock(packingQuantityInLitre, numberOfBarrel, barrelBalance, totalOilMachineStock);

            return newCount;
        });
    };

    const handleNameChange = (index, value) => {
        const newNameField = [...nameField];
        newNameField[index] = value;
        setNameField(newNameField);
    };

    const handleStockChange = (index, value) => {
        const newStockField = [...stockField];
        const stockValue = parseFloat(value);
        const capacity = parseFloat(capacityField[index]) || 0;
        const newStockErrors = [...stockErrors];
        if (stockValue > capacity) {
            newStockErrors[index] = 'Stock could not be greater than machine capacity';
            setStockErrors(newStockErrors);
        } else {
            newStockErrors[index] = '';
            setStockErrors(newStockErrors);
        }
        newStockField[index] = stockValue;
        setStockField(newStockField);

        let totalOilMachineStock = newStockField.reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
        calculateTotalStock(parseFloat(packingQuantityInLitre) || 0, parseFloat(numberOfBarrel) || 0, parseFloat(barrelBalance) || 0, totalOilMachineStock);
    };

    const handleReadingChange = (index, value) => {
        const newStockErrors = [...stockErrors];
        if (stockField[index] > capacityField[index]) {
            newStockErrors[index] = 'Stock could not be greater than machine capacity';
            setStockErrors(newStockErrors);
        } else {
            newStockErrors[index] = '';
            setStockErrors(newStockErrors);
        }
        const newReadingField = [...readingField];
        newReadingField[index] = value;
        setReadingField(newReadingField);
    };

    const handleCapacityChange = (index, value) => {
        const newCapacityField = [...capacityField];
        newCapacityField[index] = value;
        const newStockErrors = [...stockErrors];
        if (stockField[index] > newCapacityField[index]) {
            newStockErrors[index] = 'Stock could not be greater than machine capacity';
            setStockErrors(newStockErrors);
        } else {
            newStockErrors[index] = '';
            setStockErrors(newStockErrors);
        }
        setCapacityField(newCapacityField);
    };


    const renderAdditionalFields = () => {
        const fields = [];
        for (let i = 0; i < numberOfMachines; i++) {
            fields.push(
                <div key={i} className="form-group">
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                        <button type="button" style={{ border: "0" }} onClick={() => handleRemoveMachine(i)} className="btn-danger rounded px-4 py-1 mt-2">Remove</button>
                    </div>
                    <label>Name of Oil Machine {i + 1}: <b>{productName}</b><sup class="star">*</sup></label>
                    <input
                        type="text"
                        className="form-control"
                        value={nameField[i]}
                        onChange={(e) => handleNameChange(i, e.target.value)}
                    />
                    {errors[`machineName${i}`] && <div className="errorMsg">{errors[`machineName${i}`]}</div>}
                    <label>Capacity of Oil Machine {i + 1}(L) <sup class="star">*</sup></label>
                    <input
                        type="number"
                        className="form-control hide-arrow"
                        onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
                        value={capacityField[i]}
                        onChange={(e) => handleCapacityChange(i, e.target.value)}
                    />
                    {errors[`machineCapacity${i}`] && <div className="errorMsg">{errors[`machineCapacity${i}`]}</div>}
                    <label>Stock of Oil Machine {i + 1}(L) <sup class="star">*</sup></label>
                    {
                        stat_operation == "Yes" ? (
                            <input
                                disabled
                                type="number"
                                className="form-control"
                                value={stockField[i]}
                                onChange={(e) => handleStockChange(i, e.target.value)}
                            />
                        ) : (
                            <>
                                <input
                                    type="number"
                                    className="form-control hide-arrow"
                                    onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
                                    value={stockField[i]}
                                    onChange={(e) => handleStockChange(i, e.target.value)}
                                />
                                {errors[`machineStock${i}`] && <div className="errorMsg">{errors[`machineStock${i}`]}</div>}
                            </>
                        )
                    }

                    <label>Opening Reading of Oil Machine {i + 1} <sup class="star">*</sup></label>
                    <input
                        type="number"
                        className="form-control hide-arrow"
                        value={readingField[i]}
                        onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
                        onChange={(e) => handleReadingChange(i, e.target.value)}
                    />
                    {errors[`machineReading${i}`] && <div className="errorMsg">{errors[`machineReading${i}`]}</div>}
                </div>
            );
        }
        return fields;
    };

    const handleOptionChange = (e) => {
        const value = e.target.value;
        setOilMachine(value)
        if (value === "1") {
            setShowAdditional(true);
            setNumberOfMachines(1);
        } else {
            calculateTotalStock(packingQuantityInLitre, numberOfBarrel, barrelBalance, 0)
            setShowAdditional(false);
            setNumberOfMachines('');
            setNameField([]);
            setStockField([]);
            setReadingField([]);
            setCapacityField([]);
        }
    };

    const handleEditModalOpen = (id) => {
        getLooseOilDetailsToEdit(id)
        setShowAdditional(true)
        setIsEditOpen(true)
    }
    const handleDeleteModalOpen = (id) => {
        setDeleteId(id)
        setIsDeleteOpen(true)
    }
    const handleEditModalClose = () => {
        setOilMachine(null)
        setNumberOfMachines('');
        setNameField([]);
        setStockField([]);
        setReadingField([]);
        setCapacityField([]);
        setIsEditOpen(false)
        setShowAdditional(false)
    }
    const handleDeleteModalClose = () => {
        setIsDeleteOpen(false)
    }

    return (
        <>
            <div className="main-container page-wrap">
                <div className="d-md-flex">
                    {path == "/loose-oil-config/loose-oil-config" ? <Sidebar_Progress path={path} /> : <Sidebar_Progress />}
                    <div className="content-area">
                        <LoginHeader page_title="" />

                        <div className="container pb-3">
                            <div className="row mt-3">

                                <div className="col-md-12 mb-3 px-4 ">{path == "/loose-oil-config/loose-oil-config" ? <Link to={backPage || "/operational-date"}>
                                    <a href="#" className="float-end btn btn-gray">Go Back</a>
                                </Link> : <Link to="/fuel-config-report">
                                    <a href="fuel-config-report" className="float-end btn btn-gray">Back</a>
                                </Link>}</div>
                            </div>
                            <div className="row justify-content-center mt-md-3 mt-3 form-container">
                                <div className="col-md-12">

                                    {permission.status == 3 ?
                                        <div className="form-wrap">
                                            <div className="text-center">
                                                {permission.message}
                                            </div></div> :
                                        <>
                                            <div>
                                                {Loading ? ListMla : <Loader />}
                                                <div className="form-wrap list-wrp">
                                                    <div className="row mb-3">
                                                        <div className="col-md">
                                                            <div className="row">
                                                                <div className="col-md-1 col-2 pe-0">
                                                                    <img src={museum} width="25px" className="img-fluid" alt=".." />
                                                                </div>
                                                                <div className="col-md col pl-0">
                                                                    <h3 className="table-title">Loose Oil Configuration </h3>
                                                                </div>
                                                                <div className="col-md-7">
                                                                    <a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Loose/Barrel Sales</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                        </div>
                                                    </div>


                                                    <div className="table-widget">
                                                        <table className="table border">
                                                            <thead>
                                                                <tr>
                                                                    <th>Product Name</th>
                                                                    <th>Packing Quantity (L)</th>
                                                                    <th>Number Of Barrel</th>
                                                                    <th>Barrel Balance</th>
                                                                    <th>Machine Name</th>
                                                                    <th>Machine Stock</th>
                                                                    <th>Total Quantity</th>
                                                                    <th>Gst (%)</th>
                                                                    <th>Purchased price per Barrel</th>
                                                                    <th>Selling price per Barrel</th>
                                                                    <th>Purchased price per litre</th>
                                                                    <th>Selling price per litre</th>
                                                                    <th>Stock Value</th>
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {list_data.map((mlacoupon, index) => {
                                                                    const looseFields = mlacoupon.loosefields || [];
                                                                    const rowSpan = looseFields.length || 1; // If looseFields is empty, set rowSpan to 1

                                                                    if (looseFields.length === 0) {
                                                                        // When looseFields is empty, create a single row
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{mlacoupon.product_name}</td>
                                                                                <td>{mlacoupon.packing_quantity_in_litre}</td>
                                                                                <td>{mlacoupon.number_of_barrel}</td>
                                                                                <td>{mlacoupon.barrel_balance}</td>
                                                                                <td colSpan={2}>No Machine Added</td> {/* Fill in with a placeholder or appropriate message */}
                                                                                <td>{mlacoupon.total_stock_in_litre}</td>
                                                                                <td>{mlacoupon.gst_in_percent}</td>
                                                                                <td>{mlacoupon.purchase_price_per_barrel}</td>
                                                                                <td>{mlacoupon.selling_price_per_barrel}</td>
                                                                                <td>{mlacoupon.purchase_price_per_litre_inc_gst}</td>
                                                                                <td>{mlacoupon.selling_price_per_litre}</td>
                                                                                <td>{mlacoupon.stock_value}</td>
                                                                                <td>
                                                                                    <div className="d-flex icon-btn">
                                                                                        <button onClick={() => handleEditModalOpen(mlacoupon.id)}>
                                                                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                                        </button>
                                                                                        <button onClick={() => handleDeleteModalOpen(mlacoupon.id)}>
                                                                                            <i className="fa fa-trash" aria-hidden="true"></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }

                                                                    // If looseFields has data, map through it and apply rowSpan
                                                                    return looseFields.map((looseField, subIndex) => (
                                                                        <tr key={`${index}-${subIndex}`}>
                                                                            {subIndex === 0 && (
                                                                                <>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.product_name}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.packing_quantity_in_litre}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.number_of_barrel}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.barrel_balance}</td>
                                                                                </>
                                                                            )}

                                                                            <td>{looseField.name_field}</td>
                                                                            <td>{looseField.stock_field}</td>

                                                                            {subIndex === 0 && (
                                                                                <>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.total_stock_in_litre}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.gst_in_percent}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.purchase_price_per_barrel}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.selling_price_per_barrel}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.purchase_price_per_litre_inc_gst}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.selling_price_per_litre}</td>
                                                                                    <td rowSpan={rowSpan}>{mlacoupon.stock_value}</td>
                                                                                    <td rowSpan={rowSpan}>
                                                                                        <div className="d-flex icon-btn">
                                                                                            <button onClick={() => handleEditModalOpen(mlacoupon.id)}>
                                                                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                                                                            </button>
                                                                                            <button onClick={() => handleDeleteModalOpen(mlacoupon.id)}>
                                                                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            )}
                                                                        </tr>
                                                                    ));
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {path == "/loose-oil-config/loose-oil-config" ? "" :
                                                    <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={checkSkip}>Continue</a></div>
                                                }
                                            </div>
                                        </>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {isShowing ? (
                <>
                    <div className="modal-overlay" />
                    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                        <div className="modal" style={{ maxWidth: "800px" }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Loose Oil Configuration</h5>
                                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={toggle}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {
                                list_data.length === 0 &&
                                <>
                                    <div className="row justify-content-center">
                                        <label className="col-sm-5 col-form-label mb-3">Do you have Loose Oil?  <sup class="star">*</sup>   </label>
                                        <div className="col-sm-4">
                                            <div className="select-container highlight"><select class="form-control" name="loose_oil" value={looseOil} onChange={handleLooseOilChange}><option value="select">select</option><option value="yes">Yes</option><option value="no">No</option></select></div>
                                            {
                                                errors['looseOil'] && <div className="errorMsg">
                                                    {errors['looseOil']}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="row justify-content-center py-4">
                                {(looseOil == "yes" || list_data.length > 0) &&
                                    <>
                                        <div className="row">
                                            <div className="col-md-6">

                                                <form action="#">
                                                    <div className="form-group">
                                                        <label for="name">Product Name <sup class="star">*</sup></label>
                                                        <input type="text" id="product_name" name="product_name" value={productName} onChange={handleProductNameChange} className="form-control" />
                                                        {
                                                            errors['productName'] && <div className="errorMsg">
                                                                {errors['productName']}
                                                            </div>
                                                        }
                                                    </div>



                                                    {
                                                        stat_operation == "Yes" ? (<div className="form-group">
                                                            <label for="email">Packing Quantity in lt <sup class="star">*</sup></label>
                                                            <input disabled type="text" id="packing_quantity_in_litre" name="packing_quantity_in_litre" onKeyPress={(event) => preventToFour(event, event.target.value)} value={packingQuantityInLitre} onChange={handlePackingQuantityChange} className="form-control hide-arrow" />
                                                            {
                                                                errors['packingQuantityInLitre'] && <div className="errorMsg">
                                                                    {errors['packingQuantityInLitre']}
                                                                </div>
                                                            }
                                                        </div>) : (<div className="form-group">
                                                            <label for="email">Packing Quantity in lt <sup class="star">*</sup></label>
                                                            <input type="number" id="packing_quantity_in_litre" name="packing_quantity_in_litre" onKeyPress={(event) => preventToFour(event, event.target.value)} value={packingQuantityInLitre} onChange={handlePackingQuantityChange} className="form-control hide-arrow" />
                                                            {
                                                                errors['packingQuantityInLitre'] && <div className="errorMsg">
                                                                    {errors['packingQuantityInLitre']}
                                                                </div>
                                                            }
                                                        </div>)
                                                    }



                                                    <div className="form-group">
                                                        <label for="email">Product Name with unit:</label>
                                                        <input type="email" id="product_name_with_unit" name="product_name_with_unit" value={productNameWithUnit} className="form-control" readOnly />
                                                    </div>



                                                    <div className="form-group">
                                                        <label for="email">Total Stock in Lt:(barrel + barrel balance + oil machine)</label>
                                                        <input type="email" id="total_stock_in_litre" name="total_stock_in_litre" value={totalStockInLitre} className="form-control" readOnly />
                                                    </div>

                                                    {
                                                        stat_operation == "Yes" ? (<div className="form-group">
                                                            <label for="email">No of Full Barrel <sup class="star">*</sup></label>
                                                            <input type="text" id="email" name="number_of_barrel" disabled className="form-control" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={numberOfBarrel} onChange={handleNumberOfBarrelChange} />
                                                            {
                                                                errors['numberOfBarrel'] && <div className="errorMsg">
                                                                    {errors['numberOfBarrel']}
                                                                </div>
                                                            }
                                                        </div>) : (<div className="form-group">
                                                            <label for="email">No of Full Barrel <sup class="star">*</sup></label>
                                                            <input type="text" id="email" name="number_of_barrel" className="form-control" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={numberOfBarrel} onChange={handleNumberOfBarrelChange} />
                                                            {
                                                                errors['numberOfBarrel'] && <div className="errorMsg">
                                                                    {errors['numberOfBarrel']}
                                                                </div>
                                                            }
                                                        </div>)
                                                    }



                                                    {
                                                        stat_operation == "Yes" ? (<div className="form-group">
                                                            <label for="email">Barrel Balance in litre <sup class="star">*</sup></label>
                                                            <input disabled type="text" id="email" name="barrel_balance" className="form-control" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={barrelBalance} onChange={handleBarrelBalanceChange} />
                                                            {
                                                                isGreater && <div className="errorMsg">
                                                                    Barrel balance cannot be greater than packing quantity
                                                                </div>
                                                            }
                                                            {
                                                                errors['barrelBalance'] && <div className="errorMsg">
                                                                    {errors['barrelBalance']}
                                                                </div>
                                                            }
                                                        </div>) : (
                                                            <div className="form-group">
                                                                <label for="email">Barrel Balance in litre<sup class="star">*</sup></label>
                                                                <input type="text" id="email" name="barrel_balance" className="form-control" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={barrelBalance} onChange={handleBarrelBalanceChange} />
                                                                {
                                                                    isGreater && <div className="errorMsg">
                                                                        Barrel balance cannot be greater than packing quantity
                                                                    </div>
                                                                }
                                                                {
                                                                    errors['barrelBalance'] && <div className="errorMsg">
                                                                        {errors['barrelBalance']}
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    }

                                                    <div className="form-group">
                                                        <label for="category">Oil machine <sup class="star">*</sup></label>
                                                        <select id="option" name="category" className="form-control" value={oilMachine} onChange={handleOptionChange} required>
                                                            <option value="">Select a category</option>
                                                            <option value="1">Yes</option>
                                                            <option value="2">No</option>
                                                            {/* <option value="Option 3">Option 3</option> */}
                                                        </select>
                                                        {
                                                            errors['oilMachine'] && <div className="errorMsg">
                                                                {errors['oilMachine']}
                                                            </div>
                                                        }
                                                    </div>



                                                    {showAdditional && (
                                                        <div id="additionalFields">
                                                            <div className="form-group">
                                                                <label htmlFor="num_of_machines">Number of Oil Machine:</label>
                                                                <div className="input-group">
                                                                    <button
                                                                        className="btn-add"
                                                                        type="button"
                                                                        onClick={decrementMachines}
                                                                        disabled={numberOfMachines <= 0}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <input
                                                                        disabled
                                                                        type="number"
                                                                        id="num_of_machines"
                                                                        name="num_of_machines"
                                                                        className="form-control text-center ml-3 hide-arrow"
                                                                        value={numberOfMachines}
                                                                        onChange={handleNumMachinesChange}
                                                                        min="0"
                                                                    />
                                                                    <button
                                                                        className="btn-add"
                                                                        type="button"
                                                                        onClick={incrementMachines}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {
                                                                errors['numberOfOilMachines'] && <div className="errorMsg">
                                                                    {errors['numberOfOilMachines']}
                                                                </div>
                                                            }
                                                            <div id="studentFields">
                                                                {renderAdditionalFields()}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="form-group">
                                                        <label for="phone">GST % <sup class="star">*</sup></label>
                                                        <input type="number" id="phone" name="gst_in_percent" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInputNON(event, event.target.value)} value={gstInPercent} onChange={handleGstInPercentChange} />
                                                        {
                                                            errors['gstInPercent'] && <div className="errorMsg">
                                                                {errors['gstInPercent']}
                                                            </div>
                                                        }
                                                    </div>


                                                </form>
                                            </div>

                                            <div className="col-md-6">
                                                {/* <h3>Column 2</h3> */}
                                                <form>

                                                    <div className="form-group">
                                                        <label for="message">Purchase Price per Barrel (inc gst) <sup class="star">*</sup></label>
                                                        <input type="number" id="email" name="purchase_price_per_barrel" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={purchasePricePerBarrel} onChange={handlePurchasePricePerBarrelChange} />
                                                        {
                                                            errors['purchasePricePerBarrel'] && <div className="errorMsg">
                                                                {errors['purchasePricePerBarrel']}
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label for="message">Purchase Price per Barrel (exc gst):</label>
                                                        <input type="number" id="email" name="purchase_price_per_barrel_exc_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerBarrelExcGst)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">Purchase Price per Barrel (gst):</label>
                                                        <input type="number" id="email" name="purchase_price_per_barrel_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerBarrelGst)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">Selling price/barrel <sup class="star">*</sup></label>
                                                        <input type="number" id="email" name="selling_price_per_barrel" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={sellingPricePerBarrel} onChange={handleSellingPricePerBarrelChange} />
                                                        {
                                                            errors['sellingPricePerBarrel'] && <div className="errorMsg">
                                                                {errors['sellingPricePerBarrel']}
                                                            </div>
                                                        }
                                                    </div>


                                                    <div className="form-group">
                                                        <label for="message">Return Gst Barrel:</label>
                                                        <input type="email" id="email" name="return_gst_per_barrel" className="form-control" value={formatToTwoDecimal(returnGstPerBarrel)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">Margin/piece Barrel:</label>
                                                        <input type="email" id="email" name="margin_per_piece_barrel" className="form-control" value={formatToTwoDecimal(marginPerPieceBarrel)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">cgst Barrel:</label>
                                                        <input type="email" id="email" name="cgst_barrel" className="form-control" value={formatToTwoDecimal(cgstBarrel)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">sgst Barrel:</label>
                                                        <input type="email" id="email" name="sgst_barrel" className="form-control" value={formatToTwoDecimal(sgstBarrel)} readOnly />
                                                    </div>



                                                    <div className="form-group">
                                                        <label for="message">Purchase Price per lt (inc gst):</label>
                                                        <input type="number" id="email" name="purchase_price_per_litre_inc_gst" className="form-control hide-arrow" value={formatToTwoDecimal(purchasePricePerLitreIncGst)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">Purchase Price per lt (exc gst):</label>
                                                        <input type="email" id="email" name="purchase_price_per_litre_exc_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerLitreExcGst)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">Purchase Price per lt (gst):</label>
                                                        <input type="email" id="email" name="purchase_price_per_litre_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerLitreGst)} readOnly />
                                                    </div>

                                                    <div className="form-group">
                                                        <label for="message">Selling price/lt <sup class="star">*</sup></label>
                                                        <input type="number" id="email" name="selling_price_per_litre" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={sellingPricePerLitre} onChange={handleSellingPricePerLitreChange} />
                                                        {
                                                            errors['sellingPricePerLitre'] && <div className="errorMsg">
                                                                {errors['sellingPricePerLitre']}
                                                            </div>
                                                        }
                                                    </div>



                                                    <div className="form-group">
                                                        <label for="message">Return Gst / lt:</label>
                                                        <input type="number" id="email" name="return_gst_per_litre" className="form-control" value={formatToTwoDecimal(returnGstPerLitre)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">Margin/piece / lt:</label>
                                                        <input type="number" id="margin_per_piece_per_litre" name="margin_per_piece_per_litre" className="form-control" value={formatToTwoDecimal(marginPerPiecePerLitre)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">cgst / lt:</label>
                                                        <input type="number" id="email" name="cgst_per_litre" className="form-control" value={formatToTwoDecimal(cgstPerLitre)} readOnly />
                                                    </div>
                                                    <div className="form-group">
                                                        <label for="message">sgst / lt:</label>
                                                        <input type="number" id="email" name="sgst_per_litre" className="form-control" value={formatToTwoDecimal(sgstPerLitre)} readOnly />
                                                    </div>

                                                    <div className="form-group">
                                                        <label for="message">stock value:</label>
                                                        <input type="number" id="stock_value" name="stock_value" className="form-control" value={formatToTwoDecimal(stockValue)} readOnly />
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </>}

                                <div class="modal-footer text-center">
                                    {double == true ? <button type="submit" class="btn btn-primary w-auto" onClick={handleSubmit}>Save LooseOil Configuration</button> : <button type="submit" disabled class="btn btn-primary w-auto" onClick={handleSubmit}>Save LooseOil Configuration</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}



            {isEditOpen ? (
                <>
                    <div className="modal-overlay" />
                    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                        <div className="modal" style={{ maxWidth: "800px" }}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Edit Loose Oil Details</h5>
                                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={handleEditModalClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="row justify-content-center py-4">

                                <div className="row">
                                    <div className="col-md-6">

                                        <form action="#">
                                            <div className="form-group">
                                                <label for="name">Product Name <sup class="star">*</sup></label>
                                                <input type="text" id="product_name" name="product_name" value={productName} onChange={handleProductNameChange} className="form-control" />
                                                {
                                                    errors['productName'] && <div className="errorMsg">
                                                        {errors['productName']}
                                                    </div>
                                                }
                                            </div>


                                            {
                                                stat_operation == "Yes" ? (
                                                    <div className="form-group">
                                                        <label for="email">Packing Quantity in lt <sup class="star">*</sup></label>
                                                        <input type="number" disabled id="packing_quantity_in_litre" name="packing_quantity_in_litre" onKeyPress={(event) => preventNonNumericalInputNON(event, event.target.value)} value={packingQuantityInLitre} onChange={handlePackingQuantityChange} className="form-control hide-arrow" />
                                                        {
                                                            errors['packingQuantityInLitre'] && <div className="errorMsg">
                                                                {errors['packingQuantityInLitre']}
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label for="email">Packing Quantity in lt <sup class="star">*</sup></label>
                                                        <input type="number" id="packing_quantity_in_litre" name="packing_quantity_in_litre" onKeyPress={(event) => preventToFour(event, event.target.value)} value={packingQuantityInLitre} onChange={handlePackingQuantityChange} className="form-control hide-arrow" />
                                                        {
                                                            errors['packingQuantityInLitre'] && <div className="errorMsg">
                                                                {errors['packingQuantityInLitre']}
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }




                                            <div className="form-group">
                                                <label for="email">Product Name with unit:</label>
                                                <input type="email" id="product_name_with_unit" name="product_name_with_unit" value={productNameWithUnit} className="form-control" readOnly />
                                            </div>


                                            <div className="form-group">
                                                <label for="email">Total Stock in Lt:(barrel + barrel balance + oil machine)</label>
                                                <input type="email" id="total_stock_in_litre" name="total_stock_in_litre" value={totalStockInLitre} className="form-control" readOnly />
                                            </div>

                                            {
                                                stat_operation == "Yes" ? (
                                                    <div className="form-group">
                                                        <label for="email">No of Full Barrel <sup class="star">*</sup></label>
                                                        <input disabled type="number" id="email" name="number_of_barrel" className="form-control hide-arrow" value={numberOfBarrel} onChange={handleNumberOfBarrelChange} />
                                                        {
                                                            errors['numberOfBarrel'] && <div className="errorMsg">
                                                                {errors['numberOfBarrel']}
                                                            </div>
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className="form-group">
                                                        <label for="email">No of Full Barrel <sup class="star">*</sup></label>
                                                        <input type="number" id="email" name="number_of_barrel" className="form-control hide-arrow" value={numberOfBarrel} onChange={handleNumberOfBarrelChange} />
                                                        {
                                                            errors['numberOfBarrel'] && <div className="errorMsg">
                                                                {errors['numberOfBarrel']}
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }


                                            {
                                                stat_operation == "Yes" ? (<div className="form-group">
                                                    <label for="email">Barrel Balance in litre<sup class="star">*</sup></label>
                                                    <input disabled type="text" id="email" name="barrel_balance" className="form-control" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={barrelBalance} onChange={handleBarrelBalanceChange} />
                                                    {
                                                        isGreater && <div className="errorMsg">
                                                            Barrel balance cannot be greater than packing quantity
                                                        </div>
                                                    }
                                                    {
                                                        errors['barrelBalance'] && <div className="errorMsg">
                                                            {errors['barrelBalance']}
                                                        </div>
                                                    }
                                                </div>) : (
                                                    <div className="form-group">
                                                        <label for="email">Barrel Balance in litre <sup class="star">*</sup></label>
                                                        <input type="text" id="email" name="barrel_balance" className="form-control" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={barrelBalance} onChange={handleBarrelBalanceChange} />
                                                        {
                                                            isGreater && <div className="errorMsg">
                                                                Barrel balance cannot be greater than packing quantity
                                                            </div>
                                                        }
                                                        {
                                                            errors['barrelBalance'] && <div className="errorMsg">
                                                                {errors['barrelBalance']}
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }


                                            <div className="form-group">
                                                <label for="category">Oil machine <sup class="star">*</sup></label>
                                                <select id="option" name="category" className="form-control" value={oilMachine} onChange={handleOptionChange} required>
                                                    <option value="">Select a category</option>
                                                    <option value="1">Yes</option>
                                                    <option value="2">No</option>
                                                    {/* <option value="Option 3">Option 3</option> */}
                                                </select>
                                                {
                                                    errors['oilMachine'] && <div className="errorMsg">
                                                        {errors['oilMachine']}
                                                    </div>
                                                }
                                            </div>



                                            {showAdditional && (
                                                <div id="additionalFields">
                                                    <div className="form-group">
                                                        <label htmlFor="num_of_machines">Number of Oil Machine:</label>
                                                        <div className="input-group">
                                                            <button
                                                                className="btn-add"
                                                                type="button"
                                                                onClick={decrementMachines}
                                                                disabled={numberOfMachines <= 0}
                                                            >
                                                                -
                                                            </button>
                                                            <input
                                                                type="number"
                                                                id="num_of_machines"
                                                                name="num_of_machines"
                                                                className="form-control text-center ml-3 hide-arrow"
                                                                value={numberOfMachines}
                                                                onChange={handleNumMachinesChange}
                                                                min="0"
                                                            />
                                                            <button
                                                                className="btn-add"
                                                                type="button"
                                                                onClick={incrementMachines}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {
                                                        errors['numberOfOilMachines'] && <div className="errorMsg">
                                                            {errors['numberOfOilMachines']}
                                                        </div>
                                                    }
                                                    <div id="studentFields">
                                                        {renderAdditionalFields()}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="form-group">
                                                <label for="phone">GST % <sup class="star">*</sup></label>
                                                <input type="number" id="phone" name="gst_in_percent" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInputNON(event, event.target.value)} value={gstInPercent} onChange={handleGstInPercentChange} />
                                                {
                                                    errors['gstInPercent'] && <div className="errorMsg">
                                                        {errors['gstInPercent']}
                                                    </div>
                                                }
                                            </div>


                                        </form>
                                    </div>

                                    <div className="col-md-6">
                                        {/* <h3>Column 2</h3> */}
                                        <form>

                                            <div className="form-group">
                                                <label for="message">Purchase Price per Barrel (inc gst) <sup class="star">*</sup></label>
                                                <input type="number" id="email" name="purchase_price_per_barrel" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={purchasePricePerBarrel} onChange={handlePurchasePricePerBarrelChange} />
                                                {
                                                    errors['purchasePricePerBarrel'] && <div className="errorMsg">
                                                        {errors['purchasePricePerBarrel']}
                                                    </div>
                                                }
                                            </div>

                                            <div className="form-group">
                                                <label for="message">Purchase Price per Barrel (exc gst):</label>
                                                <input type="number" id="email" name="purchase_price_per_barrel_exc_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerBarrelExcGst)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">Purchase Price per Barrel (gst):</label>
                                                <input type="number" id="email" name="purchase_price_per_barrel_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerBarrelGst)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">Selling price/barrel <sup class="star">*</sup></label>
                                                <input type="number" id="email" name="selling_price_per_barrel" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={sellingPricePerBarrel} onChange={handleSellingPricePerBarrelChange} />
                                                {
                                                    errors['sellingPricePerBarrel'] && <div className="errorMsg">
                                                        {errors['sellingPricePerBarrel']}
                                                    </div>
                                                }
                                            </div>


                                            <div className="form-group">
                                                <label for="message">Return Gst Barrel:</label>
                                                <input type="email" id="email" name="return_gst_per_barrel" className="form-control" value={formatToTwoDecimal(returnGstPerBarrel)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">Margin/piece Barrel:</label>
                                                <input type="email" id="email" name="margin_per_piece_barrel" className="form-control" value={formatToTwoDecimal(marginPerPieceBarrel)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">cgst Barrel:</label>
                                                <input type="email" id="email" name="cgst_barrel" className="form-control" value={formatToTwoDecimal(cgstBarrel)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">sgst Barrel:</label>
                                                <input type="email" id="email" name="sgst_barrel" className="form-control" value={formatToTwoDecimal(sgstBarrel)} readOnly />
                                            </div>



                                            <div className="form-group">
                                                <label for="message">Purchase Price per lt (inc gst):</label>
                                                <input type="number" id="email" name="purchase_price_per_litre_inc_gst" className="form-control hide-arrow" value={formatToTwoDecimal(purchasePricePerLitreIncGst)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">Purchase Price per lt (exc gst):</label>
                                                <input type="email" id="email" name="purchase_price_per_litre_exc_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerLitreExcGst)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">Purchase Price per lt (gst):</label>
                                                <input type="email" id="email" name="purchase_price_per_litre_gst" className="form-control" value={formatToTwoDecimal(purchasePricePerLitreGst)} readOnly />
                                            </div>

                                            <div className="form-group">
                                                <label for="message">Selling price/lt <sup class="star">*</sup></label>
                                                <input type="number" id="email" name="selling_price_per_litre" className="form-control hide-arrow" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} value={sellingPricePerLitre} onChange={handleSellingPricePerLitreChange} />
                                                {
                                                    errors['sellingPricePerLitre'] && <div className="errorMsg">
                                                        {errors['sellingPricePerLitre']}
                                                    </div>
                                                }
                                            </div>


                                            <div className="form-group">
                                                <label for="message">Return Gst / lt:</label>
                                                <input type="number" id="email" name="return_gst_per_litre" className="form-control" value={formatToTwoDecimal(returnGstPerLitre)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">Margin/piece / lt:</label>
                                                <input type="number" id="margin_per_piece_per_litre" name="margin_per_piece_per_litre" className="form-control" value={formatToTwoDecimal(marginPerPiecePerLitre)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">cgst / lt:</label>
                                                <input type="number" id="email" name="cgst_per_litre" className="form-control" value={formatToTwoDecimal(cgstPerLitre)} readOnly />
                                            </div>
                                            <div className="form-group">
                                                <label for="message">sgst / lt:</label>
                                                <input type="number" id="email" name="sgst_per_litre" className="form-control" value={formatToTwoDecimal(sgstPerLitre)} readOnly />
                                            </div>

                                            <div className="form-group">
                                                <label for="message">stock value:</label>
                                                <input type="number" id="stock_value" name="stock_value" className="form-control" value={formatToTwoDecimal(stockValue)} readOnly />
                                            </div>

                                        </form>
                                    </div>
                                </div>

                                <div class="modal-footer text-center">
                                    {double == true ? <button type="submit" class="btn btn-primary w-auto" onClick={() => handleEditLooseOilSubmit(updateId)}>Save LooseOil Details</button> : <button disabled type="submit" class="btn btn-primary w-auto" onClick={() => handleEditLooseOilSubmit(updateId)} >Save LooseOil Details</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}



            {isDeleteOpen ? (
                <>
                    <div className="modal-overlay" />
                    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                        <div className="modal">
                            <div className="modal-header">
                                <h3 className="modal-title" id="staticBackdropLabel">Delete Loose Oil?</h3>
                                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={handleDeleteModalClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="row justify-content-center py-4">


                                <div class="modal-footer text-center">
                                    {double == true ? <button type="submit" class="btn btn-primary w-auto" onClick={() => handleDeleteLooseOilSubmit(deleteId)}>Delete</button> : <button disabled type="submit" class="btn btn-primary w-auto" onClick={() => handleDeleteLooseOilSubmit(deleteId)}>Delete</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : null}

        </>
    )
}

export default LooseOilComponent
