
import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import NozzleEmpConfigModal from './NozzleEmpModal.component'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import LoginHeaderOperational from '../../LoginHeaderOperational';
import NozzEmp1ConfigModal from './NozzEmp1ConfigModal';
import Loader from '../Loader.component'
import { preventNonNumericalInput } from '../../../layouts/Operational_Sidebar_Progress.component';
import { inArray } from 'jquery';
import PaginationComponent from '../../../layouts/PaginationComponent';
import moment from 'moment';

const format = 'HH:mm'


const NozzleModal = (props) => ReactDOM.createPortal(
	<NozzleEmpConfigModal  {...props} />, document.body
);

const NozzleModal1 = (props) => ReactDOM.createPortal(
	<NozzEmp1ConfigModal {...props} />, document.body
);

const PaginationComp = (props) => (
	< PaginationComponent  {...props} />
);
const NozzleAlloEmplist = (props) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");

	const [approval_id, approvalId] = useState([])
	const [approval_status, approvalStatus] = useState([])
	const [inputValues, setInputValues] = useState([]);
	const [input, setInput] = useState({});
	const [input1, setInput1] = useState({});
	const [input2, setInput2] = useState({});
	const [fields, setFields] = useState(['']);
	const [allocated_list, setAllocatedData] = useState(['']);
	const [payment_fields, setPaymentFields] = useState(['']);
	const [chek_nz, setFields2] = useState(['']);
	const history = useHistory()
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [errors, setErrors] = useState([])
	const [dropdownData, setDropdownData] = useState([])
	const [dropdownDatas, setDropdownDatas] = useState([])
	const [selected_options, setSelectedOptions] = useState([])
	const [selected_options_edit, setSelectedOptions_edit] = useState([])
	const [nozzledropdownData, setNozzleDropdownData] = useState([])
	const [double, setDouble] = useState(true);
	const [payment, setPayment] = useState([])
	const [permission, setPermission] = useState([])
	const [open_reading, setOpenReading] = useState([])
	const [Loading, setLoading] = useState(false)
	const [delete_noz, deleteNoz] = useState([])
	const [editmodal, editModal] = useState(0);
	const [digital_payment_edit,setdigital_payment_edit] = useState([])
	const [inputValues1,setInputValues1]= useState([]);
	const [inputstarttime,setStartTime]= useState([]);
	const [isSameId, setIsSameId] = useState(false);
	const [isSameIdsubmit, setIsSameIdsubmit] = useState(false);
	const [setallo, setAlloId] = useState(0);
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	//---On Load---//		
	useEffect(() => {
		check_url();
		
	}, []);

	const handleChange = (e) => {

		//   setInput({...input, config_date:e.target.value})
		setInputValues({ ...inputValues, op_date: e.target.value })
		getEmployee_nozzle(e.target.value);

		localStorage.setItem('op_date', e.target.value);
	}

	
	const deleteNozzle =(id,delete_id) => {
		//  alert(id);
		setDouble(false)
		const opDate = inputValues.op_date;
		const emp_id_op={...inputValues1}
		const epi=emp_id_op.op_emp_id;
		const employee_id=emp_id_op.employee_id;
		console.log(emp_id_op,"jjjjjjjjjjjjjjjjj")
		
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({nozzle_id:id,delete_id:delete_id,op_date: opDate,op_emp_id:epi,employee_id:employee_id})
		};
		fetch(`${API_URL}/deletenozzle`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.status == false) {

const errorlist =[];
var mul_msg = 0;
if (responseData?.data?.sales_error) {
	mul_msg = 1;
    errorlist.push( "-" + responseData.data.sales_error );
}

if (responseData?.data?.credit_sales_error) {
	mul_msg = 1;
    errorlist.push( "-" + responseData.data.credit_sales_error );
}

if (responseData?.data?.credit_sales_error) {
	mul_msg = 1;
errorlist.push( "-" + responseData.data.recoup_error );
}

if(mul_msg == 1){
	
	const msg = errorlist.join('\n\n')
	swal({
		
		title: "Cannot delete Nozzle!",
		text: msg,
		icon: "warning",
		buttons: true,
		buttons:{
			cancel: {
				text: 'Cancel',
				visible: true,
				className: 'sweet-alert-btn'
			},
			confirm:{
				text: 'Ok',
				className: 'sweet-alert-btn'
			}
		}
	});
}else{

         swal("", responseData.msg, responseData.status ? "success" : "warning")
}


					
				}
				if (responseData.status == true) {
					swal("", responseData.msg, responseData.status ? "success" : "warning")
					getEmployee_nozzle();
					let reload = 1;
					edit_nozzleallocate(epi,null,reload);
				}

			})
			.catch(e => {
			});

	}

	// useEffect(() => {
	// 	if (isSameId == true) {
	// 		swal({
	// 		  title: "Verify the entered data?",
	// 		  text: "Your Data is not saved, please update!",
	// 		  icon: "warning",
	// 		  buttons: true,
	// 		  dangerMode: true,
	// 		})
	// 	}
	// })
		
// alert(isSameIdsubmit);
	const ok =() => {
	
		const empl_namee={...inputValues1}
		const opDateee={...inputValues}
		const currentemp_idd = empl_namee.employee_id;
		const op_emp_idd=empl_namee.op_emp_id;
		const op_dat_ee=opDateee.op_date;
		const old_emp_idd=empl_namee.old_emp_id;

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({op_emp_id:op_emp_idd,emp_id:currentemp_idd,op_date:op_dat_ee,old_emp_id:old_emp_idd})

			
		};

		fetch(`${API_URL}/updateemp`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.status == false) {
					swal("", responseData.msg, responseData.status ? "success" : "warning")
					setIsSameId(false);
					
					edit_nozzleallocate(op_emp_idd,null);
				}
				if (responseData.status == true) {
					swal("", responseData.msg, responseData.status ? "success" : "warning")
					setIsSameId(true);
					let reload = 1;
					edit_nozzleallocate(op_emp_idd,null,reload);
				}
				
			})
			.catch(e => {
			});
		{console.log(requestOptions,"requestOptionsrequestOptions")}

	}

	const UpdateEmployee =(id) => {

		// if (!isSameId) {
		// 	swal.fire({
		// 		icon: 'error',
		// 		title: 'Oops...',
		// 		text: 'Your message here',
		// 	});
		// }
		
		setDouble(false)
		const empl_name={...inputValues1}
		const opDatee={...inputValues}
		const currentemp_id = empl_name.employee_id;
		const op_emp_id=empl_name.op_emp_id;
		const op_dat_e=opDatee.op_date;
		const old_emp_id=empl_name.old_emp_id;
		console.log(empl_name,"empl_name")
		
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({op_emp_id:op_emp_id,emp_id:currentemp_id,op_date:op_dat_e,old_emp_id:old_emp_id})
			
		};

		fetch(`${API_URL}/addemp`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				let updatemsg=[];

				// if(responseData.status == false && responseData.count == 0 ){
				// 	swal({
				// 		title: responseData.title,
				// 		// text: msg,
				// 		icon: "warning",
				// 		buttons: {
				// 			confirm: {
				// 				text: 'Ok',
				// 				className: 'sweet-alert-btn'
				// 			}
				// 		},
				// 		dangerMode: true,
				// 	}).then((willUpdate) => {
				// 		if (willUpdate) {
				// 			ok();
				// 		}
				// 	});
				// }

				if (responseData.status == false) {
					swal("", responseData.msg, responseData.status ? "success" : "warning")
					
				}

				if (responseData.status == false  ) {
					updatemsg.push( "-" + responseData.data.transaction);
					
				}
				console.log(updatemsg,"updatemsgupdatemsg")
				const msg = updatemsg.join('\n')
				if(responseData.status == false ){
					// alert('888')
					swal({
						title: responseData.title,
						text: msg,
						icon: "warning",
						dangerMode: true,
						buttons: {
							confirm: {
								text: 'Ok',
								className: 'sweet-alert-btn'
							},
							cancel: {
								text: 'Cancel',
								value: null,
								visible: true,
								className: 'sweet-alert-btn-cancel',
								closeModal: true,
							}
						},
						dangerMode: true,
					}).then((willUpdate) => {
						if (willUpdate) {
							ok();
						}
					});
				}

			})
			.catch(e => {
			});
		{console.log(requestOptions,"requestOptionsrequestOptions")}

	}
console.log(inputValues,"oooooooo")
	const Updatenoz =(id) => {
		if (!isSameId) {
			swal("","Please,Update the selected employee", "warning")
		}else{
					// alert("nozz")
		// setDouble(false)
		const nozaloc=[...fields]
		const op_em_id= {...inputValues1}
		const date_op= {...inputValues};
		const dig_pay=inputValues.digital_payment_type;
		const emp_id=inputValues1.employee_id;
		const op_emp=inputValues1.op_emp_id;
		const op_dat=inputValues.op_date;
		const nozzle_id=nozaloc;
	    const start_from=inputValues.start_from.format(format);
		const old_start_from=inputValues1.old_start_from;
     
		// const startedittime=inputValues.start_from;
			
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({op_date:op_dat,op_emp_d:op_emp,emp_id:emp_id,nozzle_id:nozzle_id,digital_payment:dig_pay,start_from:start_from,old_start_from:old_start_from})
			
			

		};
console.log(requestOptions,"requestOptionsrequestOptions")
		
		fetch(`${API_URL}/updatenewnozzle`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.status == true) {
					swal("", responseData.msg, responseData.status ? "success" : "warning")
					// getEmployee_nozzle()
					getEmployee_nozzle();
					toggle1();
					
				}
				if (responseData.status == false) {
					swal("", responseData.msg, responseData.status ? "success" : "warning")
				}
				
			})
			.catch(e => {
			});
		}

		// {console.log(requestOptions,"requestOptionsrequestOptions")}

	}
		// console.log(requestOptions,"requestOptions")
		

	
	

	const handleSelect = (items) => {
		console.log(items,"vv")
			var digital_payment_type = []
		
			items && items.map((item) => {
			  digital_payment_type.push(item)
			})
		
			setInputValues({
			  ...inputValues,
			  digital_payment_type: digital_payment_type.map(payment => payment.value)
			});
			{console.log(digital_payment_type,"digital_payment_typedigital_payment_type")}
		
			setSelectedOptions(digital_payment_type);

		
		  };


	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				console.log(responseData)
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					// getEmployee_nozzle();
					getEmployee_nozzle_initial();
                   
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
			});

	}

	function getPayments() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_digital_payment", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []
				
				

				const newData = responseData.map(elem => ({
					value: elem.id,
					label: elem.method_name,
				}));

				setDropdownDatas(dropdownDatas => [
					...dropdownDatas,
					...newData
				]);

				// responseData.forEach((elem) => {
				// 	setDropdownDatas((dropdownDatas) => [
				// 	  ...dropdownDatas,
				// 	  {
				// 		value: elem.id,
				// 		label: elem.method_name,
				// 	  },
				// 	]);
				//   });
			})
			.catch(e => {
				console.log(e);

			});
	}
console.log(dropdownDatas,"paymentpaymentpayment")


function getEmployee_nozzle_initial(date,id) {

	setAlloId(id);
	all = id;
	const requestOptions = {
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			op_date: date,
			allocation_id:id
		})
	};
	fetch(API_URL + "/get_employee_name_nozzle", requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {

			setInput({ ...input, config_date: responseData.start_date })
			setInput1({ ...input1, end_date: responseData.end_date })

			setInputValues({
				...inputValues,
				op_date: responseData.op_date,
				role: responseData.role,
				 //sugest_start_from: responseData.sugest_start_from != '' ? moment(responseData.sugest_start_from, 'HH:mm:ss') : null,
				 //start_from: !responseData.start_from ? moment(responseData.sugest_start_from, 'HH:mm:ss') : moment(responseData.start_from, 'HH:mm:ss')
				
			})
		
			
			var datas = []
			responseData.employee.forEach(emp => {
				datas.push([emp.emp_id, emp.emp_name])
			})
			setDropdownData(datas);
			var Nozzledatas = []
			responseData.nozzle.forEach(noz => {
				Nozzledatas.push([noz.id, noz.nozzle_name])
			})
			setNozzleDropdownData(Nozzledatas);
			console.log(inputValues,"bbbbbbbbbbbbb")
			ListEmployee(pages.current_page, responseData.op_date);
			return responseData;
		})
		.catch(e => {
			console.log(e);
		});
}

	function getEmployee_nozzle(date,id) {
if(id){
		setAlloId(id);
		all = id;
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				op_date: date,
				allocation_id:id
			})
		};
		fetch(API_URL + "/get_employee_name_nozzle", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInput({ ...input, config_date: responseData.start_date })
				setInput1({ ...input1, end_date: responseData.end_date })
				setInputValues([''])
				setInputValues({
					...inputValues,
					op_date: responseData.op_date,
					role: responseData.role,
					 sugest_start_from: responseData.sugest_start_from != '' ? moment(responseData.sugest_start_from, 'HH:mm:ss') : null,
                     start_from: !responseData.start_from ? moment(responseData.sugest_start_from, 'HH:mm:ss') : moment(responseData.start_from, 'HH:mm:ss')
                    
				})
			
				
				var datas = []
				responseData.employee.forEach(emp => {
					datas.push([emp.emp_id, emp.emp_name])
				})
				setDropdownData(datas);
				var Nozzledatas = []
				responseData.nozzle.forEach(noz => {
					Nozzledatas.push([noz.id, noz.nozzle_name])
				})
				setNozzleDropdownData(Nozzledatas);
				console.log(inputValues,"bbbbbbbbbbbbb")
				//ListEmployee(pages.current_page, responseData.op_date);
				return responseData;
			})
			.catch(e => {
				console.log(e);
			});
	
		}
		}
console.log(inputValues,"yaro")
	function ListEmployee(page, date) {
		const requestOptions = {      //---employee listing---//
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				op_date: date,
				search_keyword: search
			})
		};
		fetch(`${API_URL}/allocated-employee-list?search_keyword=${search}&page=${page}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				if (responseData.status == 3) {
					setPermission({ ...permission, status: responseData.status, message: responseData.message })

				}
				else {
					setList(responseData.data.data)
					setInput2({ ...input2, isdayclose: responseData.isdayclose,has_price_change: responseData.has_price_change  })
					setpages({
						...pages,
						current_page: responseData.data.current_page,
						last_page: responseData.data.last_page,
						per_pages: responseData.data.per_page,
						total_pages: responseData.data.total,

					})

				}
				setLoading(true)
				return responseData;
			})
			.catch(e => {
			});
	}
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListEmployee(number)
	}
	// save op_emp_id to local storage //
	function handleOpEmpId(op_emp_id, emp_id, emp_name, op_date) {
		localStorage.setItem('op_emp_id', op_emp_id);
		localStorage.setItem('save_hide', 'false');
		localStorage.setItem('emp_id', emp_id);
		localStorage.setItem('emp_name', emp_name);
		localStorage.setItem('op_date', op_date);

		history.push("/nozzle-closing")
	}
	function handleOpEmpIdEye(op_emp_id, emp_id, emp_name, op_date) {
		localStorage.setItem('op_emp_id', op_emp_id);
		localStorage.setItem('save_hide', 'true');
		localStorage.setItem('emp_id', emp_id);
		localStorage.setItem('emp_name', emp_name);
		localStorage.setItem('op_date', op_date);

		history.push("/nozzle-closing")
	}
	function handleReport(op_emp_id, emp_id, emp_name) {
		localStorage.setItem('op_emp_id', op_emp_id);
		localStorage.setItem('emp_id', emp_id);
		localStorage.setItem('emp_name', emp_name);

		history.push("/op-worksheet")
	}
	function handleEdit(id) {

	}
	var all = null;
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);
		const [isShowing1, setIsShowing1] = useState(false);
		function toggle() {

			var isdayclose = input2.isdayclose;
			var has_price_change = input2.has_price_change;
			if (isdayclose == 1) { 
				swal("", "Your Current Op-Date has already been Day-Closed, Please change Op-Date", false ? "success" : "warning")
			}else if(has_price_change == 0){
				swal("", "Please Price change for the current operation date selected", false ? "success" : "warning")
			}
			else {

				getEmployee_nozzle(inputValues.op_date);
              
				if (isShowing) {
					
					setErrors({})
					inputValues.employee_id = "";
					inputValues.start_from = "";
					inputValues.opening_cash = "";
					inputValues.sugest_start_from = "";
					setInputValues(inputValues)
					setFields([''])
					setFields2([''])
					setDropdownDatas([''])
				}
				if (!isShowing) {
					getPayments();
					setErrors({})
					setDouble(true)
				}
				setIsShowing(!isShowing);
			}
		}
		function toggle1() {
			
			
            //  alert(all)
			var isdayclose = input2.isdayclose;
			var has_price_change = input2.has_price_change;
			if (isdayclose == 1) { 
				swal("", "Your Current Op-Date has already been Day-Closed, Please change Op-Date", false ? "success" : "warning")
			}else if(has_price_change == 0){
				swal("", "Please Price change for the current operation date selected", false ? "success" : "warning")
			}
			else {
				
				getEmployee_nozzle(inputValues1.op_date,all);
			
				if (isShowing1) {
					// alert("oooo")
                    // editModal(0)
					setErrors({})
					inputValues1.employee_id = "";
					inputValues1.start_from = "";
					inputValues1.opening_cash = "";
					// inputValues1.sugest_start_from = "";
					inputValues.sugest_start_from = "";
					setInputValues(inputValues)
					setInputValues1('')
					setFields([''])
					setFields2([''])
					getEmployee_nozzle();
					ListEmployee();
					
					// setInputValues(['']);
			
				}
				
				if (!isShowing1) {
					// alert("o");
					setdigital_payment_edit();
					getPayments();
					setErrors({})
					handleSelect();
					setDouble(true)
					setSelectedOptions();
					
				}
				setIsShowing1(!isShowing1);
			}
		}
		

		return {
			isShowing,
			isShowing1,
			toggle,
			toggle1,
		}
	};
	const { isShowing, toggle } = UseModal();
	const { isShowing1, toggle1 } = UseModal();

	inputValues['start_from'] = inputValues.start_from;
	// inputValues['sugest_start_from'] = inputValues.sugest_start_from;


	//edit/////////////////////////////

	const edit_nozzleallocate = (id,index,reload) => {
	// function edit_nozzleallocate(id,index,reload) {
		setInputValues([''])
		editModal(1);
		setAlloId(id);
		getEmployee_nozzle(null,id)
		all = id;
	   
	   
	   
		console.log(all,"edit_nozzleedit_nozzleedit_nozzleedit_nozzleedit_nozzle")
	   
	   const requestOptions = {
		   method: 'post',
		   headers: {
			   "Access-Control-Allow-Origin": "*",
			   "Content-Type": "application/json",
			   "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			   "Accept": "application/json"
		   },
		   body: JSON.stringify({
			   allocation_id: id,
		   })
	   };
   
	   fetch(API_URL + "/edit_nozzle_allocation_list", requestOptions)
		   .then(response =>{ return response.json(); })
		   .then(responseData => {
		

			
			
			  

			   let Nozzle_edit=[]
			   let allocated_data=[]
			   let dig_pay=[]
              
			   responseData.data.digital_payment.forEach((elemi,index) =>{
				
				dig_pay.push({
				value: elemi.value,
                label: elemi.method_name,
				})  
				
			   }

			   
			
			)
			console.log(dig_pay,"edit_nozzle_allocation_listedit_nozzle_allocation_list")
			   setdigital_payment_edit({
				...digital_payment_edit,
				digital_pay_edit:responseData.data.digital_payment
			   })

			setInputValues({
				...inputValues,
				digital_payment_type: dig_pay.map(payment => payment.value)
			  });

			  setInputValues1({
				...inputValues1,
				old_start_from:responseData.data.employee_data.start_from,
				old_emp_id :responseData.data.employee_data.emp_id,
				op_emp_id :responseData.data.employee_data.id,
				employee_id:responseData.data.employee_data.emp_id,
				start_from_data:moment(responseData.data.employee_data.start_from, 'HH:mm:ss'),
				digital_pay_edit:responseData.data.digital_payment,
				 
				// start_from_data:moment(responseData.data.employee_data.start_from, 'HH:mm:ss') 

				 sugest_start_from: responseData.data.employee_data.start_from != '' ? moment(responseData.data.employee_data.start_from, 'HH:mm:ss') : null,
				// start_from: !responseData.data.employee_data.start_from ? moment(responseData.data.employee_data.sugest_start_from, 'HH:mm:ss') : moment(responseData.data.employee_data.start_from, 'HH:mm:ss')				
			})
			 
			   setIsSameId(true);
			   responseData.data.allocated_nozzle_data.forEach((elem, index) => {
				   Nozzle_edit.push({
					   nozzle_id:elem.nozzle_id,
					   opening_reading:elem.opening_reading,
					   id:elem.id,
					   nozzle_name:elem.noz_name,
					   close_reading:elem.close_reading,

					   // start_from:elem.start_from,

				   

			   })

			   allocated_data.push({
				nozzle_id:elem.nozzle_id,
				opening_reading:elem.opening_reading,
				id:elem.id,
				nozzle_name:elem.noz_name,
				close_reading:elem.close_reading,

				// start_from:elem.start_from,

			

		})
				   
			   })
			   

		    //    setFields(Nozzle_edit);
		       
			   setAllocatedData(allocated_data);
			   if(reload == 0){
					// Set the state after some time
					toggle1();	
			}
		    setdigital_payment_edit(digital_payment_edit)
			setSelectedOptions(dig_pay) 

   })
   
		   
		       .catch(error => {
			    console.error('There was a problem with the fetch operation:', error);
		   });
   }

   console.log(digital_payment_edit,"jjjjjjjjjjjjjjjjjjjjjjjjj")
   console.log(inputstarttime,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")


    const handleInputedit = (value) => {
	console.log(value,"startedit")
	setInputValues1({
		...inputValues1,
		start_from_data: value
	})
};



////////////new start time  in edit///////////
const [starttimedit, setstarttimedit ] = useState([]);

// function start_time_edit() {
// 	editModal(1);
// 	setAlloId(id);
// 	all = id;  
//    const requestOptions = {
// 	   method: 'post',
// 	   headers: {
// 		   "Access-Control-Allow-Origin": "*",
// 		   "Content-Type": "application/json",
// 		   "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
// 		   "Accept": "application/json"
// 	   },
// 	   body: JSON.stringify({
// 		   allocation_id: id,
// 	   })
//    };

//    fetch(API_URL + "/edit_nozzle_allocation_list/", requestOptions)
// 	   .then(response =>{ return response.json(); })
// 	   .then(responseData => {
		
		   
// 		  setstarttimedit({
// 			...starttimedit
// 		  })

	
	
		
		 
		   

// })

	   
// 		   .catch(error => {
// 			console.error('There was a problem with the fetch operation:', error);
// 	   });
// }










////////

function handleChange_nozzle_id_edit(i, event) {

	// alert("bvvvvvvvvvvv")
		const values_nozzle = [...fields];
		values_nozzle[i]['nozzle_id'] = event.target.value;
		// values_nozzle[i]['nozzle_id'] = event.target.value;
		setFields(values_nozzle);
		setInputValues1({ ...inputValues1, nozzle_id: values_nozzle })
		getOpenReading(event.target.value, i);
		disable_nz(); // to disable nozzle (handle change)
	    console.log(values_nozzle,"fields")

	}

	const handleeditSubmit = (event) => {
	//event.preventDefault();
    // alert("submit");
		setDouble(false);
		if (validateForm())
			sendNozzleData()
	}
	function EdithandleDelete(i) {
		console.log(fields,"hhhhh")
		const valuesedit = [...fields];
		
        // alert("jjjjjjjjjjj")
		console.log(valuesedit,"hhhhhhhhhhhhhhhhhhhhhhhhhhhh")
		valuesedit.splice(i, 1);
		setFields(valuesedit);
		setInputValues1({ ...inputValues1, 
			nozzle_id: valuesedit })

		for (var i = 0; i < valuesedit.length; i++) {
			if (valuesedit[i] != 0) {
				valuesedit[i] = parseInt(valuesedit[i]);
			}
		}
		setFields2(valuesedit);
	}

	const edithandleInputs = (e) => {
      
		if (inputValues1['start_from'] == null) {
			// inputValues1['start_from'] = inputValues1['sugest_start_from'];
		}

		setInputValues1({
			...inputValues1,
			[e.target.name]: e.target.value
		});

		let check = inputValues1;
		const old_emp_id=check.old_emp_id;
		const new_emp_id=check.op_emp_id;
		console.log(e.target.name,"val1")
		console.log(new_emp_id,"val2")

		if (e.target.name == "employee_id" && (old_emp_id == e.target.value)) {
			
			setIsSameId(true);
			// alert(1);
		  } else {
			setIsSameId(false);
			// alert(2);
		  }

		console.log(check,"vall")
	
// alert(e.target.value)
	};

	function handleAddedit() {

		const values_nozzle = [...fields];

		values_nozzle.push({'nozzle_id':''});
		setFields(values_nozzle);
		setErrors([])
		disable_nz();
	}


	function hide() 
	{
		// alert(isSameId);
		if ( isSameId == false) {
			// alert(111)
		  swal({
			title: "Verify the entered data?",
			text: "You Data is not saved,Please update!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		  }).then((willDelete) => {
			if (willDelete) {
			  toggle1(false);
			
			} else {
			}
		  });
		} 
		else {
		  toggle1(false);
		}
	  }
//end edit//////////
	const handleInputs = (e) => {

		if (inputValues['start_from'] == null) {
			inputValues['start_from'] = inputValues['sugest_start_from'];
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});

	};
	const handleInput = (value) => {
		console.log(value,"start")
		setInputValues({
			...inputValues,
			start_from: value
		})
		console.log(inputValues,"inputValuesinputValues")
	};

	//---onchange functions---//

	function handleChange_nozzle_id(i, event) {
		const values_nozzle = [...fields];
		console.log(values_nozzle,"values_nozzle")
		values_nozzle[i] = event.target.value;
		setFields(values_nozzle);
		setInputValues({ ...inputValues, nozzle_id: values_nozzle })
		getOpenReading(event.target.value, i);

		disable_nz(); // to disable nozzle (handle change)
		console.log(values_nozzle,"fields")
	}
	function disable_nz() {
		var elss = [];
		var els = document.getElementsByClassName('nozzle_ids');

		for (var i = 0; i < els.length; i++) {
			if (els[i].value != 0) {
				elss[i] = parseInt(els[i].value);
			}
		}
		setFields2(elss);
	}
	function getOpenReading(id, i) {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({ nozzle_id: id }),
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(API_URL + "/get_opening_reading", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				var op = responseData.close_reading;
				var s = document.getElementById("opening_reading" + i);
				s.value = op;


				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}
	function handleAdd() {

		const values_nozzle = [...fields];

		values_nozzle.push('');
		setFields(values_nozzle);
		setErrors([])
		console.log(fields,"gggggggg")
	}
	function handleDelete(i) {
		const values = [...fields];

		console.log(values)
		values.splice(i, 1);
		setFields(values);
		setInputValues({ ...inputValues, nozzle_id: values })

		for (var i = 0; i < values.length; i++) {
			if (values[i] != 0) {
				values[i] = parseInt(values[i]);
			}
		}
		setFields2(values);
	}
	function handleRemove(i, event) {

		var i = fields.length - 1
		if (i != 0) {
			const values_nozzle = [...fields];
			values_nozzle.splice(i, 1);
			setFields(values_nozzle);
			setInputValues({ ...inputValues, nozzle_id: values_nozzle })
		}


	}
	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		//event.preventDefault();

		setDouble(false);
		if (validateForm())
			sendNozzleData()
	}
	//---validations---//	
	function validateForm() {

		let errors = []
		let formIsValid = true

		if (!inputValues.employee_id) {
			formIsValid = false;
			setDouble(true)
			errors["employee_id"] = "Select Employee Name"
		}
		else {
			errors["employee_id"] = ""
		}
		if (!inputValues.start_from) {
			formIsValid = false;
			setDouble(true)
			errors["start_from"] = "Select Start From"
		}
		else {
			errors["start_from"] = ""
		}
		fields.map((inpuvalue, idx) => {

			if (!inpuvalue || inpuvalue == 0) {
				formIsValid = false;
				setDouble(true)
				errors["nozzle_id" + idx] = "Select Nozzle"

			}
			else {
				errors["nozzle_id" + idx] = ""
			}

		})
		setErrors(errors)

		return formIsValid;
	}
	//---Create EmployeeList---//
	function sendNozzleData() {
       console.log(inputValues.digital_payment_type,"digital_payment")
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				employee_id: inputValues.employee_id,
				nozzle_id: inputValues.nozzle_id,
				digital_payment:inputValues.digital_payment_type,
				op_date: inputValues.op_date,
				//opening_cash: inputValues.opening_cash,
				role: inputValues.role,
				start_from: inputValues.start_from.format(format),
				
			})
		};
		fetch(API_URL + "/nozzle_allocation", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {

				if (responseData.status == false) {
					errors["name"] = responseData.response.name
					errors["opening_balance"] = responseData.response.opening_balance
					setDouble(true)
				}
				if (responseData.status == 3) {
					errors["nozzle_id"] = responseData.message
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/nozzle-employee-list")
					setDouble(true)
					setErrors([''])
					setInputValues([])
					toggle();
					check_url();
					//ListEmployee(pages.current_page);

				}
				if (responseData.status == 11) {
					swal("", responseData.message, responseData.status ? "warning" :"success"  )
					history.push("/nozzle-employee-list")
					setDouble(true)
					setErrors([''])
					//setInputValues([])
					// toggle();
					//check_url();
					//ListEmployee(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				setDouble(true)

			});
	}




	const [isOpen, setIsOpen] = useState(false);
	function toggleModal() {
		setIsOpen(!isOpen);
	}

	function handleEmpAproval(id, status) {

		approvalId(id)
		approvalStatus(status)
		toggleModal()
	}

	function handleYes() {
		const requestOptions = {      //---employee listing---//
			method: 'post',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify({
				id: approval_id,
				status: approval_status
			})
		};
		fetch(`${API_URL}/employee_closing_approve_reject`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				history.push("/nozzle-employee-list")
				check_url();
				toggleModal();
				return responseData;
			})
			.catch(e => {


			});
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={25} operation={1} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />

						<div className="container pb-3">
							<div class="row">
								<div className="col-md-12 mb-3 px-4 mt-3">
									<Link to="/operational-date">
										<a href="/operational-date" className="float-end btn btn-gray">BACK</a>
									</Link></div></div>

						</div>

						<div class="container pb-3">


							<div class="row mt-md-5 mt-3 form-container">

								<div className="col-md-12">
									{Loading ? ListEmployee :
										// <div class="loader text-primary">Loading...</div>
										<Loader />
									}

									<div className="form-wrap list-wrp">

										<div className="row mb-3">
											<div className="col-md">
												<div className="row">
													<div className="col-md-1 col-2 pe-0">
														<img src={customer} width="25px" className="img-fluid" alt=".." />
													</div>
													<div className="col-md-6 col pl-0">

														<h3 className="table-title">Employee Closing </h3>
														<h4 className="table-title">{moment(inputValues.op_date).format("DD-MM-YYYY")}</h4>

													</div>
													<div className="col-md-1">
														<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#add-customer" onClick={toggle}>Nozzle Allocation</a>
													</div>
												</div>
												<p2 className="note mt-2">This is an Action Button for Worksheet Preparation of Nozzle Allocation </p2>
											</div>
											<div className="col-md-3">

												<input type="date" class="form-control" name="startDate" min={input.config_date} max={input1.end_date} onChange={handleChange} value={inputValues.op_date} disabled />

											</div>
											<div className="col-md-3">
												<div className="input-group mb-3 search">
													<span className="input-group-text" id="basic-addon1"><i className="fa fa-search" aria-hidden="true"></i>
													</span>
													<input type="text" className="form-control" placeholder="Search with name " title="Search with name " value={search.search_keyword} onChange={handleSearch} />
													<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

												</div>
											</div>
										</div>
									

										{permission.status == 3 ?
											<div className="form-wrap">
												<div className="text-center">
													{permission.message}
												</div></div> :
												
											<div className="table-widget">
												<table className="table border status-table">
													<thead>
														<tr>
															<th>Allocated Empolyees Names</th>
															<th>Short/Excess</th>
															<th>Start Time</th>
															<th>End Time</th>
															{/*<th>Opening Cash</th>*/}
															<th>Status</th>
															<th>Action</th>
														</tr>
													</thead>

													<tbody>
														{

													list_data.map((obj) => (		
															
																<tr>
																	{console.log(obj,"objobj")}	
																	{/* {console.log(obj.sort_or_excess.short_excess_list,obj.sort_or_excess.op_end_from,obj.sort_or_excess.op_start_from,"object")} */}
																	<td>{obj.emp_name}</td>
																	{/*}	<td>{obj.opening_cash}</td>*/}
																    <td>{obj.sort_or_excess.short_excess_list}</td>
																	<td>{obj.converted_time}</td>
																	<td>{obj.sort_or_excess.op_end_from}</td>
																	<td>{obj.status == 3 ? <a href="#" class="btn btn-success btn-xs">Approved</a> : (obj.status == 4) ? <a href="#" class="btn btn-success btn-xs">Day Closed</a> : ((obj.status == 2)) ? <a href="#" class="btn btn-warning btn-xs">Approval Pending</a> : ((obj.status == 1)) ? <a href="#" class="btn btn-warning btn-xs">Pending</a> : ''} </td>
																	<td><div className="d-flex icon-btn">
																		{/* <button onClick={(event) => history.push("/nozzle-allocation/"+obj.id)} >  <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
																	</button> */}
																 {/* edit_nozzleallocate(obj.id) */}
																 {(obj.status == 3 || obj.status == 4) }
																		{((obj.start_from != null && (obj.status < 3))) ? <button onClick={() => handleOpEmpId(obj.id, obj.emp_id, obj.emp_name, obj.op_date)} >  <i className="fa fa-ellipsis-h" aria-hidden="true" title="Nozzle Closing"></i></button> : ''}
																		{obj.status >= 3 ?<button onClick={() => handleOpEmpIdEye(obj.id, obj.emp_id, obj.emp_name, obj.op_date)} >  <i className="fa fa-eye" aria-hidden="true" title="View"></i></button>:""}
																		{((obj.start_from != null)) ? <button onClick={() => handleReport(obj.id, obj.emp_id, obj.emp_name)} >  <i className="fa fa-file-text" aria-hidden="true" title="WorkSheet"></i></button> : ''}
																		{(obj.status == 1 || obj.status == 2) ? <a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" o onClick={() => edit_nozzleallocate(obj.id,null,0)} ><i class="fa fa-pencil-square-o me-2" aria-hidden="true"></i></a>:""}
												                 		{permissions.includes("Deny Approval") == true ?
																			<>
																				{((obj.status != 1) && (obj.status != 4) && inputValues.role != "employee") ? <button onClick={() => handleEmpAproval(obj.id, obj.status)}>{(obj.status == 3) ? <i className="text-success fa fa-check" aria-hidden="true" title="Reject"></i> : <i className="text-warning fa fa-check" aria-hidden="true" title="Approval"></i>}</button> : ''}
																			</>
																			: ''}
																 {/* {((obj.start_from != null && (obj.status < 3))) ? <a href="#" class="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => ''} ><i class="fa fa-pencil-square-o me-2" aria-hidden="true"></i></a>:""} */}

																	</div></td>
																</tr>
															))}
													</tbody>
												</table>


											</div>}
										{Loading ? <PaginationComp
											pages={pages}
											handlePagination={handlePagination}
										/> : ""
										}
									</div>
									{/* <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={(event) => history.push("/creditor-debitor")}>Skip/Continue</a></div> */}
								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
			{isShowing ? (<NozzleModal
				isShowing={isShowing}
				hide={toggle}
				inputValues={inputValues}
				fields={fields}
				chek_nz={chek_nz}
				handleDelete={handleDelete}
				handleSubmit={handleSubmit}
				handleInputs={handleInputs}
				preventNonNumericalInput={preventNonNumericalInput}
				handleInput={handleInput}
				handleChange_nozzle_id={handleChange_nozzle_id}
				dropdownData={dropdownData}
				open_reading={open_reading}
				double={double}
				handleSelect={handleSelect}
				dropdownDatas={dropdownDatas}
				selected_option={selected_options}
				
				payment={payment}
				nozzledropdownData={nozzledropdownData}
				handleAdd={handleAdd}
				handleRemove={handleRemove}
				errors={errors}
			/>) : null
			}

{isShowing1 ? (<NozzleModal1
				isShowing={isShowing}
				hide={hide}
				inputValues={inputValues}
				handleInputedit={handleInputedit}
				inputValues1={inputValues1}
				inputstarttime = {inputstarttime}
				handleChange_nozzle_id_edit={handleChange_nozzle_id_edit}
				fields={fields}
				allocated_list = {allocated_list}
				chek_nz={chek_nz}
				handleDelete={handleDelete}
				handleeditSubmit={handleeditSubmit}
				EdithandleDelete={EdithandleDelete}
				edithandleInputs={edithandleInputs}
				preventNonNumericalInput={preventNonNumericalInput}
				handleInput={handleInput}
				handleChange_nozzle_id={handleChange_nozzle_id}
				dropdownData={dropdownData}
				dropdownDatas={dropdownDatas}
				deleteNozzle={deleteNozzle}
				handleSelect={handleSelect}
				selected_options={selected_options}
				selected_options_edit={selected_options_edit}
				open_reading={open_reading}
				double={double}
				UpdateEmployee={UpdateEmployee}
				editmodal={editmodal}
				// editModal={editModal}
				nozzledropdownData={nozzledropdownData}
				handleAddedit={handleAddedit}
				handleAdd={handleAdd}
				handleSubmit={handleSubmit}
				handleRemove={handleRemove}
				isSameId={isSameId}
				isSameIdsubmit={isSameIdsubmit}
				Updatenoz={Updatenoz}
				errors={errors}
			/>) : null
			}
			<Modal
				isOpen={isOpen}
				onRequestClose={toggleModal}
				contentLabel="My dialog"
				className="mymodal custommodal"
				overlayClassName="myoverlay"
			>
				<div className=" modal-dialogue-xl">
					<div className="modal-content">
						<div className="modal-title modal-header "><button type="button" onClick={toggleModal} className="btn-close" data-dismiss="modal" aria-label="Close"></button></div>
						<div className=" modal-body  modal-body"><p>Do you want to {approval_status == 2 ? 'Approve' : 'Reject'} ?</p></div>
						<div className="justify-content-end  modal-footer"><div className="d-flex">
							<button type="button" className="btn btn-primary me-2 px-4" onClick={handleYes}>Yes</button>
							<button type="submit" className="btn btn-secondary" onClick={toggleModal}>No</button>
						</div>
						</div>
					</div>
				</div>
			</Modal>

		</>

	)
}








export default NozzleAlloEmplist