
import React from "react";
import { Component } from "react";
import '../../App.css'
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from 'axios'
import { selling_mode } from "../../constant/constant"
import { packing_unit } from "../../constant/constant"
import { API_URL } from "../../constant/API_Settings"
import { account_type } from "../../constant/constant"
import { Link } from "react-router-dom";
class fuelConfigReport extends Component {
	constructor(props) {
        super(props);
     
        this.state = {
       report:[],
       fuel:[],
       tank:[],
       nozzle:[],
       rack_product:[],
       rack:[],
       product:[],
       disable:true,
       customer:[],
       creditor_debtor:[],
       payment_methods:[],
       bank_details:[],
       fleet_card:[],
       mla_coupon:[],
       employee:[],
       vendor:[],
       asset:[],
       other_income:[],
       expense:[],
       dealership:[],
       tanker:[],
       task:[],
       fuel_unload:[],
       user:[],
       confg_date:[],
      
        }
		this.handleChange = this.handleChange.bind(this)
	}

handleChange = (event) => {

	
	this.setState({
	  [event.target.name]: event.target.value,
	})
}
		handleSubmit = () => {
			
			
		}
        componentDidMount()
        {
        this.getReport()
        }
        getReport=(id)=>{
          this.state = {
            report:[],
            fuel:[],
            tank:[],
            nozzle:[],
            rack_product:[],
            rack:[],
            product:[],
            customer:[],
            creditor_debtor:[],
            payment_methods:[],
            bank_details:[],
            fleet_card:[],
            mla_coupon:[],
            employee:[],
            vendor:[],
            asset:[],
            other_income:[],
            expense:[],
            dealership:[],
            tanker:[],
            task:[],
            fuel_unload:[],
            user:[],
           
             }
          axios({
            url: API_URL+"/get-user-config",
            method: "GET",
            data: {id:id},
            headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":"application/json",
            "Authorization": "Bearer "+localStorage.getItem("AUTH_TOKEN")+"",
            "Accept": "application/json"
            }
         }).then(res=>{
          console.log(res.data.customer_data)
            var { report, fuel ,tank,nozzle,rack_product,rack,product,customer,creditor_debtor,payment_methods,bank_details,fleet_card,mla_coupon,employee,vendor,asset,other_income,expense,dealership,tanker,task,fuel_unload,user} = this.state 
        
        report.push({
            
            dealer_name: res.data.pump_details.dealer.dealer_name,
            pump_name:res.data.pump_details.pump_name,
            pump_address: res.data.pump_details.pump_address,
            confg_date:res.data.pump_details.confg_date,
        })
          

            res.data.fuel_config.forEach(elem=>{
            fuel.push({
            
             fuel_name:elem.fuel.fuel_name,
             no_of_tank:elem.no_of_tank
               
                })
          })
   

  

      
    
         

          res.data.tank_config.forEach(elem=>{
            console.log(elem);
            
            tank.push({
            
            tank_name:elem.tank_name,
            fuel_name:elem.get_fuel.fuel_name,
            no_of_nozzles:elem.no_of_nozzles,
            capacity:elem.capacity,
            diameter:elem.diameter,
            length:elem.length,
            stock_value:(elem.config_stock * elem.configured_pp).toFixed(2),
            selling_price:elem.selling_price,
            config_stock:elem.config_stock,
            current_stock:elem.current_stock,
            last_purchase_price:elem.last_purchase_price,
            configured_pp:elem.configured_pp
               
                })
          })
          res.data.nozzle_config.forEach(elem=>{
              console.log(elem)
            nozzle.push({
            
            nozzle_name:elem.nozzle_name,
            // fuel_name:elem.get_fuel.fuel_name,
            opening_reading:elem.opening_reading,
            // current_stock:elem.get_tanks?.current_stock,
            // selling_price:elem.get_tanks?.selling_price,
            // last_purchase_price:elem.get_tanks?.last_purchase_price,
            tank_name:elem.tank_name 
            
               
                })
          })
        

          this.setState({report:report ,fuel:fuel,tank:tank,nozzle:nozzle,rack_product:rack_product,rack:rack,product:product
         })
         
         console.log(res.data)
        //  this.setModalShow(true)
      
            }).catch(err=>{
               
            })
           
      }
    

      //------------------------url
check_url=()=> {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"
		},
		body: JSON.stringify({
			pathurl: window.location.pathname,
		}),
	};
	fetch(`${API_URL}/check_url`, requestOptions)
		.then(response => { return response.json(); })

		.then(responseData => {
			console.log(responseData)
			if(responseData.allowurl == 1)
			{
        if (this.props.location.pathname == "/fuel-config-report/fuel") {
          this.props.history.push(this.props.location.pathname);
          
        } 
        else {
          this.props.history.push(responseData.url);
        }
			//this.history.push(responseData.url);
				this.check_meta_date();
			}
			else{
				
				this.history.goBack();
			}

			return responseData;
		})
		.catch(e => {
		});

}
 check_meta_date=()=> {

	const requestOptions = {      
		method: 'post',
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			"Accept": "application/json"

		}
	};
	fetch(`${API_URL}/check_meta_date`, requestOptions)
		.then(response => { return response.json(); })
		.then(responseData => {
		//	console.log(responseData.op_date)
		

			return responseData;
		})
		.catch(e => {
		});

}
 
//--------------------------//
    render() {
    
        return(
        
       
       <div className="main-container page-wrap">
	<div className="d-md-flex">
{/* <Sidebar_Progress progress={5}/> */}
{this.props.location.pathname == "/fuel-config-report/fuel" ? (
<Sidebar_Progress
  path={this.props.location.pathname}
  progress={11}/>
  ) : (
  <Sidebar_Progress progress={11} />
            )}
		<div className="content-area">
<LoginHeader page_title="" />

<div className="container pb-3">
	<div className="row justify-content-center mt-md-5 mt-3 form-container report">
  {/* <div className="col-md-12 mb-3"><Link to="/nozzle-config"><a href="nozzle-config" className="float-end btn btn-gray">Back</a></Link></div> */}
  {this.props.location.pathname == "/fuel-config-report/fuel" ? (
            <div class="col-md-12 mb-3">
              <Link to={this.state.backPage || "/dashboard"}>
                <a href="#" class="float-end btn btn-gray">
                  Go Back
                </a>
              </Link>
            </div>
          ) : (
            <div class="col-md-12 mb-3">
              {/* <Link to="/target-config"> */}
              <Link to="/nozzle-config">
                <a href="/nozzle-config" class="float-end btn btn-gray">
                  Back
                </a>
              </Link>
            </div>
          )}
        <div className="col-md-12">
    
      
			<div className="form-wrap list-wrp">
            <h3 className="table-title text-center"> Fuel Configuration Summary </h3>
            <div>{this.state.confg_date}</div>
            {console.log(this.state.confg_date,"njan")}

            
          
            
        <h3 className="text-start mt-5">Pump Details</h3>
<div className="row mb-3  py-2">
    <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Dealer ID</th>
      <th>Pump Name</th>
      <th>Pump Address</th>
      </tr>
      </thead>
      <tbody>
      {

this.state.report.map((obj) => { 
return(	
  <>
    <tr>
      <td>{obj.dealer_name}   </td>
      <td>{obj.pump_name}</td>
      <td>{obj.pump_address}</td>
      </tr>
 </>
 )})} 

      </tbody>
      </table>
      </div>
    
    </div>


      </div> 


 			<h3 className="text-start">Fuel Configuration</h3>
             <div className="row mb-3  py-2">
             <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Fuel Name</th>
      <th>Number of Tank</th>
      
      </tr>
      </thead>
      <tbody>
      {

this.state.fuel.map((obj) => { 
return(	
    <>
    <tr>
      <td>{obj.fuel_name}  </td>
      <td>{obj.no_of_tank}</td>
     
      </tr>
      </>
      )})}
      </tbody>
      </table>
      </div>
    
    </div>
</div>




	<h3 className="text-start">Tank Configuration </h3>
    <div className="row mb-3  py-2">
    <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
      <th>Tank Name</th>
      <th>Fuel Name</th>
      <th>Number of Nozzles</th>
      <th>Capacity(KL)</th>
      {/* <th>Diameter</th>
      <th>Length</th> */}
      <th>Configured Stock</th>
      {/* <th>Current Stock</th> */}
      <th>Configured Purchase Price</th>
      <th>Selling Price </th>
      {/* <th>Last Purchase Price </th> */}
      <th>Stock Value</th>
      </tr>
      </thead>
      <tbody>
      {

this.state.tank.map((obj) => { 
  console.log(obj,"fff")
return(	
    <>
    <tr>
      <td>{obj.tank_name}  </td>
      <td>{obj.fuel_name}</td>
      <td>{obj.no_of_nozzles}</td>
      <td>{obj.capacity}</td>
      {/* <td>{obj.diameter}</td>
      <td>{obj.length}</td> */}
      <td>{obj.config_stock}</td>
      {/* <td>{obj.current_stock}</td> */}
      <td>{obj.configured_pp}</td>
      <td>{obj.selling_price}</td>
      {/* <td>{obj.last_purchase_price}</td> */}
      <td>{obj.stock_value}</td>
      
      
            
            
      </tr>
      </>
)})}
      </tbody>
      </table>
      </div>
    
    </div>
    </div>


	<h3 className="text-start">Nozzle Configuration </h3>

    <div className="row mb-3  py-2">
    <div className="col-md-12">
    <div className="table-widget">
					<table className="table border">
  <thead>
    <tr>
    <th>Nozzle Name</th>
  <th>Tank Name </th>
  <th>Opening Reading </th>
  

      
      </tr>
      </thead>
      <tbody>
      {

this.state.nozzle.map((obj) => { 
    console.log(obj)
return(	
    <>
    <tr>
      <td> {obj.nozzle_name} </td>
      <td>{obj.tank_name}</td>
      <td>{obj.opening_reading}</td>
      </tr>
      </>
)})}
		
      </tbody>
      </table>
      </div>
    
    </div>

      </div>
       
			</div>
      {/* <div className="text-end mt-4">
          <a className="btn btn-primary w-auto px-3 mb-md-0 mb-2" href="/target-config" >NEXT</a></div> */}

          {this.props.location.pathname !== "/fuel-config-report/fuel" ? (
            <div className="text-end mt-4">
              <a className="btn btn-primary w-auto px-3 mb-md-0 mb-2" href="/loose-oil-config" >NEXT</a></div>
           
          ) : (
            null
          )}

		</div>
      
	</div>
</div>
		</div>
		
	</div>
</div>





        )
    }
}

export default fuelConfigReport