
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import { API_URL } from "../../constant/API_Settings";
import React, { useState,useMemo } from 'react';
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import logo from '../../img/logo.svg';
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from 'html2canvas';

import Loader from '../operational-model/Employee/Loader.component'


export const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);

const BalanceSheet = (location) => {
  const backPage = useMemo(() => {
 
		if (location?.location?.backpage) {
		  return location?.location?.backpage
		}
		else
		  return undefined
	  }, [location?.location?.pathname])
  const history = useHistory()
  const [path, setLocation] = useState([])
  const [list_data, setList] = useState([])
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false)
 
  useEffect(() => {
    setAll([])
    setList([])
    setLocation(location.location.pathname)	
    check_url();
    getBalanceSheet();

  }, [])
  //------------------------url
  function check_url() {
    setAll([])
    setList([])
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
        console.log(responseData)
        if (responseData.allowurl == 1) 
        {
          if(location.location.pathname == "/cbalance-sheet/cbalance-sheet")
				  {
					history.push(location.location.pathname);
				  }
          else{         
            setInput({ ...input, stat_operation: responseData.stat_operation, configuration_report: responseData.configuration_report })
            history.push(responseData.url);
          }
            getBalanceSheet();
          
        }
        else {

          // history.goBack();

          
        }

        return responseData;
      })
      .catch(e => {
        console.log(e)
      });

  }

  async function getBalanceSheet() {
    setAll([])
    setList([])
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"

      },
    };
    await fetch(API_URL + "/c-balance-sheet", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        setList(responseData.all_entries)
          
        let arr=[];
        responseData?.all_entries?.map((elem) => {

         arr?.push({
              ac_head: elem.title,
              spr_ac_head: elem.stitle,
              sub_head: elem.accounts_head,
              drtamount: elem.drtamount,
              crtamount: elem.crtamount
         })
          // setAll(all => [
          //   ...all,

          //   {
          //     ac_head: elem.title,
          //     spr_ac_head: elem.stitle,
          //     sub_head: elem.accounts_head,
          //     drtamount: elem.drtamount,
          //     crtamount: elem.crtamount

          //   }
          // ])
          
        })
 
        setAll(arr||[])
        setLoading(true)
        setState(responseData)

      })
      .catch(e => {


      });
  }

 
  const [state, setState] = useState([])
  const [all, setAll] = useState([])
  var buffer = ''; var flag = 0;
  var s_buffer = ''; var s_flag = 0;
  var profit_loss_amount = 0; var org_amount = 0; var profit_loss = ''; var dr_total = 0; var cr_total = 0;

  function sendmailltoowner() {
    sendPDF();
    sendPDFConfiguration();

  }
  function sendPDF() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({ mail_data: list_data }),
    };
    fetch(API_URL + "/send_pdf_file", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        getBalanceSheet();
        // check_url()

        return responseData;
      })
      .catch(e => {
        console.log(e);
      });
  }
  function sendPDFConfiguration() {
    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({ mail_data: list_data }),
    };
    fetch(API_URL + "/send_pdf_file_bal2_configuration", requestOptions)
      .then(response => { return response.json(); })
      .then(responseData => {
        swal("", responseData.message, responseData.status ? "success" : "warning")
        getBalanceSheet();
        // check_url();
        return responseData;
      })
      .catch(e => {
        console.log(e);
      });
  }

  function printDocument() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        op_date: localStorage.getItem("op_date")
      })
    };
    fetch(API_URL + "/download_pdf_file", requestOptions)
      .then(response => { return response.blob(); })
      .then(responseData => {
        var blob = new Blob([responseData]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "BalanceSheet.pdf";
        link.click();
        history.push("/cbalance-sheet");
        getBalanceSheet();
        
        check_url()

       
        return responseData;
      })
      .catch(e => {
        console.log(e);
      });

  }

  function confprintDocument() {

    const requestOptions = {
      method: 'post',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        "Accept": "application/json"
      },
      body: JSON.stringify({
        op_date:localStorage.getItem("op_date")
      })
    };
    fetch(API_URL + "/download_configuration_report_pdf_file", requestOptions)
      .then(response => { return response.blob(); })
      .then(responseData => {
        var blob = new Blob([responseData]);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = "ConfigurationReport.pdf";
        link.click();

        
        getBalanceSheet();
        check_url()
    return responseData;
      })
      .catch(e => {
        console.log(e);
      });

  }

  return (
    <>

      <div className="main-container page-wrap">
        <div className="d-md-flex">
          {/* <Sidebar_Progress progress={9} /> */}
          {path == "/cbalance-sheet/cbalance-sheet"? <Sidebar_Progress path={path} />:<Sidebar_Progress />}
          <div className="content-area">
            <LoginHeader page_title="" />
            
            <div className="container pb-3">
            <div className="row mb-3">

            <div className="col-md-12 mb-3 px-4 ">{path == "/cbalance-sheet/cbalance-sheet"?<Link to={backPage || "/dashboard"}>
              <a href="#" className="float-end btn btn-gray">Go Back</a>
                </Link>:<Link to="">
                 {/* <a href="fleet-card-config" className="float-end btn btn-gray">Back</a> */}
                </Link>}
            </div>
         </div>
         <div className="row justify-content-center  mt-md-3 mt-3 form-container">
                <div className="col-md-12">
            
            {loading ? getBalanceSheet : <Loader />}

           
              
                  <div className="form-wrap balace-sheetwrap">

                    <div className="row mb-3">
                      <div className="col-md ">
                        <h2 className="table-title  mb-1">{state.pump_name}</h2>
                        <h3 className="table-title mb-1">{state.dealer_name}</h3>
                        <h3 className="table-title mb-1">{state.pump_address}</h3>
                        <h4 className="table-title mb-1">Balance Sheet</h4>
                        <h5>{state.config_date}</h5>

                        <img src={logo} className="img-fluid float-end " width="100px" />

                      </div>
                    </div>

                    <div className="row sheet-wrp">
                      <div className="col-md-12">
                        <table id="pdfdiv" className="table table-bordered border sheet-table">
                          <thead>
                            {/* <tr>
                                                          <td colspan="4"><h2 className="table-title mb-1">{state.pump_name}</h2></td>
                                                        </tr>
                                                           <tr>
                                                          <td colspan="4">{state.dealer_name}</td>
                                                        </tr>
                                                        <tr>
                                                          <td colspan="4">{state.pump_address}</td>
                                                        </tr>

                                                             <tr>
                                                          <td colspan="4"><b>BALANCESHEET</b></td>
                                                        </tr>
                                                         <tr>
                                                          <td colspan="4">{state.config_date}</td>
                                                        </tr>  */}


                            {/* <tr>
                                                          <td className="text-center " colspan="4"><img src={logo} className="img-fluid py-4" width="100px"/></td>
                                                        </tr> */}
                            <tr>
                              <th className="text-start">Account Name</th>

                              <th className="text-end">Credit</th>
                              <th className="text-end">Debit</th>
                            </tr>
                          </thead>
                          <tbody >
                            {all.map((account) => {

                              if (Number(account.drtamount) > Number(account.crtamount)) {
                                var Dramt = account.drtamount - account.crtamount;
                                Dramt = parseFloat(Dramt).toFixed(2);
                                var Cramt = '';
                                dr_total = Number(Dramt) + Number(dr_total);
                                dr_total = parseFloat(dr_total).toFixed(2);
                              }
                              else if (Number(account.crtamount) > Number(account.drtamount)) {
                                var Dramt = '';
                                var Cramt = account.crtamount - account.drtamount;
                                Cramt = parseFloat(Cramt).toFixed(2);

                                cr_total = Number(Cramt) + Number(cr_total);
                                cr_total = parseFloat(cr_total).toFixed(2);
                              }
                              if (buffer == account.ac_head) { flag = 1; }
                              else { flag = 0; }
                              if (account.ac_head != null && Cramt != Dramt) {
                                buffer = account.ac_head;
                              }

                              if (s_buffer == account.spr_ac_head) { s_flag = 1; }
                              else { s_flag = 0; }
                              if (account.spr_ac_head != null && Cramt != Dramt) {
                                s_buffer = account.spr_ac_head;
                              }

                              return (
                                <>
                                  {s_flag == 0 && Cramt != Dramt &&
                                    <>
                                      {account.spr_ac_head ?
                                        <tr><th colspan="">{account.spr_ac_head}</th>
                                          <th></th></tr> : ''}
                                    </>
                                  }

                                  {flag == 0 && Cramt != Dramt &&
                                    <>
                                      {account.ac_head ?
                                        <tr>
                                          <th colspan="">{account.ac_head}</th>
                                          <th></th>
                                        </tr> : ''}
                                    </>
                                  }
                                  {
                                    <>
                                      <tr><th><span className="sub_head">{account.sub_head}</span></th>

                                        <td className="text-end">{Cramt != '' && Cramt > 0 ? numberFormat(Cramt) : ''}</td>
                                        <td className="text-end">{Dramt != '' && Dramt > 0 ? numberFormat(Dramt) : ''}</td>
                                      </tr>
                                    </>
                                  }
                                </>
                              )
                            })}
                            <span className="sno">
                              <>
                                {Number(dr_total) == Number(cr_total) ?
                                  (org_amount = parseFloat(dr_total).toFixed(2))
                                  : ''}
                              </>
                            </span>
                            <span className="sno">
                              <>
                                {Number(dr_total) > Number(cr_total) ?
                                  (profit_loss_amount = parseFloat(dr_total - cr_total).toFixed(2),
                                    profit_loss = 'profit',
                                    org_amount = parseFloat(dr_total).toFixed(2)
                                  )
                                  : ''}
                              </>
                            </span>
                            <span className="sno">
                              <>
                                {Number(cr_total) > Number(dr_total) ?
                                  (profit_loss_amount = parseFloat(cr_total - dr_total).toFixed(2),
                                    profit_loss = 'loss',
                                    org_amount = parseFloat(cr_total).toFixed(2)
                                  )
                                  : ''}
                              </>
                            </span>

                            <tr>
                              {profit_loss == "profit" &&
                                <>

                                  <th>Owners Fund</th>


                                  <th className="text-end" >{numberFormat(profit_loss_amount)}</th>
                                  <td></td>
                                </>
                              }
                            </tr>
                            <tr>
                              {profit_loss == "loss" &&
                                <>

                                  <th>Loss</th>
                                  <td></td>
                                  <th className="text-end">{numberFormat(profit_loss_amount)}</th>


                                </>
                              }
                            </tr>
                            <tr>
                              <th>Total</th>

                              <th className="text-end">{numberFormat(org_amount)}</th>

                              <th className="text-end">{numberFormat(org_amount)}</th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                  <div className="col-md">

                  {location.location.pathname == "/cbalance-sheet/cbalance-sheet"?
                  <div className="text-end mt-4"><a onClick={printDocument} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a></div>:
                    <div className="text-end mt-4"><a onClick={printDocument} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Generate Pdf </a>
                      <a className="btn btn-success px-3 mb-md-0 mb-3  me-3" onClick={sendPDF} >Send Mail</a>
                      {input.stat_operation == "Yes" && input.configuration_report == "Yes" ? '' : <a onClick={confprintDocument} className="btn btn-success px-3 mb-md-0 mb-3 me-3">Configuration Report </a>}
                      {input.stat_operation == "Yes" && input.configuration_report == "Yes" ? <a onClick={sendmailltoowner} href='operational-date' className="btn btn-primary px-3 mb-md-0 mb-2" >Go to Operation</a> : ''}
                      <div>
                        {input.stat_operation == "Yes" && input.configuration_report == "Yes" ? '' : <p className="note" style={{ marginTop: "25px" }}>Note: Click all three options to proceed to operations.</p>}
                        {<p className="note" style={{ marginTop: "25px" }}>Note: You cant edit configured fuel stock after start operation.</p>} 
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}



export default BalanceSheet