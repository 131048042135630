import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import DensityAfterUnloadingConfig from './DensityAfterUnloadingConfig.component';
import { values } from "lodash";

// import FuelUnloadingConfig2 from './FuelUnloadingConfig2.component'
// import FuelUnloadingdensityModal from './FuelUnloadingDensityModal.component'
const FuelUnloadingEdit = ({
  preventNonNumericalInput,
  isShowing,
  EditDecant1,
  version,
  decant,
  showFuelTanker1,
  ProducttoggleReadOnly,
  container_number,
  toggleReadOnly,
  handleEditCloseReadings1,
  fuel,
  toggleReadOnlyDensity,
  toggleReadOnlyDecant,
  unload_product,
  data_list,
  handleEditReadings,
  handleEditReadings1,
  density_after_data,
  CompartmentReset,
  saveDecantFuel,
  density_data,
  handleInputss,
  handleEditDecant,
  EditDecant,
  handleAfterDensitySubmit,
  Values,
  handleType,
  handleEditRadio,
  startDate,
  handleEditDeQuantitySubmit1,
  handleDensity,
  handleAfterDensity,
  pumpDetails,
  handleInvoiceSubmitEdit,
  handleEditCloseReadings,
  product_data,
  handleTotal,
  handleRadio,
  drop,
  setDensity,
  handleInputChange,
  handleDecant,
  handleDensitybefore,
  containerData,
  handleTankerSubmit,
  input,
  handleIn,
  dropdown,
  handleInput,
  handleInput1,
  handleSelect,
  handleSelect1,
  handleSelect2,
  hide,
  setVehicle,
  errors,
  success,
  inputValues,
  handleAdd,
  handleRemove,
  decant_status,
  handleChange,
  fields,
  dropdownData,
  handleSubmit,
  handleInputs,
  handleAddFields,
  handleRemoveFields,
  handleDensitySubmitEdit,
  printPage,
}) => {
  return (
    <React.Fragment>
      {console.log(unload_product, "sdffr")}
      {console.log(Values, "values")}
      <div className="modal-overlay" />
      <div
        className="modal-wrapper"
        aria-modal
        aria-hidden
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal  modalxl">
          <div className="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Invoice details
            </h5>
            <button
              type="button"
              className="modal-close-button"
              data-dismiss="modal modalxxl"
              aria-label="Close"
              onClick={hide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="row py-3 mt-3">
            <div className="col-md-4 ">
              <div className="form-check ">
                <a>
                  {inputValues.tanker == 1 ? (
                    <input
                      className="form-check-input"
                      type="radio"
                      id="exampleRadios1"
                      name="tanker"
                      value="own_tanker"
                      checked
                      disabled
                      onClick={handleType}
                    />
                  ) : (
                    <input
                      className="form-check-input"
                      type="radio"
                      id="exampleRadios1"
                      name="tanker"
                      value="own_tanker"
                      disabled
                      onChange={handleType}
                    />
                  )}
                </a>
                <label className=" " for="examplecheckboxs1">
                  {" "}
                  Own Tanker
                </label>
              </div>
            </div>
            <div class="col-md-4 ">
              <div className="form-check ">
                {inputValues.tanker == 2 ? (
                  <input
                    className="form-check-input"
                    type="radio"
                    id="exampleRadios1"
                    name="tanker"
                    disabled
                    value="other_tanker"
                    checked
                    onClick={handleType}
                  />
                ) : (
                  <input
                    className="form-check-input"
                    type="radio"
                    id="exampleRadios1"
                    name="tanker"
                    disabled
                    value="other_tanker"
                    onChange={handleType}
                  />
                )}
                <label className=" " for="examplecheckboxs1">
                  {" "}
                  Other Tanker
                </label>
              </div>
            </div>

            {console.log(inputValues.reg_number)}

            {inputValues.tanker == 1 ? (
              <>
                <div class="row ">
                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label class="">
                        Registration Number<sub className="star">*</sub>
                      </label>
                      {console.log(inputValues.reg_number)}

                      <div class="select-container">
                        <select
                          class="form-control"
                          name="reg_number"
                          value={inputValues.reg_number}
                          onChange={handleInput1}
                          disabled
                        >
                          <option>select</option>
                          {dropdownData.map((data) => {
                            return <option value={data[0]}>{data[1]}</option>;
                          })}
                        </select>
                        <div className="errorMsg">{errors.reg_number}</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label class="">Tanker Capacity</label>

                      <input
                        type="text"
                        class="form-control  "
                        name="total_capacity"
                        placeholder=""
                        value={dropdown.total_capacity}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label class="">No.of Compartment</label>

                      <div class="select-container">
                        <select
                          className="form-control  "
                          name="no_of_containers"
                          disabled
                        >
                          {containerData.map((data) => {
                            return <option value={data[0]}>{data[1]}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                {drop.container == 1 && (
                  <div class="row mt-4">
                    <div className="col-md-4">
                      <div className="form-group mb-3 ">
                        <label class="">Capacity of Container in kl </label>

                        <input
                          type="text"
                          class="form-control  "
                          name="capacity_of_con1"
                          placeholder=""
                          value={drop.capacity_of_con1}
                          readOnly
                        />
                      </div>
                    </div>{" "}
                  </div>
                )}

                {drop.container == 2 && (
                  <>
                    <div class="row mt-4">
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 1 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control  "
                            name="capacity_of_con1"
                            placeholder=""
                            value={drop.capacity_of_con1}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 2 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control"
                            name="capacity_of_con2"
                            placeholder=""
                            value={drop.capacity_of_con2}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {drop.container == 3 && (
                  <>
                    <div class="row mt-4">
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 1 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control  "
                            name="capacity_of_con1"
                            placeholder=""
                            value={drop.capacity_of_con1}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 2 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control"
                            name="capacity_of_con2"
                            placeholder=""
                            value={drop.capacity_of_con2}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 3 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control"
                            name="capacity_of_con2"
                            placeholder=""
                            value={drop.capacity_of_con3}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {drop.container == 4 && (
                  <>
                    <div class="row mt-4">
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="" style={{ fontSize: 12 }}>Container no 1 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con1"
                            placeholder=""
                            value={drop.capacity_of_con1}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="" style={{ fontSize: 12 }}>Container no 2 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            value={drop.capacity_of_con2}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="" style={{ fontSize: 12 }}>Container no 3 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            value={drop.capacity_of_con3}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="" style={{ fontSize: 12 }}>Container no 4 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            value={drop.capacity_of_con4}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div class="row mt-4">
                  <div className="col-md-4">
                    <div className="form-group mb-3 ">
                      <label class="">
                        Invoice Number<sub className="star">*</sub>
                      </label>

                      <input
                        type="text"
                        class="form-control "
                        name="invoice_no"
                        value={inputValues.invoice_no}
                        onChange={handleInputs}
                        disabled
                      />
                      <div className="errorMsg">{errors.invoice_no}</div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group mb-3 ">
                      <label class="col-form-label">
                        Invoice Date<sub className="star">*</sub>
                      </label>

                      <input
                        type="date"
                        class="form-control  "
                        name="invoice_date"
                        value={inputValues.invoice_date}
                        min={startDate}
                        max={moment().format("YYYY-MM-DD")}
                        onChange={handleInputs}
                        disabled
                      />
                      <div className="errorMsg">{errors.invoice_date}</div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group mb-3 ">
                      <label class="col-form-label">
                        Invoice Amount<sub className="star">*</sub>
                      </label>

                      <input
                        type="text"
                        class="form-control"
                        name="invoice_amount"
                        disabled
                        onKeyPress={(event) =>
                          preventNonNumericalInput(event, event.target.value)
                        }
                        value={inputValues.invoice_amount}
                        onChange={handleInputs}
                      />
                      <div className="errorMsg">{errors.invoice_amount}</div>
                    </div>
                  </div>
                </div>

                {inputValues.tanker == 1 ? (
                  <div className="modal-footer justify-content-end p-0">
                    <button
                      className="btn btn-primary w-auto m-btn mb-2 "
                      onClick={handleSubmit}
                      disabled
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {inputValues.tanker == 2 ? (
              <>
                <div class="row mt-4">
                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label class="">
                        Registration Number<sub className="star">*</sub>
                      </label>

                      <input
                        type="text"
                        class="form-control "
                        name="reg_number"
                        value={input.reg_number}
                        onChange={handleSelect2}
                        disabled
                      />
                      <div className="errorMsg">{errors.number}</div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label class="">
                        Tanker Capacity<sub className="star">*</sub>
                      </label>

                      <input
                        type="text"
                        class="form-control  "
                        name="total_capacity"
                        value={input.total_capacity}
                        disabled
                        onChange={handleSelect2}
                      />
                      <div className="errorMsg">{errors.total_capacity}</div>
                    </div>
                  </div>

                  <div className="col-md-4 mt-4">
                    <div className="form-group mb-3 ">
                      <label class="">
                        No.of Compartment<sub className="star">*</sub>
                      </label>

                      <select
                        className="form-control  w-90"
                        name="no_of_containers"
                        value={input.no_of_containers}
                        onChange={handleIn}
                        disabled
                      >
                        <option value="0">Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                      <div className="errorMsg">{errors.no_of_containers}</div>
                    </div>
                  </div>
                </div>

                {input.no_of_containers == 1 && (
                  <div class="row mt-4">
                    <div className="col-md-4">
                      <div className="form-group mb-3 ">
                        <label class="">Capacity of Container in KL </label>

                        <input
                          type="text"
                          class="form-control  "
                          name="capacity_of_con1"
                          placeholder=""
                          value={input.capacity_of_con1}
                          onChange={handleSelect}
                          disabled
                        />
                        <div className="errorMsg">
                          {errors.capacity_of_con1}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {input.no_of_containers == 2 && (
                  <>
                    <div class="row mt-4">
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 1 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control  "
                            name="capacity_of_con1"
                            placeholder=""
                            disabled
                            value={input.capacity_of_con1}
                            onChange={handleSelect}
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con1}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 2 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            disabled
                            value={input.capacity_of_con2}
                            onChange={handleSelect}
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con2}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {input.no_of_containers == 3 && (
                  <>
                    <div class="row mt-4">
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 1 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control  "
                            name="capacity_of_con1"
                            placeholder=""
                            value={input.capacity_of_con1}
                            onChange={handleSelect}
                            disabled
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con1}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 2 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            disabled
                            value={input.capacity_of_con2}
                            onChange={handleSelect}
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con2}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group mb-3 ">
                          <label class="">Container no 3 Capacity in KL</label>
                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            disabled
                            value={input.capacity_of_con3}
                            onChange={handleSelect}
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con3}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {input.no_of_containers == 4 && (
                  <>
                    <div class="row mt-3">
                      <div className="col-md">
                        <div className="form-group  ">
                          <label class="" style={{ fontSize: 12 }}>Container no 1 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control  "
                            name="capacity_of_con1"
                            placeholder=""
                            disabled
                            value={input.capacity_of_con1}
                            onChange={handleSelect}
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con1}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group  ">
                          <label class="" style={{ fontSize: 12 }}>Container no 2 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            value={input.capacity_of_con2}
                            onChange={handleSelect}
                            disabled
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con2}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group  ">
                          <label class="" style={{ fontSize: 12 }}>Container no 3 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            value={input.capacity_of_con3}
                            onChange={handleSelect}
                            disabled
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con3}
                          </div>
                        </div>
                      </div>
                      <div className="col-md">
                        <div className="form-group  ">
                          <label class="" style={{ fontSize: 12 }}>Container no 4 Capacity in KL</label>

                          <input
                            type="text"
                            class="form-control "
                            name="capacity_of_con2"
                            placeholder=""
                            value={input.capacity_of_con4}
                            onChange={handleSelect}
                            disabled
                          />
                          <div className="errorMsg">
                            {errors.capacity_of_con4}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div class="row mt-4">
                  <div class="col-md-4">
                    <div class="form-group mb-3 ">
                      <label class="">
                        Invoice Number<sub className="star">*</sub>
                      </label>

                      <input
                        type="text"
                        class="form-control "
                        name="invoice_no"
                        value={input.invoice_no}
                        onChange={handleSelect1}
                        disabled
                      />
                      <div className="errorMsg">{errors.invoice_number}</div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group mb-3 ">
                      <label class="">
                        Invoice Date<sub className="star">*</sub>
                      </label>

                      <input
                        type="date"
                        class="form-control  "
                        name="invoice_date"
                        value={input.invoice_date}
                        min={startDate}
                        disabled
                        max={moment().format("YYYY-MM-DD")}
                        onChange={handleSelect1}
                      />
                      <div className="errorMsg">{errors.date}</div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group mb-3 ">
                      <label class="">
                        Invoice Amount<sub className="star">*</sub>
                      </label>

                      <input
                        type="text"
                        class="form-control "
                        name="invoice_amount"
                        value={input.invoice_amount}
                        onChange={handleSelect1}
                        disabled
                      />
                      <div className="errorMsg">{errors.amount}</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {inputValues.tanker == 2 ? (
              <div class="modal-footer justify-content-end p-0">
                <button
                  className="btn btn-primary m-btn w-auto mb-2 "
                  onClick={handleTankerSubmit}
                  disabled
                >
                  Next
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {/*-------------------------- second form edit------------------------ */}
          {product_data.length == 0 ? "" :
            <>
              <h5 class="modal-title" id="exampleModalLabel">
                Invoice details
              </h5>

              <div className="row mb-3 ">
                {product_data.map((fuelType, idx) => {
                  return (
                    <div className="col-md-4">
                      <div className="form-check ">
                        {fuelType.id != "" ? (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked
                            disabled
                            name="product_id"
                            onClick={(event) => {
                              ProducttoggleReadOnly(idx, event);
                            }}
                          />
                        ) : (
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={fuelType.id}
                            name="product_id"
                            disabled
                            onClick={(event) => {
                              ProducttoggleReadOnly(idx, event);
                            }}
                          />
                        )}
                        <label
                          className="form-check-label "
                          htmlFor="flexCheckDefault"
                        >
                          {fuelType.fuel_name}{" "}
                        </label>
                      </div>

                      <div class="  form-group mb-3 mt-3">
                        <p>Invoice Quantity in KL. </p>

                        <input
                          type="text"
                          className={`form-control check${fuelType.id}`}
                          name="invoice_quantity"
                          id={"quantity" + idx}
                          disabled
                          onChange={(event) => handleTotal(idx, event)}
                          defaultValue={fuelType.invoice_quantity}
                          readOnly={fuelType.isReadonly}
                        ></input>
                      </div>

                      <div class=" form-group mb-3">
                        <p>Product Wise Invoice Amount </p>

                        <input
                          type="text"
                          class="form-control amount"
                          id={"amount" + idx}
                          name="invoice_amount"
                          disabled
                          onChange={(event) => handleTotal(idx, event)}
                          defaultValue={fuelType.invoice_amount}
                          readOnly={fuelType.isReadonly}
                        ></input>
                      </div>
                      {version == "No" ? (
                        ""
                      ) : (
                        <>
                          <div class="form-group mb-3">
                            <p>Previous Invoice purchase price/Litre : </p>
                            <input
                              type="text"
                              class="form-control "
                              name="price"
                              disabled
                              onChange={handleInputs}
                              value={unload_product[idx].previous_price}
                              readOnly
                            />{" "}
                          </div>
                          <div class="form-group mb-3">
                            <p>current Invoice purchase price/Litre: </p>{" "}

                            <input
                              type="text"
                              class="form-control "
                              id={"current"}

                              value={unload_product[idx].current_price}
                              readOnly
                            />{" "}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}

                <div class="row ">
                  <div className="col-md-3">
                    <div className="form-group mb-3 ">
                      <p>
                        Total <sub className="star">*</sub>
                      </p>

                      <input
                        type="text"
                        class="form-control  "
                        id={"total"}
                        name="total"
                        defaultValue={Values.total}
                        onChange={handleTotal}
                        readOnly
                      ></input>
                      <div className="errorMsg">{errors.total}</div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group mb-3 ">
                      <p>
                        Round off<sub className="star">*</sub>
                      </p>

                      <input
                        type="text"
                        class="form-control  "
                        name="round_off"
                        id={"round_off"}
                        defaultValue={Values.round_off}
                        onChange={handleTotal}
                        readOnly
                      ></input>
                      <div className="errorMsg">{errors.round_off}</div>
                    </div>
                  </div>

    

                  

                  <div className="col-md-3">
                    <div className="form-group mb-3 ">
                      <p>
                        Net Amount<sub className="star">*</sub>{" "}
                      </p>

                      <input
                        type="text"
                        class="form-control  "
                        name="net_amount"
                        id={"net_amount"}
                        value={Values.net_amount}
                        onChange={handleTotal}
                        readOnly
                      ></input>
                      <div className="errorMsg">{errors.net_amount}</div>
                    </div>
                  </div>
                  {inputValues.tanker == 1 && Values.own_rent !== null && (
  <div className="col-md-3">
    <div className="form-group mb-3">
      <p>
        Own Tanker Rent<sub className="star">*</sub>
      </p>
      <input
        type="text"
        readOnly
        className="form-control"
        name="own_tanker_rent"
        id="own_tanker_rent"
        defaultValue={Values.own_rent}
        onChange={handleInputss}
      />
    </div>
  </div>
)}

<div className="col-md-3">
    <div className="form-group mb-3">
   
    </div>
  </div>


{inputValues.tanker == 1 &&   (
  <div>
    <div className="form-group mb-3">
      <label><h5>Other Pump Details</h5></label>
    </div>
    {pumpDetails.map((detail, index) => (
      <div key={index} className="row">
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label>Pump Name<sub className="star">*</sub></label>
            <input
              type="text"
              readOnly
              className="form-control"
              name="pump_name"
              value={detail.pump_name}
              onChange={(e) => handleInputChange(index, e)}
            />
            <div className="errorMsg">{errors[index]?.pump_name}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label>Quantity<sub className="star">*</sub></label>
            <input
              type="text"
              readOnly
              className="form-control"
              name="pump_quantity"
              value={detail.pump_quantity}
              onChange={(e) => handleInputChange(index, e)}
            />
            <div className="errorMsg">{errors[index]?.pump_quantity}</div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group mb-3">
            <label>Rent<sub className="star">*</sub></label>
            <input
              type="text"
              readOnly
              className="form-control"
              name="rent"
              value={detail.rent}
              onChange={(e) => handleInputChange(index, e)}
            />
            <div className="errorMsg">{errors[index]?.rent}</div>
          </div>
        </div>
        <div className="col-md-3">
          
          <div className="form-group mb-3">
            <label><sub className="star"></sub></label>
            <button
            disabled
              type="button"
              className="btn btn-danger"
              onClick={() => handleRemoveFields(index)}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    ))}
    <button
      type="button"
      disabled
      className="btn btn-success"
      onClick={handleAddFields}
    >
      + Add Pumps
    </button>
  </div>
)}
               
                  <div className="modal-footer justify-content-end p-0   ">
                    <button
                      className="btn btn-primary m-btn m-btn w-auto mb-2"
                      disabled
                      onClick={handleInvoiceSubmitEdit}

                    >
                      save/Continue
                    </button>
                  </div>
                </div>
              </div></>}
          {density_data.length == 0 ? "" :
            version == "No" ? (
              ""
            ) :
              // ---------------------------third form edit -----------------------//
              (
                <>
                  <h5 class="modal-title" id="exampleModalLabel">
                    Density
                  </h5>

                  <div class="row mt-4">
                    {density_data.map((fuelType, idx) => {
                      console.log(fuelType.isReadonly)
                      return (
                        <div class="">
                          <div className="col-md" key={`fuel_config${idx}`}>
                            <div className="form-group check-wrap  ">
                              <label
                                className="form-check-label me-5 fw-bold"
                                htmlFor="flexCheckDefault"
                              >
                                {fuelType.fuel_name}{" "}
                              </label>
                              {fuelType.hmv_before_unload != null ? (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="product_id"
                                  defaultChecked
                                  value={fuelType.product_id}
                                  name="product_id"
                                  onChange={(event) => {
                                    toggleReadOnlyDensity(idx, event, event.target.name);
                                  }}
                                />
                              ) : (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="product_id"
                                  value={fuelType.id}
                                  name="product_id"
                                  readOnly={fuelType.hmv_before_unload != null}

                                  onChange={(event) => {
                                    toggleReadOnlyDensity(idx, event, event.target.name);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div class="row ">
                            <div class=" py-2  col-md-6 mx-0">
                              <p>Invoice Density in KL. </p>
                            </div>
                            <div class="col-md-6 text-center  ">

                              <input
                                type="text"
                                className={`form-control check${fuelType.id}`}
                                defaultValue={fuelType.invoice_density}
                                name="invoice_density"
                                id={"density" + idx}

                                readOnly={fuelType.isReadonly}
                                onChange={(e) =>
                                  handleDensitybefore(idx, e, e.target.name)
                                }
                              ></input>
                            </div>
                          </div>
                          <div class="row ">
                            <div class=" py-2  col-md-6 mx-0">
                              <p>HMV </p>
                            </div>
                            <div class="col-md-6 text-center  ">
                              <input
                                type="text"
                                class="form-control "
                                name="hmv_before_unload"
                                defaultValue={fuelType.hmv_before_unload}
                                id={"hmv_before" + idx}

                                readOnly={fuelType.isReadonly}
                                onBlur={(e) => handleDensity(idx, e, e.target.name)}
                              ></input>
                              <div className="errorMsg">
                                {errors.hmv_after_unload}
                              </div>
                            </div>
                          </div>
                          <div class="row ">
                            <div class=" py-2  col-md-6 mx-0">
                              <p>Temperture </p>
                            </div>
                            <div class="col-md-6 text-center  ">
                              <input
                                type="text"
                                class="form-control "
                                name="temp_before_unload"
                                id={"temp_before" + idx}
                                defaultValue={fuelType.temp_before_unload}

                                readOnly={fuelType.isReadonly}
                                onBlur={(e) => handleDensity(idx, e, e.target.name)}
                              ></input>
                            </div>
                          </div>

                          <div class="row ">
                            <div class=" py-2  col-md-6 mx-0">
                              <p>Observerd Density </p>
                            </div>
                            <div class="col-md-6 text-center  ">
                              <input
                                type="text"
                                class="form-control amount"
                                name="density_before_unload"
                                id={"density_before" + idx}
                                defaultValue={fuelType.density_before_unload}
                                readOnly
                              ></input>
                            </div>
                          </div>
                          <div class="row ">
                            <div class=" py-2  col-md-6 mx-0">
                              <p>Difference </p>
                            </div>
                            <div class="col-md-6 text-center  ">
                              <input
                                type="text"
                                class="form-control"
                                name="density_difference"
                                id={"density_diff" + idx}
                                defaultValue={fuelType.density_difference}
                                readOnly
                              ></input>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="modal-footer justify-content-end p-0 ">

                    <button
                      className="btn btn-primary m-btn w-auto mb-2"
                      onClick={handleDensitySubmitEdit}

                    >
                      save/Continue
                    </button>
                  </div>
                </>
              )}
          {/* ---------------------------fourth form edit------------------------ */}
          {decant_status == true ?
            decant.length == 0 ? "" :
              decant.map((fuelType, index) => {
                return (
                  <>
                    <h5 class="modal-title" id="exampleModalLabel">
                      Decanting {fuelType.fuel_name}{" "}
                    </h5>
                    <div class="container pb-3">
                      <div class="row mb-3 mt-3 justify-content-center">
                        <div class="">
                          <div class="">
                            <div class="row">
                              <div
                                class="tab-pane fade show mt-5 active"
                                id=""
                                role="tabpanel"
                                aria-labelledby="existinguser-tab"
                              >
                                <p className="">
                                  <b></b>
                                </p>

                                {fuelType.tankData &&
                                  fuelType.tankData.map((tank, idx) => {
                                    return (
                                      <div class="row  mt-3">
                                        <div class="row  mt-3">
                                          <div class=""><p>Select Tank</p></div>
                                          <div className="form-check col-md-3 d-flex   ">
                                            <div class="">
                                              <p>Decanting Quantity in KL</p>
                                            </div>

                                            <a>
                                              {fuelType.decanting_quantity != null ?
                                                <input
                                                  className="form-check-input "
                                                  name="tank_id"
                                                  type="checkbox"
                                                  value={tank.id}
                                                  id="examplecheckboxs1"
                                                  defaultChecked
                                                  onClick={(e) => { toggleReadOnlyDecant(index, idx, e) }}
                                                /> :
                                                <input
                                                  className="form-check-input "
                                                  name="tank_id"
                                                  type="checkbox"
                                                  value={tank.id}
                                                  id="examplecheckboxs1"
                                                  onClick={(e) => { toggleReadOnlyDecant(index, idx, e) }}

                                                />}
                                            </a>
                                            <label
                                              className="form-check-label col-md-3 "
                                              for="examplecheckboxs1"
                                            >
                                              {" "}
                                              <h6> {tank.tank_name}</h6>
                                            </label>
                                          </div>
                                          <div className="col-md-2 mx-3">
                                            <input
                                              type="text"
                                              class="form-control "
                                              name="decanting_quantity"
                                              id={
                                                "decanting_quantity" + index + idx
                                              }
                                              defaultValue={
                                                fuelType.decanting_quantity
                                              }
                                              onChange={(e) =>
                                                handleEditDecant(
                                                  e,
                                                  index,
                                                  idx,
                                                  e.target.name
                                                )
                                              }
                                            ></input>
                                          </div>
                                          <div className="col-md-3 ">
                                            <a
                                              className="btn btn-primary mb-2"
                                              onClick={(event) =>
                                                handleEditDeQuantitySubmit1(
                                                  event,
                                                  index,
                                                  idx
                                                )
                                              }
                                            >
                                              save
                                            </a>
                                          </div>
                                        </div>
                                        {version == "No" ? (
                                          ""
                                        ) : (
                                          <div class="row mt-3 ">
                                            <div class="col-3 ">
                                              <label
                                                className=""
                                                for="examplecheckboxs1"
                                              >
                                                Stock Before(L)
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control  mb-2"
                                                name="stock_before"
                                                placeholder="stock"
                                                id={"stock_before" + index + idx}
                                                defaultValue={fuelType.stock_before}
                                              ></input>
                                            </div>
                                            <div class="col-3 ">
                                              <label
                                                className=""
                                                for="examplecheckboxs1"
                                              >
                                                Stock After(L)
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control "
                                                name="stock_after"
                                                placeholder="stock"
                                                id={"stock_after" + index + idx}
                                                defaultValue={fuelType.stock_after}
                                                readOnly
                                              ></input>
                                            </div>

                                            <div className=" d-flex mt-3">
                                              <div class=" col-md-3 mx-5 ">
                                                <p>Compartment number </p>
                                              </div>
                                              {container_number === 1 && (
                                                <>
                                                  <div className="form-check col-md-1   ">
                                                    <a>
                                                      {fuelType.compartments.includes(
                                                        1
                                                      ) ? (
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name="comp1"
                                                          id={"comp1" + index + idx}
                                                          value="1"
                                                          onChange={(e) =>
                                                            handleEditRadio(
                                                              e,
                                                              index,
                                                              idx
                                                            )
                                                          }
                                                          checked={true}
                                                        />
                                                      ) : (
                                                        <input
                                                          className="form-check-input"
                                                          type="radio"
                                                          name="comp1"
                                                          id={"comp1" + index + idx}
                                                          value="1"
                                                          onChange={(e) =>
                                                            handleEditRadio(
                                                              e,
                                                              index,
                                                              idx
                                                            )
                                                          }
                                                        />
                                                      )}
                                                    </a>
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      01
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                </>
                                              )}
                                              {container_number === 2 && (
                                                <>
                                                  <div className="form-check col-md-1 ">
                                                    {fuelType.compartments.includes(
                                                      1
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        checked
                                                        name="comp1"
                                                        value="1"
                                                        id={"comp1" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp1"
                                                        value="1"
                                                        id={"comp1" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      01
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                  <div className="form-check col-md-1 ">
                                                    {fuelType.compartments.includes(
                                                      2
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        checked
                                                        name="comp2"
                                                        value="2"
                                                        id={"comp2" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp2"
                                                        value="2"
                                                        id={"comp2" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      02
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                </>
                                              )}
                                              {container_number === 3 && (
                                                <>
                                                  <div className="form-check col-md-1 ">
                                                    {fuelType.compartments.includes(
                                                      1
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        checked
                                                        name="comp1"
                                                        value="1"
                                                        id={"comp1" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp1"
                                                        value="1"
                                                        id={"comp1" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}

                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      01
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                  <div className="form-check col-md-1 ">
                                                    {fuelType.compartments.includes(
                                                      2
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp2"
                                                        checked
                                                        value="2"
                                                        id={"comp2" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp2"
                                                        value="2"
                                                        id={"comp2" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      02
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                  <div className="form-check col-1 ">
                                                    {fuelType.compartments.includes(
                                                      3
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp3"
                                                        checked
                                                        value="3"
                                                        id={"comp3" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp3"
                                                        value="3"
                                                        id={"comp3" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      03
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                </>
                                              )}

                                              {container_number === 4 && (
                                                <>
                                                  <div className="form-check col-1 ">
                                                    {fuelType.compartments.includes(
                                                      1
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp1"
                                                        value="1"
                                                        id={"comp1" + index + idx}
                                                        checked
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp1"
                                                        value="1"
                                                        id={"comp1" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      01
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                  <div className="form-check col-1 ">
                                                    {fuelType.compartments.includes(
                                                      2
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp2"
                                                        checked
                                                        value="2"
                                                        id={"comp2" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp2"
                                                        value="2"
                                                        id={"comp2" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      02
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                  <div className="form-check col-1 ">
                                                    {fuelType.compartments.includes(
                                                      3
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp3"
                                                        checked
                                                        value="3"
                                                        id={"comp3" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp3"
                                                        value="3"
                                                        id={"comp3" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      03
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                  <div className="form-check col-1 ">
                                                    {fuelType.compartments.includes(
                                                      4
                                                    ) ? (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp4"
                                                        checked
                                                        value="4"
                                                        id={"comp4" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="comp4"
                                                        value="4"
                                                        id={"comp4" + index + idx}
                                                        onChange={(e) =>
                                                          handleEditRadio(
                                                            e,
                                                            index,
                                                            idx
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <label
                                                      className="form-check-label "
                                                      for="examplecheckboxs1"
                                                    >
                                                      {" "}
                                                      04
                                                    </label>
                                                    <ReactTooltip />
                                                  </div>
                                                </>
                                              )}
                                              <a
                                                class="reset_submit"
                                                id={"resetSubmit" + index + idx}
                                                onClick={() =>
                                                  CompartmentReset(index, idx)
                                                }
                                              >
                                                {" "}
                                                Reset{" "}
                                                <i
                                                  class="fa fa-refresh"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                        {version == "No" ? (
                                          ""
                                        ) : (
                                          <>
                                            {fuelType.nozzleData.map(
                                              (nozzle, nozzleIdx) => {
                                                return (
                                                  <>
                                                    <div class="row mt-4">
                                                      <div class=" py-2  col-md-3">
                                                        <p>Nozzle Name(Auto)</p>
                                                      </div>
                                                      <div class=" py-2  col-md-3">
                                                        <p>
                                                          Reading before decanting
                                                        </p>
                                                      </div>
                                                      <div class=" py-2  col-md-3">
                                                        <p>
                                                          Reading after decanting{" "}
                                                        </p>
                                                      </div>
                                                      <div class=" py-2  col-md-3">
                                                        <p>Difference </p>
                                                      </div>
                                                    </div>
                                                    <div class="row">
                                                      <div class="col-md text-center pe-2 ">
                                                        <input
                                                          type="text"
                                                          class="form-control "
                                                          name="nozzle_name"
                                                          defaultValue={
                                                            nozzle.nozzle_name
                                                          }
                                                          readOnly
                                                        ></input>
                                                      </div>

                                                      <div class="col-md text-center pe-2">
                                                        <input
                                                          type="text"
                                                          class="form-control "
                                                          name="opening_reading"
                                                          id={
                                                            "opening_reading" +
                                                            index +
                                                            idx +
                                                            nozzleIdx
                                                          }
                                                          defaultValue={
                                                            nozzle.opening_reading_before
                                                          }
                                                          onChange={(event) =>
                                                            handleEditReadings(
                                                              event,
                                                              nozzleIdx,
                                                              idx,
                                                              index,
                                                              event.target.name
                                                            )
                                                          }
                                                        ></input>
                                                      </div>

                                                      <div class="col-md text-center pe-2">
                                                        <input
                                                          type="text"
                                                          class="form-control "
                                                          name="close_reading"
                                                          id={
                                                            "close_reading" +
                                                            index +
                                                            idx +
                                                            nozzleIdx
                                                          }
                                                          defaultValue={
                                                            nozzle.closing_reading_after ==
                                                              null
                                                              ? nozzle.opening_reading_before
                                                              : nozzle.closing_reading_after
                                                          }
                                                          onChange={(event) =>
                                                            handleEditCloseReadings(
                                                              event,
                                                              nozzleIdx,
                                                              idx,
                                                              index,
                                                              event.target.name
                                                            )
                                                          }
                                                        ></input>
                                                      </div>
                                                      <div class="col-md text-center pe-2">
                                                        <input
                                                          type="text"
                                                          class="form-control  "
                                                          name="difference"
                                                          id={
                                                            "difference" +
                                                            index +
                                                            idx +
                                                            nozzleIdx
                                                          }
                                                          value={nozzle.difference}
                                                          readOnly
                                                        ></input>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </>
                                        )}
                                        {version == "No" ? (
                                          ""
                                        ) : (
                                          <div class="row g-3 justify-content-center mt-3 mb-2">
                                            <div class="col ">
                                              <label class="col-form-label ">
                                                Stock Difference(L)
                                              </label>
                                              <div class="d-md-flex">
                                                <input
                                                  type="text"
                                                  name="stock_difference"
                                                  id={
                                                    "stock_difference" + index + idx
                                                  }
                                                  defaultValue={
                                                    fuelType.stock_difference
                                                  }
                                                  class="form-control "
                                                  readOnly
                                                />
                                                <p class="ps-3 pt-2">+</p>
                                              </div>
                                            </div>
                                            <div class="col ">
                                              <label class="col-form-label ">
                                                Reading Difference(L)
                                              </label>

                                              <div class="d-md-flex">
                                                <input
                                                  type="text"
                                                  name="reading_difference"
                                                  id={
                                                    "reading_difference" +
                                                    index +
                                                    idx
                                                  }
                                                  defaultValue={
                                                    fuelType.reading_difference
                                                  }
                                                  class="form-control "
                                                  readOnly
                                                />
                                                <p class="ps-3 pt-2">=</p>
                                              </div>
                                            </div>
                                            <div class="col ">
                                              <label class="col-form-label ">
                                                Calulated Quantity(L)
                                              </label>
                                              <div class="d-md-flex">
                                                <input
                                                  type="text"
                                                  name="calculated_quantity"
                                                  defaultValue={
                                                    fuelType.calculated_quantity
                                                  }
                                                  id={
                                                    "calculated_quantity" +
                                                    index +
                                                    idx
                                                  }
                                                  readOnly
                                                  class="form-control "
                                                />
                                                <p class="ps-3 pt-2">-</p>
                                              </div>
                                            </div>
                                            <div class="col ">
                                              <label class="col-form-label ">
                                                Decanting Quantity(L)
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control "
                                                name="eq_decanting_quantity"
                                                defaultValue={
                                                  fuelType.decanting_quantity_lit
                                                }
                                                id={
                                                  "eq_decanting_quantity" +
                                                  index +
                                                  idx
                                                }
                                                readOnly
                                              />
                                            </div>
                                            <div class="col-2 ">
                                              <label class="col-form-label ">
                                                Excess/Short(L)
                                              </label>
                                              <input
                                                type="text"
                                                class="form-control "
                                                name="excess_short"
                                                defaultValue={
                                                  fuelType.excess_short_amount
                                                }
                                                id={"excess_short" + index + idx}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>

                          <div class="col-md-10"></div>
                        </div>
                        {version == "No" ? (
                          <div class="">
                            <div className="modal-footer justify-content-end p-0 ">
                              <a
                                className="btn btn-primary m-btn w-auto "
                                onClick={() => saveDecantFuel(index, fuelType)}
                              >
                                save/Continue
                              </a>
                            </div>   
                 	          <p className="modal-footer justify-content-end" style={{color:"#5d38dbeb"}}>
                              After successfull this save, Please 
					                  <u><a href="#" onClick={hide}>click here</a></u>to close
                            </p>
                          </div>
                          
                        ) : (
                          <div className="modal-footer justify-content-end p-0">
                            <a
                              className="btn btn-primary  w-auto m-btn mb-2"
                              onClick={() => EditDecant(index, fuelType)}
                            >
                              save/Continue
                            </a>
                          </div>
                        )}

                      </div>
                    </div>
                  </>
                );
              }) :

            decant.map((fuelType, index) => {
              console.log(fuelType)

              return (
                <>
                  <h5 class="modal-title" id="exampleModalLabel">Decanting {fuelType.fuel_name} </h5>
                  <div class="container pb-3">


                    <div class="row mb-3 justify-content-center">

                      <div class="">
                        <div class="">
                          <div class="row">

                            <div class="tab-pane fade mt-5 show active" id="" role="tabpanel" aria-labelledby="existinguser-tab">
                              <p className=""><b></b></p>

                              {fuelType.tankName.map((tank, idx) => {
                                return (
                                  <div class="row mt-4 ">

                                    <div class="row mt-3">

                                      {/* <div class="d-flex col-6"> */}
                                      {/* <div class="col-md-1 text-center   "></div> */}

                                      <div class=""><p>Select Tank</p></div>
                                      <div className="form-check col-md-3 d-flex   ">
                                        <div class=""><p>Decanting Quantity in KL</p></div>
                                        <a>  <input className="form-check-input col-md-3 " name="tank_id" type="checkbox" id={"tank_id" + idx} value={tank.id}
                                          onClick={(e) => { toggleReadOnlyDecant(index, idx, e) }} />
                                        </a><label className="form-check-label col-md-3 " for="examplecheckboxs1">  <h6>{tank.tank_name}</h6>
                                        </label>
                                      </div>
                                      <ReactTooltip />

                                      {/* <div class="row  "> */}

                                      {/* <div class="col-md-12  text-center    "> */}
                                      <div className="col-md-3 mx-3">
                                        <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow" name="decanting_quantity" placeholder="Decanting quantity" id={"decanting_quantity" + index + idx} onChange={(e) => handleDecant(e, index, idx, e.target.name)}></input>
                                        <div className="errorMsg">{errors.decanting_quantity}</div>

                                      </div>
                                      <div className="col-md-3 "><a className="btn btn-primary mb-2" onClick={(event) => handleEditDeQuantitySubmit1(event, index, idx)}>save</a></div>
                                      {/* </div> */}
                                    </div>

                                    {version == "No" ? "" :

                                      <div class="row mt-3   ">

                                        <div class="col-3 ">
                                          <label className="" for="examplecheckboxs1">Stock Before(L)</label>
                                          <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow mb-2" name="stock_before" id={"stock_before" + index + idx} readOnly value={tank.stock}></input>
                                        </div>
                                        <div class="col-3 ">
                                          <label className="" for="examplecheckboxs1">Stock After(L)</label>
                                          <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow " name="stock_after" id={"stock_after" + index + idx} readOnly></input>
                                        </div>

                                        <div className=" d-flex mt-3">


                                          <div class="   col-md-3"><p>Compartment number </p></div>
                                          {fuelType.noOfComponent === 1 &&
                                            <>
                                              <div className="form-check col-md-2   ">

                                                <a>  <input className={"form-check-input compartment" + index + idx} type="radio" name="comp1" id={"comp1" + index + idx} value="1" onChange={handleRadio} />
                                                </a><label className="form-check-label " for="examplecheckboxs1">   01
                                                </label>
                                                <ReactTooltip />

                                              </div>
                                            </>}
                                          {fuelType.noOfComponent === 2 &&
                                            <>
                                              <div className="form-check col-md-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio"
                                                  name="comp1" value="1" id={"comp1" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1" >  01
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                              <div className="form-check col-md-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio"
                                                  name="comp2" value="2" id={"comp2" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  02
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                            </>}
                                          {fuelType.noOfComponent === 3 &&
                                            <>
                                              <div className="form-check col-md-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio"
                                                  name="comp1" value="1" id={"comp1" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  01
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                              <div className="form-check col-md-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio" name="comp2"
                                                  value="2" id={"comp2" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  02
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                              <div className="form-check col-md-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio" name="comp3"
                                                  value="3" id={"comp3" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  03
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                            </>}

                                          {fuelType.noOfComponent === 4 &&
                                            <>
                                              <div className="form-check col-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio" name="comp1"
                                                  value="1" id={"comp1" + index + idx} onClick={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  01
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                              <div className="form-check col-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio" name="comp2"
                                                  value="2" id={"comp2" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  02
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                              <div className="form-check col-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio" name="comp3"
                                                  value="3" id={"comp3" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  03
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                              <div className="form-check col-1 ">

                                                <input className={"form-check-input compartment" + index + idx} type="radio" name="comp4"
                                                  value="4" id={"comp4" + index + idx} onChange={handleRadio} />
                                                <label className="form-check-label " for="examplecheckboxs1">  04
                                                </label>
                                                <ReactTooltip />
                                              </div>
                                            </>}
                                          <a class="reset_submit" id={"resetSubmit" + index + idx} onClick={() => CompartmentReset(index, idx)}> Reset <i class="fa fa-refresh" aria-hidden="true"></i> </a>
                                          {/* <a class="reset_submit"  onClick={CompartmentReset}> Reset <i class="fa fa-refresh" aria-hidden="true"></i> </a> */}
                                        </div>
                                      </div>}
                                    {version == "No" ? "" :
                                      tank.tankNozzleData.map((nozzle, nozzleIdx) => {
                                        return (
                                          <>
                                            <div class="row mt-4">

                                              <div class=" py-2  col-md-3"><p>Nozzle Name(Auto)</p></div>
                                              <div class=" py-2  col-md-3"><p>Reading before decanting</p></div>
                                              <div class=" py-2  col-md-3"><p>Reading after decanting </p></div>
                                              <div class=" py-2  col-md-3"><p>Difference </p></div>
                                            </div>
                                            <div class="row">
                                              <div class="col-md text-center pe-2 ">
                                                <input type="text" class="form-control " name="nozzle_name" defaultValue={nozzle.nozzle_name} readOnly></input>
                                              </div>

                                              <div class="col-md text-center pe-2">
                                                <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow " name="opening_reading" id={"opening_reading" + index + idx + nozzleIdx} defaultValue={nozzle.close_reading == null ? nozzle.opening_reading : nozzle.close_reading} onChange={(event) => handleEditReadings1(event, nozzleIdx, idx, index, event.target.name)}></input>
                                              </div>

                                              <div class="col-md text-center pe-2">
                                                <input type="number" onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)} class="form-control hide-arrow " name="close_reading" id={"close_reading" + index + idx + nozzleIdx} defaultValue={nozzle.close_reading == null ? nozzle.opening_reading : nozzle.close_reading} onChange={(event) => handleEditCloseReadings1(event, nozzleIdx, idx, index, event.target.name)}></input>
                                              </div>
                                              <div class="col-md text-center pe-2">
                                                <input type="text" class="form-control  " name="difference" id={"difference" + index + idx + nozzleIdx} value={nozzle.difference} readOnly ></input>
                                              </div>

                                            </div>

                                          </>
                                        )
                                      })}
                                    {version == "No" ? "" :
                                      <div class="row g-3 justify-content-center mt-3 mb-2">

                                        <div class="col ">
                                          <label class=" ">Stock Difference(L)</label>
                                          <div class="d-md-flex"><input type="text" name="stock_difference" id={"stock_difference" + index + idx} value={tank.stock_difference} class="form-control " readOnly />
                                            <p class="ps-2 pt-1">+</p></div>
                                        </div>
                                        <div class="col ">
                                          <label class=" ">Reading Difference(L)</label>
                                          {console.log(tank)}
                                          <div class="d-md-flex"><input type="text" name="reading_difference" id={"reading_difference" + index + idx} value={tank.reading_difference} class="form-control " readOnly />
                                            <p class="ps-2 pt-1">=</p></div>
                                        </div>
                                        <div class="col ">
                                          <label class="">Calulated Quantity(L)</label>
                                          <div class="d-md-flex"><input type="text" name="calculated_quantity" id={"calculated_quantity" + index + idx} readOnly class="form-control  " />
                                            <p class="ps-2 pt-1">-</p></div>
                                        </div>
                                        <div class="col ">
                                          <label class=" ">Decanting Quantity(L)</label>
                                          <input type="text" class="form-control " name="eq_decanting_quantity" id={"eq_decanting_quantity" + index + idx} readOnly />



                                        </div>
                                        <div class="col-2 ">
                                          <label class=" ">Excess/Short(L)</label>
                                          <input type="text" class="form-control  " name="excess_short" id={"excess_short" + index + idx} readOnly />

                                        </div>

                                      </div>
                                    }
                                  </div>
                                )
                              })}



                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                    {version == "No" ? <div class="">
                      <div className="modal-footer justify-content-end p-0 "><a className="btn btn-primary m-btn w-auto " onClick={() => saveDecantFuel(index, fuelType)}>save/Continue</a></div>

                    </div> :
                      <div class="">
                        <div className="modal-footer justify-content-end p-0 "><a className="btn btn-primary m-btn w-auto " onClick={() => EditDecant1(index, fuelType)}>save/Continue</a></div>

                      </div>}


                  </div>
                </>)
            })}
          {
            density_after_data[0]?.afterDecant.length != 0 ? "" :
              showFuelTanker1 ?
                <DensityAfterUnloadingConfig
                  unloading={density_data}

                /> : null

          }

          {density_after_data?.length == 0 ? "" :
            version == "No" ? (
              ""
            ) : (
              <>
                {/* --------------------------fifth form edit ---------------------------- */}
                {density_after_data[0].afterDecant.length == 0 ? "" :
                  <h5 class="modal-title" id="exampleModalLabel">
                    Density After Unloading and Dispensing 50 LTr
                  </h5>
                }
                {

                  density_after_data?.map((fuelConfig, index) => {
                    return (

                      <>

                        {console.log(density_after_data)}
                        {fuelConfig?.afterDecant?.length == 0 ? "" :
                          <div class="col-md-12">
                            <div class="">

                              <div class="row mb-3 justify-content-center">
                                <div class="row mt-md-5 mt-3 form-container">

                                  <div class="col-md-12 mt-4">
                                    <div class="row ">
                                      <div class=" py-2  col-md-6">
                                        <p>
                                          <strong>Product</strong>
                                          <sub className="star">*</sub>
                                        </p>
                                      </div>

                                      <div class="col-md text-center pe-2 ">
                                        <input
                                          type="text"
                                          class=" form-control "
                                          value={fuelConfig.fuel_name}
                                          readOnly
                                        />
                                        <div className="errorMsg">
                                          {errors.product_id}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row ">
                                      <div class=" py-2  col-md-6">
                                        <p>
                                          Tank Name<sub className="star">*</sub>
                                        </p>
                                      </div>

                                      {fuelConfig.tankData.map((tank, idx) => {
                                        return (
                                          <>
                                            <div class="col-md text-center pe-2 ">
                                              <input
                                                type="text"
                                                class=" form-control "
                                                readOnly
                                                value={tank.tank_name}
                                              ></input>
                                              <div className="errorMsg">
                                                {errors.tank_id}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                    </div>

                                    <div class="row ">
                                      <div class=" py-2  col-md-6 mx-0">
                                        <p>
                                          HMV <sub className="star">*</sub>{" "}
                                        </p>
                                      </div>
                                      {
                                        fuelConfig?.afterDecant?.map((decant, idx) => {
                                          return (
                                            <>
                                              <div class="col-md text-center  ">
                                                <input
                                                  type="text"
                                                  class="form-control "
                                                  name="hmv_after_unload"
                                                  id={"hmv" + index + idx}
                                                  defaultValue={decant.hmv_after_unload}
                                                  onBlur={(e) =>
                                                    handleAfterDensity(
                                                      index,
                                                      idx,
                                                      e,
                                                      e.target.name
                                                    )
                                                  }
                                                ></input>
                                                <div className="errorMsg">
                                                  {errors.hmv_after_unload}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>
                                    <div class="row ">
                                      <div class=" py-2  col-md-6 mx-0">
                                        <p>
                                          Temperature <sub className="star">*</sub>{" "}
                                        </p>
                                      </div>
                                      {
                                        fuelConfig?.afterDecant?.map((decant, idx) => {
                                          return (
                                            <>
                                              <div class="col-md text-center  ">
                                                <input
                                                  type="text"
                                                  class="form-control "
                                                  name="temp_after_unload"
                                                  id={"temp" + index + idx}
                                                  defaultValue={decant.temp_after_unload}
                                                  onBlur={(e) =>
                                                    handleAfterDensity(
                                                      index,
                                                      idx,
                                                      e,
                                                      e.target.name
                                                    )
                                                  }
                                                ></input>
                                                <div className="errorMsg">
                                                  {errors.temp_after_unload}
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>

                                    <div class="row ">
                                      <div class=" py-2  col-md-6 mx-0">
                                        <p> Density </p>
                                      </div>
                                      {
                                        fuelConfig?.afterDecant?.map((decant, idx) => {
                                          return (
                                            <>
                                              <div class="col-md text-center  ">
                                                <input
                                                  type="text"
                                                  class="form-control amount"
                                                  name="density_after_unload"
                                                  id={"observed_density" + index + idx}
                                                  defaultValue={
                                                    decant.density_after_unload
                                                  }
                                                  readOnly
                                                  onChange={(e) =>
                                                    handleAfterDensity(
                                                      index,
                                                      idx,
                                                      e,
                                                      e.target.name
                                                    )
                                                  }
                                                ></input>
                                              </div>
                                            </>
                                          );
                                        })}
                                    </div>

                                    <div class="col-md-10"></div>
                                  </div>


                                </div>
                              </div>

                            </div>
                          </div>
                        }
                      </>
                    );
                  })}
                {density_after_data[0].afterDecant.length == 0 ? "" :
                  <div class="">
                    <div className="modal-footer justify-content-end p-0">
                      <a
                        className="btn btn-primary w-auto m-btn mb-2"
                        onClick={handleAfterDensitySubmit}
                      >
                        save/Continue
                      </a>
                    </div>
                  </div>
                }
                
               
                       
          <p className="modal-footer justify-content-end" style={{color:"#5d38dbeb"}} >
          After successfull this save, Please 
          <u><a href="#" onClick={hide}>click here</a></u> to close
          </p> 
              </>
            )}

          <div class="col-md modal-footer">
            <a onClick={printPage} className="btn btn-success mb-2">
              print
            </a>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default FuelUnloadingEdit;
