import React, { useState } from 'react';
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from '../../../../img/customer-review.png'
import ReactDOM from 'react-dom';
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import Modal from "react-modal";
import SummarySheet from '../SummarySheet.component';
import TabHeader from '../TabHeader.component';
import CardSalesModal from './CardSalesModal.component'
import LoginHeaderOperational from '../../LoginHeaderOperational';
import Loader from '../Loader.component'
import DeleteModalConfig from './DeleteModalConfig.component'
import moment from 'moment';
import { convertAmountToWords, preventNonNumericalInput,prevDec, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";
const CardModal = (props) => ReactDOM.createPortal(
	<CardSalesModal  {...props} />, document.body
);
const DeleteModal = (props) => ReactDOM.createPortal(
	<DeleteModalConfig  {...props} />, document.body
);
const CardSalesConfig = (props) => {
	const [summary, setSummary] = useState([])
	const [delete_id, deleteId] = useState([])
	const [inputValues, setInputValues] = useState({});
	const [input, setInput] = useState({});
	const [fields, setFields] = useState(['']);
	const history = useHistory()
	const [double, setDouble] = useState(true);
	const [search, setSearch] = useState('')
	const [list_data, setList] = useState([])
	const [input2, setInput2] = useState({});
	const [payment, setPayment] = useState([])
	const [errors, setErrors] = useState({})
	const [success, setSuccess] = useState([])
	const [total, setTotal] = useState([])
	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})
	//loader
	const [Loading, setLoading] = useState(false);
	//---On Load---//		
	useEffect(() => {
		check_url();

	}, []);
	function getSummerySheet() {
		const requestOptions = {
			method: 'post',
			body: JSON.stringify({
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
				op_date: localStorage.getItem('op_date'),
			}),

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/getSummerySheet", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setSummary(responseData)
				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	//------------------------url
	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					check_meta_date();
				}
				else {

					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function check_meta_date() {

		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			}
		};
		fetch(`${API_URL}/check_meta_date`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				//	console.log(responseData.op_date)
				if (!responseData.op_date || !localStorage.getItem('emp_id') || !localStorage.getItem('op_date')) {
					history.push("/nozzle-employee-list");
				}
				else {

					setInput({ ...input, op_date: responseData.op_date })
					getSummerySheet();
					ListCardSales(pages.current_page);
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}
	function ListCardSales(page) {
		const requestOptions = {      //---Digital payment listing---//
			method: 'get',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},

		};
		fetch(`${API_URL}/card_sales_digital_payment?search_keyword=${search}&page=${page}&emp_id=${localStorage.getItem('emp_id')}&op_emp_id=${localStorage.getItem('op_emp_id')}&op_date=${localStorage.getItem('op_date')}`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setLoading(true);

				setList(responseData.data.data)

				setInput2({ ...input2, isdayclose: responseData.isdayclose })
				setTotal({
					...total,
					totalamount: responseData.total_amount,
				})

				var total_pages = responseData.data.last_page

				var items = []
				for (let number = 1; number <= total_pages; number++) {
					number === page ? items.push(
						<li key={number} className="page-item active"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,) : items.push(
							<li key={number} className="page-item"><a className="page-link" onClick={() => handlePagination(number)}>{number}</a></li>
                      ,)
				}

				setpages({
					...pages,
					current_page: responseData.data.current_page,
					last_page: responseData.data.last_page,
					total_pages: responseData.data.last_page,
					pagination_items: items
				})

				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	//product name dropdown api integration//
	// function getPayment() {
	// 	const requestOptions = {
	// 		method: 'get',

	// 		headers: {
	// 			"Access-Control-Allow-Origin": "*",
	// 			"Content-Type": "application/json",
	// 			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
	// 			"Accept": "application/json"
	// 		},
	// 	};
	// 	fetch(API_URL + "/get_digital_payment", requestOptions)
	// 		.then(response => { return response.json(); })
	// 		.then(responseData => {
	// 			var datas = []

	// 			responseData.forEach(elem => {
	// 				datas.push([elem.id, elem.method_name])
	// 			})

	// 			setPayment(datas);
	// 			return responseData;
	// 		})
	// 		.catch(e => {
	// 			console.log(e);

	// 		});
	// }

	function getPayment() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
				"Accept": "application/json"
			}
		};
	
		const op_emp_id = localStorage.getItem('op_emp_id');
		const url = `${API_URL}/get_digital_payment?op_emp_id=${op_emp_id}`;
	
		fetch(url, requestOptions)
			.then(response => response.json())
			.then(responseData => {
				var datas = [];
	
				responseData.forEach(elem => {
					datas.push([elem.id, elem.method_name]);
				});
	
				setPayment(datas);
				return responseData;
			})
			.catch(e => {
				console.log(e);
			});
	}
	
	const handlePagination = (number) => {
		setpages({
			...pages,
			current_page: number        //---pagination---//
		}
		)

		ListCardSales(number)
	}
	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		function toggle() {
			getPayment();
			var isdayclose = input2.isdayclose;
			if (isdayclose == 1) {
				swal("", "You do not have this permission after day close", false ? "success" : "warning")
			}
			else {
				if (isShowing) {
					setInputValues({})
					setErrors({})
					setSuccess({})
					setFields([''])
				}
				if (!isShowing) {
					setDouble(true)
					setErrors({})

				}
				setIsShowing(!isShowing);
			}
		}

		return {
			isShowing,
			toggle,
		}
	};
	const UseDeleteModal = () => {

		const [isClose, setClose] = useState(false);

		function toggling() {

			setClose(!isClose);
		}

		return {
			isClose,
			toggling,
		}
	};
	const { isClose, toggling } = UseDeleteModal();
	const { isShowing, toggle } = UseModal();
	//---Delete popup function---//
	const [isOpen, setIsOpen] = useState(false);

	//---onchange functions---//
	const handleIn = (e) => {
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});
		convertAmountToWords(e, e.target.value)



	}
	const handleInputs = (e) => {

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});

	};

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}
	// ---handlesubmit function---//
	const handleSubmit = (event) => {
		event.preventDefault();
		setDouble(false);
		sendCard()
	}

	//---Create EmployeeList---//
	function sendCard() {

		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				payment_method_id: inputValues.payment_method_id,
				amount: inputValues.amount,
				note: inputValues.note,
				card_no: inputValues.card_no,
				id: inputValues.id,
				op_date: localStorage.getItem("op_date"),
				op_emp_id: localStorage.getItem('op_emp_id'),
				emp_id: localStorage.getItem('emp_id'),
			})
		};
		fetch(API_URL + "/card_sales_digital_payment", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				setSuccess({
					...success,
					disabled: responseData.status === true ? true : false,
				})
				let errors = []
				if (responseData.status == false) {
					errors["payment_method_id"] = responseData.response.payment_method_id
					errors["amount"] = responseData.response.amount
					setDouble(true);
				}
				if (responseData.status == "DENY") {
					toggle();
					swal("", responseData.message, responseData.status ? "warning" : "warning")
					setDouble(true)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					history.push("/card-sales")
					setErrors([''])
					inputValues.payment_method_id = ""
					inputValues.amount = ""
					inputValues.note = ""
					inputValues.card_no = ""
					inputValues.id = ""
					setInputValues(inputValues)
					getSummerySheet()
					toggle();
					setDouble(true)
					ListCardSales(pages.current_page);

				}
				setErrors(errors)
				return responseData;
			})
			.catch(e => {
				console.log(e)
				swal("Something went wrong", "", "warning")
				setDouble(true)
			});
	}
	//---Edit employee list---//	
	function edit_Card(id) {
		setErrors([])

		const requestOptions = {
			method: 'get',
			data: {
				id: id,


			},
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/card_sales_digital_payment/" + id + "/edit", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {

				setInputValues(responseData);
				toggle();
				return responseData;
			})
			.catch(e => {
				console.log(e)

			});
	}
	//---Delete EmployeeList---//	
	function delete_card(id) {

		var isdayclose = input2.isdayclose;
		if (isdayclose == 1) {
			swal("", "You do not have this permission after day close", false ? "success" : "warning")
		}
		else {
			deleteId(id)

			toggling()
		}

	}

	function deleteCardSales() {

		setDouble(false)
		const requestOptions = {
			method: 'DELETE',

			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
			body: JSON.stringify(delete_id)
		};
		fetch(API_URL + "/card_sales_digital_payment/" + delete_id, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				swal("", responseData.message, responseData.status ? "success" : "warning")
				getSummerySheet()
				ListCardSales(pages.current_page);
				toggling()
				setDouble(true)
				return responseData;


			})

			.catch(err => {
				console.log(err)
				swal("Something went wrong", "", "warning")

			})
	}


	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={7} />
					<div className="content-area">
						<LoginHeaderOperational props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">
								<div className="col-md-9">
									<TabHeader progress={7} />
									<div className="row">
										{Loading ? ListCardSales :
											// <div class="loader text-primary">Loading...</div>
											<Loader />
										}
									</div>
									<div class="form-wrap ">

										<div class="tab-content" id="myTabContent">
											<div class="tab-pane fade show active" id="cardsales" role="tabpanel" aria-labelledby="cardsales-tab">
												<div class="">
													<div class="row mb-3">
														<div class="col-md">
															<div class="row">

																<div class="col-md col pl-0">
																	<h3 class="table-title">Card Sales / Digital Payment </h3><h4>({localStorage.getItem("emp_name")})-{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																	<h4 class="table-title">Shift Time : {localStorage.getItem("shift_time")} </h4>
																</div>

															</div>
														</div>
														<div className="col-md-4">
															<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Digital Payment</a>
														</div>
														<div class="col-md-4">
															<div class="input-group mb-3 search">
																<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																</span>
																<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
																<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>														</div>
														</div>
													</div>


													<div class="table-widget">
														<table class="table border">
															<thead>
																<tr>

																	<th>Payment Type System</th>
																	<th>Description</th>
																	<th>Card Number</th>
																	<th >Amount</th>
																	<th>Operational Date</th>
																	<th>Action</th>

																</tr>
															</thead>
															<tbody>
																{
																	list_data.map(obj => (
																		<tr>

																			<td>{obj.method_name}</td>
																			<td>{obj.note}</td>
																			<td>{obj.card_no}</td>
																			<td>{obj.amount}</td>
																			<td>{moment(obj.op_date).format("DD-MM-YYYY")}</td>


																			<td class="py-2"><a href="#" class="text-secondary" type="submit" onClick={() => edit_Card(obj.id)} ><i class="fa fa-pencil-square-o pe-2" aria-hidden="true"></i></a>
																			{localStorage.getItem("save_hide") == 'false' ?
																			<a href="#" class="text-secondary" type="submit" onClick={() => delete_card(obj.id)}><i class="fa fa-trash-o pe-2" aria-hidden="true"></i></a>
																			:''}
																			</td>
																		</tr>
																	))}
																<tr>
																	<td colSpan={3} class="text-end mt-4"><b>Total Amount</b></td>
																	<td colSpan={3}> {total.totalamount ? parseFloat(total.totalamount).toFixed(2) : '0'}</td>
																</tr>
															</tbody>
														</table>
														<nav aria-label="Page navigation example">
															<ul className="pagination justify-content-end">
																{pages.total_pages > 1 && pages.pagination_items}
															</ul>
														</nav>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-3">
									<SummarySheet summary={summary} />
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			{isShowing ? (<CardModal
				isShowing={isShowing}
				hide={toggle}
				handleIn={handleIn}
				success={success}
				handleInputs={handleInputs}
				preventNonNumericalInput={preventNonNumericalInput}
				prevNonNumericalWithDec={prevNonNumericalWithDec}
				prevDec={prevDec}
				inputValues={inputValues}
				payment={payment}
				handleSubmit={handleSubmit}
				double={double}

				errors={errors}
			/>) : null
			}
			{isClose ? (<DeleteModal
				isClose={isClose}
				hide={toggling}
				deleteCardSales={deleteCardSales}
				double={double}
			/>) : null
			}

		</>
	)
}








export default CardSalesConfig