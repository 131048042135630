import React, { useState,useMemo, useEffect } from 'react';
import customer_review from '../../img/customer-review.png';
import LoginHeader from "../layouts/LoginHeader.component";
import Sidebar_Progress from "../layouts/Sidebar_Progress.component";
import axios from 'axios';
import { API_URL } from "../../constant/API_Settings";
import { Modal, ModalFooter } from "react-bootstrap";
import moment from 'moment';
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import museum from '../../img/museum.svg'
import Loader from '../operational-model/Employee/Loader.component';
import ManagerLoginHeader from '../operational-model/Manager/ManagerLoginHeader.component';




const Productsconfig = (location) => { 

  const UseModal = () =>{
    
    const [isShowing,setIsShowing] =  useState(false);
 
    function toggle() {
      
      setIsShowing(!isShowing);
      seterrors('')
      setinputValues('')
      setLooseOil('')
      seterrors('')
      
    }


    return {
        isShowing,
        toggle,
      }
  
  }

  const { isShowing, toggle } = UseModal();
  const [inputValues, setinputValues] = useState('');
  const [stat_operation, setStartoperation] = useState('')
  const [path, setLocation] = useState([])
  const history = useHistory()
  const [editisShowing, seteditisShowing] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  // const [deleteid,setdeleteid]= useState(null)
  const [list_gas, setListgas] = useState([])
  const [Loading, setLoading] = useState(false);
  const [inputValuescombined, setinputValuescombined] = useState('');
  const [errors, seterrors] = useState({product_name    :'',
                                        product_quantity : '',
                                        no_of_full_cylinder : '',   
                                        no_of_empty_cylinder:'',  
                                        gas_gst:'',   
                                        full_cyl_pp_prise:'', 
                                        sellingprice_full_cyl:'', 
                                        empty_cylinder_inc:'',
                                        selling_price_empty:'',
                                        exc_emptofull:'',
                                        selling_emptofull:'',
                                        looseOil:'',
});
const [looseOil, setLooseOil] = useState("")

const handleLooseOilChange = (e) => {
  setLooseOil(e.target.value)
}
  
  const handleInputs = (e) => {
    const { name, value } = e.target;
  
    setinputValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [name]: value,
      };
      const p_name = newValues.product_name ? newValues.product_name : "";
      const p_qty = newValues.product_quantity ? newValues.product_quantity : "";
      const combinedName = p_name && p_qty ? `${p_name} ${p_qty} KG` : '';
      const combinedNamefullcyl = p_name && p_qty ? `${p_name} ${p_qty} KG Full Cylinder`: '';
      const  combinedemptycylindername =p_name && p_qty ? `${p_name} ${p_qty} KG Empty Cylinder`: '';
      const full_cylinder_exc_gst=newValues.full_cyl_pp_prise ? newValues.full_cyl_pp_prise : "";
      const Commom_Gst_gas       = newValues.gas_gst ? newValues.gas_gst : "";
      const full_cyl_selling_price = newValues.sellingprice_full_cyl ?  newValues.sellingprice_full_cyl : "";
      const to_tal_quantityfull=newValues.no_of_full_cylinder ? newValues.no_of_full_cylinder : "";
      const empty_cyl_exc_gst = newValues.empty_cylinder_inc ? newValues.empty_cylinder_inc : "";
      const empty_exc_selling_price=newValues.selling_price_empty ? newValues.selling_price_empty : "";
      const to_tal_quantityempty=newValues.no_of_empty_cylinder ? newValues.no_of_empty_cylinder : "";
      const pp_inc_emptofull=newValues.pp_exc_emptofull ? newValues.pp_exc_emptofull : "";
      const sellingemptofull= newValues.selling_emptofull ? newValues.selling_emptofull : "";
      

      newValues.empty_cylinder_inc = Number(full_cylinder_exc_gst) -Number(pp_inc_emptofull)
      newValues.selling_price_empty =  newValues.empty_cylinder_inc 
      
      console.log(newValues.empty_cylinder_inc,"combinedNamefullcyl")
        let pp_exc_gst              =0;
        let pp_gas_gst              =0;
        let emp_cyl_exc_gst         =0;
        let emp_pp_gst              =0;
        let returngstfull           =0;
        let emp_return_gst          =0;
        let marginfullcyl           =0;
        let marginemptycyl          =0;
        let total_full_qty          =0;
        let total_emp_qty           =0;
        let exc_emptofull           =0;
        let pp_empty_full           =0;
        let sellingprice_emptofull  =0;
        let marginemptofull         =0;


        if(full_cylinder_exc_gst){
          // alert(full_cyl_selling_price)
          const result = TotalCalculation(full_cylinder_exc_gst,Commom_Gst_gas,full_cyl_selling_price,to_tal_quantityfull)
          pp_exc_gst   = result.exc_gst_gas
          pp_gas_gst   =result.pp_gas_gst
          returngstfull=result.returngst
          marginfullcyl=result.marginpergas
          total_full_qty=result.Stockvalue
        }
        console.log(returngstfull,"returngstfull")
        if(empty_cyl_exc_gst){
          const result2 = TotalCalculation(empty_cyl_exc_gst,Commom_Gst_gas,empty_exc_selling_price,to_tal_quantityempty)
          emp_cyl_exc_gst=result2.exc_gst_gas
          emp_pp_gst=result2.pp_gas_gst
          emp_return_gst=result2.returngst
          marginemptycyl=result2.marginpergas
          total_emp_qty=result2.Stockvalue
        }

      

        const result3 = TotalCalculation(pp_inc_emptofull,Commom_Gst_gas,sellingemptofull)
        exc_emptofull = result3.exc_gst_gas
        pp_empty_full = result3.pp_gas_gst
        sellingprice_emptofull= result3.returngst
        marginemptofull= result3.marginpergas

        console.log(marginemptofull,"marginemptofull")
      
      // end combine name   

      // exc gst full
      // if(full_inc_gst){
      // full_exc_gst  =Number((full_inc_gst * (100 / (100 + Number( full_cyl_gst)))).toFixed(2));
      // }
      // end exc gst full

      // pp exc gst full
      //  pp_exc_gst= Number(full_inc_gst) * Number(full_cyl_gst) / (100 + Number(full_cyl_gst));
      // end exc gst full

      
      
      // const full_inc_gst =newValues.full_cyl_pp_prise ? newValues.full_cyl_pp_prise:"";
      // const full_cyl_gst = newValues.gas_gst ? newValues.gas_gst:"";
      
      // const emp_cyl_gas =newValues.empty_cylinder_inc ? newValues.empty_cylinder_inc:"";
     
      // let full_exc_gst   = 0
      // let pp_exc_gst     = 0
      // let emp_cyl_exc    = 0
      // let pp_exc_emp_gst = 0

      // if(full_inc_gst){
      //   const result = calculategas(full_inc_gst,full_cyl_gst)
      //   full_exc_gst = result.pp_exc_gst
      //   pp_exc_gst = result.pp_gst
      // }

      // if(emp_cyl_gas){
      //   const result2 = calculategas(emp_cyl_gas,full_cyl_gst)
      //   console.log(result2,"result")
      //   emp_cyl_exc = result2.pp_exc_gst
      //   pp_exc_emp_gst = result2.pp_gst
      // }


// console.log(result,"result")
      //returngst//
      // const sellingfullprice=newValues.sellingprice_full_cyl? newValues.sellingprice_full_cyl:"";
      // const return_full_cyl_gst=Number(sellingfullprice)*Number( full_cyl_gst)/100;
      // const marginfullcyl=Number(sellingfullprice)-(Number(full_inc_gst)+Number(return_full_cyl_gst))
      // const exc_gst =newValues.no_of_full_cylinder? newValues.no_of_full_cylinder:"";
      // const stockvaluefullcyl= Number(full_exc_gst)*Number(exc_gst)
      //endreturngst//

      return {
        ...newValues,
        combined_name: combinedName,
        pp_exc_gst_full:pp_exc_gst.toFixed(2),
        emp_cyl_exc_gst:emp_cyl_exc_gst.toFixed(2),
        pp_gas_gst:pp_gas_gst.toFixed(2),
        emp_pp_gst:emp_pp_gst.toFixed(2),
        returngstfull:returngstfull.toFixed(2),
        emp_return_gst:emp_return_gst.toFixed(2),
        marginfullcyl:marginfullcyl.toFixed(2),
        marginemptycyl:marginemptycyl.toFixed(2),
        total_full_qty:total_full_qty.toFixed(2),
        total_emp_qty:total_emp_qty.toFixed(2),
        exc_emptofull:exc_emptofull.toFixed(2),
        pp_empty_full:pp_empty_full.toFixed(2),
        sellingprice_emptofull:sellingprice_emptofull.toFixed(2),
        marginemptofull:marginemptofull.toFixed(2),
        combinedfullcylindername:combinedNamefullcyl,
        combinedemptycylindername:combinedemptycylindername,
      };

    
    });
 
  };
  const TotalCalculation =( incgst,gstpercentage,sellingprice,total_quantity)=>{
  const exc_gst_gas =Number(incgst)*(100/(100+Number(gstpercentage)))
  const pp_gas_gst = Number(incgst)*Number(gstpercentage)/(100+Number(gstpercentage))
  // const returngst = Number(sellingprice)*(Number(gstpercentage)/100)
  const returngst1 =Number(sellingprice)*(100/(100+(Number(gstpercentage))))
  const returngst2=Number(incgst)*(100/(100+(Number(gstpercentage))))
  const returngst3 =returngst1 -returngst2
  const returngst = returngst3*(Number(gstpercentage)/100)
  const marginpergas = Number(sellingprice)-(Number(incgst)+Number(returngst))
  const Stockvalue  =Number(exc_gst_gas)*Number(total_quantity)
// alert(sellingprice)
  return{exc_gst_gas,pp_gas_gst,returngst,marginpergas,Stockvalue}
 }





//  const handleSubmit = (event) => {
//   event.preventDefault();
//   if ((looseOil === "no")) {
//     swal("Success!", "Saved Successfully.", "success");
//     toggle();
//   } 

//   if(validateForm())
//   { 
//     Save_gas_cylinder_config();
//     gas_save();
//     setinputValues('');
//   }
// }
const handleSubmit = (event) => {
  event.preventDefault();

  if (looseOil === "no") {
    swal("Success!", "Saved Successfully.", "success");
    toggle();
    return; // Early return to prevent further execution
  } 

  if (validateForm()) { 
    Save_gas_cylinder_config();
    gas_save();
    setinputValues('');
    toggle(); // Close the modal
  }
}



  const handleditsubmit = (event) => {
    event.preventDefault();
  if(validateForm())
  { 
    Save_gas_cylinder_config();
    gas_save();
    setinputValues('');
  }
}

const [search, setSearch] = useState({})

useEffect(() => {
   gas_save();
    check_url()
    setLocation(location.location.pathname)
}, [search]);

function check_url() {
  const requestOptions = {
      method: 'post',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
      body: JSON.stringify({
          pathurl: window.location.pathname,
      }),
  };
  fetch(`${API_URL}/check_url`, requestOptions)
      .then(response => { return response.json(); })

      .then(responseData => {
          setStartoperation(responseData.stat_operation)
          if (responseData.allowurl == 1) {
              if (location.location.pathname == "/Productsconfig") {
                  history.push(location.location.pathname);
              }
              // else {
              //     history.push(responseData.url);
              // }
             
          }
          else {

              history.goBack();
          }

          return responseData;
      })
      .catch(e => {
          console.log(e)
      });

}

function gas_save() {
  const requestOptions = {
      method: 'post',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
      body: JSON.stringify({
          pathurl: window.location.pathname,
      }),
  };
  fetch(`${API_URL}/gas_cylinder`, requestOptions)
      .then(response => { return response.json(); })
      

      .then(responseData => {
          setListgas(responseData.data)
          setLoading(true)
          return responseData;
      })
      .catch(e => {
          console.log(e)
      });

}
// console.log(list_gas,"list_gaslist_gas")
function validateForm() {
  let formIsValid = true;
  let errors = []

  if ((!looseOil || looseOil === "select") && list_gas.length === 0) {
    formIsValid = false;
    errors["looseOil"] = "Please select if you have Gas Cylinder Products";
} else {
    errors["looseOil"] = "";
}

if (looseOil === "yes" || list_gas.length > 0) {
  if(inputValues.product_name == "" || inputValues.product_name == undefined){
    formIsValid = false;
    errors["product_name"] = "Enter Product Name"
  }else{
    errors["product_name"] = ""
  }
  if(inputValues.product_quantity == "" || inputValues.product_quantity == undefined){
    formIsValid = false;
    errors["product_quantity"] = "Please enter the quantity"
  }else{
    errors["product_quantity"] = ""
  }
  if(!inputValues.no_of_full_cylinder && stat_operation !== "Yes"){
    formIsValid = false;
    errors["no_of_full_cylinder"] = "Please enter no of full cylinder"
  }else{
    errors["no_of_full_cylinder"] = ""
  }
  if(!inputValues.no_of_empty_cylinder && stat_operation !== "Yes"){
    formIsValid = false;
    errors["no_of_empty_cylinder"] = "Please enter no of empty cylinder"
  }else{
    errors["no_of_empty_cylinder"] = ""
  }
  if(!inputValues.gas_gst){
    formIsValid = false;
    errors["gas_gst"] = "Please enter gst%"
  }else{
    errors["gas_gst"] = ""
  }
  if(!inputValues.full_cyl_pp_prise){
    formIsValid = false;
    errors["full_cyl_pp_prise"] = "Please enter a valid purchase price"
  }else{
    errors["full_cyl_pp_prise"] = ""
  }
  if(!inputValues.sellingprice_full_cyl){
    formIsValid = false;
    errors["sellingprice_full_cyl"] = "Please enter selling price"
  }else{
    errors["sellingprice_full_cyl"] = ""
  }
  if(!inputValues.empty_cylinder_inc){
    formIsValid = false;
    errors["empty_cylinder_inc"] = "Please enter a valid purchase price"
  }else{
    errors["empty_cylinder_inc"] = ""
  }
  if(!inputValues.selling_price_empty){
    // alert(inputValues.selling_price_empty)
    formIsValid = false;
    errors["selling_price_empty"] = "Please enter selling price"
  }else{
    errors["selling_price_empty"] = ""
  }
  if(!inputValues.pp_exc_emptofull){
   
    formIsValid = false;
    errors["pp_exc_emptofull"] = "Please enter a valid purchase price"
  }else{
    errors["pp_exc_emptofull"] = ""
  }
  if(!inputValues.selling_emptofull){
    // alert(inputValues.selling_emptofull)
    formIsValid = false;
    errors["selling_emptofull"] = "Please enter selling price"
  }else{
    errors["selling_emptofull"] = ""
  }
}
  
  seterrors(errors)
  return formIsValid;
 }

 function checkSkip() {
  history.push("/Products")
}

 const backPage = useMemo(() => {

  if (location?.location?.backpage) {
      return location?.location?.backpage
  }
  else
      return undefined
}, [location?.location?.pathname])

 function Save_gas_cylinder_config() {
 
  const requestOptions = {
      method: 'POST',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
      body: JSON.stringify({
        
        id:inputValues.id,
        stat_operation:stat_operation, 
        product_name: inputValues.product_name,
        product_quantity:inputValues.product_quantity,
        combined_name:inputValues.combined_name,
        no_of_full_cylinder:inputValues.no_of_full_cylinder,
        no_of_empty_cylinder:inputValues.no_of_empty_cylinder,
        gas_gst:inputValues.gas_gst,
        full_cyl_pp_prise:inputValues.full_cyl_pp_prise,
        pp_exc_gst_full:inputValues.pp_exc_gst_full,
        full_cyl_pp_prise:inputValues.full_cyl_pp_prise,
        sellingprice_full_cyl:inputValues.sellingprice_full_cyl,
        returngstfull:inputValues.returngstfull,
        marginfullcyl:inputValues.marginfullcyl,
        total_full_qty:inputValues.total_full_qty,
        empty_cylinder_inc:inputValues.empty_cylinder_inc,
        emp_cyl_exc_gst:inputValues.emp_cyl_exc_gst,
        emp_pp_gst:inputValues.emp_pp_gst,
        selling_price_empty:inputValues.selling_price_empty,
        emp_return_gst:inputValues.emp_return_gst,
        marginemptycyl:inputValues.marginemptycyl,
        total_emp_qty:inputValues.total_emp_qty,
        pp_exc_emptofull:inputValues.pp_exc_emptofull,
        exc_emptofull:inputValues.exc_emptofull,
        pp_empty_full:inputValues.pp_empty_full,
        selling_emptofull:inputValues.selling_emptofull,
        return_gst_emp_to_full:inputValues.sellingprice_emptofull,
        marginemptofull:inputValues.marginemptofull,
        fullcylindername:inputValues.combinedfullcylindername,
        emptycylindername:inputValues.combinedemptycylindername,
        pp_gas_gst:inputValues.pp_gas_gst,
      })
      
  };
  console.log(inputValues,"ooooooooooooooooooooooooooooooo")
  fetch(API_URL + "/save_gas_cylinder", requestOptions)
  .then(response => { return response.json(); })

			.then(responseData => {
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")

          if(inputValues.id){
            
            handleEditModalclose();
          }else{
            
            toggle();
          }
         
        }
        if (responseData.status == false) {
          // alert(2)
					swal("", responseData.response.combined_name[0], responseData.status ? "success" : "warning")
         
        }
        
      }).catch(error => {
        swal("Something went wrong", "", "warning")
      
    });
  
  console.log(requestOptions,"requestOptions")  
}
const preventToFour = (event, currentValue) => {
  const char = String.fromCharCode(event.which);

  // Allow digits, one decimal point, and basic control keys (like backspace, delete, arrows, etc.)
  const allowedSpecialKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
  if (allowedSpecialKeys.includes(event.key)) {
      return; // Allow these keys
  }

  // Only allow digits and decimal points
  if (!/^[0-9.]$/.test(char)) {
      event.preventDefault(); // If not a digit or a decimal point, prevent the action
      return;
  }

  // Split current input into parts: before and after decimal point
  const parts = currentValue.split(".");

  // Allow only one decimal point
  if (char === "." && parts.length > 1) {
      event.preventDefault(); // If there's already a decimal point, prevent a new one
      return;
  }

  // If no decimal point, ensure the first part (before the decimal) doesn't exceed 5 digits
  if (parts.length === 1 && parts[0].length >= 8 && char !== ".") {
      event.preventDefault(); // If trying to add more than 5 digits before the decimal, prevent the action
      return;
  }

  // If there's a decimal point, ensure only 2 digits are allowed after it
  if (parts.length === 2 && parts[1].length >= 2) {
      event.preventDefault(); // If trying to add more than 2 digits after the decimal, prevent the action
      return;
  }
};

const handleEditModalOpen = (id) => {
  edit_gas(id)
  seteditisShowing(true)
}
const handleEditModalclose = (id) => {
  
  seteditisShowing(false)
  
}

function handledeletegas(id){
  setIsDeleteOpen(true)
  setinputValues({...inputValues,
  delete_id:id})
}

function handledeletegasclose( ){
  setIsDeleteOpen(false)
}

function edit_gas(id) {
  const requestOptions = {
      method: 'post',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
      body: JSON.stringify({
          pathurl: window.location.pathname,
           id: id 
      }),
  };
  fetch(`${API_URL}/edit_gas_cylinder`, requestOptions)
      .then(response => { return response.json(); })
      

      .then(responseData => {

        setinputValues({
					...inputValues,
          id:id,
          product_name:responseData.data.name,
          product_quantity:responseData.data.quantity,
          combined_name:responseData.data.product_name,
          no_of_full_cylinder:responseData.data.no_of_full_cylinder,
          no_of_empty_cylinder:responseData.data.no_of_empty_cylinder,
          gas_gst:responseData.data.gst_percentage,
          full_cyl_pp_prise:responseData.data.purchase_price_full,
          pp_exc_gst_full:responseData.data.purchase_price_exc_gst_full,
          pp_gas_gst:responseData.data.purchase_price_gst_full,
          sellingprice_full_cyl:responseData.data.selling_price_full,
          returngstfull:responseData.data.return_gst_full,
          marginfullcyl:responseData.data.margin_price_full,
          total_full_qty:responseData.data.stock_value_full,
          combinedfullcylindername:responseData.data.ac_name_full,
          combinedemptycylindername:responseData.data.ac_name_empty,
          empty_cylinder_inc:responseData.data.purchase_price_empty,
          emp_cyl_exc_gst:responseData.data.purchase_price_exc_gst_empty,
          emp_pp_gst:responseData.data.purchase_price_gst_empty,
          selling_price_empty:responseData.data.selling_price_empty,
          emp_return_gst:responseData.data.return_gst_empty,
          marginemptycyl:responseData.data.margin_price_empty,
          total_emp_qty:responseData.data.stock_value_empty,
          pp_exc_emptofull:responseData.data.purchase_price_exchange,
          exc_emptofull:responseData.data.purchase_price_exc_gst_exchange,
          pp_empty_full:responseData.data.purchase_price_gst_exchange,
          selling_emptofull:responseData.data.selling_price_exchange,
          sellingprice_emptofull:responseData.data.return_gst_exchange,
          marginemptofull:responseData.data.margin_price_exchange,

        })
        // setinputValues(responseData.data)

        // if (responseData.status == true) {
				// 	swal("", responseData.message, responseData.status ? "success" : "warning")
        //   toggle();
        // }
          return responseData;
      })
      .catch(e => {
          console.log(e)
      });

}
console.log(inputValues,"vvvvvvvv")


function delete_gas() {
  const requestOptions = {
      method: 'POST',
      headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          "Accept": "application/json"
      },
      body: JSON.stringify({
        id:inputValues.delete_id,
      })
  };
   fetch(API_URL + "/edit_gas_delete", requestOptions)
      .then(response => {
          return response.json();
      })
      .then(responseData => {
        if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
          handledeletegasclose();
          gas_save();
          }
          
      return responseData;
      }).catch(e => {
         
          console.log(e);
        
      });
}


  console.log(inputValues,"requestOptions")

  return(
    <>
   <div className="main-container page-wrap">
   <div className="d-md-flex">
   {path == "/Productsconfig/Productsconfig" ? <Sidebar_Progress path={path} /> : <Sidebar_Progress />}
          <div className="content-area">
          <ManagerLoginHeader/>
          <div class="container pb-3 tab-view">
          <div class="row  mt-3 form-container">
          <div className="row mt-3">

<div className="col-md-12 mb-3 px-4 ">{path == "/Productsconfig/Productsconfig" ? <Link to={backPage || "/operational-date"}>
    <a href="#" className="float-end btn btn-gray">Go Back</a>
</Link> : <Link to="/loose-oil-config">
    <a href="loose-oil-config" className="float-end btn btn-gray">Back</a>
</Link>}</div>
</div>
          <div class="form-wrap ">
          {Loading ? gas_save : <Loader />}
          <div class="tab-content" id="myTabContent">
              <div class="tab-pane  fade show active" id="packedoil" role="tabpanel" aria-labelledby="packedoil-tab">
              <div class="">
                <div class="row mb-3">
                <div className="col-4">
                
                <img src={museum} width="25px" className="img-fluid" alt=".." /><h3 class="table-title">Gas Cylinder Configuration</h3>
                </div>
                <div className="col-4">
                <a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add GAS PRODUCTS</a>
                </div>
                <div class="col-md-4">
																		<div hidden class="input-group mb-3 search">
																			<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																			</span>
																			<input type="text" className="form-control" placeholder="Search  " value={''} onChange={''} />
																			<a className="btn btn-primary bt-search btn-sm" data-bs-target="#gas" onClick={''} >Submit</a>
																		</div>
						 </div>

                  <div class="table-widget">
                    <div  class="table border">
                      <table>
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>No of Full-Cylinder</th>
                            <th>No of empty-Cylinder</th>
                            <th>purchase Price full-cyl(inc-gst)</th>
                            <th>purchase Price full-cyl(exc-gst)</th>
                            <th>Selling Price/Full-Cylinder</th>
                            <th>Stock Value Full Cylinder</th>
                            <th>purchase Price Empty-cyl(inc-gst)</th>
                            <th>purchase Price Empty-cyl(exc-gst)</th>
                            <th>Selling Price/empty-Cylinder</th>
                            <th>Stock Value Empty Cylinder</th>
                            <th>purchase Price(Empty to full cyl)(inc-gst)</th>
                            <th>purchase Price(Empty to full cyl)(exc-gst)</th>
                            <th>Selling Price/Empty to Full Cylinder</th>
                            <th>Action</th>
                            
                          </tr>
                        </thead>
                        <tbody>
  {list_gas.map((gaslist, idx) => (
    
    <tr key={idx}>
      <td>{gaslist.product_name}</td>
      <td>{gaslist.quantity}</td>
      <td>{gaslist.no_of_full_cylinder}</td>
      <td>{gaslist.no_of_empty_cylinder}</td>
      <td>{gaslist.purchase_price_full}</td>
      <td>{gaslist.purchase_price_exc_gst_full}</td>
      <td>{gaslist.selling_price_full}</td>
      <td>{gaslist.stock_value_full}</td>
      <td>{gaslist.purchase_price_empty}</td>
      <td>{gaslist.purchase_price_exc_gst_empty}</td>
      <td>{gaslist.selling_price_empty}</td>
      <td>{gaslist.stock_value_empty}</td>
      <td>{gaslist.purchase_price_exchange}</td>
      <td>{gaslist.purchase_price_exc_gst_exchange}</td>
      <td>{gaslist.selling_price_exchange}</td>
      <td>
        <div className="d-flex icon-btn">
        <button onClick={() => handleEditModalOpen(gaslist.id)}>
        <i className="fa fa-pencil" aria-hidden="true"></i>
         </button>
        <button onClick={() => handledeletegas(gaslist.id)}>
         <i className="fa fa-trash" aria-hidden="true"></i>
         </button>
         </div>
          </td>
      
      {console.log(gaslist,"gaslist")}

    </tr>
    
  ))}
</tbody>

                      </table>
                    </div>
                  </div>
             </div>
             </div> 
             </div> 
            


          </div>
          </div>
          {path == "/productsconfig/productsconfig" ? "" :
   <div className="text-end mt-4"><a className="btn btn-primary px-3 mb-md-0 mb-2" onClick={checkSkip}>Continue</a></div>
    }
          </div>
          
          </div>
         
          </div>
    </div>
    
    </div>
{/* /////////modal */}
{

isShowing ? (
<>
<div className="modal-overlay" />
<div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
<div className="modal  modalxl">
<div className="modal-body">
<div className="modal-header">

									<h5 className="modal-title">Gas Cylinder Configuration</h5>
       
									<button type="button" className="close" onClick={toggle}>
										<span aria-hidden="true">&times;</span>
									</button>
                 
								</div>

                {
  list_gas.length === 0 && (
    <div className="row justify-content-center align-items-center">
      <label className="col-sm-5 col-form-label mb-3">
        Do you have Gas Products? <sup className="star">*</sup>
      </label>
      <div className="col-sm-4">
        <div className="select-container highlight">
          <select
            className="form-control"
            name="loose_oil"
            value={looseOil}
            onChange={handleLooseOilChange}
          >
            <option value="select">select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {
                                                errors['looseOil'] && <div className="errorMsg">
                                                    {errors['looseOil']}
                                                </div>
                                            }
        </div>
      </div>
    </div>
  )
}

                {(looseOil == "yes" || list_gas.length > 0) &&
                <div className="modal-body">
                <form>
                <div className="row mb-3">
      <div className="col">
        <label for="productName" className="form-label">Product Name<sup class="star">*</sup></label>
        <input type="text" className="form-control" id="productName" name="product_name"  onChange={handleInputs} />
        <div className="errorMsg">{errors.product_name}</div>
      </div>

      <div className="col">
        <label for="productQuantity" className="form-label">Product Quantity in Kg<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="productQuantity" onKeyPress={(event) => preventToFour(event, event.target.value)}  name="product_quantity" onChange={handleInputs}/>
        <div className="errorMsg">{errors.product_quantity}</div>
      </div>

      <div className="col">
        <label for="productQuantity" className="form-label">Product Full Name</label>
        <input value={inputValues.combined_name}  readOnly type="text" className="form-control" id="productQuantity" />
      </div>

    </div>

    {/* <div className="row mb-3">
    <div className="col-4">
      <label for="productFullName" className="form-label">Product Full Name</label>
      <input value="GAS 5 Kg" type="text" className="form-control" id="productFullName" />
 
    </div>
  
  </div> */}
    <div className="row mb-3">
      
    { stat_operation == "Yes" ? (<div className="col">
        <label for="numberOfFullCylinder" className="form-label">Number of Full Cylinder<sup class="star">*</sup></label>
        <input disabled type="text" className="form-control" id="numberOfFullCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_full_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_full_cylinder}</div>
      </div>) : (<div className="col">
        <label for="numberOfFullCylinder" className="form-label">Number of Full Cylinder<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="numberOfFullCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_full_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_full_cylinder}</div>
      </div>)
}
{ stat_operation == "Yes" ? (<div className="col">
        <label for="numberOfEmptyCylinder" className="form-label">Number of Empty Cylinder<sup class="star">*</sup></label>
        <input  disabled type="text" className="form-control" id="numberOfEmptyCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_empty_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_empty_cylinder}</div>
      </div>): (<div className="col">
        <label for="numberOfEmptyCylinder" className="form-label">Number of Empty Cylinder<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="numberOfEmptyCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_empty_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_empty_cylinder}</div>
      </div>)}
      <div className="col">
        <label for="gstPercentage" className="form-label">GST Percentage<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="gstPercentage"onKeyPress={(event) => preventToFour(event, event.target.value)}  name="gas_gst" onChange={handleInputs}  />
        <div className="errorMsg">{errors.gas_gst}</div>
      </div>
    </div>
    <div className="row mb-3">
      <div className="col">
        <label for="purchasePriceFullCylinderIncGst" className="form-label">Purchase Price/FullCylinder (inc GST)<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="purchasePriceFullCylinderIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="full_cyl_pp_prise" onChange={handleInputs}  />
        <div className="errorMsg">{errors.full_cyl_pp_prise}</div>
      </div>
      <div className="col">
        <label  for="purchasePriceFullCylinderExcGst" className="form-label">Purchase Price/Full Cylinder (exc GST)</label>
        <input  value={inputValues.pp_exc_gst_full} disabled type="text"  className="form-control" id="purchasePriceFullCylinderExcGst" />
      </div>
      <div className="col">
        <label  for="purchasePriceFullCylinderGst" className="form-label">Purchase Price/Full Cylinder(GST)</label>
        <input  value={inputValues.pp_gas_gst}  disabled type="text" className="form-control" id="purchasePriceFullCylinderGst" />
      </div>
    </div>
    <div className="row mb-3">
      <div className="col">
        <label for="sellingPriceFullCylinderIncGst" className="form-label">Selling Price/Full Cylinder (inc GST)<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="sellingPriceFullCylinderIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="sellingprice_full_cyl" onChange={handleInputs} />
        <div className="errorMsg">{errors.sellingprice_full_cyl}</div>
      </div>
      <div className="col">
        <label for="returnGstPerFullCylinder" className="form-label">Return GST per Full Cylinder</label>
        <input value={inputValues.returngstfull}  disabled type="text" className="form-control" id="returnGstPerFullCylinder" />
      </div>
      <div className="col">
        <label for="marginFullCylinder" className="form-label">Margin/Full Cylinder</label>
        <input value={inputValues.marginfullcyl} disabled type="text" className="form-control" id="marginFullCylinder" />
      </div>
    </div>
    <div className="row mb-3">
      <div className="col-4">
        <label for="stockValueFullCylinder" className="form-label">Stock Value Full Cylinder</label>
        <input value={inputValues.total_full_qty} disabled type="text" className="form-control" id="stockValueFullCylinder" />
      </div>
      <div className="col-4">
        <label for="fullnameFullCylinder" className="form-label">Name of Full Cylinder</label>
        <input value={inputValues.combinedfullcylindername}  disabled type="text" className="form-control" id="fullnameFullCylinder" />
      </div>
      <div className="col-4">
        <label for="stockValueFullCylinder" className="form-label">Name of Empty Cylinder</label>
        <input  value={inputValues.combinedemptycylindername}  disabled  type="text" className="form-control" id="stockValueFullCylinder" />
      </div>
    </div>
   
    <div className="row mb-3">
      <div className="col">
        <label for="purchasePriceEmptyToFullCylinderExchangeIncGst" className="form-label">Purchase Price/Empty to Full Cylinder Exchange (inc GST)<sup class="star">*</sup></label>
        <input  type="text" className="form-control" id="purchasePriceEmptyToFullCylinderExchangeIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="pp_exc_emptofull" onChange={handleInputs} />
        <div className="errorMsg">{errors.pp_exc_emptofull}</div>
      </div>
      <div className="col">
        <label for="purchasePriceEmptyToFullCylinderExchangeExcGst" className="form-label">Purchase Price/Empty to Full Cylinder Exchange (exc GST)</label>
        <input value={inputValues.exc_emptofull}  disabled type="text" className="form-control" id="purchasePriceEmptyToFullCylinderExchangeExcGst" />
      </div>
      <div className="col">
        <label for="purchasePriceEmptyToFullCylinderExchangeGst" className="form-label">Purchase Price/Empty to Full Cylinder Exchange (GST)</label>
        <input value={inputValues.pp_empty_full}  disabled type="text" className="form-control" id="purchasePriceEmptyToFullCylinderExchangeGst" />
      </div>
      
    </div>
    <div className="row mb-3">
      <div className="col">
        <label for="sellingPriceEmptyToFullCylinderExchangeIncGst" className="form-label">Selling Price/Empty to Full Cylinder Exchange (inc GST)<sup class="star">*</sup></label>
        <input  type="text" className="form-control"  id="sellingPriceEmptyToFullCylinderExchangeIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="selling_emptofull" onChange={handleInputs} />
        <div className="errorMsg">{errors.selling_emptofull}</div>
      </div>
      <div className="col">
        <label for="returnGstPerEmptyToFullCylinderExchange" className="form-label">Return GST per Empty to Full Cylinder Exchange</label>
        <input value={inputValues.sellingprice_emptofull}  disabled type="text" className="form-control" id="returnGstPerEmptyToFullCylinderExchange" />
      </div>
      <div className="col">
        <label for="marginEmptyToFullCylinderExchange" className="form-label">Margin/Empty to Full Cylinder Exchange</label>
        <input  value={inputValues.marginemptofull} disabled type="text" className="form-control" id="marginEmptyToFullCylinderExchange" />
      </div>
{/*bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb  */}
      <div className="row mb-3">
      <div className="col">
        <label for="purchasePriceEmptyCylinderIncGst"  className="form-label">Purchase Price/Empty Cylinder (inc GST)<sup class="star">*</sup></label>
        <input  name="empty_cylinder_inc" disabled type="text" value={inputValues.empty_cylinder_inc}  className="form-control" onKeyPress={(event) => preventToFour(event, event.target.value)} id="purchasePriceEmptyCylinderIncGst" onChange={handleInputs} />
        <div className="errorMsg">{errors.empty_cylinder_inc}</div>
      </div>
      <div className="col">
        <label for="purchasePriceEmptyCylinderExcGst" className="form-label">Purchase Price/Empty Cylinder (exc GST)</label>
        <input  value={inputValues.emp_cyl_exc_gst}   disabled type="text" name="emp_cyl_exc" className="form-control" id="purchasePriceEmptyCylinderExcGst" />
      </div>
      <div className="col">
        <label for="purchasePriceEmptyCylinderGst" className="form-label">Purchase Price/Empty Cylinder (GST)</label>
        <input value={inputValues.emp_pp_gst} name="pp_exc_emp_gst" disabled type="text" className="form-control" id="purchasePriceEmptyCylinderGst" />
      </div>
    </div>
    <div  hiddenclassName="row mb-3">
      
      <div hidden className="col">
        <label for="sellingPriceEmptyCylinderIncGst" hidden className="form-label">selling Price/Empty Cylinder (inc GST)<sup class="star">*</sup></label>
        <input  type="text" name="selling_price_empty" disabled value={inputValues.selling_price_empty}  className="form-control" onKeyPress={(event) => preventToFour(event, event.target.value)} id="sellingPriceEmptyCylinderIncGst" onChange={handleInputs} />
        <div className="errorMsg">{errors.selling_price_empty}</div>
      </div>
      <div hidden className="col">
        <label for="returnGstPerEmptyCylinder" hidden className="form-label">Return GST per Empty Cylinder</label>
        <input  value={inputValues.emp_return_gst} disabled type="text" className="form-control" id="returnGstPerEmptyCylinder" />
      </div>
      <div hidden className="col">
        <label for="marginEmptyCylinder" hidden className="form-label">Margin/Empty Cylinder</label>
        <input value={inputValues.marginemptycyl}  disabled type="text" className="form-control" id="marginEmptyCylinder" />
      </div>
    </div>
    <div className="row mb-3">
      
      <div hidden className="col-4">
        <label for="stockValueEmptyCylinder" hidden className="form-label">Stock Value Empty Cylinder</label>
        <input value={inputValues.total_emp_qty} disabled type="text" className="form-control" id="stockValueEmptyCylinder" />
      </div>
                 
    </div>
    
     
      </div>


                </form>

                </div>
}
<div class="modal-footer text-center">
                                     <button  type="submit" class="btn btn-primary w-auto" onClick={handleSubmit} >Save Gas Cylinder Details</button>
                                </div>

</div> 

</div>

</div>

</>): null

}



{/* edit */}

{
  editisShowing ? (
<>
<div className="modal-overlay" />
<div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
<div className="modal  modalxl">
<div className="modal-body">
<div className="modal-header">
									<h5 className="modal-title">Add Gas</h5>
									<button type="button" className="close" onClick={handleEditModalclose}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
                <div className="modal-body">
                <form>
                <div className="row mb-3">
      <div className="col">
        <label for="productName" className="form-label">Product Name<sup class="star">*</sup></label>
        <input value={inputValues.product_name} type="text" className="form-control" id="productName" name="product_name"  onChange={handleInputs} />
        <div className="errorMsg">{errors.product_name}</div>
      </div>

      <div className="col">
        <label for="productQuantity" className="form-label">Product Quantity in Kg<sup class="star">*</sup></label>
        <input value={inputValues.product_quantity}  type="text" className="form-control" id="productQuantity" onKeyPress={(event) => preventToFour(event, event.target.value)}  name="product_quantity" onChange={handleInputs}/>
        <div className="errorMsg">{errors.product_quantity}</div>
      </div>

      <div className="col">
        <label for="productQuantity" className="form-label">Product Full Name</label>
        <input value={inputValues.combined_name}  readOnly type="text" className="form-control" id="productQuantity" />
      </div>

    </div>

    {/* <div className="row mb-3">
    <div className="col-4">
      <label for="productFullName" className="form-label">Product Full Name</label>
      <input value="GAS 5 Kg" type="text" className="form-control" id="productFullName" />
 
    </div>
  
  </div> */}
    <div className="row mb-3">
      
    { stat_operation == "Yes" ? (<div className="col">
        <label for="numberOfFullCylinder" className="form-label">Number of Full Cylinder<sup class="star">*</sup></label>
        <input disabled type="text"   className="form-control" id="numberOfFullCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_full_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_full_cylinder}</div>
      </div>) : (<div className="col">
        <label for="numberOfFullCylinder" className="form-label">Number of Full Cylinder<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.no_of_full_cylinder} className="form-control" id="numberOfFullCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_full_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_full_cylinder}</div>
      </div>)
}
{ stat_operation == "Yes" ? (<div className="col">
        <label for="numberOfEmptyCylinder" className="form-label">Number of Empty Cylinder<sup class="star">*</sup></label>
        <input  disabled type="text" className="form-control" id="numberOfEmptyCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_empty_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_empty_cylinder}</div>
      </div>): (<div className="col">
        <label for="numberOfEmptyCylinder" className="form-label">Number of Empty Cylinder<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.no_of_empty_cylinder} className="form-control" id="numberOfEmptyCylinder" onKeyPress={(event) => preventToFour(event, event.target.value)} name="no_of_empty_cylinder" onChange={handleInputs} />
        <div className="errorMsg">{errors.no_of_empty_cylinder}</div>
      </div>)}
      <div className="col">
        <label for="gstPercentage" className="form-label">GST Percentage<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.gas_gst} className="form-control" id="gstPercentage"onKeyPress={(event) => preventToFour(event, event.target.value)}  name="gas_gst" onChange={handleInputs}  />
        <div className="errorMsg">{errors.gas_gst}</div>
      </div>
    </div>
    <div className="row mb-3">
      <div className="col">
        <label for="purchasePriceFullCylinderIncGst" className="form-label">Purchase Price/FullCylinder (inc GST)<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.full_cyl_pp_prise} className="form-control" id="purchasePriceFullCylinderIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="full_cyl_pp_prise" onChange={handleInputs}  />
        <div className="errorMsg">{errors.full_cyl_pp_prise}</div>
      </div>
      <div className="col">
        <label  for="purchasePriceFullCylinderExcGst" className="form-label">Purchase Price/Full Cylinder (exc GST)</label>
        <input  value={inputValues.pp_exc_gst_full} disabled type="text"  className="form-control" id="purchasePriceFullCylinderExcGst" />
      </div>
      <div className="col">
        <label  for="purchasePriceFullCylinderGst" className="form-label">Purchase Price/Full Cylinder(GST)</label>
        <input  value={inputValues.pp_gas_gst}  disabled type="text" className="form-control" id="purchasePriceFullCylinderGst" />
      </div>
    </div>
    <div className="row mb-3">
      <div className="col">
        <label for="sellingPriceFullCylinderIncGst" className="form-label">Selling Price/Full Cylinder (inc GST)<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.sellingprice_full_cyl} className="form-control" id="sellingPriceFullCylinderIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="sellingprice_full_cyl" onChange={handleInputs} />
        <div className="errorMsg">{errors.sellingprice_full_cyl}</div>
      </div>
      <div className="col">
        <label for="returnGstPerFullCylinder" className="form-label">Return GST per Full Cylinder</label>
        <input value={inputValues.returngstfull}  disabled type="text" className="form-control" id="returnGstPerFullCylinder" />
      </div>
      <div className="col">
        <label for="marginFullCylinder" className="form-label">Margin/Full Cylinder</label>
        <input value={inputValues.marginfullcyl} disabled type="text" className="form-control" id="marginFullCylinder" />
      </div>
    </div>
    <div className="row mb-3">
      <div className="col-4">
        <label for="stockValueFullCylinder" className="form-label">Stock Value Full Cylinder</label>
        <input value={inputValues.total_full_qty} disabled type="text" className="form-control" id="stockValueFullCylinder" />
      </div>
      <div className="col-4">
        <label for="fullnameFullCylinder" className="form-label">Name of Full Cylinder</label>
        <input value={inputValues.combinedfullcylindername}  disabled type="text" className="form-control" id="fullnameFullCylinder" />
      </div>
      <div className="col-4">
        <label for="stockValueFullCylinder" className="form-label">Name of Empty Cylinder</label>
        <input  value={inputValues.combinedemptycylindername}  disabled  type="text" className="form-control" id="stockValueFullCylinder" />
      </div>
    </div>
   
    <div className="row mb-3">
      <div className="col">
        <label for="purchasePriceEmptyToFullCylinderExchangeIncGst" className="form-label">Purchase Price/Empty to Full Cylinder Exchange (inc GST)<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.pp_exc_emptofull} className="form-control" id="purchasePriceEmptyToFullCylinderExchangeIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="pp_exc_emptofull" onChange={handleInputs} />
        <div className="errorMsg">{errors.pp_exc_emptofull}</div>
      </div>
      <div className="col">
        <label for="purchasePriceEmptyToFullCylinderExchangeExcGst" className="form-label">Purchase Price/Empty to Full Cylinder Exchange (exc GST)</label>
        <input value={inputValues.exc_emptofull}  disabled type="text" className="form-control" id="purchasePriceEmptyToFullCylinderExchangeExcGst" />
      </div>
      <div className="col">
        <label for="purchasePriceEmptyToFullCylinderExchangeGst" className="form-label">Purchase Price/Empty to Full Cylinder Exchange (GST)</label>
        <input value={inputValues.pp_empty_full}  disabled type="text" className="form-control" id="purchasePriceEmptyToFullCylinderExchangeGst" />
      </div>
      
    </div>
    <div className="row mb-3">
      <div className="col">
        <label for="sellingPriceEmptyToFullCylinderExchangeIncGst" className="form-label">Selling Price/Empty to Full Cylinder Exchange (inc GST)<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.selling_emptofull} className="form-control" id="sellingPriceEmptyToFullCylinderExchangeIncGst" onKeyPress={(event) => preventToFour(event, event.target.value)} name="selling_emptofull" onChange={handleInputs} />
        <div className="errorMsg">{errors.selling_emptofull}</div>
      </div>
      <div className="col">
        <label for="returnGstPerEmptyToFullCylinderExchange" className="form-label">Return GST per Empty to Full Cylinder Exchange</label>
        <input value={inputValues.sellingprice_emptofull}  disabled type="text" className="form-control" id="returnGstPerEmptyToFullCylinderExchange" />
      </div>
      <div className="col">
        <label for="marginEmptyToFullCylinderExchange" className="form-label">Margin/Empty to Full Cylinder Exchange</label>
        <input  value={inputValues.marginemptofull} disabled type="text" className="form-control" id="marginEmptyToFullCylinderExchange" />
      </div>
      <div className="row mb-3">
      <div className="col">
        <label for="purchasePriceEmptyCylinderIncGst" className="form-label">Purchase Price/Empty Cylinder (inc GST)<sup class="star">*</sup></label>
        <input  name="empty_cylinder_inc" type="text" disabled value={inputValues.empty_cylinder_inc} className="form-control" onKeyPress={(event) => preventToFour(event, event.target.value)} id="purchasePriceEmptyCylinderIncGst" onChange={handleInputs} />
        <div className="errorMsg">{errors.empty_cylinder_inc}</div>
      </div>
      <div className="col">
        <label for="purchasePriceEmptyCylinderExcGst" className="form-label">Purchase Price/Empty Cylinder (exc GST)</label>
        <input  value={inputValues.emp_cyl_exc_gst}  disabled type="text" name="emp_cyl_exc" className="form-control" id="purchasePriceEmptyCylinderExcGst" />
      </div>
      <div className="col">
        <label for="purchasePriceEmptyCylinderGst" className="form-label">Purchase Price/Empty Cylinder (GST)</label>
        <input value={inputValues.emp_pp_gst} name="pp_exc_emp_gst" disabled type="text" className="form-control" id="purchasePriceEmptyCylinderGst" />
      </div>
    </div>
    <div className="row mb-3">
      
      <div hidden className="col">
        <label for="sellingPriceEmptyCylinderIncGst" hidden className="form-label">Selling Price/Empty Cylinder (inc GST)<sup class="star">*</sup></label>
        <input  type="text" value={inputValues.selling_price_empty} disabled name="selling_price_empty" className="form-control" onKeyPress={(event) => preventToFour(event, event.target.value)} id="sellingPriceEmptyCylinderIncGst" onChange={handleInputs} />
        <div className="errorMsg">{errors.selling_price_empty}</div>
      </div>
      <div hidden className="col">
        <label for="returnGstPerEmptyCylinder" hidden className="form-label">Return GST per Empty Cylinder</label>
        <input  value={inputValues.emp_return_gst} disabled type="text" className="form-control" id="returnGstPerEmptyCylinder" />
      </div>
      <div hidden className="col">
        <label for="marginEmptyCylinder" hidden className="form-label">Margin/Empty Cylinder</label>
        <input value={inputValues.marginemptycyl}  disabled type="text" className="form-control" id="marginEmptyCylinder" />
      </div>
    </div>
    <div className="row mb-3">
      
      <div hidden className="col-4">
        <label for="stockValueEmptyCylinder" hidden className="form-label">Stock Value Empty Cylinder</label>
        <input value={inputValues.total_emp_qty} disabled type="text" className="form-control" id="stockValueEmptyCylinder" />
      </div>
    </div>
     
      <div class="modal-footer text-center">
                                     <button  type="submit" class="btn btn-primary w-auto" onClick={handleditsubmit} >Edit Gas Cylinder Details</button>
                                </div>
      </div>


                </form>

                </div>

</div> 

</div>

</div>

</>): null
}

 {isDeleteOpen ? (
                <>
                    <div className="modal-overlay" />
                    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
                        <div className="modal">
                            <div className="modal-header">
                                <h3 className="modal-title" id="staticBackdropLabel">Do you want to delete this product?</h3>
                                <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={handledeletegasclose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {/* <div className="row justify-content-center py-4">
                                <div class="modal-footer text-center">
                                <button type="submit" class="btn btn-primary w-auto" onClick={delete_gas}>Yes</button></div>
                           
                                
                                <button type="submit" class="btn btn-danger w-auto" onClick={handledeletegasclose}>No</button></div> */}
                                <div class="text-center py-3">
                                      <a class="btn btn-danger w-auto" onClick={handledeletegasclose}>Cancel</a>
                                      <a class="btn btn-primary w-auto" onClick={delete_gas}>Delete</a>
                                    </div> 
                         
                        </div>
                    </div>
                </>
            ) : null}
{/* endedit */}

    {/* ///////endmodal */}
    </>

  
  )

  
  
}



export default Productsconfig;
