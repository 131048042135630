import React, { useState } from "react";
import LoginHeaderOperational from "../../LoginHeaderOperational";
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import customer from "../../../../img/customer-review.png";
import ReactTooltip from "react-tooltip";
import ReactDOM from "react-dom";
import { API_URL } from "../../../../constant/API_Settings";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import FuelUnloadingConfig1Modal from "./FuelUnloadingModal1.component";
import ManagerLoginHeader from "../ManagerLoginHeader.component";
import FuelUnloadingEdit from "./FuelUnloadingEdit.component";
import DeleteModalConfig from "./DeleteModalConfig.component";
import { useLocation } from 'react-router-dom';
import {
  convertAmountToWords,
  preventNonNumericalInput,
  prevNonNumericalWithDec,
} from "../../../layouts/Operational_Sidebar_Progress.component";
import PaginationComponent from "../../../layouts/PaginationComponent";
import Loader from "../../Employee/Loader.component";
import moment from "moment";

const PaginationComp = (props) => <PaginationComponent {...props} />;
const FuelUnloadingModal = (props) =>
  ReactDOM.createPortal(
    <FuelUnloadingConfig1Modal {...props} />,
    document.body
  );
const FuelUnloadingEditModal = (props) =>
  ReactDOM.createPortal(<FuelUnloadingEdit {...props} />, document.body);
const DeleteModal = (props) =>
  ReactDOM.createPortal(<DeleteModalConfig {...props} />, document.body);
const FuelUnloadingConfig1 = (props) => {
  const location = useLocation();
  const [inputValues, setInputValues] = useState([]);
  const [Values, setValues] = useState([]);
  const [product_data, setProduct] = useState([]);
  const [density_data, setDensity] = useState([]);
  const [densityedit_data, setDensityEdit] = useState([]);
  const [density_after_data, setAfterDensity] = useState([]);
  const [after_density_data, setDensityAfter] = useState([]);
  const [input, setInput] = useState({});
  const [input1, setInput1] = useState({});
  const [fields, setFields] = useState([""]);
  const history = useHistory();
  const [list, setList] = useState([]);
  const [list_data, setListdata] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [dropdownData, setDropdownData] = useState([]);
  const [containerData, setContainerData] = useState([]);
  const [dropdown, setDropdown] = useState([]);
  const [drop, setDrop] = useState({});
  const [double, setDouble] = useState(true);
  const [comp, setComp] = useState([]);
  const [unloading, setUnloading] = useState("");
  const [data_list, setData] = useState([]);
  const [decant_status, setStatus] = useState([]);
  const [unload_product, setUnloadProduct] = useState([]);
  const [errors, setErrors] = useState([]);
  const [productid, setProductid] = useState([])
  const [success, setSuccess] = useState([]);
  const [fuel_config, SetFuel] = useState([]);
  const [version, setVersion] = useState([]);
  const [fuel, setFuelData] = useState([]);
  const [startDate, setDate] = useState([]);
  const [c_Date, c_setDate] = useState([]);
  const [fuelData, setFuelID] = useState([]);
  const [decant, SetDecant] = useState([]);
  const [container_number, SetContainerNumber] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [approvalId, setApprovalId] = useState("");
  const [unloadId, setUnloadlId] = useState("");
  const [delete_id, deleteId] = useState([]);
  const [permission, setPermission] = useState([]);
  const [loading, setLoading] = useState(false);
  const[day_closing_date,setDayclosedate]=useState({date:location?.state||""}) 
  const [pumpDetails, setPumpDetails] = useState([
		{ pump_name: '', pump_quantity: '', rent: '' }
	  ]);

  // -------------------first form--------------------//
  const [close, setCloseModal] = useState(false);
  function hide() {
    if (localStorage.getItem("close") == "true") {
      swal({
        title: "Verify the entered data?",
        text: "Once closed, you will not be able to edit invoice details sections !",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          toggle(false);
        } else {
        }
      });
    } else {
      toggle(false);
    }
  }
  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggle() {
      if(c_Date ==1){
        swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false? "success":"warning")
    }else{
      localStorage.removeItem("close");
      if (isShowing) {
        setInputValues([]);
        setInput([]);
        setShowFuelTanker(false);
        setErrors([]);
        setSuccess({});
        setFields([""]);
        setDrop({});
        setContainerData([]);
        setDropdown([]);
        setUnloading("");
        ListFuelUnloading(pages.current_page);
        //    setDropdownData([])
      }
      if (!isShowing) {
        getOwnTankDetails();
        getFuel();
        setErrors([]);
        setDouble(true);

        console.log(inputValues);
      }

      setIsShowing(!isShowing);
    }
  }
    return {
      isShowing,
      toggle,
    };
  };

  const { isShowing, toggle } = UseModal();

  const UseEditModal = () => {
    const [isShow, setShow] = useState(false);

    function toggling() {
      getOwnTankDetails();
      if (isShow) {
        setInputValues([]);
        setInput([]);
        setShowFuelTanker(false);
        setErrors([]);
        setSuccess({});
        setUnloading("");
        setFields([""]);
        setDrop({});
        setContainerData([]);
        setDropdown([]);
        //    setDropdownData([])
      }
      if (!isShow) {
        setDouble(true);

        setErrors([]);
      }
      setShow(!isShow);
    }

    return {
      isShow,
      toggling,
    };
  };

  const { isShow, toggling } = UseEditModal();

  const [isOpen, setIsOpen] = useState(false);
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  // function toggleDeleteModal() {
  // 	setIsOpen(!isOpen);
  // }

  const [showFuelTanker, setShowFuelTanker] = useState(false);

  const [showFuelTanker1, setShowFuelTanker1] = useState(false);
  function Example1() {
    setShowFuelTanker(true);
  }

  //get tanker listinfg-----------------

  async function getOwnTankDetails() {
    const requestOptions = {
      method: "get",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    await fetch(API_URL + "/fuel-unload", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        var datas = [];

        responseData.own_tank_data.forEach((elem) => {
          datas.push([elem.id, elem.reg_number]);
        });

        setDropdownData(datas);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				console.log(responseData,"kok")
				c_setDate(responseData)

			})
			.catch(e => {
				console.log(e);

			});

	}
  async function handleCapacityView() {
    const requestOptions = {
      method: "post",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({ tank_capacity: input.total_capacity }),
    };
    await fetch(API_URL + "/other_tank_detail", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setInput({
          ...input,
          id: responseData.id,
          no_of_containers: responseData.no_of_containers,
          capacity_of_con1: responseData.capacity_of_con1,
          capacity_of_con2: responseData.capacity_of_con2,
          capacity_of_con3: responseData.capacity_of_con3,
          capacity_of_con4: responseData.capacity_of_con4,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }
  ///-------------------------.////

  async function getTotalCapacity(id) {
    const requestOptions = {
      method: "post",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({ id: id }),
    };
    await fetch(API_URL + "/unload-tank-data", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        var datas = [];
        var container = [];
        // responseData.forEach(elem => {

        // 	datas.push([elem.id,elem.total_capacity])
        // })

        container.push([responseData.id, responseData.no_of_containers]);

        setDropdown({ total_capacity: responseData.total_capacity });
        setContainerData(container);
        setDrop({
          id: responseData.id,
          container: responseData.no_of_containers,
          capacity_of_con1: responseData.capacity_of_con1,
          capacity_of_con2: responseData.capacity_of_con2,
          capacity_of_con3: responseData.capacity_of_con3,
          capacity_of_con4: responseData.capacity_of_con4,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // ---handlesubmit function---//
  const handleSubmit = (event) => {
    event.preventDefault();
    setDouble(false);
    sendTankerData();
  };
  const handleTankerSubmit = (event) => {
    event.preventDefault();
    setDouble(false);
    sendOtherTankerData();
  };

  const handleInputChange = (index, e) => {
		const { name, value } = e.target;
		const newPumpDetails = [...pumpDetails];
		newPumpDetails[index][name] = value;
		setPumpDetails(newPumpDetails);
	  };

    const handleAddFields = () => {
      setPumpDetails([
        ...pumpDetails,
        { pump_name: '', pump_quantity: '', rent: '' }
      ]);
      };
  
      const handleRemoveFields = (index) => {
      const newPumpDetails = pumpDetails.filter((_, i) => i !== index);
      setPumpDetails(newPumpDetails);
      };

  function sendOtherTankerData() {
    console.log(input);
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        reg_number: input.reg_number,
        invoice_no: input.invoice_no,
        invoice_date: document.getElementById("m_op_date").value,
        invoice_amount: input.invoice_amount,
        tanker_type: inputValues.tanker,
        op_date: document.getElementById("m_op_date").value,
        status: input.status,
        total_capacity: input.total_capacity,
        no_of_containers: input.no_of_containers,
        capacity_of_con1: input.capacity_of_con1,
        capacity_of_con2: input.capacity_of_con2,
        capacity_of_con3: input.capacity_of_con3,
        capacity_of_con4: input.capacity_of_con4,
        id: unloading,
        tanker_id: input.tanker_id,
      }),
    };
    fetch(API_URL + "/fuel-unload", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        let errors = [];
        if (responseData.status == false) {
          errors["number"] = responseData.response.reg_number;
          errors["invoice_number"] = responseData.response.invoice_no;
          errors["date"] = responseData.response.invoice_date;
          errors["amount"] = responseData.response.invoice_amount;
          errors["total_capacity"] = responseData.response.total_capacity;
          errors["no_of_containers"] = responseData.response.no_of_containers;
          errors["capacity_of_con1"] = responseData.response.capacity_of_con1;
          errors["capacity_of_con2"] = responseData.response.capacity_of_con2;
          errors["capacity_of_con3"] = responseData.response.capacity_of_con3;
          errors["capacity_of_con4"] = responseData.response.capacity_of_con4;
          // errors["op_date"] = responseData.response.op_date
          setDouble(true);
        }
        if (responseData.status == true) {
          setUnloading(responseData.unloading_id);
          setInput({
            ...input,
            tanker_id: responseData.tanker_id,
          });

          setShowFuelTanker(true);
        }
        if (responseData.status === 3) {
          swal(
            "",
            responseData.message,
            responseData.status ? "warning" : "success"
          );
        }

        setErrors(errors);
        return responseData;
      })
      .catch((e) => {
        // alert("Something Went wrong", e.responseData)
        setDouble(true);
      });
  }

  function sendTankerData() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        reg_number: inputValues.reg_number,
        invoice_no: inputValues.invoice_no,
        invoice_date: document.getElementById("m_op_date").value,
        invoice_amount: inputValues.invoice_amount,
        tanker_type: inputValues.tanker,
        op_date: document.getElementById("m_op_date").value,
        // tanker:inputValues.tanker_type,
        tanker_id: drop.id,
        id: unloading,
      }),
    };
    fetch(API_URL + "/fuel-unload", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        let errors = {};
        if (responseData.status == false) {
          errors["reg_number"] = responseData.response.reg_number;
          errors["invoice_no"] = responseData.response.invoice_no;
          errors["invoice_date"] = responseData.response.invoice_date;
          errors["invoice_amount"] = responseData.response.invoice_amount;
          // errors["op_date"] = responseData.response.op_date
          setDouble(true);
        }
        if (responseData.status == true) {
          setUnloading(responseData.unloading_id);
          setShowFuelTanker(true);
        }
        if (responseData.status === 3) {
          swal(" ", responseData.message, "warning");
        }

        setErrors(errors);
        return responseData;
      })
      .catch((e) => {
        // alert("Something Went wrong", e.responseData)
        setDouble(true);
      });
  }

  const handleInput = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };
  const handleDate = (e) => {
    setInputValues({
      ...inputValues,
      op_date: e.target.value,
    });
  };
  const handleInput1 = (e) => {
    var id = e.target.value;
    getTotalCapacity(id);
    // getTotalno_of_containers(id)
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
      
    });
  };
  {console.log(inputValues,"777")}
  const handleSelect = (e) => {
    console.log(e.target.value);
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelect1 = (e) => {
    convertAmountToWords(e, e.target.value)
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleSelect2 = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleIn = (e) => {
    setInput({
      ...input,

      [e.target.name]: e.target.value,
    });
  };

  const handle = (e) => {
    var id = e.target.value;

    setInputValues({
      ...inputValues,

      no_of_containers: e.target.value,
    });
  };
  ///-------------------Listing fuel unloading -----------///

  function ListFuelUnloading(page) {
    setLoading(true);

    const requestOptions = {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    fetch(
      `${API_URL}/fuel-unload?&search_keyword=${search}&page=${page}&day_close_date=${day_closing_date?.date}`,
      requestOptions
    )
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == 3) {
          setPermission({
            ...permission,
            status: responseData.status,
            message: responseData.message,
          });
        }
        setVersion(responseData.full_version);

        // responseData.data.fuel_config.forEach(elem=>{
        // 	list.push({
        // 	 unloading_id:elem.unloading_id,
        // 	 invoice_no:elem.invoice_no,
        // 	 invoice_date:elem.invoice_date,
        // 	  invoice_amount:elem.invoice_amount
        // 		})})
        // 		console.log(list)
        setList(responseData.invoice_details.data);
        setListdata(responseData.own_tank_data);
        setpages({
          ...pages,
          current_page: responseData.invoice_details.current_page,
          last_page: responseData.invoice_details.last_page,
          per_pages: responseData.invoice_details.per_page,
          total_pages: responseData.invoice_details.total,
        });

        setLoading(false);

        return responseData;
      })
      .catch((e) => { });
  }

  const handleInputs = (e) => {
    convertAmountToWords(e, e.target.value)
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  //product name dropdown api integ

  //------------------end-----------------//

  function handleChange(e) {
    var value;

    if (e.target.value == "own_tanker") {
      value = 1;
    } else {
      value = 2;
    }
    setInputValues({
      ...inputValues,
      [e.target.name]: value,
    });
  }
  function handleType(e) {
    var value;

    if (e.target.value == "own_tanker") {
      value = 1;
    } else {
      value = 2;
    }
    setInputValues({
      ...inputValues,
      [e.target.name]: value,
    });
  }

  const handleCha = (e) => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });
  };

  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    check_url();
    is_day_closed();
  }, []);
  function check_url() {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pathurl: window.location.pathname,
      }),
    };
    fetch(`${API_URL}/check_url`, requestOptions)
      .then((response) => {
        return response.json();
      })

      .then((responseData) => {
        if (responseData.allowurl == 1) {
          history.push(responseData.url);
          ListFuelUnloading(1);
        } else {
          history.goBack();
        }

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const [pages, setpages] = useState({
    current_page: 1,
    last_page: 0,
    total_pages: 0,
    pagination_item: [],
    total_pages: 0,
  });
  const UseDeleteModal = () => {
    const [isClose, setClose] = useState(false);

    function toggleDeleteModal() {
      setClose(!isClose);
    }

    return {
      isClose,
      toggleDeleteModal,
    };
  };
  const { isClose, toggleDeleteModal } = UseDeleteModal();
  const handlePagination = (number) => {
    setpages({
      ...pages,
      current_page: number, //---pagination---//
    });

    ListFuelUnloading(number);
  };

  function editFuelUnloading(id) {
    if(c_Date ==1){
      swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false? "success":"warning")
  }else{
    const requestOptions = {
      method: "get",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    };
    fetch(API_URL + "/fuel-unload/" + id + "/edit", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData.productId)
        setProductid(responseData.productId)
        if (responseData.Tanker.tanker_type == 1) {
          setDrop({
            id: responseData.Tanker.id,
            container: responseData.Tanker.no_of_containers,
            capacity_of_con1: responseData.Tanker.capacity_of_con1,
            capacity_of_con2: responseData.Tanker.capacity_of_con2,
            capacity_of_con3: responseData.Tanker.capacity_of_con3,
            capacity_of_con4: responseData.Tanker.capacity_of_con4,
          });
          var container = [];

          container.push([
            responseData.Tanker.id,
            responseData.Tanker.no_of_containers,
          ]);
          setContainerData(container);

          setDropdown({ total_capacity: responseData.Tanker.total_capacity });

          setInputValues({
            ...inputValues,
            tanker: responseData.Tanker.tanker_type,
            reg_number: responseData.unload.tanker_id,
            invoice_no: responseData.unload.invoice_no,
            invoice_amount: responseData.unload.invoice_amount,
            invoice_date: responseData.unload.invoice_date,
            id: responseData.unload.id ? responseData.unload.id : "",
            op_date: responseData.unload.op_date,
            status: responseData.unload.status,
          });
        }
        if (responseData.Tanker.tanker_type == 2) {
          setInput({
            ...input,
            no_of_containers: responseData.Tanker.no_of_containers,
            total_capacity: responseData.Tanker.total_capacity,
            capacity_of_con1: responseData.Tanker.capacity_of_con1,
            capacity_of_con2: responseData.Tanker.capacity_of_con2,
            capacity_of_con3: responseData.Tanker.capacity_of_con3,
            capacity_of_con4: responseData.Tanker.capacity_of_con4,
            status: responseData.unload.status,
            tanker_id: responseData.Tanker.id,
            reg_number: responseData.Tanker.reg_number,
            invoice_no: responseData.unload.invoice_no,
            invoice_amount: responseData.unload.invoice_amount,
            invoice_date: responseData.unload.invoice_date,
            id: responseData.unload.id ? responseData.unload.id : "",
          });
          setInputValues({
            ...inputValues,
            tanker: responseData.Tanker.tanker_type,
            op_date: responseData.unload.op_date,
            unloading_id: responseData.unload_product.unloading_id,
          });
        }
        setValues({
          ...Values,
          total: responseData.unload.total_amount,
          net_amount: responseData.unload.net_amount,
          round_off: responseData.unload.round_off,
          own_rent: responseData.unload.own_rent,
          id: responseData.unload.id ? responseData.unload.id : "",
        });

        let product = [];
        responseData.unload_product.map((elem, index) => {
          product.push({
            invoice_quantity: elem.quantity,
            invoice_amount: elem.amount,
            product_id: elem.product_id,
            fuel_name: elem.fuel_product.fuel.fuel_name,
            fuel_id: elem.fuel_product.fuel_id,
            id: elem.id,
            current_price: elem.price_per_ltr,
            previous_price: elem.previous_price,
          });
        });

        let otherpump = [];
        responseData.other_rent.map((elem1, index) => {
        otherpump.push({
          id:elem1.id,
          rent:elem1.other_rent,
          pump_name:elem1.pump_name,
          pump_quantity:elem1.qty,
        });
       console.log(otherpump,"otherpump")
        });
        setPumpDetails(otherpump)
        setProduct(product);
        setUnloading(responseData.unload.id);

        let unloadproduct = [];
        responseData.unload_product.map((elem, index) => {
          unloadproduct.push({
            invoice_quantity: elem.quantity,
            invoice_amount: elem.amount,
            current_price: elem.price_per_ltr,
            previous_price: elem.previous_price,
          });
        });

        setUnloadProduct(unloadproduct);
        let fuel = [];
        responseData.unload_product.map((elem, index) => {
          fuel.push({
            product_id: elem.product_id,
            fuel_name: elem.fuel_product.fuel.fuel_name,
            fuel_id: elem.fuel_product.fuel_id,
            id: elem.id,
          });
        });
        setFuelData(fuel);
        let fuelid = [];
        responseData.unload_product.map((elem, index) => {
          fuelid.push({
            product_id: elem.product_id,
          });
        });
        setFuelID(fuelid);

        let density = [];
        responseData.unload_product.map((elem, index) => {
          density.push({
            product_id: elem.product_id,
            fuel_name: elem.fuel_product.fuel.fuel_name,
            fuel_id: elem.fuel_product.fuel_id,
            hmv_before_unload: elem.hmv_before_unload,
            temp_before_unload: elem.temp_before_unload,
            density_before_unload: elem.density_before_unload,
            invoice_density: elem.invoice_density,
            density_difference: elem.density_difference,
            unloading_id: elem.unloading_id,
            id: elem.id,
            unloadingProductId: elem.unloadingProductId,
            // isReadonly: false,
            // isRequired: false,
          });
        });

        setDensity(density);
        setDensityEdit(density);
        let density_after = [];
        responseData.unload_product.map((elem, index) => {
          density_after.push({
            product_id: elem.product_id,
            fuel_name: elem.fuel_product.fuel.fuel_name,
            fuel_id: elem.fuel_product.fuel_id,
            unloading_id: elem.unloading_id,
            id: elem.id,
            tankData: elem.tankData,
            afterDecant: elem.afterDecant,
            hmv_after_unload: elem.hmv_after_unload,
            temp_after_unload: elem.temp_after_unload,
            density_after_unload: elem.density_after_unload,
            unload_product_id: elem.unloadingProductId,
            // isReadonly: false,
            // isRequired: false,
          });
        });

        setAfterDensity(density_after);
        setDensityAfter(density_after);
        setStatus(responseData.isDecant);
        if (responseData.isDecant == true) {
          let decanting = [];
          responseData?.decant?.map((elem, index) => {
            decanting.push({
              calculated_quantity: elem.calculated_quantity,
              fuel_name: elem.fuel_product.fuel.fuel_name,
              fuel_id: elem.fuel_product.fuel_id,
              product_id: elem.product_id,
              unloading_id: elem.unloading_id,
              id: elem.id,
              nozzleData: elem.nozzleData,
              tankData: elem.tankData,
              decanting_quantity: elem.decanting_quantity,
              decanting_quantity_lit: elem.decanting_quantity_lit,
              excess_short: elem.excess_short,
              excess_short_amount: elem.excess_short_amount,
              stock_difference: elem.stock_difference,
              stock_before: elem.stock_before,
              stock_after: elem.stock_after,
              reading_difference: elem.reading_difference,
              compartments: elem.compartments,
              isReadonly: false,
              isRequired: false,
            });
            console.log(decanting);
          });
          SetDecant(decanting);
        } else {
          let decanting = [];
          responseData?.decant?.map((fuel_data, index) => {
            decanting.push({
              fuel_name: fuel_data.fuel_name,
              id: fuel_data.fuel_id,
              tankName: fuel_data.tankName,
              noOfComponent: fuel_data.noOfComponent,
              // unloadingProductId: fuel_data.unloadingProductId,
              nozzleData: fuel_data.nozzleData,
              isReadonly: true,
              isRequired: false,
            });
            console.log(decanting);
          });
          SetDecant(decanting);
        }
        let unloadid = [];
        responseData.decant.map((elem, index) => {
          unloadid.push({
            unloading_product_id: elem.unloading_product_id,
          });
        });
        setUnloadlId(unloadid);
        SetContainerNumber(responseData.Tanker.no_of_containers);
        toggling();

        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }
}
  // --------------------second form Edit---------------------//
  function ProducttoggleReadOnly(index, e) {
    let fuelConfigsData = [...product_data];

    let fuelConfigData = fuelConfigsData[index];
    fuelConfigData.isReadonly = !fuelConfigData.isReadonly;
    fuelConfigData.isRequired = !fuelConfigData.isRequired;
    fuelConfigsData[index] = fuelConfigData;
    fuelConfigsData[index][e.target.name] = e.target.value;
    setFuelData(fuelConfigsData);
    setFuelID(fuelConfigData);
    // fuel[e.target.name] = { ...inputValues[e.target.name], [index]: e.target.value };
    // console.log(inputValues)
    //   setInputValues(inputValues)
    //   setFuelData(inputValues)
  }

  const handleInvoiceSubmitEdit = (event, index) => {
    event.preventDefault();

    sendInvoiceDataEdit();
  };

  function getFuel() {
    axios({
      url: API_URL + "/unload-list",
      method: "post",
      data: {
        unloading_id: unloading,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((res) => {
        let fuelConfigData = [];
        res.data.fuel_type.map((fuel_data) => {
          fuelConfigData.push({
            fuel_name: fuel_data.fuel_name,
            id: fuel_data.fuel_id,
            tankData: fuel_data.tankName,
            noOfComponent: fuel_data.noOfComponent,
            unloadingProductId: fuel_data.unloadingProductId,
            nozzleData: fuel_data.nozzleData,
            no_of_tank: null,
            isReadonly: true,
            isRequired: false,
          });
        });

        setDate(res.data.start_date);
        setInput({ ...input, config_date: res.data.start_date });
        setInput1({ ...input1, end_date: res.data.end_date });
        SetFuel(fuelConfigData);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function getDecant() {
    setDensityAfter([])
    setAfterDensity([])
    axios({
      url: API_URL + "/unload-list",
      method: "post",
      data: {
        unloading_id: unloading,
        product_id: productid,
        isAfterUnloading: true
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
    })
      .then((responseData) => {
        console.log(responseData);
        let density_after = [];
        responseData.data.fuel_type.map((elem, index) => {
          density_after.push({
            product_id: elem.fuel_id,
            fuel_name: elem.fuel_name,
            unloading_id: responseData.data.unloading_id,
            tankData: elem.tankName,
            afterDecant: elem.tankName,
            unloading_product_id: elem.unloadingProductId
            // hmv_after_unload: elem.hmv_after_unload,
            // temp_after_unload: elem.temp_after_unload,
            // density_after_unload: elem.density_after_unload,
          });
        });
        // setDensityAfter(density_after)

        setAfterDensity(density_after);
        setDensityAfter(density_after);
        console.log(density_after)
        history.push("/op-fuelunloading");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [oentnker, setOentnker] = useState({
		own_tanker_rent: ''
	});

  const handleInputss = (e) => {
		const { name, value } = e.target;
		setOentnker((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

  const handleTotal = (idx, e) => {
    let product_list = [];
    let product = [];
    let product_id = [];
    product_list = [...unload_product];
    console.log(product_list, "jg");
    if (product_list[idx]) {
      var currentValue = 0;
      for (var i = 0; i < product_list.length; i++) {
        const multiple = document.getElementById("amount" + i).value;

        currentValue += Number(multiple);
        const quantity = document.getElementById("quantity" + i).value;
        var litre_quantity =
          document.getElementById("quantity" + i).value * 1000;
        var current_price = litre_quantity
          ? document.getElementById("amount" + i).value / litre_quantity
          : 0;
        data_list[idx] = current_price.toFixed(2);
        setData(data_list);
        console.log(product_list[i], "ghg");
        product_list[i].current_price = current_price.toFixed(2);
        product_list[i].invoice_quantity = quantity;
        product_list[i].invoice_amount = multiple;
        setUnloadProduct(product_list);
        product.push({
          invoice_quantity: quantity,
          invoice_amount: multiple,
        });
      }
    }

    const nt_amnt = document.getElementById("total");
    nt_amnt.value = currentValue.toFixed(2);
    var current = currentValue.toFixed(2);
    const rounded = Math.round(current);
    if (current % 1 != 0) {
      const [whole, decimal] = String(current).split(".");

      var dc;
      if (rounded > current) {
        var d = rounded - current;
        console.log(d);
        var dec = d.toFixed(2);

        dc = "+" + dec;
      } else {
        var d = current - rounded;
        console.log(d);
        var dec = d.toFixed(2);

        dc = "-" + dec;
      }
    } else {
      dc = 0;
    }

    const r = document.getElementById("round_off");
    r.value = dc;
    const a = document.getElementById("net_amount");
    a.value = rounded;

    console.log(rounded);
    setValues({
      ...Values,
      total: current,
      round_off: dc,
      net_amount: rounded,
      product_id: product_id,
    });
  };

  function sendInvoiceDataEdit() {
    const product = unload_product;
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        product,

        net_amount: Values.net_amount,
        unloading_id: Values.id,
        round_off: Values.round_off,

        total: Values.total,
        product_id: fuelData,
        id: Values.id,
      }),
    };
    fetch(API_URL + "/unload-amount", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // setSuccess({
        // 	...success,
        // 	disabled: responseData.status === true ? true : false,
        // })

        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );

          setErrors([""]);
        }
        let errors = [];
        if (responseData.status == false) {
          errors["total"] = responseData.response.total;
          errors["round_off"] = responseData.response.round_off;
          errors["net_amount"] = responseData.response.net_amount;
        }
        if (responseData.status == 3) {
          swal(
            "",
            responseData.message,
            responseData.status ? "warning" : "success"
          );
        }

        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
  }
  // -------------------------- 3rd Form  Edit-----------------------------//

  const handleDensity = (i, e, prop) => {
    const hmv = document.getElementById("hmv_before" + i).value;
    const temp = document.getElementById("temp_before" + i).value;

    getDensity();
    async function getDensity() {
      const requestOptions = {
        method: "post",

        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          Accept: "application/json",
        },
        body: JSON.stringify({ hmv: hmv, temp: temp }),
      };
      await fetch(API_URL + "/observed-data", requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          if (responseData.status === true) {
            var op = responseData.observed_density;

            var s = document.getElementById("density_before" + i);
            s.value = op;
            var d = document.getElementById("density" + i);

            var difference = d.value - op;

            var diff = document.getElementById("density_diff" + i);
            diff.value = difference.toFixed(2);
          }
          let errors = [];
          if (responseData.status === false) {
            // errors["hmv_before_unload"] = responseData.message
            swal(
              "",
              responseData.message,
              responseData.status ? "success" : "warning"
            );
          }
          setErrors(errors);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    const update_data = [...densityedit_data];

    update_data[i][prop] = e.target.value;
    setDensity(update_data);
  };
  function toggleReadOnlyDensity(index, e, prop) {
    let fuelConfigsData = density_data;
    console.log(fuelConfigsData);
    if (e.target.checked == false) {
      fuelConfigsData[index].isReadonly = true
      fuelConfigsData[index].hmv_before_unload = ""
      fuelConfigsData[index].invoice_density = ""
      fuelConfigsData[index].density_before_unload = ""
      fuelConfigsData[index].temp_before_unload = ""
      if (document.getElementById("hmv_before" + index).value != "") {
        document.getElementById("hmv_before" + index).value = "";
      }
      if (document.getElementById("temp_before" + index).value != "") {
        document.getElementById("temp_before" + index).value = "";
      }
      if (document.getElementById("density_before" + index).value != "") {
        document.getElementById("density_before" + index).value = "";
      }
      if (document.getElementById("density" + index).value != "") {
        if (version != "No") {
          document.getElementById("density" + index).value = "";
          document.getElementById("density_diff" + index).value = "";
        }
      }
    }
    else {
      fuelConfigsData[index].isReadonly = false
    }



    setDensity(fuelConfigsData);
    history.push("/op-fuelunloading");
    // SetFuelType({ ...fuel_configs, fuelConfigsData });
  }
  const handleDensitybefore = (i, e, prop) => {
    const update_data = [...densityedit_data];

    update_data[i][prop] = e.target.value;
    setDensity(update_data);
    var op = document.getElementById("density_before" + i).value;
    var d = document.getElementById("density" + i).value;
    var difference = d - op;
    var fixed = difference.toFixed(2);
    var diff = document.getElementById("density_diff" + i);
    diff.value = fixed;
  };

  const handleDensitySubmitEdit = (e) => {
    sendDensityDataEdit();
  };
  function sendDensityDataEdit() {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        density_data,
      }),
    };
    fetch(API_URL + "/edit-unload-invoice-density", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        });

        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          history.push("/op-fuelunloading");
          setErrors([""]);
          // setInputValues([])
          // toggle();
        }

        if (responseData.status === false) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }
        if (responseData.status === 3) {
          swal(" ", responseData.message, "warning");
        }

        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
  }

  // -------------------------4th form edit-----------------------//
  const handleEditDecant = (e, index, idx, prop) => {
    inputValues["decanting_quantity"] = document.getElementById(
      "decanting_quantity" + index + idx
    ).value;
    const data = [...decant];
    if (decant_status == true) {
      inputValues["tank_id"] = data[index].tankData[idx].id;
    } else {
      inputValues["tank_id"] = data[index].tankName[idx].id;
    }
    setInputValues(inputValues);
  };
  const handleDecant = (e, index, idx, prop) => {
    inputValues["decanting_quantity"] = document.getElementById(
      "decanting_quantity" + index + idx
    ).value;
    setInputValues(inputValues);
  };
  const handleEditDeQuantitySubmit1 = (event, index, tankIndex) => {
    event.preventDefault();

    sendQuantity(index, tankIndex);
  };
  function sendQuantity(index, tankIndex) {
    let decantVal = document.getElementById(
      "decanting_quantity" + index + tankIndex
    ).value;
    const data = [...decant];
    if (decant_status == true) {
      inputValues["tank_id"] = data[index].tankData[tankIndex].id;
    } else {
      inputValues["tank_id"] = data[index].tankName[tankIndex].id;
    }
    setInputValues(inputValues);

    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        unloading_id:
          decant_status == true
            ? decant[index].unloading_id
            : density_data[index].unloading_id,
        unloading_product_id:
          decant_status == true
            ? unloadId[index].unloading_product_id
            : density_data[index].unloadingProductId,
        tank_id: inputValues.tank_id,
        product_id:
          decant_status == true
            ? decant[index].product_id
            : density_data[index].product_id,
        decanting_quantity: document.getElementById(
          "decanting_quantity" + index + tankIndex
        ).value,
        method: "edit",
      }),
    };
    fetch(API_URL + "/load-decanting-quantity", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        // setSuccess({
        // 	...success,
        // 	disabled: responseData.status === true ? true : false,
        // })

        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          var stockAfter = document.getElementById(
            "stock_after" + index + tankIndex
          );
          var stock = responseData.stock_after;
          stockAfter.value = stock.toFixed(2);
          var stockBefore = document.getElementById(
            "stock_before" + index + tankIndex
          ).value;

          var stockDifference = responseData.stock_after - stockBefore;
          document.getElementById(
            "stock_difference" + index + tankIndex
          ).value = stockDifference.toFixed(2);
          document.getElementById(
            "reading_difference" + index + tankIndex
          ).value = 0;
          document.getElementById(
            "calculated_quantity" + index + tankIndex
          ).value =
            Number(
              document.getElementById("stock_difference" + index + tankIndex)
                .value
            ) +
            Number(
              document.getElementById("reading_difference" + index + tankIndex)
                .value
            );
          document.getElementById(
            "eq_decanting_quantity" + index + tankIndex
          ).value =
            document.getElementById("decanting_quantity" + index + tankIndex)
              .value * 1000;
          console.log(
            Number(
              document.getElementById("calculated_quantity" + index + tankIndex)
                .value
            )
          );
          console.log(
            Number(
              document.getElementById(
                "eq_decanting_quantity" + index + tankIndex
              ).value
            )
          );
          document.getElementById("excess_short" + index + tankIndex).value =
            Number(
              document.getElementById("calculated_quantity" + index + tankIndex)
                .value
            ) -
            Number(
              document.getElementById(
                "eq_decanting_quantity" + index + tankIndex
              ).value
            );

          setErrors([""]);
          // setInputValues([])
        }
        if (responseData.status == false) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }

        if (responseData.status === 3) {
          swal("", responseData.message, "warning");
        }

        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
  }
  const CompartmentReset = (index, idx) => {
    document.getElementById("resetSubmit" + index + idx).onclick = function () {
      var radio1 = document.querySelector("#comp1" + index + idx + ":checked");
      var radio2 = document.querySelector("#comp2" + index + idx + ":checked");
      var radio3 = document.querySelector("#comp3" + index + idx + ":checked");
      var radio4 = document.querySelector("#comp4" + index + idx + ":checked");
      if (radio1) {
        radio1.checked = false;
      }
      if (radio2) {
        radio2.checked = false;
      }
      if (radio3) {
        radio3.checked = false;
      }
      if (radio4) {
        radio4.checked = false;
      }
    };
  };
  function toggleReadOnlyDecant(index, tankIndex, e) {
    console.log(e.target.checked);
    let fuelConfigsData = decant;
    let fuelConfigData = fuelConfigsData[index];
    fuelConfigData.isReadonly = !fuelConfigData.isReadonly;
    if (e.target.checked == false) {
      fuelConfigData.decanting_quantity = "";
      // fuelConfigData.tankData[tankIndex].id = ""
      if (
        document.getElementById("decanting_quantity" + index + tankIndex)
          .value != ""
      ) {
        document.getElementById(
          "decanting_quantity" + index + tankIndex
        ).value = "";
      }
    }
    console.log(fuelConfigData);
    setInputValues({
      ...inputValues,
      product_id: fuelConfigData.id,

      tank_id:
        decant_status == false
          ? fuelConfigData.tankName[tankIndex].id
          : fuelConfigData.tankData[tankIndex].id,
      unloadingProductId: fuelConfigData.unloadingProductId,
    });
  }

  function handleEditRadio(e, index, idx) {
    //   setComp({...comp,
    // 	 [e.target.name]:e.target.value});
  }
  const handleEditReadings = (e, nozzleIdx, idx, index, prop) => {
    const nozzleRead = [...decant];

    nozzleRead[index].nozzleData[idx]["opening_reading_before"] =
      e.target.value;
    nozzleRead[index].nozzleData[idx]["closing_reading_after"] =
      document.getElementById("close_reading" + index + idx + nozzleIdx).value;
    const diff = document.getElementById("difference" + nozzleIdx);
    for (var i = 0; i < nozzleRead[index].nozzleData.length; i++) {
      var difference =
        nozzleRead[index].nozzleData[idx]["closing_reading_after"] -
        nozzleRead[index].nozzleData[idx]["opening_reading_before"];
      nozzleRead[index].nozzleData[idx]["difference"] = difference;
      // var eachDiff = document.getElementById("difference"+nozzleIdx)
      var s = document.getElementById("difference" + index + idx + nozzleIdx);
      s.value = difference;
    }

    let readDiff = 0;
    for (var i = 0; i < nozzleRead[index].nozzleData.length; i++) {
      readDiff =
        readDiff +
        Number(document.getElementById("difference" + index + idx + i).value);
    }
    document.getElementById("reading_difference" + index + idx).value =
      readDiff;
    document.getElementById("calculated_quantity" + index + idx).value =
      Number(document.getElementById("stock_difference" + index + idx).value) +
      Number(document.getElementById("reading_difference" + index + idx).value);
    document.getElementById("eq_decanting_quantity" + index + idx).value =
      document.getElementById("decanting_quantity" + index + idx).value;
    document.getElementById("excess_short" + index + idx).value =
      Number(
        document.getElementById("calculated_quantity" + index + idx).value
      ) -
      Number(
        document.getElementById("eq_decanting_quantity" + index + idx).value
      );

    SetDecant(nozzleRead);
  };

  function handleRadio(e, index, idx) {
    setComp({
      ...comp,
      [e.target.name]: e.target.value,
    });

    document.getElementById(e.target.id).disabled = false;
  }
  const handleEditReadings1 = (e, nozzleIdx, idx, index, prop) => {
    const nozzleRead = [...decant];

    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
      "opening_reading_before"
    ] = e.target.value;
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
      "closing_reading_after"
    ] = document.getElementById(
      "close_reading" + index + idx + nozzleIdx
    ).value;
    const diff = document.getElementById("difference" + nozzleIdx);
    for (var i = 0; i < nozzleRead[index].tankName.length; i++) {
      var difference =
        nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
        "closing_reading_after"
        ] -
        nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
        "opening_reading_before"
        ];
      nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["difference"] =
        difference;
      // var eachDiff = document.getElementById("difference"+nozzleIdx)
      var s = document.getElementById("difference" + index + idx + nozzleIdx);
      s.value = difference;
    }

    let readDiff = 0;
    for (var i = 0; i < nozzleRead[index].tankName.length; i++) {
      readDiff =
        readDiff +
        Number(document.getElementById("difference" + index + idx + i).value);
    }
    document.getElementById("reading_difference" + index + idx).value =
      readDiff;
    document.getElementById("calculated_quantity" + index + idx).value =
      Number(document.getElementById("stock_difference" + index + idx).value) +
      Number(document.getElementById("reading_difference" + index + idx).value);
    document.getElementById("eq_decanting_quantity" + index + idx).value =
      document.getElementById("decanting_quantity" + index + idx).value;
    document.getElementById("excess_short" + index + idx).value =
      Number(
        document.getElementById("calculated_quantity" + index + idx).value
      ) -
      Number(
        document.getElementById("eq_decanting_quantity" + index + idx).value
      );

    SetDecant(nozzleRead);
  };
  const handleEditCloseReadings = (e, nozzleIdx, idx, index, prop) => {
    const nozzleRead = [...decant];
    nozzleRead[index].nozzleData[idx]["closing_reading_after"] = e.target.value;
    nozzleRead[index].nozzleData[idx]["opening_reading_before"] =
      document.getElementById(
        "opening_reading" + index + idx + nozzleIdx
      ).value;
    const diff = document.getElementById("difference" + nozzleIdx);
    for (var i = 0; i < nozzleRead[index].nozzleData.length; i++) {
      var difference =
        nozzleRead[index].nozzleData[idx]["closing_reading_after"] -
        nozzleRead[index].nozzleData[idx]["opening_reading_before"];
      nozzleRead[index].nozzleData[idx]["difference"] = difference.toFixed(2);
      // var eachDiff = document.getElementById("difference"+nozzleIdx)
      var s = document.getElementById("difference" + index + idx + nozzleIdx);
      s.value = difference;
    }

    let readDiff = 0;
    for (var i = 0; i < nozzleRead[index].nozzleData.length; i++) {
      readDiff =
        readDiff +
        Number(document.getElementById("difference" + index + idx + i).value);
    }
    document.getElementById("reading_difference" + index + idx).value =
      readDiff.toFixed(2);
    document.getElementById("calculated_quantity" + index + idx).value =
      Number(document.getElementById("stock_difference" + index + idx).value) +
      Number(document.getElementById("reading_difference" + index + idx).value);
    document.getElementById("eq_decanting_quantity" + index + idx).value =
      document.getElementById("decanting_quantity" + index + idx).value;
    document.getElementById("excess_short" + index + idx).value =
      Number(
        document.getElementById("calculated_quantity" + index + idx).value
      ) -
      Number(
        document.getElementById("eq_decanting_quantity" + index + idx).value
      );

    SetDecant(nozzleRead);
  };
  const handleEditCloseReadings1 = (e, nozzleIdx, idx, index, prop) => {
    const nozzleRead = [...decant];
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
      "closing_reading_after"
    ] = e.target.value;
    nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
      "opening_reading_before"
    ] = document.getElementById(
      "opening_reading" + index + idx + nozzleIdx
    ).value;
    const diff = document.getElementById("difference" + nozzleIdx);
    for (var i = 0; i < nozzleRead[index].tankName.length; i++) {
      var difference =
        nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
        "closing_reading_after"
        ] -
        nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx][
        "opening_reading_before"
        ];
      nozzleRead[index].tankName[idx].tankNozzleData[nozzleIdx]["difference"] =
        difference.toFixed(2);
      // var eachDiff = document.getElementById("difference"+nozzleIdx)
      var s = document.getElementById("difference" + index + idx + nozzleIdx);
      s.value = difference;
    }

    let readDiff = 0;
    for (var i = 0; i < nozzleRead[index].tankName.length; i++) {
      readDiff =
        readDiff +
        Number(document.getElementById("difference" + index + idx + i).value);
    }
    document.getElementById("reading_difference" + index + idx).value =
      readDiff.toFixed(2);
    document.getElementById("calculated_quantity" + index + idx).value =
      Number(document.getElementById("stock_difference" + index + idx).value) +
      Number(document.getElementById("reading_difference" + index + idx).value);
    document.getElementById("eq_decanting_quantity" + index + idx).value =
      document.getElementById("decanting_quantity" + index + idx).value;
    document.getElementById("excess_short" + index + idx).value =
      Number(
        document.getElementById("calculated_quantity" + index + idx).value
      ) -
      Number(
        document.getElementById("eq_decanting_quantity" + index + idx).value
      );

    SetDecant(nozzleRead);
  };
  const EditDecant1 = (index, data) => {
    var tankNozzleData = [];
    var stockDiff = [];
    var readDiff = [];
    var calcQuantity = [];
    var eq_decanting_quantity = [];
    var excess_short = [];
    let lastIndex = decant.length - 1;
    tankNozzleData = decant[index];
    if (decant_status == true) {
      for (var i = 0; i < tankNozzleData.tankData.length; i++) {
        // console.log(document.getElementById("calculated_quantity"+index+i).value)
        stockDiff.push(
          Number(document.getElementById("stock_difference" + index + i).value)
        );
        readDiff.push(
          Number(
            document.getElementById("reading_difference" + index + i).value
          )
        );
        calcQuantity.push(
          Number(
            document.getElementById("calculated_quantity" + index + i).value
          )
        );
        eq_decanting_quantity.push(
          Number(
            document.getElementById("eq_decanting_quantity" + index + i).value
          )
        );
        excess_short.push(
          Number(document.getElementById("excess_short" + index + i).value)
        );
      }

      for (i = 0; i < tankNozzleData.tankData.length; i++) {
        var comp1 = document.getElementById("comp1" + index + i);
        var comp2 = document.getElementById("comp2" + index + i);
        var comp3 = document.getElementById("comp3" + index + i);
        var comp4 = document.getElementById("comp4" + index + i);

        if (comp1 && comp1.checked) {
          tankNozzleData.tankData[i].comp1 = Number(comp1.value);
        }

        if (comp2 && comp2.checked) {
          tankNozzleData.tankData[i].comp2 = Number(comp2.value);
        }
        if (comp3 && comp3.checked) {
          tankNozzleData.tankData[i].comp3 = Number(comp3.value);
        }
        if (comp4 && comp4.checked) {
          tankNozzleData.tankData[i].comp4 = Number(comp4.value);
        }
      }
    } else {
      for (var i = 0; i < tankNozzleData.tankName.length; i++) {
        // console.log(document.getElementById("calculated_quantity"+index+i).value)
        stockDiff.push(
          Number(document.getElementById("stock_difference" + index + i).value)
        );
        readDiff.push(
          Number(
            document.getElementById("reading_difference" + index + i).value
          )
        );
        calcQuantity.push(
          Number(
            document.getElementById("calculated_quantity" + index + i).value
          )
        );
        eq_decanting_quantity.push(
          Number(
            document.getElementById("eq_decanting_quantity" + index + i).value
          )
        );
        excess_short.push(
          Number(document.getElementById("excess_short" + index + i).value)
        );
      }

      for (i = 0; i < tankNozzleData.tankName.length; i++) {
        var comp1 = document.getElementById("comp1" + index + i);
        var comp2 = document.getElementById("comp2" + index + i);
        var comp3 = document.getElementById("comp3" + index + i);
        var comp4 = document.getElementById("comp4" + index + i);

        if (comp1 && comp1.checked) {
          tankNozzleData.tankName[i].comp1 = Number(comp1.value);
        }

        if (comp2 && comp2.checked) {
          tankNozzleData.tankName[i].comp2 = Number(comp2.value);
        }
        if (comp3 && comp3.checked) {
          tankNozzleData.tankName[i].comp3 = Number(comp3.value);
        }
        if (comp4 && comp4.checked) {
          tankNozzleData.tankName[i].comp4 = Number(comp4.value);
        }
      }
    }
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        fuelData: tankNozzleData,
        stock_difference: stockDiff,
        reading_difference: readDiff,
        calculated_quantity: calcQuantity,
        eq_decanting_quantity: eq_decanting_quantity,
        excess_short: excess_short,
        isDecant: decant_status,
        unloading_id:
          decant_status == true
            ? decant[index].unloading_id
            : density_data[index].unloading_id,
        unloading_product_id:
          decant_status == true
            ? unloadId[index].unloading_product_id
            : density_data[index].unloadingProductId,
      }),
    };
    fetch(API_URL + "/update-unload-decanting-density", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == true) {
          history.push("/op-fuelunloading");

          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          if (
            lastIndex == index &&
            density_after_data[0].afterDecant.length == 0
          ) {
            setShowFuelTanker1(true);

          }
        }
        if (responseData.status == false) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }
        if (responseData.status === 3) {
          swal("", responseData.message, "warning");
        }
        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
    setShowFuelTanker(true);
  };
  const EditDecant = (index, data) => {
    var tankNozzleData = [];
    var stockDiff = [];
    var readDiff = [];
    var calcQuantity = [];
    var eq_decanting_quantity = [];
    var excess_short = [];
    let lastIndex = decant.length - 1;
    tankNozzleData = decant[index];
    if (decant_status == true) {
      for (var i = 0; i < tankNozzleData.tankData.length; i++) {
        // console.log(document.getElementById("calculated_quantity"+index+i).value)
        stockDiff.push(
          Number(document.getElementById("stock_difference" + index + i).value)
        );
        readDiff.push(
          Number(
            document.getElementById("reading_difference" + index + i).value
          )
        );
        calcQuantity.push(
          Number(
            document.getElementById("calculated_quantity" + index + i).value
          )
        );
        eq_decanting_quantity.push(
          Number(
            document.getElementById("eq_decanting_quantity" + index + i).value
          )
        );
        excess_short.push(
          Number(document.getElementById("excess_short" + index + i).value)
        );
      }

      for (i = 0; i < tankNozzleData.tankData.length; i++) {
        var comp1 = document.getElementById("comp1" + index + i);
        var comp2 = document.getElementById("comp2" + index + i);
        var comp3 = document.getElementById("comp3" + index + i);
        var comp4 = document.getElementById("comp4" + index + i);

        if (comp1 && comp1.checked) {
          tankNozzleData.tankData[i].comp1 = Number(comp1.value);
        }

        if (comp2 && comp2.checked) {
          tankNozzleData.tankData[i].comp2 = Number(comp2.value);
        }
        if (comp3 && comp3.checked) {
          tankNozzleData.tankData[i].comp3 = Number(comp3.value);
        }
        if (comp4 && comp4.checked) {
          tankNozzleData.tankData[i].comp4 = Number(comp4.value);
        }
      }
    } else {
      for (var i = 0; i < tankNozzleData.tankName.length; i++) {
        // console.log(document.getElementById("calculated_quantity"+index+i).value)
        stockDiff.push(
          Number(document.getElementById("stock_difference" + index + i).value)
        );
        readDiff.push(
          Number(
            document.getElementById("reading_difference" + index + i).value
          )
        );
        calcQuantity.push(
          Number(
            document.getElementById("calculated_quantity" + index + i).value
          )
        );
        eq_decanting_quantity.push(
          Number(
            document.getElementById("eq_decanting_quantity" + index + i).value
          )
        );
        excess_short.push(
          Number(document.getElementById("excess_short" + index + i).value)
        );
      }

      for (i = 0; i < tankNozzleData.tankName.length; i++) {
        var comp1 = document.getElementById("comp1" + index + i);
        var comp2 = document.getElementById("comp2" + index + i);
        var comp3 = document.getElementById("comp3" + index + i);
        var comp4 = document.getElementById("comp4" + index + i);

        if (comp1 && comp1.checked) {
          tankNozzleData.tankName[i].comp1 = Number(comp1.value);
        }

        if (comp2 && comp2.checked) {
          tankNozzleData.tankName[i].comp2 = Number(comp2.value);
        }
        if (comp3 && comp3.checked) {
          tankNozzleData.tankName[i].comp3 = Number(comp3.value);
        }
        if (comp4 && comp4.checked) {
          tankNozzleData.tankName[i].comp4 = Number(comp4.value);
        }
      }
    }
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        fuelData: tankNozzleData,
        stock_difference: stockDiff,
        reading_difference: readDiff,
        calculated_quantity: calcQuantity,
        eq_decanting_quantity: eq_decanting_quantity,
        excess_short: excess_short,
        isDecant: decant_status,
        unloading_id:
          decant_status == true
            ? decant[index].unloading_id
            : density_data[index].unloading_id,
        unloading_product_id:
          decant_status == true
            ? unloadId[index].unloading_product_id
            : density_data[index].unloadingProductId,
      }),
    };
    fetch(API_URL + "/update-unload-decanting-density", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == true) {
          history.push("/op-fuelunloading");
          getDecant();
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          if (
            lastIndex == index &&
            density_after_data[0].afterDecant.length == 0
          ) {
            setShowFuelTanker1(true);

          }
        }
        if (responseData.status == false) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }
        if (responseData.status === 3) {
          swal("", responseData.message, "warning");
        }
        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
    setShowFuelTanker(true);
  };
  const saveDecant = (index, data) => {
    var tankNozzleData = [];
    var stockDiff = [];
    var readDiff = [];
    var calcQuantity = [];
    var eq_decanting_quantity = [];
    var excess_short = [];
    let lastIndex = decant.length - 1;
    tankNozzleData = decant[index];
    for (var i = 0; i < tankNozzleData.tankName.length; i++) {
      stockDiff.push(
        Number(document.getElementById("stock_difference" + index + i).value)
      );
      readDiff.push(
        Number(document.getElementById("reading_difference" + index + i).value)
      );
      calcQuantity.push(
        Number(document.getElementById("calculated_quantity" + index + i).value)
      );
      eq_decanting_quantity.push(
        Number(
          document.getElementById("eq_decanting_quantity" + index + i).value
        )
      );
      excess_short.push(
        Number(document.getElementById("excess_short" + index + i).value)
      );
    }

    for (i = 0; i < tankNozzleData.tankName.length; i++) {
      var comp1 = document.getElementById("comp1" + index + i);
      var comp2 = document.getElementById("comp2" + index + i);
      var comp3 = document.getElementById("comp3" + index + i);
      var comp4 = document.getElementById("comp4" + index + i);

      if (comp1 && comp1.checked) {
        tankNozzleData.tankName[i].comp1 = Number(comp1.value);
      } else {
        tankNozzleData.tankName[i].comp1 = "";
      }

      if (comp2 && comp2.checked) {
        tankNozzleData.tankName[i].comp2 = Number(comp2.value);
      } else {
        tankNozzleData.tankName[i].comp2 = "";
      }
      if (comp3 && comp3.checked) {
        tankNozzleData.tankName[i].comp3 = Number(comp3.value);
      } else {
        tankNozzleData.tankName[i].comp3 = "";
      }
      if (comp4 && comp4.checked) {
        tankNozzleData.tankName[i].comp4 = Number(comp4.value);
      } else {
        tankNozzleData.tankName[i].comp4 = "";
      }
    }
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        fuelData: tankNozzleData,
        unloading_id: inputList.unloading_id,
        // unloading_product_id:
        stock_difference: stockDiff,
        reading_difference: readDiff,
        calculated_quantity: calcQuantity,
        eq_decanting_quantity: eq_decanting_quantity,
        unloading_product_id: decant[index]?.unloadingProductId,
        excess_short: excess_short,
      }),
    };
    fetch(API_URL + "/update-unload-decanting-density", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          getFuel();
          if (lastIndex == index) setShowFuelTanker(true);
        }
        if (responseData.status == false) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }
        console.log(responseData);
        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
  };

  const saveDecantFuel = (index) => {

    console.log(inputValues)
    const data = [...decant];
    console.log(decant)
    var tank_id = [];
    if (decant_status) {
      data[index].tankData.map((obj, idx) => {
        tank_id.push(obj.id);
      });
    } else {
      data[index].tankName.map((obj, idx) => {
        tank_id.push(obj.id);
      });
    }

    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        unloading_id:
          decant_status == true
            ? decant[index].unloading_id
            : density_data[index].unloading_id,
        unloading_product_id:
          decant_status == true
            ? unloadId[index].unloading_product_id
            : density_data[index].unloadingProductId,
        tank_id: tank_id,
        product_id:
          decant_status == true
            ? decant[index].product_id
            : inputValues.product_id,
      }),
    };
    fetch(API_URL + "/update-unload-decanting-density", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }
        if (responseData.status == false) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
        }
        if (responseData.status === 3) {
          swal("", responseData.message, "warning");
        }
        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
  };

  function printPage() {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        unloading_id: Values.id,
      }),
    };
    fetch(API_URL + "/download_fuel-unload", requestOptions)
      .then((response) => {
        return response.blob();
      })
      .then((responseData) => {
        var blob = new Blob([responseData]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Fuel_unload.pdf";
        link.click();
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  // -------------------------5 th form edit----------------------//

  const handleAfterDensity = (index, idx, e, prop) => {
    const hmv = document.getElementById("hmv" + index + idx).value;
    const temp = document.getElementById("temp" + index + idx).value;
    let fuelData = [...after_density_data];
    console.log(fuelData)
    console.log(density_after_data)
    for (var i = 0; i < fuelData[index].afterDecant.length; i++) {
      if (e.target.name == "temp_after_unload") {
        fuelData[index].afterDecant[idx]["temp_after_unload"] = document.getElementById("temp" + index + idx).value;
      }
    }
    for (var i = 0; i < fuelData[index].afterDecant.length; i++) {
      fuelData[index].afterDecant[idx]["hmv_after_unload"] =
        document.getElementById("hmv" + index + idx).value;
    }
    fuelData["product_id"] = {
      ...fuelData["product_id"],
      [idx]: fuelData[idx]?.id,
    };
    setAfterDensity(fuelData)
    setDensityAfter(fuelData);


    getDensity();
    async function getDensity() {
      const requestOptions = {
        method: "post",

        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
          Accept: "application/json",
        },
        body: JSON.stringify({
          unloading_id: unloading,
          product_id: inputValues.product_id,
          hmv: hmv,
          temp: temp,
        }),
      };
      await fetch(API_URL + "/observed-data", requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((responseData) => {
          if (responseData.status === true) {
            var op = responseData.observed_density;
            var s = document.getElementById("observed_density" + index + idx);
            s.value = op;
          }
          let errors = [];
          if (responseData.status === false) {
            // errors["hmv_before_unload"] = responseData.message
            swal(
              "",
              responseData.message,
              responseData.status ? "success" : "warning"
            );
          }

          setErrors(errors);
        })
        .catch((e) => {
          console.log(e);
        });
    }


  };

  const handleAfterDensitySubmit = (e) => {
    sendData();
  };

  function sendData() {
    const requestOptions = {
      method: "post",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({ updateData: density_after_data }),
    };
    fetch(API_URL + "/update-decanting-density", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setSuccess({
          ...success,
          disabled: responseData.status === true ? true : false,
        });

        if (responseData.status == true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          history.push("/op-fuelunloading");
          setErrors([""]);
        }

        let errors = [];
        if (responseData.status == false) {
          errors["hmv_after_unload"] = responseData.response.hmv_after_unload;
          errors["tank_id"] = responseData.response.tank_id;
          errors["temp_after_unload"] = responseData.response.temp_after_unload;
          errors["product_id"] = responseData.response.product_id;
        }
        if (responseData.status === 3) {
          swal(
            "",
            responseData.message,
            responseData.status ? "warning" : "warning"
          );
        }
        setErrors(errors);
        return responseData;
      })
      .catch((e) => { });
  }

  function handleApproval(id, status) {
    if(c_Date ==1){
      swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false? "success":"warning")
  }else{
    setApprovalStatus(status);
    setApprovalId(id);
    toggleModal();
  }
}
const[loadings,setLoadings]=useState(false)
  function handleYes() {
    setLoadings(true)
    const requestOptions = {
      //---employee listing---//
      method: "post",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: approvalId,
        status: approvalStatus,
      }),
    };
    fetch(`${API_URL}/fuel-unload-approve`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        swal(
          "",
          responseData.message,
          responseData.status ? "success" : "warning"
        );
        history.push("/op-fuelunloading");
        toggleModal();
        ListFuelUnloading(pages.current_page);
        setLoadings(false)
        return responseData;
      })
      .catch((e) => {  setLoadings(false)});
  }

  function deleteUnload(id) {
    if(c_Date ==1){
      swal("","Your Current Operational Date has already been Day-Closed, Please change Operational Date",false? "success":"warning")
  }else{
    deleteId(id);

    toggleDeleteModal();
  }
  }
  function deleteUnloading() {
    const requestOptions = {
      method: "DELETE",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
        Accept: "application/json",
      },
      body: JSON.stringify(delete_id),
    };
    fetch(API_URL + "/fuel-unload/" + delete_id, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData.status === true) {
          swal(
            "",
            responseData.message,
            responseData.status ? "success" : "warning"
          );
          ListFuelUnloading(pages.current_page);
          toggleDeleteModal();
        }

        return responseData;
      })

      .catch((err) => { });
  }
console.log(inputValues.tanker,"bhhhhh")
  return (
    <>
      <div className="main-container page-wrap">
        <div className="d-md-flex">
          <Sidebar_Progress props={props} progress={0} operation={1} />
          <div className="content-area">
            <ManagerLoginHeader props={props} page_title="" />
            <div class="container pb-3 tab-view">
              <div class="row  mt-3 form-container">
                <div class="form-wrap ">
                  {permission.status == 3 ? (
                    <div className="form-wrap mt-5">
                      <div className="text-center">{permission.message}</div>
                    </div>
                  ) : (
                    <>
                      <div>
                        {loading ? <Loader /> : ""}
                        <div class="tab-content" id="myTabContent">
                          <div
                            class="tab-pane  fade show active"
                            id="packedoil"
                            role="tabpanel"
                            aria-labelledby="packedoil-tab"
                          >
                            <div class="">
                              <div class="row mb-3">
                                <div class="col-md">
                                  <div class="row">
                                    <div class="col-md col pl-0">
                                      <h3 class="table-title">
                                      FUEL PURCHASE AND UNLOADING
                                      </h3>{" "}
                                      <h4>

                                        {/* {localStorage.getItem("op_date")} */}

                                      </h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <a
                                    className="btn btn-primary px-3 mb-md-0 mb-2 "
                                    data-bs-target="#mla"
                                    onClick={toggle}
                                  >
                                    Add PURCHASE AND UNLOADING
                                  </a>
                                </div>
                                <div class="col-md-4">
                                  <div class="input-group mb-3 search">
                                    <span
                                      class="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <i
                                        class="fa fa-search"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search  "
                                      aria-describedby="basic-addon1"
                                      value={search.search_keyword}
                                      onChange={handleSearch}

                                    />
                                    <a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>

                                  </div>
                                </div>
                              </div>

                              <div class="table-widget">
                                <table class="table border">
                                  <thead>
                                    <tr>
                                      <th>Invoice Number</th>
                                      <th>Invoice Date</th>
                                      <th>Invoice Amount</th>
                                      <th>Status</th>

                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {list.map((obj) => (
                                      <>
                                        <tr>
                                          <td>{obj.invoice_no}</td>
                                          <td>
                                            {moment(obj.invoice_date).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </td>
                                          <td>{obj.invoice_amount}</td>
                                          <td>
                                            {obj.status === 3 ? (
                                              <a
                                                href="#"
                                                class="btn btn-success btn-xs"
                                              >
                                                Approved
                                              </a>
                                            ) : obj.status == 2 ? (
                                              <a
                                                href="#"
                                                class="btn btn-warning btn-xs"
                                              >
                                                Approval Pending
                                              </a>
                                            ) : obj.status == 4 ? (
                                              <a
                                                href="#"
                                                class="btn btn-success btn-xs"
                                              >
                                                Day Close
                                              </a>
                                            ) : obj.status == 1 ? (
                                              <a
                                                href="#"
                                                class="btn btn-warning btn-xs"
                                              >
                                                Pending
                                              </a>
                                            ) : (
                                              ""
                                            )}{" "}
                                          </td>

                                          <td class="py-2">
                                            <div className="d-flex icon-btn">
                                              <a
                                                href="#"
                                                class="text-dark"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Edit"
                                                onClick={() =>
                                                  editFuelUnloading(obj.id)
                                                }
                                              >
                                                <i
                                                  class="fa fa-pencil-square-o me-2"
                                                  aria-hidden="true"
                                                ></i>
                                              </a>
                                              {obj.status != 3 &&
                                                obj.status != 4 ? (
                                                <a
                                                  href="#"
                                                  class="text-dark"
                                                  data-bs-toggle="tooltip"
                                                  data-bs-placement="top"
                                                  title="Delete"
                                                  onClick={() =>
                                                    deleteUnload(obj.id)
                                                  }
                                                >
                                                  <i
                                                    class="fa fa-trash-o pe-2"
                                                    aria-hidden="true"
                                                  ></i>
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                              {obj.status != 3 &&
                                                obj.status != 4 ? (
                                                <a
                                                  onClick={() =>
                                                    handleApproval(
                                                      obj.id,
                                                      obj.status
                                                    )
                                                  }
                                                >
                                                  {obj.status === 3 ? (
                                                    <i
                                                      className="text-success fa fa-check me-2"
                                                      aria-hidden="true"
                                                      title="Reject"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      className="text-warning fa fa-check me-2"
                                                      aria-hidden="true"
                                                      title="Approval"
                                                    ></i>
                                                  )}
                                                </a>
                                              ) : obj.status == 3 ? (
                                                <a>
                                                  <i
                                                    className="text-success fa fa-check me-2"
                                                    aria-hidden="true"
                                                    title="Approved"
                                                  ></i>
                                                </a>
                                              ) : obj.status == 4 ? (
                                                <a>
                                                  <i
                                                    className="text-success fa fa-check me-2"
                                                    aria-hidden="true"
                                                    title="Day Closed"
                                                  ></i>
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                  </tbody>
                                </table>
                                <PaginationComp
                                  pages={pages}
                                  handlePagination={handlePagination}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {close == true && (
        <>
          <div className="modal-overlay" />
          <div className="modal-wrapper">
            <div className="modal modal-dialog">
              <div class="modal-title modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class=" modal-body  modal-body">
                <p>Please verify your data</p>
              </div>
              <div class="justify-content-end  modal-footer">
                <div class="d-flex">
                  <button type="button" class="btn btn-secondary me-2">
                    No
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    onClick={deleteUnloading}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isShowing ? (
        <FuelUnloadingModal
          isShowing={isShowing}
          preventNonNumericalInput={preventNonNumericalInput}
          prevNonNumericalWithDec={prevNonNumericalWithDec}
          hide={hide}
          input1={input1}
          Example1={Example1}
          handleCha={handleCha}
          handle={handle}
          handleIn={handleIn}
          handleDate={handleDate}
          startDate={startDate}
          handleTankerSubmit={handleTankerSubmit}
          handleSelect={handleSelect}
          handleSelect1={handleSelect1}
          handleSelect2={handleSelect2}
          Values={Values}
          double={double}
          version={version}
          setValues={setValues}
          product_data={product_data}
          setProduct={setProduct}
          fuel_config={fuel_config}
          SetFuel={SetFuel}
          unloading={unloading}
          handleInputs={handleInputs}
          handleInput1={handleInput1}
          handleChange={handleChange}
          handleInput={handleInput}
          containerData={containerData}
          dropdownData={dropdownData}
          dropdown={dropdown}
          drop={drop}
          input={input}
          inputValues={inputValues}
          fields={fields}
          success={success}
          inputList={inputList}
          showFuelTanker={showFuelTanker}
          handleSubmit={handleSubmit}
          handleCapacityView={handleCapacityView}
          setInputList={setInputList}
          errors={errors}
        />
      ) : null}
      {isShow ? (
        <FuelUnloadingEditModal
          isShow={isShow}
          handleInputss={handleInputss}
          preventNonNumericalInput={preventNonNumericalInput}
          prevNonNumericalWithDec={prevNonNumericalWithDec}
          hide={toggling}
          input1={input1}
          handleRadio={handleRadio}
          handleAddFields={handleAddFields}
          handleRemoveFields={handleRemoveFields}
          handleAfterDensitySubmit={handleAfterDensitySubmit}
          handleTotal={handleTotal}
          handleDate={handleDate}
          comp={comp}
          setComp={setComp}
          handleCha={handleCha}
          EditDecant1={EditDecant1}
          pumpDetails={pumpDetails}
          handle={handle}
          CompartmentReset={CompartmentReset}
          decant_status={decant_status}
          handleIn={handleIn}
          data_list={data_list}
          handleEditRadio={handleEditRadio}
          handleInputChange={handleInputChange}
          handleEditReadings={handleEditReadings}
          density_after_data={density_after_data}
          handleTankerSubmit={handleTankerSubmit}
          handleSelect={handleSelect}
          handleSelect1={handleSelect1}
          toggleReadOnlyDensity={toggleReadOnlyDensity}
          handleSelect2={handleSelect2}
          handleDensity={handleDensity}
          handleDensitybefore={handleDensitybefore}
          Values={Values}
          handleEditReadings1={handleEditReadings1}
          version={version}
          handleEditDeQuantitySubmit1={handleEditDeQuantitySubmit1}
          setValues={setValues}
          handleDecant={handleDecant}
          saveDecantFuel={saveDecantFuel}
          saveDecant={saveDecant}
          product_data={product_data}
          setProduct={setProduct}
          fuel_config={fuel_config}
          SetFuel={SetFuel}
          fuel={fuel}
          toggleReadOnlyDecant={toggleReadOnlyDecant}
          container_number={container_number}
          handleInvoiceSubmitEdit={handleInvoiceSubmitEdit}
          handleInputs={handleInputs}
          handleInput1={handleInput1}
          setDensity={setDensity}
          density_data={density_data}
          handleType={handleType}
          ProducttoggleReadOnly={ProducttoggleReadOnly}
          handleInput={handleInput}
          handleAfterDensity={handleAfterDensity}
          handleDensitySubmitEdit={handleDensitySubmitEdit}
          containerData={containerData}
          dropdownData={dropdownData}
          dropdown={dropdown}
          drop={drop}
          startDate={startDate}
          input={input}
          inputValues={inputValues}
          fields={fields}
          success={success}
          inputList={inputList}
          handleEditDecant={handleEditDecant}
          handleEditCloseReadings={handleEditCloseReadings}
          handleEditCloseReadings1={handleEditCloseReadings1}
          EditDecant={EditDecant}
          printPage={printPage}
          decant={decant}
          showFuelTanker1={showFuelTanker1}
          unload_product={unload_product}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setInputList={setInputList}
          errors={errors}
        />
      ) : null}

      {isClose ? (
        <DeleteModal
          isClose={isClose}
          hide={toggleDeleteModal}
          deleteUnloading={deleteUnloading}
        />
      ) : null}

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal custommodal"
        overlayClassName="myoverlay"
      >
        <div class="modal-content">
          <div class="modal-title modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Fuel Unloading
            </h5>
            <button
              type="button"
              onClick={toggleModal}
              class="btn-close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class=" modal-body  modal-body">
            <p>Do you want to {approvalStatus === 3 ? "Reject" : "Approve"}</p>
            <p style={{fontStyle:"italic",color: "#6610f2",fontSize:"13px"}}>
              After approving fuel unload on a future date, 
              we can't do nozzle closing and nozzle allocation 
              for current and past dates. 
              </p>
          </div>
          <div class="justify-content-end  modal-footer">
            <div class="d-flex">
              <button
                type="button"
                class="btn btn-secondary me-2"
                onClick={toggleModal}
              >
                No
              </button>
              <button type="submit" class="btn btn-primary" onClick={handleYes} disabled={loadings}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FuelUnloadingConfig1;
