import React, { useEffect, useState } from 'react'
import Sidebar_Progress from "../../../layouts/Operational_Sidebar_Progress.component";
import ManagerLoginHeader from '../ManagerLoginHeader.component'
import { useLocation } from 'react-router-dom';
import swal from "sweetalert";
import moment from 'moment';
import Loader from '../../Employee/Loader.component';
import { API_URL } from '../../../../constant/API_Settings';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { convertAmountToWords, preventNonNumericalInput, prevNonNumericalWithDec } from "../../../layouts/Operational_Sidebar_Progress.component";

const token = localStorage.getItem("AUTH_TOKEN")

const OilBarrelProductConfig = (props) => {
	let permissions = localStorage.getItem("PERMISSIONS", "PERMISSIONS")?.split(",");
	const location = useLocation();
	const [inputValues, setInputValues] = useState('');
	const history = useHistory()
	const [double, setDouble] = useState(true);
	const [list_data, setList] = useState([])
	// const [list_data_view, setListview] = useState([])
	// const [errors, setErrors] = useState({})
	const [permission, setPermission] = useState([])
	const [dropdownData, setDropdownData] = useState([])
	const [search, setSearch] = useState('')
	const [loosetotal, setLooseTotal] = useState(0);
	const [startDate, setDate] = useState([])
	const [c_Date, c_setDate] = useState([])
	const [total, setTotal] = useState([])
	const [productNames, setProductNames] = useState([]);
    const[editlooseoil,Seteditlooseoil]=useState(false); 
	const[deletelooseoil,Setdeletelooseoil]=useState(false);
	const [list_oil, setListoil] = useState([]);
	const [list_oil_view, setListoilview] = useState([]);


	const [pages, setpages] = useState(
		{
			current_page: 1,
			last_page: 0,
			total_pages: 0,
			pagination_item: [],
			total_pages: 0,
		})

	const [day_closing_date, setDayclosedate] = useState({ date: location?.state || "" })

	//loader
	const [Loading, setLoading] = useState(false);

	const [productCount, setProductCount] = useState(1);
	const [products, setProducts] = useState([
		{ type: '', productName: '',product_id:"", mrp: '', machines: [], hasMachine: false, fullBarrelQty: '', fullBarrelMrp: '', fullBarrelSubTotal: '', looseBarrelSalesQty: '', looseBarrelMrp: '', looseBarrelSubTotal: '' },
	]);

	const [machineDetails, setMachineDetails] = useState({});

{console.log(inputValues,"machineDetails")}
	const handleAddProduct = () => {
		setProductCount(productCount + 1);
		setProducts([...products, { productName: '',product_id:"", type: '', mrp: '', machines: [], hasMachine: false, fullBarrelQty: '', fullBarrelMrp: '', fullBarrelSubTotal: '', looseBarrelSalesQty: '', looseBarrelMrp: '', looseBarrelSubTotal: '' }]);
	};

	const handleRemoveProduct = (index) => {
		if (products.length === 1) {
			return
		}
		const updatedProducts = products.filter((_, i) => i !== index);
		setProducts(updatedProducts);
	};

	const handleProductChange = (productIndex, key, value) => {
		const updatedProducts = [...products];

		if(key === "fullBarrelQty"){
			updatedProducts[productIndex].fullBarrelSubTotal = parseFloat(value) * parseFloat(updatedProducts[productIndex].fullBarrelMrp)
			if(isNaN(updatedProducts[productIndex].fullBarrelSubTotal)){
				updatedProducts[productIndex].fullBarrelSubTotal = 0
			}
		}
		if(key === "fullBarrelMrp"){ 
			updatedProducts[productIndex].fullBarrelSubTotal = parseFloat(value) * parseFloat(updatedProducts[productIndex].fullBarrelQty)
			if(isNaN(updatedProducts[productIndex].fullBarrelSubTotal)){
				updatedProducts[productIndex].fullBarrelSubTotal = 0
			}
		}
		if(key === "looseBarrelSalesQty"){
			updatedProducts[productIndex].looseBarrelSubTotal = parseFloat(value) * parseFloat(updatedProducts[productIndex].looseBarrelMrp)
			if(isNaN(updatedProducts[productIndex].looseBarrelSubTotal)){
				updatedProducts[productIndex].looseBarrelSubTotal = 0
			}
		}
		if(key === "looseBarrelMrp"){
			updatedProducts[productIndex].looseBarrelSubTotal = parseFloat(value) * parseFloat(updatedProducts[productIndex].looseBarrelSalesQty)
			if(isNaN(updatedProducts[productIndex].looseBarrelSubTotal)){
				updatedProducts[productIndex].looseBarrelSubTotal = 0
			}
		}

		const path = key.split(".");
		if (path[0] === 'machines') {
			const machineIndex = parseInt(path[1], 10);
			if (!updatedProducts[productIndex].machines) {
				updatedProducts[productIndex].machines = [];
			}
			if (!updatedProducts[productIndex].machines[machineIndex]) {
				updatedProducts[productIndex].machines[machineIndex] = {};
			}
			updatedProducts[productIndex].machines[machineIndex][path[2]] = value;

			if (path[2] === 'quantity') {
				const openingReading = parseFloat(updatedProducts[productIndex].machines[machineIndex].openingReading || 0);
				const quantity = parseFloat(value);
				updatedProducts[productIndex].machines[machineIndex].closingReading = openingReading + quantity;
				if (isNaN(updatedProducts[productIndex].machines[machineIndex].closingReading)) {
					updatedProducts[productIndex].machines[machineIndex].closingReading = 0
				}


	 
			}
			

			if (path[2] === 'closingReading') {
				const openingReading = parseFloat(updatedProducts[productIndex].machines[machineIndex].openingReading || 0);
				const closingReading = parseFloat(value);
				updatedProducts[productIndex].machines[machineIndex].quantity = closingReading - openingReading;
				if (isNaN(updatedProducts[productIndex].machines[machineIndex].quantity)) {
					updatedProducts[productIndex].machines[machineIndex].quantity = 0
				}

			}

			// If changing the machine name, set opening reading based on machine details
			if (path[2] === 'machine') {
				const machineDetail = machineDetails[productIndex]?.find(
					(md) => md.name_field === value
				);
				if (machineDetail) {
					updatedProducts[productIndex].machines[machineIndex].openingReading = machineDetail.reading_field || '';
					updatedProducts[productIndex].machines[machineIndex].closingReading = '';
					updatedProducts[productIndex].machines[machineIndex].quantity = '';
					updatedProducts[productIndex].machines[machineIndex].machine_id = machineDetail.id||'';
					{console.log(machineDetail,"ccv")}

				}
			}
		} else {
		
			console.log(value,"val")
			updatedProducts[productIndex][key] = value;
			if (key === "type" && value === "machine" && updatedProducts[productIndex].machines.length === 0) {
				updatedProducts[productIndex].machines.push({
					machine: '',
					openingReading: '',
					machine_id:'',
					closingReading: '',
					quantity: '',
				});
			}
			
		}

	
		


		console.log("Updated products:", updatedProducts[productIndex].machines); // Debug to see updated state
		setProducts(updatedProducts);
		console.log(updatedProducts,"updatedProductsupdatedProducts")

		calculatesubtotal()
	};

	const handleChange = (e) => {
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});
		
	
		if (e.target.name === "sales_type" || e.target.value === "0") {
			setInputValues(prevValues => ({
				...prevValues,
				customer_id: ""
			}));
		}
	
		if (e.target.value === "1") {
			getCustomer();
		}
	
		if (e.target.value === "2") {
			getPayments();
		}
	
		// if (e.target.name === "productType") {
		//     getmrp();
		// }
	};
	const [dropdownDatas, setDropdownDatas] = useState([])
	function getPayments() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/get_digital_payment", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []
				
				
				let newData = [];
				 newData = responseData.map(elem => ({
					value: elem.id,
					label: elem.method_name,
				}));
	
				setDropdownDatas(dropdownDatas => [
					...newData
				]);
	
				// responseData.forEach((elem) => {
				// 	setDropdownDatas((dropdownDatas) => [
				// 	  ...dropdownDatas,
				// 	  {
				// 		value: elem.id,
				// 		label: elem.method_name,
				// 	  },
				// 	]);
				//   });
			})
			.catch(e => {
				console.log(e);
	
			});
	}
	function oil_list() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/looseoil_product_gstsales", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				setListoil(responseData)
				setLoading(true);
			})
			.catch(e => {
				console.log(e);
	
			});
	}
console.log(list_oil,"oiloilss")
// function calculatesubtotal(){
// 		let total_quantity = 0;
// 	     let prod_lop=0;
// 		 let ppp = products;
		

// 		 ppp.map((pro, idx) => {
// 			let prod_lop = pro.machines; // Use let instead of const
// 			console.log(prod_lop, `machinesssssssssssssss`);
// 			console.log(pro.mrp, "ppp");
// 			const machinequqntitytotal = prod_lop;
// 			console.log(machinequqntitytotal, "machinequqntitytotal");
	
		
// 		machinequqntitytotal.forEach((machinequanty, index) => {
// 		  total_quantity += Number(machinequanty.quantity);
// 		  console.log(total_quantity, `total_quantitytotal_quantity  `);
// 		});
		
	
// 		const ans1 = (total_quantity) * pro.mrp;
// 		const ans2 = pro.looseBarrelSalesQty * pro.mrp;
// 		const ans3 = pro.fullBarrelQty * pro.fullBarrelMrp;
// 		let total_looseoilproduct = Number(ans1) + Number(ans2) + Number(ans3);
		
// 		console.log(ans1, "loosetotal1");
// 		console.log(ans2, "loosetotal2");
// 		console.log(ans3, "loosetotal3");
		
// 		setLooseTotal(total_looseoilproduct);

// 	});

// 	}

function calculatesubtotal() {
    let total_looseoilproduct = 0;
	
    products.map((pro, idx) => {
        let prod_lop = pro.machines; 
        console.log(prod_lop, `machinesssssssssssssss`);
        console.log(pro.mrp, "ppp");

        let total_quantity = 0;

        prod_lop.forEach((machinequanty, index) => {
            total_quantity += Number(machinequanty.quantity);
            console.log(total_quantity, `total_quantitytotal_quantity  `);
        });

        const ans1 = total_quantity * pro.mrp;
        const ans2 = pro.looseBarrelSalesQty * pro.mrp;
        const ans3 = pro.fullBarrelQty * pro.fullBarrelMrp;

        let total_looseoilproduct_per_product = Number(ans1) + Number(ans2) + Number(ans3);
		
        console.log(ans1, "loosetotal1");
        console.log(ans2, "loosetotal2");
        console.log(ans3, "loosetotal3");

        total_looseoilproduct += total_looseoilproduct_per_product;
    });

    setLooseTotal(total_looseoilproduct);
}


console.log(loosetotal,"loosetotal5")
	const handleAddMachine = (productIndex) => {
		const updatedProducts = [...products];
		updatedProducts[productIndex].machines.push({ machine: '', openingReading: '',machine_id:"", closingReading: '', quantity: '' });
		setProducts(updatedProducts);
	};
	const handleRemoveMachine = (productIndex) => {
		const updatedProducts = [...products];
		if (updatedProducts[productIndex].machines.length > 0) {
			updatedProducts[productIndex].machines.pop();
			setProducts(updatedProducts);
		}
	};

	useEffect(() => {
		check_url();
		is_day_closed();
		oil_list();

	}, []);


	function check_url() {
		const requestOptions = {
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				pathurl: window.location.pathname,
			}),
		};
		fetch(`${API_URL}/check_url`, requestOptions)
			.then(response => { return response.json(); })

			.then(responseData => {

				if (responseData.allowurl == 1) {
					history.push(responseData.url);
					// ListPackedOil(pages.current_page)
				}
				else {
					history.goBack();
				}

				return responseData;
			})
			.catch(e => {
				console.log(e)
			});

	}

	const handleDate = (e) => {
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeType = (e) => {
		if (e.target.name = "sales_type" || e.target.value == 0) {
			inputValues.customer_id = ""

		}
		if (e.target.value == 1) {
			getCustomer()
		}

		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value
		});


	};

	// const handleChange = (e) => {
	// 	setInputValues({
	// 		...inputValues,
	// 		[e.target.name]: e.target.value
	// 	});
	// };

	const [errors, setErrors] = useState({sales_type:"",
	                                     customer_id:"",
										 productName:"",
										 fullBarrelQty:"",
										 digital_type:"",
										 looseBarrelSalesQty:"",
										 machine:"",
										 closingReading:"",
										 quantity:"",
	                                     

	})
	// function validation(){
    //   let formIsValid = true;
	//   let errors =[]
	  
	//   if ((inputValues.sales_type != 0 && inputValues.sales_type != 1)) {
			
	// 	formIsValid = false;
	// 	errors["sales_type"] = "Sales type is required"
	// } else {
	// 	errors["sales_type"] = ""
	// }
	// if (inputValues.sales_type == 1 && !inputValues.customer_id) {
	// 	formIsValid = false;
	// 	errors["customer_id"] = "Customer Name is required"
	// } else {
	// 	errors["customer_id"] = "";
	// }
	//   if (!products[0].productName ) {
		
	// 	formIsValid = false;
	// 	errors["productName"] = "Select Product";
	// } else {
	// 	errors["productName"] = "";
	// }
	// if(!products[0].fullBarrelQty){
	// 	formIsValid =false;
	// 	errors["fullBarrelQty"]="Please enter Quantity"
	//   }else{
	// 	errors["fullBarrelQty"] = ""
	//   }
	//   if(!products[0].looseBarrelSalesQty){
	// 	formIsValid =false;
	// 	errors["looseBarrelSalesQty"]="Please enter Quantity"
	//   }else{
	// 	errors["looseBarrelSalesQty"] = ""
	//   }
	//   if (Array.isArray(products) && products.length > 0 && Array.isArray(products[0].machines)) {
	//   let machinedetails = products[0].machines
	//     machinedetails.map((machinelist, index) => {
	// 	let machinename = machinelist.machine;
	// 	let closingReading = machinelist.closingReading;
	//     let openingReading = machinelist.openingReading;
	//     let quantity = machinelist.quantity;
		
	// 	if (products[0].hasMachine === true && machinename == '') {
		
	// 	formIsValid = false;
	// 	errors["machine"] = "Please Select Machine";
	//     } else {
	//       errors["machine"] = "";
	//     }

	// 	if (products[0].hasMachine === true && closingReading == '') {
			
	// 		formIsValid = false;
	// 		errors["closingReading"] = "Please enter closingReading";
	// 		} else {
	// 		  errors["closingReading"] = "";
	// 		}
	// 	if (products[0].hasMachine === true && quantity == '') {
			
	// 		formIsValid = false;
	// 		errors["quantity"] = "Please enter quantity";
	// 	} else {
	// 		errors["quantity"] = "";
	// 	}
		
		
	//   });
	// }
	//   setErrors(errors)
	//   return formIsValid;
	  
	// }

	const validation = () => {
        let formIsValid = true;
        let errors = {};

        if ((inputValues.sales_type != 0 && inputValues.sales_type != 1 && inputValues.sales_type != 2)) {
          
			formIsValid = false;
			errors["sales_type"] = "Sales type is required"
		} else {
			errors["sales_type"] = ""
		}

        if (inputValues.sales_type == 1 && !inputValues.customer_id) {
            formIsValid = false;
            errors["customer_id"] = "Customer Name is required";
        } else {
            errors["customer_id"] = "";
        }

		if (inputValues.sales_type == 2 && !inputValues.digital_type) {
			formIsValid = false;
			errors["digital_type"] = "Select Payment mode"
		} else {
			errors["digital_type"] = "";
		}

        products.forEach((product, index) => {
            if (!product.productName) {
                formIsValid = false;
                errors[`productName_${index}`] = "Select Product";
            } else {
                errors[`productName_${index}`] = "";
            }

            if (!product.fullBarrelQty) {
                formIsValid = false;
                errors[`fullBarrelQty_${index}`] = "Please enter Quantity";
            } else {
                errors[`fullBarrelQty_${index}`] = "";
            }

            if (!product.looseBarrelSalesQty) {
                formIsValid = false;
                errors[`looseBarrelSalesQty_${index}`] = "Please enter Quantity";
            } else {
                errors[`looseBarrelSalesQty_${index}`] = "";
            }

            if (Array.isArray(product.machines)) {
                product.machines.forEach((machine, machineIndex) => {
                    let machinename = machine.machine;
                    let closingReading = machine.closingReading;
                    let openingReading = machine.openingReading;
                    let quantity = machine.quantity;

                    if (product.hasMachine === true && machinename == '') {
                        formIsValid = false;
                        errors[`machine_${index}_${machineIndex}`] = "Please Select Machine";
                    } else {
                        errors[`machine_${index}_${machineIndex}`] = "";
                    }

                    // if (product.hasMachine === true && closingReading == '') {
                    //     formIsValid = false;
                    //     errors[`closingReading_${index}_${machineIndex}`] = "Please enter closingReading";
                    // } else if(product.hasMachine === true && closingReading > openingReading){
			
					// 	errors[`closingReading_${index}_${machineIndex}`] = "Closing Reading must greater than or equal to Opening Readings"; 
					// 	formIsValid = false 
						
					// }else {
                    //     errors[`closingReading_${index}_${machineIndex}`] = "";
                    // }

					if (product.hasMachine === true) {
						if (closingReading === '') {
							formIsValid = false;
							errors[`closingReading_${index}_${machineIndex}`] = "Please enter closingReading";
						} else if (closingReading < openingReading) {
							errors[`closingReading_${index}_${machineIndex}`] = "Closing Reading must be greater than or equal to Opening Reading"; 
							formIsValid = false;
						} else {
							errors[`closingReading_${index}_${machineIndex}`] = "";
						}
					}

                    if (product.hasMachine === true && quantity == '' && !product.quantity ==0) {
                        formIsValid = false;
                        errors[`quantity_${index}_${machineIndex}`] = "Please enter quantity";
                    } else {
                        errors[`quantity_${index}_${machineIndex}`] = "";
                    }
                });
            }
        });

        setErrors(errors);
        return formIsValid;
    };

	const preventToFour = (event, currentValue) => {
		const char = String.fromCharCode(event.which);
	  
		// Allow digits, one decimal point, and basic control keys (like backspace, delete, arrows, etc.)
		const allowedSpecialKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
		if (allowedSpecialKeys.includes(event.key)) {
			return; // Allow these keys
		}
	  
		// Only allow digits and decimal points
		if (!/^[0-9.]$/.test(char)) {
			event.preventDefault(); // If not a digit or a decimal point, prevent the action
			return;
		}
	  
		// Split current input into parts: before and after decimal point
		const parts = currentValue.split(".");
	  
		// Allow only one decimal point
		if (char === "." && parts.length > 1) {
			event.preventDefault(); // If there's already a decimal point, prevent a new one
			return;
		}
	  
		// If no decimal point, ensure the first part (before the decimal) doesn't exceed 5 digits
		if (parts.length === 1 && parts[0].length >= 8 && char !== ".") {
			event.preventDefault(); // If trying to add more than 5 digits before the decimal, prevent the action
			return;
		}
	  
		// If there's a decimal point, ensure only 2 digits are allowed after it
		if (parts.length === 2 && parts[1].length >= 2) {
			event.preventDefault(); // If trying to add more than 2 digits after the decimal, prevent the action
			return;
		}
	  };
	

	function submitdata() {
	
		console.log(inputValues,"inpppppppppppp")
		
		const requestOptions = {
			method: 'POST',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				product_data: products,
				// sale_type: "3",
				sales_type: inputValues.sales_type,
				emp_id:'',
				customer_id: inputValues.customer_id,
				note: inputValues.note,
				digital_payment_id:inputValues.digital_type,
				id: inputValues.id ? inputValues.id : "",
				date: document.getElementById("m_op_date").value,
				total: loosetotal,
				
			})
		};
		console.log(requestOptions,"requestOptions")
		fetch(API_URL + "/looseoil_product_gstsales", requestOptions)
			.then(response => {
				return response.json();
			})
			.then(responseData => {
				if (responseData.status == "DENY") {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
				}
				if (responseData.status == 2) {
					swal("", responseData.message, responseData.status ? "warning" : "warning")
				}
				if (responseData.status == 3) {
					swal("", responseData.message, "warning")
				}
				if (responseData.status == 5) {
					swal("", responseData.message)
				}
				if (responseData.status == 4) {
					swal("", responseData.message)
				}
				if (responseData.status == 6) {
					swal("", responseData.message)
				}
				if (responseData.status == 7) {
					swal("", responseData.message)
				}
				if (responseData.status == 10) {
					swal("", responseData.message)
				}
				if (responseData.status == 11) {
					swal("", responseData.message)
				}
				if (responseData.status == true) {
					swal("", responseData.message, responseData.status ? "success" : "warning")
					oil_list();
					toggle()
					// history.push("/oil-barrel-sales")
					
					// ListPackedOil(pages.current_page);
					// setErrors(errors)
					return responseData;
				}
				if (responseData.status == false) {
					swal("", responseData.message.product_data0)
				}
			})
			.catch(e => {
				swal("Someting went wrong", "", "warning")
			});
		
	}

	function getCustomer() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"

			},
		};
		fetch(API_URL + "/get_customers", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				var datas = []

				responseData.forEach(elem => {
					datas.push([elem.id, elem.name])
				})

				setDropdownData(datas);

				return responseData;
			})
			.catch(e => {
				console.log(e);

			});
	}

	function is_day_closed() {
		const requestOptions = {
			method: 'get',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
		};
		fetch(API_URL + "/is_day_closed", requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				c_setDate(responseData)


			})
			.catch(e => {
				console.log(e);

			});
	}

	const getProducts = () => {
		axios({
			url: API_URL + "/get-loose-oil",
			method: "post",
			headers: {
				"X-Id-Token": "abc123abc123",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
			},
		})
			.then((response) => {
				let data = [];
				response?.data?.data?.map((item) => {
					data?.push({
						value: item?.id,
						label: item?.product_name,
					});
				});
				setProductNames(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getMachinesWithName = (productName, productIndex) => {
		const product = productNames.find((p) => p.label === productName);
		if (product && product.value) {
			getMachines(product.value, productIndex);
		} else {
			console.log(`Product with name ${productName} not found.`);
		}
	};

	const getMachines = (productId, productIndex) => {


		const requestOptions = {
			
			method: 'post',
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
				"Accept": "application/json"
			},
			body: JSON.stringify({
				id: productId
			}),
		};
		fetch(`${API_URL}/edit-loose-oil`, requestOptions)
			.then(response => { return response.json(); })
			.then(responseData => {
				const machineData = responseData.loosefields || [];
				const sellingPricePerBarrel = responseData.selling_price_per_barrel;
				const sellingPricePerLitre = responseData.selling_price_per_litre
				setMachineDetails((prev) => ({
					...prev,
					[productIndex]: machineData,
					
					
				}))
				
				console.log([machineData],"machineDetailsmachineDetails")
				// Update the hasMachine property based on machine data
				const updatedProducts = [...products];
				updatedProducts[productIndex].fullBarrelQty = ''
				updatedProducts[productIndex].looseBarrelSalesQty = ''
				updatedProducts[productIndex].fullBarrelSubTotal = ''
				updatedProducts[productIndex].looseBarrelSubTotal = ''
				updatedProducts[productIndex].product_id = productId
				updatedProducts[productIndex].fullBarrelMrp = sellingPricePerBarrel
				updatedProducts[productIndex].looseBarrelMrp = sellingPricePerLitre
				updatedProducts[productIndex].mrp = sellingPricePerLitre
				updatedProducts[productIndex].hasMachine = machineData.length > 0;
				
				setProducts(updatedProducts);
			})
			.catch(e => {
				console.log(e)
			});
	};

	const getAvailableProducts = (index) => {
		const selectedProductNames = products
			.map((product) => product.productName)
			.filter((name) => name)

		return productNames.filter(
			(product) => !selectedProductNames.includes(product.label) || product.label === products[index].productName
		);
	}


	// function ListPackedOil(page) {
	// 	const requestOptions = {      //---employee listing---//
	// 		method: 'get',
	// 		headers: {
	// 			"Access-Control-Allow-Origin": "*",
	// 			"Content-Type": "application/json",
	// 			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
	// 			"Accept": "application/json"

	// 		},

	// 	};
	// 	fetch(`${API_URL}/looseoil_product_sales?&search_keyword=${search}&page=${page}&list_item=nf&day_close_date=${day_closing_date?.date}`, requestOptions)
	// 		.then(response => { return response.json(); })
	// 		.then(responseData => {
	// 			if (responseData.status == 3) {
	// 				setPermission({ ...permission, status: responseData.status, message: responseData.message })
	// 			}
	// 			setList(responseData.data.data)
	// 			setDate(responseData.start_date)
	// 			setTotal({
	// 				...total,
	// 				totalamount: responseData.totalamt,
	// 			})
	// 			setDouble(true)

	// 			setpages({
	// 				...pages,
	// 				current_page: responseData.data.current_page,
	// 				last_page: responseData.data.last_page,
	// 				per_pages: responseData.data.per_page,
	// 				total_pages: responseData.data.total,

	// 			})
	// 			setLoading(true);
	// 			return responseData;
	// 		})
	// 		.catch(e => {
	// 			console.log(e)

	// 		});
	// }



	//---Create popup function---//
	const UseModal = () => {

		const [isShowing, setIsShowing] = useState(false);

		const toggle = () => {
			if (c_Date === 1) {
				swal("", "Your Current Operational Date has already been Day-Closed, Please change Operational Date", false ? "success" : "warning")
	} else {
				getCustomer();
				getProducts();
	
				if (isShowing) {
					setProducts(['']);
					setLooseTotal(['']);
					setErrors({});
					setInputValues('')
					getPayments('');
				} else {
					setDouble(true);
				}
	
				setIsShowing(!isShowing);
			}
		};
	
		return {
			isShowing,
			toggle,
		};

		// function toggle() {

		// 	if (c_Date == 1) {
		// 		swal("", "Your Current Operational Date has already been Day-Closed, Please change Operational Date", false ? "success" : "warning")
		// 	} else {
				
		// 		getCustomer();
		// 		getProducts();
		// 		if (isShowing) {
		// 			const [inputValues, setInputValues] = useState({});
		// 			setProducts([''])
		// 			setLooseTotal([''])
		// 			setErrors({})
		// 			setErrors('')
		// 			getPayments('')
					
					
		// 		}
		// 		if (!isShowing) {
		// 			setDouble(true)
		// 			// setErrors({})

		// 		}
		// 		setIsShowing(!isShowing);
		// 	}
		// }
		// return {
		// 	isShowing,
		// 	toggle,
		// }


		
	};
	const { isShowing, toggle } = UseModal();

	// ---handlesubmit function---//
	const handleSubmit = () => {
		if(validation()){
		submitdata()
		}
	}

	const handleSearch = (event) => {
		setSearch(event.target.value)
	}

	const handlediscount = (e) => {
		setInputValues({
			...inputValues,
			[e.target.name]: e.target.value,
		});
	}


	////edit////
	const handleEditModalOpen = (id) => {
		edit_oil_sales(id)
	Seteditlooseoil(true)
	  }

	const handleEditModalclose = () => {
	Seteditlooseoil(false)
	  }

	  const [delete_id, deleteId] = useState([])
	  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	  

	const handledeletemodalopen=(id)=>{
		Setdeletelooseoil(true)
		deleteId(id)
	}
	const handledeletemodalclose=()=>{
		Setdeletelooseoil(false)
	}

	function tw2t_delete() {
		const requestOptions = {
			method: 'post', 
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
				"Accept": "application/json"
			},
			body: JSON.stringify({
				
				id: delete_id
			}),
		};
	
		fetch(`${API_URL}/twot_delete`, requestOptions)
			.then(response => response.json())
			.then(responseData => {
			  swal("", responseData.message, responseData.status ? "success" : "warning")
			  handledeletemodalclose()
			  oil_list();
			 
				
			})
			.catch(e => {
				console.log(e);
			});
	}

	function edit_oil_sales(id) {
		// alert(id);
		
	  
		const requestOptions = {
		  method: 'get',
		  headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem("AUTH_TOKEN"),
			"Accept": "application/json"
		  },
		};
	  
	  
	  
		fetch(`${API_URL}/looseoil_product_gstsales?id=${id}`, requestOptions) 
		  .then(response => {
			if (!response.ok) {
			  throw new Error(`HTTP error! status: ${response.status}`);
			}
			return response.json();
		  })
		  .then(responseData => {
			setListoilview(responseData);
		  })
		  .catch(error => {
			console.error("There was an error with the fetch operation:", error);
			setLoading(false); 
		  });
	  }
	////




	console.log("oro productintem details...", products);
	console.log("selected machines...", products[0].machines);
	console.log("motham inputValues....", inputValues);
	console.log("ethokke products und....", productNames);
	console.log("Machine Details Aaneee....", machineDetails);


	var today = new Date().toISOString().split('T')[0];
	// const list_oil = list_oil || [];
	return (
		<>
			<div className="main-container page-wrap">
				<div className="d-md-flex">
					<Sidebar_Progress props={props} progress={26} operation={1} />
					<div className="content-area">
						<ManagerLoginHeader props={props} page_title="" />
						<div class="container pb-3 tab-view">
							<div class="row  mt-3 form-container">


								<div className="col-5">
									<div className="row">

									</div>
								</div>
								{permissions.includes("Customer Attendant") ?
									<div className="form-wrap mt-5">
										<div className="text-center">
											You don't have permission to access this page
										</div></div> : permission.status == 3 ?
										<div className="form-wrap mt-5">
											<div className="text-center">
												{permission.message}
											</div></div> :
										<>

											<div>
											{Loading ? oil_list : <Loader />}
												<div class="form-wrap ">

													<div class="tab-content" id="myTabContent">
														<div class="tab-pane  fade show active" id="packedoil" role="tabpanel" aria-labelledby="packedoil-tab">
															<div class="">
																<div class="row mb-3">
																	<div class="col-md">
																		<div class="row">

																			<div class="col-md col pl-0">
																				<h3 class="table-title">Oil Barrel Sales</h3> <h4>{moment(localStorage.getItem("op_date")).format("DD-MM-YYYY")}</h4>
																			</div>

																		</div>
																	</div>
																	<div className="col-md-4">
																		<a className="btn btn-primary px-3 mb-md-0 mb-2 " data-bs-target="#mla" onClick={toggle} >Add Oil Barrel Product</a>
																	</div>
																	<div class="col-md-4">
																		<div class="input-group mb-3 search">
																			<span class="input-group-text" id="basic-addon1"><i class="fa fa-search" aria-hidden="true"></i>
																			</span>
																			<input type="text" className="form-control" placeholder="Search  " value={search.search_keyword} onChange={handleSearch} />
																			<a className="btn btn-primary bt-search btn-sm" data-bs-target="#mla" onClick={check_url} >Submit</a>
																		</div>
																	</div>
																</div>


																<div className="table-widget" style={{ padding: '10px', backgroundColor: '#f8f9fa' }}>
    <div className="table border" style={{ border: '1px solid #dee2e6', borderRadius: '5px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px' }}>
            <thead style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                <tr>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Date</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Sales Type </th>
                    {/* <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Customer</th> */}
                    {/* <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Digital Type</th> */}
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Product Name</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>No of FullBarrel</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>FullBarrel Total</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>LooseBarrel Sales Quantity</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>LooseBarrel Total</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Note</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Total</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Action</th>
                </tr>
            </thead>
			<tbody>
 {(list_oil || []).map((pro, proindex) => {
     console.log('pro:', pro);
     console.log('pro.products:', pro.products); 

     return (
         <React.Fragment key={proindex}>
             {(pro.products || []).map((submeta, submetaindex) => (
                 <tr key={submetaindex}>
                     {submetaindex === 0 && (
                     <td rowSpan={pro.products.length}>
                         {moment(pro.date).format("DD-MM-YYYY")}
                     </td>
                     )}
                     {submetaindex === 0 && (
                     <td rowSpan={pro.products.length}>
                         {pro.customer_id === null 
                             ? (pro.digital_payment_id !== null ? "Digital Payment" : "Cash Sales") 
                             : "Credit Sales"}
                     </td>
                     )}
                     <td>{submeta.product_full_name}</td>
                     <td>{submeta.fullBarrelQty}</td>
                     <td>{submeta.fullBarrelSubTotal}</td>
                     <td>{submeta.looseBarrelSalesQty}</td>
                     <td>{submeta.looseBarrelSubTotal}</td>
                     {submetaindex === 0 && (
                     <td rowSpan={pro.products.length}>
                         {pro.note === null ? "--" : pro.note}
                     </td>
                     )}
                     <td>{pro.total}</td>
                     {submetaindex === 0 && (
                         <td className="py-2" rowSpan={pro.products.length}>
                             <a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit" onClick={() => handleEditModalOpen(pro.id)}>
                                 <i className="fa fa-eye" aria-hidden="true"></i>
                             </a>
                             {pro.status < 4 && (
                                 <a href="#" className="text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete" onClick={() => handledeletemodalopen(pro.id)}>
                                     <i className="fa fa-trash-o me-2" aria-hidden="true"></i>
                                 </a>
                             )}
                             {permissions.includes("Deny Approval") && (
                                 <></>
                             )}
                         </td>
                     )}
                 </tr>
             ))}
         </React.Fragment>
     );
 })}
</tbody>


        </table>
    </div>
</div>


															</div>
														</div>
													</div>
												</div>
											</div></>}
							</div>

						</div>
					</div>
				</div>
			</div>
			{

				isShowing ? (<>
					<div className="modal-overlay" />
					<div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
						<div className="modal  modalxl">
							<div className="modal-body">

								<div className="modal-header">
									<h5 className="modal-title">Add Oil-Barrel-Products</h5>
									<button type="button" className="close" onClick={toggle}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>

								<div className="modal-body">
									<form>
							

<div className="form-group">
											<label>Sales Type:</label><sup class="star">*</sup>
											<select className="form-control" name="sales_type" value={inputValues.sales_type} onChange={handleChange}>
											    <option value="">select</option>
												<option value="0">Cash Sales</option>
												<option value="1">Credit Sales</option>
                                                <option value="2">Digital Payment Sales</option>
											</select>
											<div className="errorMsg">{errors.sales_type}</div>
										</div>
     {inputValues.sales_type == 0 || inputValues.sales_type == 1 ? (
      <div className="form-group">
        <label htmlFor="customerName">Customer Name:</label><sup class="star">*</sup>
      
        <select className="form-control" id="customerName" name="customer_id" value={inputValues.customer_id} onChange={handleChange}>
       

          {inputValues.sales_type == 0 ? <option>Cash</option> : <option value="">Select</option>}
			{inputValues.sales_type == 1 &&

dropdownData.map((data) => {
         return <option value={data[0]}>{data[1]}</option>
    })
												}
        </select>
        <div className="errorMsg">{errors.customer_id}</div>
      </div>
      ) : null}
      {inputValues.sales_type == 2 ? (
      <div className="form-group">
    <label htmlFor="customerName">Digital Payment Type:</label><sup class="star">*</sup>
    <label className="red-symbol">*</label>
    
        <select
            className="form-control"
            id="digitaltype"
            name="digital_type"
            onChange={handleChange}
        >
            <option value="">Select</option>
            {dropdownDatas.map(item => (
                <option key={item.value} value={item.value}>
                    {item.label}
                </option>
            ))}
        </select>
        <div className="errorMsg">{errors.digital_type}</div>
</div>
) : null}

<div className="form-group">
                
                    <label htmlFor="customerName">Description</label>
                 
                    <input type="text" class="form-control " name="note" value={inputValues.note} onChange={handleChange} />
                  </div>

										{/* Products Section */}
										<div id="products">
											{products.map((product, index) => (
												<div className="product-item" key={index} style={{
													border: "1px solid #ddd",
													padding: "20px",
													marginBottom: "20px"
												}}>
													{console.log(products,"productsproducts")}
													{/* Product Header with Remove Button */}
													<div className="form-row d-flex justify-content-between align-items-center">
														<h4 className="mb-0">Product </h4>
														{index + 1}
														{
															index !== 0 && <button
																type="button"
																className="btn btn-danger"
																onClick={() => handleRemoveProduct(index)}
															>
																- Remove Product
															</button>
														}
													</div>

													{/* Product Name */}
													<div className="form-group">
														<label>Product Name:</label><sup class="star">*</sup>

														<select class="form-control"
															value={product.productName}
															// id={product.productId}
															onChange={(e) => {
																handleProductChange(index, 'productName', e.target.value)
																getMachinesWithName(product.productName, index)

															}}
														>
															<option value="">Select product</option>

															{getAvailableProducts(index).map((item) => (
																<option key={item.value} value={item.label}>
																	{item.label}
																</option>
															))}
														</select>
														<div className="errorMsg">{errors[`productName_${index}`]}</div>
													</div>

													<div className="d-flex flex-wrap justify-content-between align-items-center">

														<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
															<label>No of FullBarrel:</label><sup class="star">*</sup>
															<input
															    onKeyPress={(event) => preventToFour(event, event.target.value)}
																value={product.fullBarrelQty} type="number" class="form-control hide-arrow" id="closingReading1" name="closingReading[]"
																onChange={(e) => handleProductChange(index, 'fullBarrelQty', e.target.value)}
															/>
															<div className="errorMsg">{errors[`fullBarrelQty_${index}`]}</div>
														</div>

														<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
															<label>FullBarrel Mrp:</label>
															<input
															   
																value={product.fullBarrelMrp} type="number" readOnly class="form-control hide-arrow" id="closingReading1" name="closingReading[]" 
																onChange={(e) => handleProductChange(index, 'fullBarrelMrp', e.target.value)}
															/>
														</div>

														<div className="form-group" style={{ flex: 1 }}>
															<label>Sub Total:</label>
															<input disabled
																value={product.fullBarrelSubTotal} type="number" class="form-control" id="closingReading1" 
																onChange={(e) => handleProductChange(index, 'fullBarrelSubTotal', e.target.value)}
															/>
														</div>
													</div>


													<div className="d-flex flex-wrap justify-content-between align-items-center">

														<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
															<label>LooseBarrel Sales Qty:</label><sup class="star">*</sup>
															<input
															    onKeyPress={(event) => preventToFour(event, event.target.value)}
																value={product.looseBarrelSalesQty}
																type="number"
																className="form-control hide-arrow"
																onChange={(e) => handleProductChange(index, 'looseBarrelSalesQty', e.target.value)}
																
															/>
															<div className="errorMsg">{errors[`looseBarrelSalesQty_${index}`]}</div>
														</div>
														
														<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
															<label>Mrp :</label>
															<input
																value={product.looseBarrelMrp} type="number" readOnly class="form-control hide-arrow" id="closingReading1" name="closingReading[]" 
																onChange={(e) => handleProductChange(index, 'looseBarrelMrp', e.target.value)}
															/>
														</div>

														<div className="form-group" style={{ flex: 1 }}>
															<label>Sub Total:</label>
															<input disabled
																value={product.looseBarrelSubTotal} type="number" class="form-control hide-arrow" id="closingReading1" name="closingReading[]" 
																onChange={(e) => handleProductChange(index, 'looseBarrelSubTotal', e.target.value)}
															/>
														</div>
													</div>


													<div className="form-group">
														<label>Machine:</label>
														<select
															className="form-control"
															value={product.type}
															onChange={(e) => handleProductChange(index, 'type', e.target.value)}
														>
															<option value="">Select</option>
															<option value="">NO</option>
															{product.hasMachine && <option value="machine">YES</option>}
														</select>
													</div>


													{product.type === 'machine' && (
														<div className="form-group">
															{product.machines.map((machine, machineIndex) => (
																<>
																	<div key={machineIndex} className="d-flex flex-wrap justify-content-between align-items-center">
																		<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
																			<label>Machine Name:</label><sup class="star">*</sup>
																			<select
																				className="form-control"
																				name={`machine[${index}][${machineIndex}]`}
																				value={machine.machine}
																				onChange={(e) =>
																					handleProductChange(index, `machines.${machineIndex}.machine`, e.target.value)
																				}
																			>
																				<option value="">Select Machine</option>
																				
																				{(machineDetails[index] || []).map((machine) => (
																					
																					<option key={machine.id} value={machine.name_field}>
																						{machine.name_field}  
																					</option>
																					
																					
																				)
																				
																				)}
																			</select>
																			<div className="errorMsg">{errors[`machine_${index}_${machineIndex}`]}</div>
																		</div>

																		<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
																			<label>Opening Reading:</label>
																			<input
																				disabled
																				type="text"
																				className="form-control"
																				placeholder="Opening reading"
																				value={machine.openingReading}
																				onChange={(e) =>
																					handleProductChange(index, `machines.${machineIndex}.openingReading`, e.target.value)
																				}
																			/>
																		</div>

																		<div className="form-group" style={{ flex: 1, marginRight: '10px' }}>
																			<label>Closing Reading:</label><sup class="star">*</sup>
																			<input
																			onKeyPress={(event) => preventToFour(event, event.target.value)}
																				type="text"
																				className="form-control"
																				placeholder="Closing reading"
																				value={machine.closingReading}
																				onChange={(e) =>
																					handleProductChange(index, `machines.${machineIndex}.closingReading`, e.target.value)
																				}
																			/>
																			<div className="errorMsg">{errors[`closingReading_${index}_${machineIndex}`]}</div>
																		</div>

																		<div className="form-group" style={{ flex: 1 }}>
																			<label>Quantity:</label><sup class="star">*</sup>
																			<input
																			onKeyPress={(event) => preventToFour(event, event.target.value)}
																				type="text"
																				className="form-control"
																				placeholder="Quantity"
																				value={machine.quantity}
																				onChange={(e) =>
																					handleProductChange(index, `machines.${machineIndex}.quantity`, e.target.value)
																				}
																			/>
																			<div className="errorMsg">{errors[`quantity_${index}_${machineIndex}`]}</div>
																		</div>
																	</div>

																	<div class="form-check form-check-inline">
																		<label class="form-check-label" for="machineCheckbox">Machine not worling?</label>
																		<input class="form-check-input" type="checkbox" id="machineCheckbox" />
																	</div>
																</>

															))}


															<div className="form-group mt-2 d-flex justify-content-between px-2">
																{
																	machineDetails[index] && product.machines.length < machineDetails[index].length && (
																		<button
																			type="button"
																			className="btn btn-warning w-auto"
																			onClick={() => handleAddMachine(index)}
																		>
																			+ Add Machine
																		</button>
																	)
																}

																{product.machines.length > 1 && (
																	<button
																		type="button"
																		className="btn btn-danger w-auto"
																		onClick={() => handleRemoveMachine(index)}
																	>
																		- Remove Machine
																	</button>
																)}
															</div>

														</div>
													)}

													{/* MRP Field */}
													<div  className="form-group">
														<label>MRP per Litre:</label>
														<input
														readOnly
															type="number"
															className="form-control hide-arrow"
															value={product.mrp}
															onChange={(e) => handleProductChange(index, 'mrp', e.target.value)}
														/>
													</div>
												</div>
											))}
										</div>

										{/* Add Product Button */}
										{
											products.length < productNames.length && <button
										
												type="button"
												className="btn btn-success mb-3 mt-3"
												onClick={handleAddProduct}
											>
												+ Add Product
											</button>
										}

										< div hidden className="form-group" >
											<label>SubTotal:</label>
											<input
												type="text"
												className="form-control hide-arrow"
												name="subtotal"
												value={inputValues.subtotal}
												onChange={handleChange}
											/>
										</div>

										<div className="form-group">
											<label>Operational Date:</label>
											<input
												disabled
												type="date"
												className="form-control"
												name="op_date" id={"m_op_date"} value={inputValues.op_date == undefined ? (localStorage.getItem("m_op_date") < startDate ? (startDate > today ? " " : startDate) : localStorage.getItem("m_op_date")) : inputValues.op_date}
												min={startDate}
												max={today}
												onChange={handleDate}
											/>
										</div>
										<div hidden className="form-group">
											<label>Discount:</label>
											<input
												type="text"
												className="form-control"
												name="discount"
												id="discount"
												value={inputValues.discount}
												onChange={handlediscount}
												onKeyPress={(event) => preventNonNumericalInput(event, event.target.value)}
											/>
										</div>
										<div className="form-group">
											<label>Total:</label>
											<input
											readOnly
												type="number"
												className="form-control hide-arrow"
												name="text"
												value={loosetotal}
												// onChange={handleChange}
											/>
										</div>
										<div  hidden className="form-group">
											<label>Invoice Amount:</label>
											<input
												type="text"
												className="form-control"
												name="amt_aft_discound"
												value={inputValues.amt_aft_discound}
												onChange={handleChange}
											/>
										</div>
										<div hidden className="form-group">
											<label>RoundOff</label>
											<input
												type="text"
												className="form-control"
												name="roundoff"
												value={inputValues.roundoff}
												onChange={handleChange}
											/>
										</div>


									</form>
								</div>

								<div class="modal-footer text-center">
									<button class="btn btn-danger w-auto" onClick={toggle}>Cancel</button>
									<button class="btn btn-primary  w-auto " type="submit" onClick={handleSubmit}>Submit</button>
								</div>

							</div>
						</div>
					</div>
				</>) : null
			}

		{/* // */}
		{

editlooseoil ? (<>
	
	<React.Fragment>

    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)' }}>
  <div className="modal modal-dialog-xl" style={{ maxWidth: '90vw', width: '90vw', maxHeight: '80vh', height: 'auto', overflow: 'auto' }}>
    <div className="modal-header mb-3">
      <h5 className="text-center" id="staticBackdropLabel">Loose oil Sale</h5>
      <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={handleEditModalclose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div className="modal-body" style={{ overflow: 'auto' }}>
	<div className="table-widget" style={{ padding: '10px', backgroundColor: '#f8f9fa' }}>
    <div className="table border" style={{ border: '1px solid #dee2e6', borderRadius: '5px' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '14px' }}>
            <thead style={{ backgroundColor: '#343a40', color: '#ffffff' }}>
                <tr>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Date</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Sales Type </th>
                    {/* <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Customer</th> */}
                    {/* <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Digital Type</th> */}
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Product Name</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>No of FullBarrel</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>FullBarrel Total</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>LooseBarrel Sales Quantity</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>LooseBarrel Total</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Note</th>
                    <th style={{ padding: '8px', border: '1px solid #dee2e6' }}>Total</th>
                </tr>
            </thead>
            <tbody>
    {list_oil_view.map((pro, proindex) => {
        console.log('pro:', pro); // Log the entire pro object
        console.log('pro.products:', pro.products); // Log the products array of the current pro object

        return (
            <React.Fragment key={proindex}>
                {pro.products.map((submeta, submetaindex) => (
              
					<tr key={submetaindex}>
						{submetaindex === 0 && (
						<td rowSpan={pro.products.length}>
							{moment(pro.date).format("DD-MM-YYYY")}
						</td>
					)}
					{submetaindex === 0 && (
						<td rowSpan={pro.products.length}>
							{pro.customer_id === null 
								? (pro.digital_payment_id !== null ? "Digital Payment" : "Cash Sales") 
								: "Credit Sales"}
						</td>
					)}
					<td>{submeta.product_full_name}</td>
					<td>{submeta.fullBarrelQty}</td>
					<td>{submeta.fullBarrelSubTotal}</td>
					<td>{submeta.looseBarrelSalesQty}</td>
					<td>{submeta.looseBarrelSubTotal}</td>
					{submetaindex === 0 && (
						<td rowSpan={pro.products.length}>
							{pro.note === null ? "--" : pro.note}
						</td>
					)}
					
				<td>{pro.total}</td>
			
				</tr>
                ))}
            </React.Fragment>
        );
    })}
</tbody>

        </table>
    </div>
</div>
    </div>
  </div>
</div>





  </React.Fragment>
</>) : null
}

		{/*  */}

	

{deletelooseoil ? (
			    <>
				<div className="modal-overlay" />
				<div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
					<div className="modal">
						<div className="modal-header">
							<h3 className="modal-title" id="staticBackdropLabel">Do you want to delete this product?</h3>
							<button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={handledeletemodalclose}>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					   
							<div class="text-center py-3">
								  <a class="btn btn-danger w-auto" onClick={handledeletemodalclose}>Cancel</a>
								  <a class="btn btn-primary w-auto" onClick={tw2t_delete}>Delete</a>
								</div> 
					 
					</div>
				</div>
			</>
		   ) : null}
		   

		</>
	)
}

export default OilBarrelProductConfig